<template>
  <div style="margin-top: -77px;">
    <!-- <h2 class="page-title-bar"><i class="ico ico-system"></i>{{$t('Report Config')}}</h2> -->

    <v-row>
      <v-col>
        <!-- <h3 class="tit-group mt-10">{{$t('Report Setting')}}</h3> -->
         <h3 class="tit-group-bg mt-10" style="color: rgba(0, 0, 0, 0.6);line-height: 1;">{{$t('Report Setting')}}</h3>
         <!-- <h4 class="tit-section-sub" style="background: #E2E2E2 !important;">{{$t('Report Setting')}}</h4> -->
        <!-- data table -->
        <table class="tbl-custom-type01 max-100">
          <colgroup>
            <col style="width:25%">
            <col style="width:25%">
            <col style="width:25%">
            <col>
          </colgroup>
          <thead>
            <tr>
              <th>{{$t('Range')}}</th>
              <th>{{$t('On/Off')}}</th>
              <th>{{$t('Cycle')}}</th>
              <th>{{$t('Reported Time')}}<span>({{$t('Account Time Zone')}})</span></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{$t(range)}}</td>
              <td>
                <v-switch inset hide-details solo
                  class="form-switch"
                  v-model="use"
                ></v-switch>
              </td>
              <td>
                <v-select
                  dense hide-details solo
                  v-model="cycle"
                  :items="cycleItems"
                  ref="cycle"
                  width="50"
                  class="form-select"
                  :rules="[rules.required]"
                  @keydown="clearTabindex"
                ></v-select>
              </td>
              <td>
                <v-select
                  dense hide-details solo
                  v-model="reportedTime"
                  ref="reportedTime"
                  :items="getReportedTimeItems"
                  class="form-select"
                  width="50"
                  @keydown="clearTabindex"
                >
                <template v-slot:item="{item }">
         <span style="font-size: 0.8125rem;">{{item }} </span>
        </template>
        <template v-slot:selection="{item }">
         <span class="LedPatternClass" style="display: contents !important;">{{ item }}</span>
        </template>

                </v-select>

              </td>
              <td>:</td>
              <td>
                <v-select
                  dense hide-details solo
                  v-model="reportMinute"
                  ref="reportMinute"
                  :items="getReportedTimeItemsMint"
                  item-text="txt"
                  item-value="val"
                  class="form-select"
                  @keydown="clearTabindex"
                >
                </v-select>

              </td>
            </tr>
          </tbody>
        </table>

        <v-btn :disabled="buttonDisabled" text class="btn-s mt-5" @click="setReport()">{{$t('Save')}}</v-btn>
        <v-btn :disabled="buttonDisabled" text class="btn-s mt-5 ml-2" @click="toSendnow()">{{$t('Send now')}}</v-btn>

      </v-col>
    </v-row>
    <v-row class="mt-15">
      <v-col class="pb-0">
        <!-- <h3 class="tit-group">{{$t('Mail Setting')}}</h3> -->
         <h3 class="tit-group-bg" style="color: rgba(0, 0, 0, 0.6);line-height: 1;">{{$t('Mail Setting')}}</h3>

         <!-- <h4 class="tit-section-sub" style="background: #E2E2E2 !important;">{{$t('Mail Setting')}}</h4> -->

        <div class="halpBox">
          <v-row>
            <v-col>
            <p class="label-txt mt-4">{{$t('Email Host')}}</p>
              <v-text-field outlined dense hide-details solo
                v-model="host"
                ref="host"
                :placeholder="$t('Mail Server Host Address')"
                class="form-input"
               :rules="[rules.required]"
                clearable
                @keydown="clearTabindex"
              ></v-text-field>
            </v-col>
            <v-col>
            <p class="label-txt mt-4">{{$t('Email Port')}}</p>
              <v-text-field outlined dense hide-details solo
                v-model="port"
                ref="port"
                type="number"
                @keyup.prevent="handleKeyUp"
                :placeholder="$t('Mail Server Port Number')"
                class="form-input"
               :rules="[rules.required]"
                clearable
                @keydown="clearTabindex"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
            <p class="label-txt mt-4">{{$t('Email Address')}}</p>
              <v-text-field
                v-model="userName"
                ref="userName"
                :rules="[rules.email]"
                :placeholder="$t('Mail Address')"
                class="form-input"
                clearable
                @keydown="clearTabindex"
                outlined dense hide-details solo
              ></v-text-field>
            </v-col>
            <v-col>
            <p class="label-txt mt-4">{{$t('Email Password')}}</p>
              <v-text-field outlined dense hide-details solo
                v-model="password"
                ref="password"
                :placeholder="$t('Mail Password')"
                type="password"
                class="form-input"
               :rules="[rules.required]"
                clearable
                @keydown="clearTabindex"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
            <p class="label-txt mt-4">{{$t('Email Encoding')}}</p>
              <v-text-field outlined dense hide-details solo
                v-model="encoding"
                ref="encoding"
                :placeholder="$t('UTF-8')"
               :rules="[rules.required]"
                class="form-input"
                clearable
                @keydown="clearTabindex"
              ></v-text-field>
            </v-col>
            <v-col>
            <p class="label-txt mt-4">{{$t('Email Auth')}}</p>
              <v-select
                outlined dense hide-details solo
                v-model="auth"
                ref="auth"
                :placeholder="$t('Email Auth')"
               :rules="[rules.required]"
                :items="['SSL', 'TLS']"
                class="form-select"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <br>
    <v-btn  :disabled="serverDisabled" text class="btn-s mt-5" @click="setServer()">{{$t('Save')}}</v-btn>
        <v-btn :disabled="serverDisabled"  text class="btn-s mt-5 ml-2" @click="FieldValidation()">{{$t('Check')}}</v-btn>

  </div>
</template>

<script>
import EventBus from '@/plugins/eventBus.js'

export default {
  name: 'ReportConfig',
  data () {
    const getReportedTimeItemsMint = [
      { txt: '0', val: 0 },
      { txt: '10', val: 10 },
      { txt: '20', val: 20 },
      { txt: '30', val: 30 },
      { txt: '40', val: 40 },
      { txt: '50', val: 50 }
    ]
    return {
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      getReportedTimeItemsMint,
      dialog: false,
      buttonDisabled: false,
      serverDisabled: false,
      reportName: '',
      range: '',
      use: false,
      cycle: '',
      cycleItems: ['Daily'], // ['Monthly', 'Weekly', 'Daily']
      reportedTime: '',
      reportMinute: '',
      host: '',
      port: '',
      auth: '',
      userName: '',
      password: '',
      encoding: '',
      requests: {
        getReport: {
          method: 'get',
          url: '/api/common/config/report'
        },
        setReport: {
          method: 'post',
          url: '/api/common/config/report'
        },
        getServer: {
          method: 'get',
          url: '/api/common/config/emailsetting'
        },
        setServer: {
          method: 'put',
          url: '/api/common/config/emailsetting'
        },
        checkServer: {
          method: 'put',
          url: '/api/common/config/emailTest'
        },
        sendNow: {
          method: 'post',
          url: '/api/common/report/report/send'
        }
      },
      rules: {
        email: value => {
          if (value === undefined) value = ''
          const pattern = /^$|^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
          return (
            pattern.test(value) || 'Not a valid Email'
          )
        },
        required: value => !!value || 'Required.'

      }

    }
  },
  computed: {
    getReportedTimeItems () {
      const items = []
      for (let i = 0; i < 24; i++) {
        items.push(i)
      }
      return items
    }

  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },

    handleKeyUp (e) {
      // const notEngExp = /[^A-Za-z]/g
      const koreanExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g
      // if (notEngExp.test(e.key)) { e.target.value = e.target.value.replace(koreanExp, '') }
      e.target.value = e.target.value.replace(koreanExp, '')
    },
    getReport () {
      const data = {}
      const params = {}
      // params.company = this.$store.state.auth.user.company
      if (this.productionType === 'cloud') {
        params.company = this.$store.state.auth.user.company
      }

      const config = {
        data: data,
        params: params
      }
      this.$utils
        .callAxios(
          this.requests.getReport.method,
          this.requests.getReport.url,
          config
        )
        .then((res) => {
          const result = res.data

          if (result.responseCode === '200') {
            this.reportName = result.reportList[0].reportName
            this.range = result.reportList[0].range
            this.cycle = result.reportList[0].cycle
            this.reportedTime = result.reportList[0].reportedTime
            this.reportMinute = result.reportList[0].reportMinute
            this.use = result.reportList[0].use === 'ON'
          }
        })
    },
    setReport () {
      if (this.cycle === '' || this.cycle === null || this.cycle === undefined) {
        this.$refs.cycle.focus()
        EventBus.$emit('messageAlert', this.$t('Input the data'))
        return
      }
      if (this.reportedTime === '' || this.reportedTime === null || this.reportedTime === undefined) {
        this.$refs.reportedTime.focus()
        EventBus.$emit('messageAlert', this.$t('Input the data'))
        return
      }
      if (this.reportMinute === '' || this.reportMinute === null || this.reportMinute === undefined) {
        this.$refs.reportMinute.focus()
        EventBus.$emit('messageAlert', this.$t('Input the data'))
        return
      }

      const data = {}
      const params = {}
      params.company = this.$store.state.auth.user.company
      data.reportList = [{
        range: this.range,
        use: this.use ? 'ON' : 'OFF',
        cycle: this.cycle,
        reportedTime: this.reportedTime,
        reportMinute: parseInt(this.reportMinute)
      }]

      const config = {
        params: params
      }
      this.$utils
        .callAxiosWithBody(
          this.requests.setReport.method,
          this.requests.setReport.url,
          data,
          config
        )
        .then((res) => {
          const result = res.data
          if (result.responseCode === '200') {
            EventBus.$emit('messageAlert', this.$t('Saved successfully'))
            //   this.initServer() // 초기화 안하도록 요청됨
          } else {
            EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          }
        })
    },

    // Send Now
    toSendnow () {
      const config = { params: {} }
      this.$utils
        .callAxiosWithBody(
          this.requests.sendNow.method,
          this.requests.sendNow.url,
          config
        )
        .then((res) => {
          const result = res.data
          console.log(result)
          if (result.responseCode === '200') {
            EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          } else {
            EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          }
        })
        .catch(error => {
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage)
          )
        })
    },
    getServer () {
      const data = {}
      const params = {}
      params.company = this.$store.state.auth.user.company
      const config = {
        data: data,
        params: params
      }
      this.$utils
        .callAxios(
          this.requests.getServer.method,
          this.requests.getServer.url,
          config
        )
        .then((res) => {
          const result = res.data

          if (result.responseCode === '200') {
            this.host = result.host
            this.port = result.port
            this.auth = result.auth
            this.userName = result.userName
            this.password = result.password
            this.encoding = result.encoding
          }
        })
    },
    FieldValidation () {
      if (this.host === '' || this.host === null) {
        this.$refs.host.focus()
        EventBus.$emit('messageAlert', this.$t('Input the email host'))
        return
      }
      if (this.port === '' || this.port === null) {
        this.$refs.port.focus()
        EventBus.$emit('messageAlert', this.$t('Input the email port'))
        return
      }
      if (this.userName === '' || this.userName === null) {
        this.$refs.userName.focus()
        EventBus.$emit('messageAlert', this.$t('Input the mail address'))
        return
      }
      if (this.password === '' || this.password === null) {
        this.$refs.password.focus()
        EventBus.$emit('messageAlert', this.$t('Input the password'))
        return
      }
      if (this.encoding === '' || this.encoding === null) {
        this.$refs.encoding.focus()
        EventBus.$emit('messageAlert', this.$t('Input the email encoding'))
        return
      }
      if (this.auth === '' || this.auth === null) {
        this.$refs.auth.focus()
        EventBus.$emit('messageAlert', this.$t('Input the email auth'))
      }
      const data = {}
      // const params = {}
      // params.company = this.$store.state.auth.user.company
      data.host = this.host
      data.port = this.port
      data.userName = this.userName
      data.password = this.password
      data.encoding = this.encoding
      data.auth = this.auth
      // const config = {
      //   params: params
      // }
      if (data) {
        this.$utils
          .callAxiosWithBody(
            this.requests.checkServer.method,
            this.requests.checkServer.url,
            data

          )
          .then((res) => {
            const result = res.data
            console.log(result)
            if (result.responseCode === '200') {
              EventBus.$emit('messageAlert', this.$t(result.responseMessage))
            // this.initServer()
            } else {
              EventBus.$emit('messageAlert', this.$t(result.responseMessage))
            }
          })
          .catch(error => {
            EventBus.$emit(
              'messageAlert',
              this.$t(error.response.data.responseMessage)
            )
          })
      } else {

      }
    },
    setServer () {
      if (this.host === '' || this.host === null) {
        this.$refs.host.focus()
        EventBus.$emit('messageAlert', this.$t('Input the data'))
        return
      }
      if (this.port === '' || this.port === null) {
        this.$refs.port.focus()
        EventBus.$emit('messageAlert', this.$t('Input the data'))
        return
      }
      if (this.userName === '' || this.userName === null) {
        this.$refs.userName.focus()
        EventBus.$emit('messageAlert', this.$t('Input the data'))
        return
      }
      if (this.password === '' || this.password === null) {
        this.$refs.password.focus()
        EventBus.$emit('messageAlert', this.$t('Input the data'))
        return
      }
      if (this.encoding === '' || this.encoding === null) {
        this.$refs.encoding.focus()
        EventBus.$emit('messageAlert', this.$t('Input the data'))
        return
      }
      if (this.auth === '' || this.auth === null) {
        this.$refs.auth.focus()
        EventBus.$emit('messageAlert', this.$t('Input the data'))
        return
      }
      const data = {}
      const params = {}
      params.company = this.$store.state.auth.user.company
      data.host = this.host
      data.port = this.port
      data.userName = this.userName
      data.password = this.password
      data.encoding = this.encoding
      data.auth = this.auth
      const config = {
        params: params
      }
      if (data) {
        this.$utils
          .callAxiosWithBody(
            this.requests.setServer.method,
            this.requests.setServer.url,
            data,
            config
          )
          .then((res) => {
            const result = res.data
            if (result.responseCode === '200') {
              EventBus.$emit('messageAlert', this.$t('Saved successfully'))
            // this.initServer()
            } else {
              EventBus.$emit('messageAlert', this.$t(result.responseMessage))
            }
          })
          .catch(error => {
            EventBus.$emit(
              'messageAlert',
              this.$t(error.response.data.responseMessage)
            )
          })
      } else {

      }
    },
    initServer () {
      this.host = ''
      this.port = ''
      this.userName = ''
      this.password = ''
      this.encoding = ''
      this.auth = ''
    }
  },
  mounted () {
    // 상단 공통 팝업 & 자동완성기능 사용유무
    EventBus.$emit('enableSelectedStores', false)

    //  Report Setting : Save
    this.$store.dispatch('auth/getDisabledBtn', '9100').then(flag => {
      this.buttonDisabled = flag
    })

    // //  Mail Setting : Save
    this.$store.dispatch('auth/getDisabledBtn', '9100').then(flag => {
      this.serverDisabled = flag
    })

    this.getReport()

    this.getServer()
  }
}
</script>

<style scoped>
  .settingBox{padding:50px; border:1px solid #b8b8b8;}
  .LedPatternClass + input{
  display: contents !important;
}
</style>
