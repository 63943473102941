var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("img", {
          staticStyle: { "margin-right": "15px", height: "31px" },
          attrs: { src: require("@/assets/img/bell-ring.png") }
        }),
        _vm._v(_vm._s(_vm.$t("Alert Settings")) + " ")
      ]),
      _c("div", { staticClass: "row align-items-center" }, [
        _c("div", { staticClass: "col-8 d-flex" }, [
          _c("div", { staticClass: "searchArea w-100" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("v-text-field", {
                  staticClass: "form-input col",
                  attrs: {
                    label: _vm.$t("Email ID"),
                    placeholder: _vm.$t("Input Email ID"),
                    outlined: "",
                    dense: "",
                    "hide-details": "",
                    clearable: ""
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.getAlarmSubscriptionList(1)
                    }
                  },
                  model: {
                    value: _vm.search.email,
                    callback: function($$v) {
                      _vm.$set(_vm.search, "email", $$v)
                    },
                    expression: "search.email"
                  }
                }),
                _c("v-text-field", {
                  staticClass: "form-input ml-3 col",
                  attrs: {
                    label: _vm.$t("User Name"),
                    placeholder: _vm.$t("Input UserName"),
                    outlined: "",
                    dense: "",
                    "hide-details": "",
                    clearable: ""
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.getAlarmSubscriptionList(1)
                    }
                  },
                  model: {
                    value: _vm.search.userName,
                    callback: function($$v) {
                      _vm.$set(_vm.search, "userName", $$v)
                    },
                    expression: "search.userName"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "mt-6" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn type-search ml-4",
                        attrs: { text: "" },
                        on: {
                          click: function($event) {
                            return _vm.paging(1)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Search")) + " ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn ml-3 type-search",
                        attrs: { text: "" },
                        on: { click: _vm.clearSearch }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Clear")) + " ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-end align-items-center",
          staticStyle: {
            "margin-top": "-50px",
            float: "right",
            "margin-right": "8px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("span", { staticClass: "mr-2" }, [
                _c("b", [_vm._v(_vm._s(_vm.$t("Temperature Unit")))])
              ]),
              _c("Toggle", {
                staticClass: "ml-2",
                attrs: { toggleState: _vm.toggleState },
                on: {
                  valueChanged: function($event) {
                    return _vm.valueChanged($event)
                  }
                }
              })
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "mt-10" }, [
        _c("div", { staticStyle: { width: "100%", display: "flex" } }, [
          _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _c("div", { staticClass: "sub-header" }, [
                _vm._v(" " + _vm._s(_vm.$t("Alert Subscription List")) + " ")
              ]),
              _c(
                "v-data-table",
                {
                  staticClass: "tbl-type01 mt-5 row-class",
                  attrs: {
                    headers: _vm.tableHeaders,
                    items: _vm.tableItems,
                    "item-key": "serialNo",
                    "hide-default-footer": true,
                    options: _vm.options,
                    "items-per-page": _vm.rowPerPage,
                    "server-items-length": _vm.tableItems.length,
                    "show-select": false,
                    "fixed-header": "",
                    "must-sort": ""
                  },
                  on: {
                    "update:options": function($event) {
                      _vm.options = $event
                    },
                    "click:row": function($event) {
                      return _vm.addNotiPopup($event, "EDIT")
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.email",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " + _vm._s(item.email ? item.email : "-") + " "
                            )
                          ]
                        }
                      },
                      {
                        key: "item.userName",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(item.userName ? item.userName : "-") +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "item.emailNoti",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(item.emailNoti ? "TRUE" : "FALSE") +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "item.action",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("div", [
                              _c(
                                "p",
                                {
                                  staticClass: "d-inline-block pointer pr-3",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.deleteItem(item)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "red--text darken-2 pointer",
                                      staticStyle: { color: "#001e38" },
                                      attrs: { size: "24" }
                                    },
                                    [_vm._v("mdi-trash-can-outline ")]
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _c(
                    "template",
                    { slot: "no-data" },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "ma-0",
                          staticStyle: { "line-height": "19px" },
                          attrs: { dense: "", color: "#697A8A" }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { color: "#fff" } },
                            [_vm._v("mdi-alert-outline")]
                          ),
                          _vm._v(
                            " " + _vm._s(_vm.$t("No data available")) + " "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "table-options" }, [
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: {
                    text: "",
                    icon: "",
                    disabled: _vm.btnActionAlertSettings
                  },
                  on: {
                    click: function($event) {
                      return _vm.addNotiPopup($event, "ADD")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Add")))]
              )
            ],
            1
          ),
          _c("div", [
            _c("div", { staticClass: "pageInfo" }, [
              _vm._v(_vm._s(_vm.pageInfoText))
            ]),
            _c(
              "div",
              { staticClass: "pagination2" },
              [
                _c(
                  "label",
                  {
                    staticClass: "pageInfo mr-1",
                    staticStyle: { position: "relative !important" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Show")) + " ")]
                ),
                _c("v-select", {
                  staticClass: "form-input pt-0 col rowPerPage",
                  attrs: {
                    items: _vm.rowPerPageList,
                    placeholder: _vm.$t("Limit"),
                    dense: "",
                    "persistent-hint": "",
                    outlined: "",
                    "hide-details": ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.paging(1)
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s("" + item) +
                              _vm._s(_vm.$t("Rows")) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "selection",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", { staticClass: "selection-v-select" }, [
                            _vm._v(
                              " " + _vm._s("" + item) + _vm._s(_vm.$t("Rows"))
                            )
                          ])
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.rowPerPage,
                    callback: function($$v) {
                      _vm.rowPerPage = $$v
                    },
                    expression: "rowPerPage"
                  }
                }),
                _c(
                  "div",
                  {},
                  [
                    _c("v-text-field", {
                      staticClass: "form-input pt-0 col goToPage ml-1",
                      attrs: {
                        placeholder: "Page",
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        solo: "",
                        "append-icon": "mdi-chevron-right"
                      },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.goToPage(
                            _vm.goToPageInput,
                            _vm.paging,
                            _vm.totalPages
                          )
                        },
                        keypress: function($event) {
                          return _vm.checkIsNumber($event)
                        },
                        "click:append": function($event) {
                          return _vm.goToPage(
                            _vm.goToPageInput,
                            _vm.paging,
                            _vm.totalPages
                          )
                        }
                      },
                      model: {
                        value: _vm.goToPageInput,
                        callback: function($$v) {
                          _vm.goToPageInput = $$v
                        },
                        expression: "goToPageInput"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "pagination-nav pagination-nav-prev",
                    class: _vm.page < 10 ? "pagination-nav-disabled" : null,
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.goTenPageBackwards(_vm.page, _vm.paging)
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets//img/ico-paging-prev.png"),
                        alt: "이전 10페이지 이동"
                      }
                    })
                  ]
                ),
                _c("v-pagination", {
                  staticStyle: { display: "block" },
                  attrs: {
                    length: _vm.totalPages * 1 || 1,
                    "total-visible": 8,
                    color: "#2f3b4c"
                  },
                  on: { input: _vm.paging },
                  model: {
                    value: _vm.pageIndex,
                    callback: function($$v) {
                      _vm.pageIndex = $$v
                    },
                    expression: "pageIndex"
                  }
                }),
                _c(
                  "button",
                  {
                    staticClass: "pagination-nav pagination-nav-next",
                    class:
                      _vm.pageIndex + 10 > _vm.totalPages
                        ? "pagination-nav-disabled"
                        : null,
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.goTenPageForward(
                          _vm.pageIndex,
                          _vm.paging,
                          _vm.totalPages
                        )
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets//img/ico-paging-next.png"),
                        alt: "다음 10페이지 이동"
                      }
                    })
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm.deleteConfirmation
        ? _c("DeleteConfirmation", {
            attrs: { dialogConfirm: _vm.deleteConfirmation },
            on: {
              confirmAction: function($event) {
                return _vm.deleteAction($event)
              }
            }
          })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "700" },
          model: {
            value: _vm.addNotiPopupModel,
            callback: function($$v) {
              _vm.addNotiPopupModel = $$v
            },
            expression: "addNotiPopupModel"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _vm.notiEdit === false
                ? _c("h3", { staticClass: "page-title-bar" }, [
                    _c("i", { staticClass: "ico ico-template" }),
                    _vm._v(_vm._s(_vm.$t("Add Email Notifications")))
                  ])
                : _vm._e(),
              _vm.notiEdit === true
                ? _c("h3", { staticClass: "page-title-bar" }, [
                    _c("i", { staticClass: "ico ico-template" }),
                    _vm._v(_vm._s(_vm.$t("Update Email Notifications")))
                  ])
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        staticClass: "form-input",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: _vm.$t("User Name"),
                          placeholder: "" + _vm.$t("Enter UserName"),
                          clearable: ""
                        },
                        model: {
                          value: _vm.username,
                          callback: function($$v) {
                            _vm.username = $$v
                          },
                          expression: "username"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        staticClass: "form-input",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          label: _vm.$t("Email ID"),
                          rules: [_vm.rules.email],
                          placeholder: "" + _vm.$t("Enter Email ID"),
                          clearable: ""
                        },
                        model: {
                          value: _vm.emailID,
                          callback: function($$v) {
                            _vm.emailID = $$v
                          },
                          expression: "emailID"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        staticClass: "form-select",
                        attrs: {
                          label: _vm.$t("Email Notification"),
                          placeholder: "" + _vm.$t("Select"),
                          items: [
                            { text: "TRUE", value: true },
                            { text: "FALSE", value: false }
                          ],
                          "item-text": "text",
                          "item-value": "value",
                          clearable: "",
                          outlined: "",
                          dense: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.EmailNofi,
                          callback: function($$v) {
                            _vm.EmailNofi = $$v
                          },
                          expression: "EmailNofi"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _vm.notiEdit === false
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn",
                          attrs: {
                            disabled:
                              _vm.username === null || _vm.emailID === null,
                            text: "",
                            icon: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.toSaveNewNotification()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Save")))]
                      )
                    : _vm._e(),
                  _vm.notiEdit === true
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn",
                          attrs: {
                            disabled:
                              _vm.username === null || _vm.emailID === null,
                            text: "",
                            icon: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.toSaveEditNotification()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Update")))]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.addNotiPopupModel = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }