import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import VueSidebarMenu from '@/components/sidebar-menu'

import axios from 'axios'
import i18n from './i18n'
import JsonExcel from 'vue-json-excel'
import Utils from '@/plugins/utils'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import VueSimpleAlert from 'vue-simple-alert'
import VCalendar from 'v-calendar'
import VueHtml2Canvas from 'vue-html2canvas'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import * as ChartModuleMore from 'highcharts/highcharts-more'
import borderRadius from 'highcharts-border-radius'
import HighchartsCustomEvents from 'highcharts-custom-events'
import Drilldown from 'highcharts/modules/drilldown.js'
import Stock from 'highcharts/modules/stock.js'

import 'regenerator-runtime/runtime'

/* axios 공통 헤더 설정 */
axios.defaults.headers.get['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'

require('@/assets/css/vue-sidebar-menu.css')
require('@/assets/css/layout.css')
require('@mdi/font/css/materialdesignicons.css')
require('roboto-fontface/css/roboto/roboto-fontface.css')
require('vue-sidebar-menu/src/scss/vue-sidebar-menu.scss')
require('vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css')

Vue.component('downloadExcel', JsonExcel)

Vue.prototype.$axios = axios
Vue.config.productionTip = false

Vue.use(VueSidebarMenu)
Vue.use(Utils)
Vue.use(VueSimpleAlert)
Vue.use(PerfectScrollbar)
Vue.use(VCalendar, {
  componentPrefix: 'vc'
})
Vue.use(VueHtml2Canvas)
Vue.use(HighchartsVue)
Drilldown(Highcharts)
HighchartsCustomEvents(Highcharts)
borderRadius(Highcharts)
ChartModuleMore(Highcharts)
Stock(Highcharts)
Vue.use(Highcharts)

Vue.filter('currency', function (value) {
  // number comma 표현
  if (!value) return 0
  return Number(value)
    .toFixed(0)
    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
})

export default new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
