import { render, staticRenderFns } from "./SidebarMenu.vue?vue&type=template&id=e2322ff2&scoped=true&"
import script from "./SidebarMenu.vue?vue&type=script&lang=js&"
export * from "./SidebarMenu.vue?vue&type=script&lang=js&"
import style0 from "./SidebarMenu.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./SidebarMenu.vue?vue&type=style&index=1&id=e2322ff2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2322ff2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VApp,VCard,VTab,VTabItem,VTabs})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/aims-dashboard/aims-dashboard/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e2322ff2')) {
      api.createRecord('e2322ff2', component.options)
    } else {
      api.reload('e2322ff2', component.options)
    }
    module.hot.accept("./SidebarMenu.vue?vue&type=template&id=e2322ff2&scoped=true&", function () {
      api.rerender('e2322ff2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SidebarMenu/SidebarMenu.vue"
export default component.exports