var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "d-flex justify-space-between" }, [
        _c("h2", { staticClass: "page-title-bar" }, [
          _c("i", { staticClass: "ico ico-overview" }),
          _vm._v(_vm._s(_vm.$t("Overview")) + " ")
        ])
      ]),
      _c(
        "v-row",
        {},
        [
          _c(
            "v-col",
            {
              staticClass: "py-0 px-0 mt-8",
              attrs: { sm: "12", md: "12", lg: "12", xl: "12" }
            },
            [
              _c(
                "v-row",
                { staticClass: "ma-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex",
                      staticStyle: { padding: "0px !important" },
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0 d-flex",
                          attrs: { cols: "6" }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pr-3", attrs: { cols: "12" } },
                            [
                              _c("div", { staticClass: "heading w-100" }, [
                                _c(
                                  "h2",
                                  {
                                    staticClass: "page-title-bar",
                                    staticStyle: {
                                      "font-weight": "normal",
                                      "font-size": "20px",
                                      color: "#001E38"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("Device Status")) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "v-card",
                                {
                                  staticClass: "summary-card d-flex",
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { "elevation-2": "", outlined: "" }
                                },
                                [
                                  !_vm.deviceLoading
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "col-12 px-0 py-0 d-flex"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-6 px-0 py-0 row mx-0"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "heading w-100"
                                                },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass: "title-head"
                                                    },
                                                    [_vm._v("Connectivity")]
                                                  )
                                                ]
                                              ),
                                              _vm.connectivity.length !== 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-100 d-flex justify-center"
                                                    },
                                                    [
                                                      _c("GradientDonutChart", {
                                                        attrs: {
                                                          chartSeries:
                                                            _vm.connectivity,
                                                          chartLabels:
                                                            _vm.connectivityLabels,
                                                          chartType: "donut",
                                                          height: 250,
                                                          chartPlotOptions:
                                                            _vm.donutChartPlotOptions,
                                                          colorOption:
                                                            _vm.color,
                                                          dataLabelsOption:
                                                            _vm.optionEnable,
                                                          chartLegendOption:
                                                            _vm.legendPosBottom,
                                                          tooltip: _vm.tooltip
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.connectivity.length !== 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-100 d-flex justify-center"
                                                    },
                                                    _vm._l(
                                                      _vm.connectivityLabels,
                                                      function(label, idx) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: idx,
                                                            staticClass: "mr-3"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-baseline"
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "square-icon mr-2",
                                                                  style: {
                                                                    backgroundColor:
                                                                      _vm.color[
                                                                        idx
                                                                      ]
                                                                  }
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "name"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        label
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "name",
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "18px"
                                                                }
                                                              },
                                                              [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .connectivity[
                                                                        idx
                                                                      ]
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-100 mx-5 mt-5",
                                                      staticStyle: {
                                                        height: "100%"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-alert",
                                                        {
                                                          staticClass:
                                                            "ma-0 d-flex justify-center",
                                                          staticStyle: {
                                                            "line-height":
                                                              "19px",
                                                            color: "#fff"
                                                          },
                                                          attrs: {
                                                            dense: "",
                                                            color: "#697A8A"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-1",
                                                              attrs: {
                                                                color: "#fff"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-alert-outline"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "No data available"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-6 px-0 py-0 row mx-0"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "heading w-100"
                                                },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass: "title-head"
                                                    },
                                                    [_vm._v("Battery")]
                                                  )
                                                ]
                                              ),
                                              _vm.battery.length !== 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-100 d-flex justify-center"
                                                    },
                                                    [
                                                      _c("GradientDonutChart", {
                                                        attrs: {
                                                          chartSeries:
                                                            _vm.battery,
                                                          chartLabels:
                                                            _vm.batteryLabels,
                                                          chartType: "donut",
                                                          height: 250,
                                                          chartPlotOptions:
                                                            _vm.donutChartPlotOptions,
                                                          colorOption:
                                                            _vm.batteryColor,
                                                          dataLabelsOption:
                                                            _vm.optionEnable,
                                                          chartLegendOption:
                                                            _vm.legendPosBottom,
                                                          tooltip: _vm.tooltip
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.battery.length !== 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-100 d-flex justify-center"
                                                    },
                                                    _vm._l(
                                                      _vm.batteryLabels,
                                                      function(label, idx) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: idx,
                                                            staticClass: "mr-3"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-baseline"
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "square-icon mr-2",
                                                                  style: {
                                                                    backgroundColor:
                                                                      _vm
                                                                        .batteryColor[
                                                                        idx
                                                                      ]
                                                                  }
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "name"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        label
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "name",
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "18px"
                                                                }
                                                              },
                                                              [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .battery[
                                                                        idx
                                                                      ]
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-100 mx-5 mt-5",
                                                      staticStyle: {
                                                        height: "100%"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-alert",
                                                        {
                                                          staticClass:
                                                            "ma-0 d-flex justify-center",
                                                          staticStyle: {
                                                            "line-height":
                                                              "19px",
                                                            color: "#fff"
                                                          },
                                                          attrs: {
                                                            dense: "",
                                                            color: "#697A8A"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-1",
                                                              attrs: {
                                                                color: "#fff"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-alert-outline"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "No data available"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                            ]
                                          )
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "wrapper-loading w-100"
                                        },
                                        [_c("div", { staticClass: "loader" })]
                                      )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "px-0 py-0 d-flex",
                          attrs: { cols: "6" }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pr-3", attrs: { cols: "12" } },
                            [
                              _c("div", { staticClass: "heading w-100" }, [
                                _c(
                                  "h2",
                                  {
                                    staticClass: "page-title-bar",
                                    staticStyle: {
                                      "font-weight": "normal",
                                      "font-size": "20px",
                                      color: "#001E38"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(_vm.$t("Alert")) + " ")]
                                )
                              ]),
                              _c(
                                "v-card",
                                {
                                  staticClass: "summary-card d-flex",
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { "elevation-2": "", outlined: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-12 px-0 py-0 row mx-0"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "heading w-100 d-flex mx-3 mt-3"
                                        },
                                        [
                                          _c(
                                            "h3",
                                            {
                                              staticClass:
                                                "title-head pr-2 pt-0",
                                              class:
                                                _vm.alertActive === "Active"
                                                  ? "active"
                                                  : "",
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleAlertBox(
                                                    "Active"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" Active ")]
                                          ),
                                          _c(
                                            "h3",
                                            {
                                              staticClass:
                                                "title-head pr-3 pt-0"
                                            },
                                            [_vm._v("|")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "w-100 d-flex justify-space-between"
                                            },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "title-head pl-2 pt-0",
                                                  class:
                                                    _vm.alertActive ===
                                                    "History"
                                                      ? "active"
                                                      : "",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.handleAlertBox(
                                                        "History"
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" History ")]
                                              ),
                                              _vm.alertActive === "History"
                                                ? _c("v-select", {
                                                    staticClass:
                                                      "form-select col-3 pt-0",
                                                    attrs: {
                                                      label: "",
                                                      placeholder: _vm.$t(
                                                        "Select Duration"
                                                      ),
                                                      outlined: "",
                                                      dense: "",
                                                      "hide-details": "",
                                                      items: _vm.durationList,
                                                      "item-text": "text",
                                                      "item-value": "val"
                                                    },
                                                    on: {
                                                      change: _vm.updateDuration
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedDuration,
                                                      callback: function($$v) {
                                                        _vm.selectedDuration = $$v
                                                      },
                                                      expression:
                                                        "selectedDuration"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _vm.alertActive === "Active" &&
                                      !_vm.alertLoading
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "w-100 d-flex",
                                              staticStyle: {
                                                height: "100%",
                                                "margin-top": "6px"
                                              }
                                            },
                                            [
                                              _vm.activeAlert.length !== 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-9 d-flex justify-center"
                                                    },
                                                    [
                                                      _c("GradientDonutChart", {
                                                        attrs: {
                                                          chartSeries:
                                                            _vm.activeAlert,
                                                          chartLabels:
                                                            _vm.activeAlertLabels,
                                                          chartType: "donut",
                                                          height: 250,
                                                          chartPlotOptions:
                                                            _vm.donutChartPlotOptions,
                                                          colorOption:
                                                            _vm.activeAlertColor,
                                                          dataLabelsOption:
                                                            _vm.optionEnable,
                                                          chartLegendOption:
                                                            _vm.legendPosBottom,
                                                          tooltip: _vm.tooltip
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.activeAlert.length !== 0
                                                ? _c(
                                                    "div",
                                                    { staticClass: "col-3" },
                                                    _vm._l(
                                                      _vm.activeAlertLabels,
                                                      function(label, idx) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: idx,
                                                            staticClass: "mt-3",
                                                            staticStyle: {
                                                              cursor: "pointer"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openAlertDialog(
                                                                  idx,
                                                                  "donut"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-baseline"
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "square-icon mr-2",
                                                                  style: {
                                                                    backgroundColor:
                                                                      _vm
                                                                        .activeAlertColor[
                                                                        idx
                                                                      ]
                                                                  }
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "name"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        label
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "name",
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "18px"
                                                                }
                                                              },
                                                              [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .activeAlert[
                                                                        idx
                                                                      ]
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass: "w-100 mx-5"
                                                    },
                                                    [
                                                      _c(
                                                        "v-alert",
                                                        {
                                                          staticClass:
                                                            "ma-0 d-flex justify-center",
                                                          staticStyle: {
                                                            "line-height":
                                                              "19px",
                                                            color: "#fff"
                                                          },
                                                          attrs: {
                                                            dense: "",
                                                            color: "#697A8A"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-1",
                                                              attrs: {
                                                                color: "#fff"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-alert-outline"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "No data available"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                            ]
                                          )
                                        : _vm.alertActive === "History" &&
                                          !_vm.alertLoading
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "w-100",
                                              style:
                                                _vm.categories.length === 0
                                                  ? "height: 100%;"
                                                  : ""
                                            },
                                            [
                                              _vm.categories.length !== 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex w-100"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            width:
                                                              "calc(100% - 135px)"
                                                          }
                                                        },
                                                        [
                                                          _c("StackChart", {
                                                            attrs: {
                                                              data:
                                                                _vm.stackChartData,
                                                              categories:
                                                                _vm.categories,
                                                              xTitle: "",
                                                              noData:
                                                                _vm.noData,
                                                              colors:
                                                                _vm.stackColor,
                                                              height: 320
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "px-0 d-flex align-center"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                width: "125px"
                                                              }
                                                            },
                                                            _vm._l(
                                                              _vm.stackChartData,
                                                              function(
                                                                label,
                                                                idx
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: idx,
                                                                    staticClass:
                                                                      "mt-6 ml-3",
                                                                    staticStyle: {
                                                                      cursor:
                                                                        "pointer"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.openAlertDialog(
                                                                          idx,
                                                                          "stack"
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex align-baseline"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "square-icon mr-2",
                                                                            style: {
                                                                              backgroundColor:
                                                                                _vm
                                                                                  .stackColor[
                                                                                  idx
                                                                                ]
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "name"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                label.name
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    ),
                                                                    label.data
                                                                      .lenght !==
                                                                    0
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "name",
                                                                            staticStyle: {
                                                                              "margin-left":
                                                                                "18px"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    label.data.reduce(
                                                                                      function(
                                                                                        accumulator,
                                                                                        currentValue
                                                                                      ) {
                                                                                        return (
                                                                                          accumulator +
                                                                                          currentValue
                                                                                        )
                                                                                      },
                                                                                      0
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-100 px-5 pt-5",
                                                      staticStyle: {
                                                        height: "100%"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-alert",
                                                        {
                                                          staticClass:
                                                            "ma-0 d-flex justify-center",
                                                          staticStyle: {
                                                            "line-height":
                                                              "19px",
                                                            color: "#fff"
                                                          },
                                                          attrs: {
                                                            dense: "",
                                                            color: "#697A8A"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-1",
                                                              attrs: {
                                                                color: "#fff"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-alert-outline"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "No data available"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "wrapper-loading w-100",
                                              staticStyle: { height: "87%" }
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "loader"
                                              })
                                            ]
                                          )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 pb-0" }, [
          _c("div", { staticClass: "searchArea col" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "d-flex col-12 align-baseline px-0 py-0" },
                [
                  _c("v-text-field", {
                    staticClass: "form-select col",
                    attrs: {
                      label: _vm.$t("Sensor Name"),
                      outlined: "",
                      "hide-details": "",
                      dense: "",
                      placeholder: _vm.$t("Input Sensor Name"),
                      clearable: ""
                    },
                    model: {
                      value: _vm.searchBy.sensorName,
                      callback: function($$v) {
                        _vm.$set(_vm.searchBy, "sensorName", $$v)
                      },
                      expression: "searchBy.sensorName"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "form-select col ml-3",
                    attrs: {
                      label: _vm.$t("Sensor ID"),
                      outlined: "",
                      "hide-details": "",
                      dense: "",
                      placeholder: _vm.$t("Input Sensor ID"),
                      clearable: ""
                    },
                    model: {
                      value: _vm.searchBy.sensorId,
                      callback: function($$v) {
                        _vm.$set(_vm.searchBy, "sensorId", $$v)
                      },
                      expression: "searchBy.sensorId"
                    }
                  }),
                  _c("v-select", {
                    staticClass: "form-select col ml-3",
                    attrs: {
                      label: _vm.$t("Sensor Status"),
                      placeholder: _vm.$t("Select Sensor Status"),
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      items: _vm.sensorStatusList,
                      "item-text": "text",
                      "item-value": "val",
                      clearable: ""
                    },
                    model: {
                      value: _vm.searchBy.sensorStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.searchBy, "sensorStatus", $$v)
                      },
                      expression: "searchBy.sensorStatus"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "form-select col ml-3",
                    attrs: {
                      label: _vm.$t("Location"),
                      outlined: "",
                      "hide-details": "",
                      dense: "",
                      placeholder: _vm.$t("Input Location"),
                      clearable: ""
                    },
                    model: {
                      value: _vm.searchBy.location,
                      callback: function($$v) {
                        _vm.$set(_vm.searchBy, "location", $$v)
                      },
                      expression: "searchBy.location"
                    }
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn type-search ml-3",
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          return _vm.clickSearch()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Search")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn type-search ml-3",
                      attrs: { text: "" },
                      on: { click: _vm.clearSearch }
                    },
                    [_vm._v(_vm._s(_vm.$t("Clear")) + " ")]
                  )
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "mt-1" },
        [
          _c(
            "v-data-table",
            {
              staticClass: "tbl-type01 mt-5 row-class",
              attrs: {
                headers: _vm.tableHeaders,
                items: _vm.tableItems,
                "item-key": "serialNo",
                "hide-default-footer": true,
                options: _vm.options,
                "server-items-length": _vm.tableItems.length,
                "show-select": true,
                "fixed-header": "",
                "single-select": "",
                height:
                  _vm.tableItems && _vm.tableItems.length > 8 ? "550px" : "auto"
              },
              on: {
                "update:options": function($event) {
                  _vm.options = $event
                },
                "click:row": function($event) {
                  return _vm.openSensorDetails($event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item.status",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.status !== "-"
                          ? _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c("p", {
                                  staticClass: "circle",
                                  style:
                                    item.status.toLowerCase() ===
                                    "offline".toLowerCase()
                                      ? "background-color: #e27070"
                                      : "background-color: #9bcde3"
                                })
                              ]
                            )
                          : _c("div", [_vm._v(_vm._s(item.status))])
                      ]
                    }
                  },
                  {
                    key: "item.action",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", { staticClass: "d-flex" }, [
                          _c(
                            "p",
                            {
                              staticClass: "d-inline-block pointer mr-2",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.editDialogOpen(item)
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: " darken-2",
                                  staticStyle: { color: "#001e38" },
                                  attrs: { size: "24" }
                                },
                                [_vm._v("mdi-square-edit-outline")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "d-inline-block pointer",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteItem(item)
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "red--text darken-2",
                                  attrs: { size: "24" }
                                },
                                [_vm._v("mdi-trash-can-outline")]
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    }
                  }
                ],
                null,
                true
              ),
              model: {
                value: _vm.SelectedsensorValue,
                callback: function($$v) {
                  _vm.SelectedsensorValue = $$v
                },
                expression: "SelectedsensorValue"
              }
            },
            [
              _c("template", { slot: "loading" }, [
                _c("div", { staticClass: "wrapper-loading" }, [
                  _c("div", { staticClass: "loader" })
                ])
              ]),
              _c(
                "template",
                { slot: "no-data" },
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "ma-0",
                      staticStyle: { "line-height": "19px" },
                      attrs: { dense: "", color: "#697A8A" }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { color: "#fff" } },
                        [_vm._v("mdi-alert-outline")]
                      ),
                      _vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          ),
          _c("div", { staticClass: "table-options" }, [
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "btn",
                    attrs: { disabled: _vm.btnAction, text: "" },
                    on: { click: _vm.addSensor }
                  },
                  [_vm._v(_vm._s(_vm.$t("Add Sensor")))]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "btn ml-3",
                    attrs: {
                      disabled:
                        _vm.SelectedsensorValue.length === 0 || _vm.btnAction,
                      text: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.unassignSensor()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("Unassign")))]
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "col-12 pr-0",
              staticStyle: { padding: "0px", margin: "auto" }
            },
            [
              _c("Pagination", {
                attrs: {
                  pageInfoText: _vm.pageInfoText,
                  pageIndex: _vm.pageIndex,
                  rowPerPage: _vm.rowPerPage,
                  goToPageInput: _vm.goToPageInput,
                  totalPages: _vm.totalPages
                },
                on: {
                  paging: function($event) {
                    return _vm.paging($event)
                  },
                  updatePage: function($event) {
                    return _vm.updatePage($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.deleteConfirmation
        ? _c("DeleteConfirmation", {
            attrs: { dialogConfirm: _vm.deleteConfirmation },
            on: {
              confirmAction: function($event) {
                return _vm.deleteAction($event)
              }
            }
          })
        : _vm._e(),
      _vm.addSensorDialog
        ? _c("AddSensor", {
            attrs: { addDialogPopup: _vm.addSensorDialog },
            on: {
              closeAddModal: function($event) {
                _vm.addSensorDialog = false
              }
            }
          })
        : _vm._e(),
      _vm.sensorDetailsDialog
        ? _c("SensorDetails", {
            attrs: {
              addDialogPopup: _vm.sensorDetailsDialog,
              sensorDetails: _vm.sensorDetails
            },
            on: {
              closeAddModal: _vm.closeSensorDetailsDialog,
              openEditDialog: function($event) {
                return _vm.editDialogOpen($event)
              }
            }
          })
        : _vm._e(),
      _vm.alertDetailsDialog
        ? _c("AlertDetails", {
            attrs: {
              alertDialogPopup: _vm.alertDetailsDialog,
              alertDetails: _vm.alertDetails
            },
            on: { closeAlertModal: _vm.closeAlertDetailsDialog }
          })
        : _vm._e(),
      _vm.editSensorDialog
        ? _c("EditSensor", {
            attrs: {
              addDialogPopup: _vm.editSensorDialog,
              editList: _vm.editData
            },
            on: { closeEditModal: _vm.closeEditSensorDialog }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }