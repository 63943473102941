var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "1000", persistent: "" },
      model: {
        value: _vm.notiDialogPopup,
        callback: function($$v) {
          _vm.notiDialogPopup = $$v
        },
        expression: "notiDialogPopup"
      }
    },
    [
      _c("v-card", { staticClass: "popup add_store_popup" }, [
        _c(
          "div",
          [
            _c(
              "v-btn",
              {
                staticClass: "cornerClose",
                attrs: { icon: "", dark: "", "x-small": "" },
                on: { click: _vm.closeModal }
              },
              [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("mdi-close")])],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "popup-titbox d-flex align-center justify-space-between"
              },
              [
                _c("h3", { staticClass: "page-title-bar align-start" }, [
                  _c("div", [
                    _c("img", {
                      staticStyle: { "margin-right": "15px", height: "31px" },
                      attrs: { src: require("@/assets/img/bell-ring.png") }
                    }),
                    _vm._v(_vm._s(_vm.$t("Notification Received By")) + " ")
                  ])
                ]),
                _c("div", { staticClass: "d-grid mt-2" }, [
                  _c("p", { staticClass: "info-text" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$t("Sensor ID")) + " :")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.alertIdData.sensorCode
                            ? _vm.alertIdData.sensorCode
                            : "-"
                        ) +
                        " "
                    )
                  ]),
                  _c("p", { staticClass: "info-text" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$t("Sensor Name")) + " :")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.alertIdData.sensorName
                            ? _vm.alertIdData.sensorName
                            : "-"
                        ) +
                        " "
                    )
                  ])
                ])
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "fixedHeader",
            staticStyle: { "max-height": "calc(100vh - 330px) !important" }
          },
          [
            _c(
              "div",
              { staticStyle: { width: "100%" } },
              [
                _c(
                  "v-data-table",
                  {
                    staticClass: "tbl-type01 mt-5 row-class",
                    attrs: {
                      headers: _vm.tableHeaders,
                      items: _vm.tableItems,
                      "item-key": "serialNo",
                      "hide-default-footer": true,
                      options: _vm.options,
                      "items-per-page": _vm.rowPerPage,
                      "server-items-length": _vm.tableItems.length,
                      "show-select": false,
                      "must-sort": "",
                      height:
                        _vm.tableItems && _vm.tableItems.length > 8
                          ? "370px"
                          : "auto",
                      "fixed-header": ""
                    },
                    on: {
                      "update:options": function($event) {
                        _vm.options = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.status",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", [
                                _c(
                                  "p",
                                  {
                                    style:
                                      item.status === true
                                        ? "color:#21D600"
                                        : "color:#EF726B"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.status === true
                                            ? "Success"
                                            : "Failed"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.email",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(item.email ? item.email : "-") +
                                  " "
                              )
                            ]
                          }
                        },
                        {
                          key: "item.isClear",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("span", {
                                class: item.isClear
                                  ? "green-circle"
                                  : "red-circle"
                              })
                            ]
                          }
                        },
                        {
                          key: "item.insertTime",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.insertTime ? item.insertTime : "-"
                                  ) +
                                  " "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c(
                      "template",
                      { slot: "no-data" },
                      [
                        _c(
                          "v-alert",
                          {
                            staticClass: "ma-0",
                            staticStyle: { "line-height": "19px" },
                            attrs: { dense: "", color: "#697A8A" }
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-1", attrs: { color: "#fff" } },
                              [_vm._v("mdi-alert-outline")]
                            ),
                            _vm._v(
                              " " + _vm._s(_vm.$t("No data available")) + " "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          ]
        ),
        _c("div", { staticClass: "table-options" }, [
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeModal()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Close")))]
              )
            ],
            1
          ),
          _c("div", [
            _c("div", { staticClass: "pageInfo" }, [
              _vm._v(_vm._s(_vm.pageInfoText))
            ]),
            _c(
              "div",
              { staticClass: "pagination2" },
              [
                _c(
                  "label",
                  {
                    staticClass: "pageInfo mr-1",
                    staticStyle: { position: "relative !important" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("Show")) + " ")]
                ),
                _c("v-select", {
                  staticClass: "form-input pt-0 col rowPerPage",
                  attrs: {
                    items: _vm.rowPerPageList,
                    placeholder: _vm.$t("Limit"),
                    dense: "",
                    "persistent-hint": "",
                    outlined: "",
                    "hide-details": ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.paging(1)
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s("" + item) +
                              _vm._s(_vm.$t("Rows")) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "selection",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c("span", { staticClass: "selection-v-select" }, [
                            _vm._v(
                              " " + _vm._s("" + item) + _vm._s(_vm.$t("Rows"))
                            )
                          ])
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.rowPerPage,
                    callback: function($$v) {
                      _vm.rowPerPage = $$v
                    },
                    expression: "rowPerPage"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "mr-2" },
                  [
                    _c("v-text-field", {
                      staticClass: "form-input pt-0 col goToPage ml-1",
                      attrs: {
                        placeholder: "Page",
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        solo: "",
                        "append-icon": "mdi-chevron-right"
                      },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.goToPage(
                            _vm.goToPageInput,
                            _vm.paging,
                            _vm.totalPages
                          )
                        },
                        keypress: function($event) {
                          return _vm.checkIsNumber($event)
                        },
                        "click:append": function($event) {
                          return _vm.goToPage(
                            _vm.goToPageInput,
                            _vm.paging,
                            _vm.totalPages
                          )
                        }
                      },
                      model: {
                        value: _vm.goToPageInput,
                        callback: function($$v) {
                          _vm.goToPageInput = $$v
                        },
                        expression: "goToPageInput"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "pagination-nav pagination-nav-prev",
                    class: _vm.page < 10 ? "pagination-nav-disabled" : null,
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.goTenPageBackwards(_vm.page, _vm.paging)
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets//img/ico-paging-prev.png"),
                        alt: "이전 10페이지 이동"
                      }
                    })
                  ]
                ),
                _c("v-pagination", {
                  staticStyle: { display: "block" },
                  attrs: {
                    length: _vm.totalPages * 1 || 1,
                    "total-visible": 8,
                    color: "#2f3b4c"
                  },
                  on: { input: _vm.paging },
                  model: {
                    value: _vm.page,
                    callback: function($$v) {
                      _vm.page = $$v
                    },
                    expression: "page"
                  }
                }),
                _c(
                  "button",
                  {
                    staticClass: "pagination-nav pagination-nav-next",
                    class:
                      _vm.page + 10 > _vm.totalPages
                        ? "pagination-nav-disabled"
                        : null,
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.goTenPageForward(
                          _vm.page,
                          _vm.paging,
                          _vm.totalPages
                        )
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets//img/ico-paging-next.png"),
                        alt: "다음 10페이지 이동"
                      }
                    })
                  ]
                )
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }