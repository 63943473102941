<template>
    <v-dialog v-model="alertDialogPopup" width="1000" persistent>
      <v-card class="popup add_store_popup">
        <div>
          <div class="popup-titbox d-flex align-center justify-space-between">
            <h3 class="page-title-bar align-start">
              <div>
                <img
                  src="@/assets/img/thermometer-lines.png"
                  alt="temp"
                  style="margin-right: 15px; height: 31px;"
                  v-if="alertDetails.name === 'High Temperature' || alertDetails.name ==='Low Temperature'"
                />
                <img
                  src="@/assets/img/water-percent.png"
                  alt="temp"
                  style="margin-right: 5px; height: 31px;"
                  v-else
                />{{ $t(alertDetails.name) }}
              </div>
            </h3>
            <div class="d-grid mt-2">
              <p class="info-text" style="font-size: 26px;">
                <b>{{ $t("Total") }} :</b> <span class=""
                :style="alertDetails.name === 'High Temperature' || alertDetails.name === 'High Humidity' ? 'color:#FF8097' :'color:#6DA3F8'">{{alertDetails.value}}</span>
              </p>
            </div>
          </div>
        </div>
        <div
          class="fixedHeader"
          style="max-height: calc(100vh - 330px) !important;"
        >
          <div style="width: 100%;">
            <v-data-table
              :headers="tableHeaders"
              :items="tableItems"
              item-key="serialNo"
              :hide-default-footer="true"
              :options.sync="options"
              :items-per-page="rowPerPage"
              :server-items-length="tableItems.length"
              :show-select="false"
              class="tbl-type01 mt-5 row-class"
              fixed-header
            >
              <template slot="no-data">
                <v-alert
                  dense
                  color="#697A8A"
                  class="ma-0"
                  style="line-height: 19px"
                >
                  <v-icon class="mr-1" color="#fff">mdi-alert-outline</v-icon>
                  {{ $t("No data available") }}
                </v-alert>
              </template>
              <template v-slot:[`item.value`]="{ item }">
                <div
                v-if="alertDetails.name === 'High Temperature' || alertDetails.name === 'Low Temperature'"
                :style="alertDetails.name === 'High Temperature' || alertDetails.name === 'High Humidity' ? 'color:#FF8097' :'color:#6DA3F8'"
              >
                <p v-if="toggleState">{{ item.value }} <span>°</span>C</p>
                <p v-else>
                  {{ ((item.value * 9) / 5 + 32).toFixed(2) }} <span>°</span>F
                </p>
              </div>
              <div
              v-else
                :style="alertDetails.name === 'High Temperature' || alertDetails.name === 'High Humidity' ? 'color:#FF8097' :'color:#6DA3F8'"
              >
                <p>{{ item.value }} %</p>
              </div>
              </template>
            </v-data-table>
          </div>
        </div>
        <div class="table-options">
          <div>
            <v-btn text icon @click="closeModal()" class="btn">{{
              $t("Close")
            }}</v-btn>
          </div>
          <div>
            <div class="pageInfo">{{ pageInfoText }}</div>
            <div class="pagination2">
              <label class="pageInfo mr-1" style="position:relative !important;">
                {{ $t("Show") }}
              </label>
              <v-select
                v-model="rowPerPage"
                :items="rowPerPageList"
                :placeholder="$t('Limit')"
                class="form-input pt-0 col rowPerPage"
                dense
                persistent-hint
                outlined
                hide-details
                @change="paging(1)"
              >
                <template v-slot:item="{ item }">
                  {{ `${item}` }}
                </template>
                <template v-slot:selection="{ item }">
                  <span class="selection-v-select"> {{ `${item}` }}{{$t('Rows')}}</span>
                </template>
              </v-select>

              <div class="mr-2">
                <v-text-field
                  v-model="goToPageInput"
                  class="form-input pt-0 col goToPage ml-1"
                  placeholder="Page"
                  outlined
                  dense
                  hide-details
                  solo
                  @keyup.enter="goToPage(goToPageInput, paging, totalPages)"
                  append-icon="mdi-chevron-right"
                  @keypress="checkIsNumber($event)"
                  @click:append="goToPage(goToPageInput, paging, totalPages)"
                ></v-text-field>
              </div>
              <button
                @click="goTenPageBackwards(page, paging)"
                :class="page < 10 ? 'pagination-nav-disabled' : null"
                class="pagination-nav pagination-nav-prev"
                type="button"
              >
                <img
                  src="@/assets//img/ico-paging-prev.png"
                  alt="이전 10페이지 이동"
                />
              </button>
              <v-pagination
                v-model="pageIndex"
                @input="paging"
                :length="totalPages * 1 || 1"
                :total-visible="8"
                color="#2f3b4c"
                style="display: block"
              ></v-pagination>
              <button
                @click="goTenPageForward(pageIndex, paging, totalPages)"
                :class="
                  pageIndex + 10 > totalPages ? 'pagination-nav-disabled' : null
                "
                class="pagination-nav pagination-nav-next"
                type="button"
              >
                <img
                  src="@/assets//img/ico-paging-next.png"
                  alt="다음 10페이지 이동"
                />
              </button>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </template>
<script>
import commons from '@/plugins/commons'
import Pagenation from '@/mixins/Pagenation'
import EventBus from '@/plugins/eventBus'
import codes from '@/plugins/codes'
export default {
  name: 'AlarmNotification',
  mixins: [Pagenation],
  props: {
    alertDialogPopup: {
      type: Boolean,
      default: false
    },
    alertDetails: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      toggleState: true,
      rowPerPage: 10,
      page: 1,
      totalPages: 0,
      pageIndex: 1,
      pageInfoText: '1 to 4, 4 in total',
      goToPageInput: 1,
      options: {
        sortBy: [],
        sortDesc: [],
        itemsPerPage: 10
      },
      tableItems: []
    }
  },
  computed: {
    rowPerPageList () {
      return [5, 10, 20, 50, 100]
    },
    selectedStore () {
      return this.$store.getters['dataStore/GET_SELECTED_STORE'].code
    },
    tableHeaders () {
      const columns = [
        {
          text: this.$t('#'),
          sortable: false,
          value: 'serialNo'
        },
        {
          text: this.$t('TIME'),
          sortable: false,
          value: 'alertTime'
        },
        {
          text: this.$t('SENSOR ID'),
          sortable: false,
          value: 'sensorCode'
        },
        {
          text: this.$t('SENSOR NAME'),
          sortable: false,
          value: 'sensorName'
        }
      ]
      if (this.alertDetails.name === 'High Temperature' || this.alertDetails.name === 'Low Temperature') {
        columns.push({
          text: this.$t('TEMPERATURE'),
          sortable: false,
          value: 'value'
        })
      } else {
        columns.push({
          text: this.$t('HUMIDITY %'),
          sortable: false,
          value: 'value'
        })
      }
      return columns
    }
  },
  watch: {
    pageIndex: {
      handler (page) {
        this.goToPageInput = page
      }
    }
  },
  created () {
    if (localStorage.getItem('tempIndicator') !== null) {
      this.toggleState = JSON.parse(localStorage.getItem('tempIndicator'))
    } else {
      this.toggleState = true
      localStorage.setItem('tempIndicator', 'true')
    }
  },
  mounted () {
    this.getAlertHistoryList(1)
  },
  methods: {
    goToPage (goToPageInput, paging, totalPages) {
      if (totalPages < goToPageInput) {
      } else {
        paging(Number(goToPageInput))
      }
    },
    closeModal () {
      this.$emit('closeAlertModal')
    },
    checkIsNumber: commons.isNumber,
    paging (page) {
      this.page = Number(page)
      this.pageIndex = this.page
      this.goToPageInput = page
      this.getAlertHistoryList(page)
    },
    getAlertHistoryList (page) {
      const msg = 'Loading...'
      EventBus.$emit('toggleLoadingModal', msg, true)
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore,
          alertType: this.alertDetails.name.split(' ').join('_').toUpperCase(),
          size: this.rowPerPage
        }
      }
      if (this.alertDetails.type === 'history') {
        config.params.startTime = this.alertDetails.startTime
        config.params.endTime = this.alertDetails.endTime
      } else {
        config.params.status = 'ON'
      }
      if (page !== null) config.params.page = page - 1
      this.$utils
        .callAxios(
          codes.requests.getAlertHistory.method,
          codes.requests.getAlertHistory.url,
          config
        )
        .then(res => {
          EventBus.$emit('toggleLoadingModal', msg, false)
          const headers = res.headers
          if (res.status === 200) {
            this.tableItems = res.data.alertHistory.map((obj, idx) => {
              return {
                serialNo: headers['x-number'] * 1 * headers['x-size'] + idx + 1,
                ...obj,
                alertTime: commons.convertDate(obj.alertTime)
              }
            })
          } else {
            this.tableItems = []
          }
          this.pageInfoText = commons.getPageInfoText(headers)
          this.totalPages = headers['x-totalpages']
        })
        .catch(error => {
          EventBus.$emit('toggleLoadingModal', msg, false)
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    },
    deleteItem (data) {
      console.log(data)
    }
  }
}
</script>
  <style scoped></style>
