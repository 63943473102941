<template>
  <div>
    <h2 class="page-title-bar">
      <i class="ico ico-system"></i>{{ $t('Hardware Settings') }}
    </h2>
    <br />
    <!-- <v-card style="border-radius: 10px"> -->
      <!-- <v-toolbar flat>
        <template v-slot:extension>
          <v-tabs v-model="tab" grow style="margin-top: -135px">
            <v-tabs-slider></v-tabs-slider> -->
            <!-- <v-tab
              href="#tab-1st"
              class="primary--text tab-divider"
              style="border-top-left-radius: 10px;background: rgb(226, 226, 226) !important;"
            >
              <span>{{ $t("LABEL") }}</span>
            </v-tab> -->
            <!-- <v-tab href="#tab-2nd" class="primary--text tab-divider">
              <span>{{ $t("GATEWAY") }}</span>
            </v-tab> -->
          <!-- </v-tabs>
        </template>
      </v-toolbar> -->
      <!-- <v-tabs-items v-model="tab" style="padding: 40px">
        <v-tab-item
          :value="'tab-1st'"
          :transition="false"
          :reverse-transition="false"
          v-if="tab === 'tab-1st'"
        > -->
          <v-card flat>
            <div class="settingBox" style="border: none;max-width:100%">
              <div>
                <!-- <h4
                  class="tit-section-sub"
                  style="background: #e2e2e2 !important"
                >
                  {{ $t("Default Setting") }}
                </h4> -->
          <h3 class="tit-group-bg mt-1" style="color: rgba(0, 0, 0, 0.6);line-height: 1;">{{$t('Default Setting')}}</h3>

              </div>
              <v-row>
                <v-col style="max-width: 30%">
                  <div style="padding-left: 20px">
                    <p class="label-txt">{{ $t("Max Pages") }}</p>
                    <br />
                    <div
                      style="
                        justify-content: normal;
                        display: inline-flex;
                        padding-left: 11px;
                      "
                    >
                      <v-row>
                        <v-select
                :items="Maxpages"
                item-text="txt"
                item-value="val"
                v-model="Maxpagesselected"
                :placeholder="Maxpages[0].txt"
                class="form-select"
                outlined
                dense
                style="width: 80px"
                hide-details
                solo
                return-object
              >
              </v-select>

                        <span
                          ><p
                            class="label-txt"
                            style="
                              font-weight: bold;
                              margin-left: 12px;
                              margin-top: 10px;
                            "
                          >
                          {{ $t("pages") }}

                          </p></span
                        >
                      </v-row>
                    </div>
                  </div>
                </v-col>
                <!-- <v-col>
                  <div>
                    <p style="margin-left: -13px" class="label-txt">
                      {{ $t("Low Power Mode") }}
                    </p>
                    <br />
                    <div style="justify-content: normal; display: inline-flex">
                      <v-row>
                        <v-select
                          :items="ApprovalItems"
                          item-text="txt"
                          item-value="val"
                          v-model="Lowpowermode"
                          :placeholder="ApprovalItems[0].txt"
                          class="form-select"
                          outlined
                          dense
                          style="width: 80px"
                          hide-details
                          solo
                          return-object
                        >
                        <template v-slot:item="{item }">
                           <span style="font-size: 0.8125rem;">{{ $t(item.txt) }}</span>
                        </template>
                        <template v-slot:selection="{item }">
                            <span class="LedPatternClass" style="display: contents !important;">{{ $t(item.txt) }}</span>
                        </template>
                      </v-select>
                      </v-row>
                    </div>
                  </div>
                </v-col> -->
              </v-row>
              <div class="mt-5">
                <v-btn :disabled="btnDisabledHardwareSettings" class="btn-s ml-4"  @click="Toeditdefaulfsettings" text>
                  {{ $t("Save") }}
                </v-btn>
              </div>
              <br />

              <!-- <h4
                class="tit-section-sub"
                style="background: #e2e2e2 !important"
              >
                {{ $t("Signal Setting") }}
              </h4> -->
          <h3 class="tit-group-bg mt-1" style="color: rgba(0, 0, 0, 0.6);line-height: 1;">{{$t('Signal Setting')}}</h3>

              <v-row>
                <v-col style="max-width: 30%">
                  <div style="padding-left: 20px">
                    <p class="label-txt">{{ $t("Alive Interval for overview") }}</p>
                    <br />
                    <div
                      style="
                        justify-content: normal;
                        display: inline-flex;
                        padding-left: 11px;
                      "
                    >
                      <v-row>
                        <v-text-field
                          v-model="aliveInterval"
                          @keyup.prevent="handleKeyUp"
                          :disabled="btnDisabledAliveInterval"
                          min="0"
                          class="form-input"
                          style="width: 80px;"
                          type="number"
                          outlined
                          dense
                          hide-details
                          solo
                        ></v-text-field>

                        <span
                          ><p
                            class="label-txt"
                            style="
                              font-weight: bold;
                              margin-left: 12px;
                              margin-top: 10px;
                            "
                          >
                          {{ $t("hours") }}

                          </p></span
                        >
                      </v-row>
                    </div>
                  </div>
                </v-col>
                <v-col style="max-width: 30%">
                  <div>
                    <p style="margin-left: -13px" class="label-txt">
                      {{ $t("Original Scan Cycle") }}
                    </p>
                    <br />
                    <div style="justify-content: normal; display: inline-flex">
                      <v-row>
                        <span
                          ><p
                            class="label-txt"
                            style="
                              font-weight: bold;
                              margin-left: 0px;
                              margin-top: 10px;
                            "
                          >
                          {{ $t("Every") }}
                          </p></span
                        >
                        <v-text-field
                        outlined
                        dense
                        hide-details
                        solo
                        min='1'
                        max='128'
                        v-model="origianlscancycle"
                        class="form-input appendIcon"
                        type="number"
                        style="max-width: 80px; margin-left: 10px;"
                        clearable
                        @keydown="clearTabindex"
                      ></v-text-field>
                        <span
                          ><p
                            class="label-txt"
                            style="
                              font-weight: bold;
                              margin-left: 10px;
                              margin-top: 10px;
                            "
                          >

                           * 10 {{ $t("seconds") }}
                          </p></span
                        >
                      </v-row>
                    </div>
                  </div>
                </v-col>
                <v-col style="max-width: 30%">
                  <div>
                    <p style="margin-left: -13px" class="label-txt">
                      {{ $t("Rescan on Disconnection") }}
                    </p>
                    <br />
                    <div style="justify-content: normal; display: inline-flex">
                      <v-row>
                        <v-select
                          :items="rescanondisconnect"
                          item-text="txt"
                          item-value="val"
                          v-model="rescanondisconnection"
                          :placeholder="rescanondisconnect[0].txt"
                          class="form-select"
                          outlined
                          dense
                          style="width: 180px"
                          hide-details
                          solo
                          return-object
                        ></v-select>
                      </v-row>
                    </div>
                  </div>
                </v-col>
                </v-row>
                <br>
                <v-row>
                <v-col style="max-width: 30%">
                  <div style="padding-left:20px">
                    <p style="" class="label-txt">
                      {{ $t("Rescan on Bad Signal") }}
                    </p>
                    <br />
                    <div style="justify-content: normal; display: inline-flex;padding-left:11px">
                      <v-row>
                        <v-select
                          :items="rescanonbadsignal"
                          item-text="txt"
                          item-value="val"
                           v-model="rescanonbadsignalselected"
                          :placeholder="rescanonbadsignal[0].txt"
                          class="form-select"
                          outlined
                          dense
                          style="width: 180px"
                          hide-details
                          solo
                           return-object
                        ></v-select>
                      </v-row>
                    </div>
                  </div>
                </v-col>
                <v-col style="max-width: 30%">
                  <div>
                    <p style="margin-left: -13px" class="label-txt">
                      {{ $t("Activation Cycle") }}
                    </p>
                    <br />
                    <div style="justify-content: normal; display: inline-flex">
                      <v-row>
                        <v-select
                          :items="activationcycle"
                          item-text="txt"
                          item-value="val"
                           v-model="activationcycleselected"
                          :placeholder="activationcycle[0].txt"
                          class="form-select"
                          outlined
                          dense
                          style="width: 80px"
                          hide-details
                          solo
                           return-object
                        ></v-select>
                        <span
                          ><p
                            class="label-txt"
                            style="
                              font-weight: bold;
                              margin-left: 10px;
                              margin-top: 10px;
                            "
                          >
                            {{ $t("seconds") }}
                          </p></span
                        >
                      </v-row>
                    </div>
                  </div>
                </v-col>
                <v-col style="max-width: 30%">
                  <div>
                    <p style="margin-left: -13px" class="label-txt">
                      {{ $t("Rescan on Requiry Fail") }}
                    </p>
                    <br />
                    <div style="justify-content: normal; display: inline-flex">
                      <v-row>
                        <v-select
                          :items="rescanonrequiryfail"
                          item-text="txt"
                          item-value="val"
                           v-model="rescanonrequiryfailselected"
                          :placeholder="rescanonrequiryfail[0].txt"
                          class="form-select"
                          outlined
                          dense
                          style="width: 80px"
                          hide-details
                          solo
                           return-object
                        ></v-select>
                        <span
                          ><p
                            class="label-txt"
                            style="
                              font-weight: bold;
                              margin-left: 10px;
                              margin-top: 10px;
                            "
                          >
                          {{ $t("times") }}
                          </p></span
                        >
                      </v-row>

                    </div>
                  </div>
                </v-col>
                </v-row>
                <div class="mt-5">
                <v-btn :disabled="btnDisabledHardwareSettings" class="btn-s ml-4"  @click="Toeditsignalsetting" text>
                  {{ $t("Save") }}
                </v-btn>
              </div>
              <br>
          <h3 class="tit-group-bg mt-1" style="color: rgba(0, 0, 0, 0.6);line-height: 1;">{{$t('On-Demand Alive Setting')}}</h3>
        <table class="tbl-custom-type01 max-100">
          <colgroup>
            <col style="width:25%">
            <col style="width:25%">
            <col style="width:25%">
            <col style="width:25%">
            <col>
          </colgroup>
          <thead>
            <tr>
              <th>{{$t('On/Off')}}</th>
              <th>{{$t('Cycle')}}</th>
              <th>{{$t('Scheduled Time')}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <v-switch inset hide-details solo
                  class="form-switch"
                   :items="items"
                          item-text="txt"
                          item-value="val"
                           v-model="OnOffDemandAlive"
                ></v-switch>
              </td>
              <td>
               {{$t('Daily')}}
              </td>
              <td>
 <v-row>
                      <v-select
                        :items="OnDamandHours"
                        v-model="updateOnDemandhours"
                        class="form-select ml-1"
                        dense
                        style="width: 0px"
                        hide-details
                        solo
                      >
                      </v-select>
                      <span
                        ><p
                          class="label-txt"
                          style="
                            font-weight: bold;
                            margin-left: 0px;
                            margin-top: 13px;
                          "
                        >
                          :
                        </p></span
                      >
                      <v-select
                        :items="OnDamandMinutes"
                        v-model="updateOnDemandminutes"
                        class="form-select ml-0"
                        dense
                        style="width: 0px"
                        hide-details
                        solo
                      >
                      </v-select>
                    </v-row>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>

           <div class="mt-5">
                <v-btn :disabled="btnDisabledHardwareSettings" class="btn-s ml-4"  @click="ToeditOnDemandAlive" text>
                  {{ $t("Save") }}
                </v-btn>
              </div>
            </div>
          </v-card>
        <!-- </v-tab-item> -->
        <!-- <v-tab-item
          :value="'tab-2nd'"
          :transition="false"
          :reverse-transition="false"
          v-if="tab === 'tab-2nd'"
        >
          <v-card flat>
            <h1></h1>
          </v-card>
        </v-tab-item> -->
      <!-- </v-tabs-items> -->
    <!-- </v-card> -->
  </div>
</template>
<script>
import EventBus from '@/plugins/eventBus'

export default {

  name: 'HardwareSetting',
  data () {
    const items = [
      { txt: 'On', val: 'true' },
      { txt: 'Off', val: 'false' }
    ]
    const Maxpages = [
      { txt: '1', val: '1' },
      { txt: '2', val: '2' },
      { txt: '3', val: '3' },
      { txt: '4', val: '4' },
      { txt: '5', val: '5' },
      { txt: '6', val: '6' },
      { txt: '7', val: '7' }
    ]
    const ApprovalItems = [
      { txt: 'On', val: 'true' },
      { txt: 'Off', val: 'false' }
    ]
    const rescanondisconnect = [
      { txt: this.$t('Immediately'), val: 'true' },
      { txt: this.$t('Original Scan Cycle'), val: 'false' }
    ]
    const rescanonbadsignal = [
      { txt: this.$t('Disable'), val: '0' },
      { txt: this.$t('Rescan:Normal Sensitivity'), val: '1' },
      { txt: this.$t('Rescan:High Sensitivity'), val: '2' },
      { txt: this.$t('Rescan:Super High Sensitivity'), val: '3' }

    ]
    const activationcycle = [
      { txt: '60', val: '0' },
      { txt: '3', val: '1' },
      { txt: '5', val: '2' },
      { txt: '10', val: '3' },
      { txt: '15', val: '4' },
      { txt: '20', val: '5' },
      { txt: '25', val: '6' },
      { txt: '30', val: '7' }
    ]
    const rescanonrequiryfail = [
      { txt: '20', val: '0' },
      { txt: '4', val: '1' },
      { txt: '6', val: '2' },
      { txt: '8', val: '3' }
    ]

    return {
      tab: null,
      aliveInterval: null,
      btnDisabledAliveInterval: null,
      Maxpages,
      ApprovalItems,
      origianlscancycle: 0,
      rescanondisconnect,
      rescanonbadsignal,
      Maxpagesselected: Maxpages[0].txt,
      Lowpowermode: ApprovalItems[0].txt,
      rescanondisconnection: rescanondisconnect[0].txt,
      rescanonbadsignalselected: rescanonbadsignal[0].txt,
      activationcycle,
      activationcycleselected: activationcycle[0].txt,
      rescanonrequiryfail,
      rescanonrequiryfailselected: rescanonrequiryfail[0].txt,

      //   Foredit
      Maxpagesselectededit: Maxpages[0].txt,
      Lowpowermodeedit: ApprovalItems[0].txt,
      origianlscancycleedit: 0,
      rescanondisconnectionedit: rescanondisconnect[0].txt,
      rescanonbadsignalselectededit: rescanonbadsignal[0].txt,
      activationcycleselectededit: activationcycle[0].txt,
      rescanonrequiryfailselectededit: rescanonrequiryfail[0].txt,

      // button disabled
      btnDisabledHardwareSettings: null,
      //
      // On demand Alive
      items,
      updateOnDemandhours: '00',
      updateOnDemandminutes: '00',
      OnOffDemandAlive: false
    }
  },
  computed: {
    OnDamandHours () {
      const demand = []
      for (let i = 0; i <= 23; i++) {
        if (i >= 0 && i < 10) {
          demand.push('0' + i)
        } else {
          demand.push(i.toString(10))
        }
      }
      console.log(demand)
      return demand
    },
    OnDamandMinutes () {
      const demandmin = []
      for (let j = 0; j < 6; j++) {
        if (j === 0) {
          demandmin.push(j + '0')
        } else {
          demandmin.push((j * 10).toString(10))
        }
      }
      return demandmin
    }
  },
  mounted () {
    EventBus.$emit('enableSelectedStores', false)
    this.getpropertydetails()
    this.togetaliveinterval()
    this.TogetDefaultOnDemandAlive()
    this.aliveInterval = this.$store.getters['auth/GET_INTERVAL']
    this.$store.dispatch('auth/getDisabledBtn', '9200').then((flag) => {
      this.btnDisabledAliveInterval = flag
    })
    this.$store.dispatch('auth/getDisabledBtn', '9200').then((flag) => {
      this.btnDisabledHardwareSettings = flag
    })
  },
  methods: {
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    getpropertydetails () {
      const url = '/api/common/property'
      this.$utils.callAxios('get', url).then((res) => {
        if (res.data) {
          console.log(res.data)
          if (res.data.aimsPortalBiz1MaxPage !== undefined || res.data.aimsPortalBiz1MaxPage !== '') {
            this.Maxpagesselected = this.Maxpages.find(
              (item) => item.txt === res.data.aimsPortalBiz1MaxPage
            )
          } else {
            this.Maxpagesselected = this.Maxpages.find(
              (item) => item.txt === this.Maxpages[0].txt
            )
          }
          if (res.data.realtimeConfigHibernate === 'false') {
            this.Lowpowermode = this.ApprovalItems.find(
              (item) => item.txt === 'Off'
            )
          } else {
            this.Lowpowermode = this.ApprovalItems.find(
              (item) => item.txt === 'On'
            )
          }
          if (res.data.realtimeConfigScandirect === 'false') {
            this.rescanondisconnection = this.rescanondisconnect.find(
              (item) => item.txt === 'Original Scan Cycle'
            )
          } else {
            this.rescanondisconnection = this.rescanondisconnect.find(
              (item) => item.txt === 'Immediately'
            )
          }

          if (res.data.realtimeConfigConnectthreshmode === '0') {
            this.rescanonbadsignalselected = this.rescanonbadsignal.find(
              (item) => item.txt === 'Disable'
            )
          }
          if (res.data.realtimeConfigConnectthreshmode === '1') {
            this.rescanonbadsignalselected = this.rescanonbadsignal.find(
              (item) => item.txt === 'Rescan:Normal Sensitivity'
            )
          }
          if (res.data.realtimeConfigConnectthreshmode === '2') {
            this.rescanonbadsignalselected = this.rescanonbadsignal.find(
              (item) => item.txt === 'Rescan:High Sensitivity'
            )
          }
          if (res.data.realtimeConfigConnectthreshmode === '3') {
            this.rescanonbadsignalselected = this.rescanonbadsignal.find(
              (item) => item.txt === 'Rescan:Super High Sensitivity'
            )
          }

          if (res.data.realtimeConfigretryperiod === '0') {
            this.activationcycleselected = this.activationcycle.find(
              (item) => item.txt === '60'
            )
          }
          if (res.data.realtimeConfigretryperiod === '1') {
            this.activationcycleselected = this.activationcycle.find(
              (item) => item.txt === '3'
            )
          }
          if (res.data.realtimeConfigretryperiod === '2') {
            this.activationcycleselected = this.activationcycle.find(
              (item) => item.txt === '5'
            )
          }
          if (res.data.realtimeConfigretryperiod === '3') {
            this.activationcycleselected = this.activationcycle.find(
              (item) => item.txt === '10'
            )
          }
          if (res.data.realtimeConfigretryperiod === '4') {
            this.activationcycleselected = this.activationcycle.find(
              (item) => item.txt === '15'
            )
          }
          if (res.data.realtimeConfigretryperiod === '5') {
            this.activationcycleselected = this.activationcycle.find(
              (item) => item.txt === '20'
            )
          }
          if (res.data.realtimeConfigretryperiod === '6') {
            this.activationcycleselected = this.activationcycle.find(
              (item) => item.txt === '25'
            )
          }
          if (res.data.realtimeConfigretryperiod === '7') {
            this.activationcycleselected = this.activationcycle.find(
              (item) => item.txt === '30'
            )
          }

          if (res.data.realtimeConfigRetrycount === '0') {
            this.rescanonrequiryfailselected = this.rescanonrequiryfail.find(
              (item) => item.txt === '20'
            )
          }
          if (res.data.realtimeConfigRetrycount === '1') {
            this.rescanonrequiryfailselected = this.rescanonrequiryfail.find(
              (item) => item.txt === '4'
            )
          }
          if (res.data.realtimeConfigRetrycount === '2') {
            this.rescanonrequiryfailselected = this.rescanonrequiryfail.find(
              (item) => item.txt === '6'
            )
          }
          if (res.data.realtimeConfigRetrycount === '3') {
            this.rescanonrequiryfailselected = this.rescanonrequiryfail.find(
              (item) => item.txt === '8'
            )
          }

          this.origianlscancycle = res.data.realtimeConfigScanperiod

          //   for edit
          this.Maxpagesselectededit = res.data.aimsPortalBiz1MaxPage
          this.Lowpowermodeedit = res.data.realtimeConfigHibernate
          this.origianlscancycleedit = res.data.realtimeConfigScanperiod
          this.rescanondisconnectionedit = res.data.realtimeConfigScandirect
          this.rescanonbadsignalselectededit = res.data.realtimeConfigConnectthreshmode
          this.activationcycleselectededit = res.data.realtimeConfigretryperiod
          this.rescanonrequiryfailselectededit = res.data.realtimeConfigRetrycount
        }
      })
    },

    // On Demand Alive
    TogetDefaultOnDemandAlive () {
      const url = '/api/common/config/onDemandAlive'
      this.$utils.callAxios('get', url).then((res) => {
        if (res.data) {
          this.OnOffDemandAlive = res.data.onoff === 'true'
          const scheduledTime = res.data.scheduledTime.split(':')
          this.updateOnDemandhours = scheduledTime[0]
          this.updateOnDemandminutes = scheduledTime[1]
        }
      })
    },
    ToeditOnDemandAlive () {
      const config = {}
      const body = {
        onoff: this.OnOffDemandAlive,
        cycle: 'Daily',
        scheduledTime: this.updateOnDemandhours + ':' + this.updateOnDemandminutes
      }
      const url = '/api/common/config/onDemandAlive'
      this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
        EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
        this.TogetDefaultOnDemandAlive()
      })
    },
    // End
    Toeditdefaulfsettings () {
      const url = '/api/common/property'
      if (this.Maxpagesselectededit === this.Maxpagesselected.val) {
      } else {
        const config = {
          params: {
            key: 'PORTL_BIZ1_MAX_PAGE',
            value: this.Maxpagesselected.val
          }
        }
        const body = {}
        this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.getpropertydetails()
        })
      }
      if (this.Lowpowermodeedit === this.Lowpowermode.val) {
      } else {
        const config = {
          params: {
            key: 'REALTIME_CONFIG_HIBERNATE',
            value: this.Lowpowermode.val
          }
        }
        const body = {}
        this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.getpropertydetails()
        })
      }
    },
    togetaliveinterval () {
      const url = '/api/common/config/aliveInterval'
      this.$utils.callAxios('get', url).then((res) => {
        if (res.data) {
          console.log(res.data)
          this.aliveInterval = res.data.aliveInterval
          this.$store.state.auth.user.aliveInterval = this.aliveInterval
        }
      })
    },
    Toeditsignalsetting () {
      console.log(this.btnDisabledAliveInterval)
      console.log(this.aliveInterval)
      const url = '/api/common/property'

      // for aliveInterval
      if (this.btnDisabledAliveInterval === false) {
        if (this.aliveInterval) {
          const url = '/api/common/config/aliveInterval'
          const config = {
            params: {
              company: this.$store.state.auth.user.company,
              aliveInterval: this.aliveInterval
            }
          }
          const body = {}
          this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
            console.log(res)
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
            this.togetaliveinterval()
          })
        } else {}
      }

      // origianlscancycle
      if (this.origianlscancycleedit === this.origianlscancycle) {
      } else {
        const config = {
          params: {
            key: 'REALTIME_CONFIG_SCANPERIOD',
            value: this.origianlscancycle
          }
        }
        const body = {}
        this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.getpropertydetails()
          this.togetaliveinterval()
        })
      }

      // Re scan on disconnection

      if (this.rescanondisconnectionedit === this.rescanondisconnection.val) {
      } else {
        const config = {
          params: {
            key: 'REALTIME_CONFIG_SCANDIRECT',
            value: this.rescanondisconnection.val
          }
        }
        const body = {}
        this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.getpropertydetails()
        })
      }

      // Re scan on bad signal

      if (this.rescanonbadsignalselectededit === this.rescanonbadsignalselected.val) {
      } else {
        const config = {
          params: {
            key: 'REALTIME_CONFIG_CONNECTTHRESHMODE',
            value: this.rescanonbadsignalselected.val
          }
        }
        const body = {}
        this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.getpropertydetails()
        })
      }

      // Activation Cycle
      if (this.activationcycleselectededit === this.activationcycleselected.val) {
      } else {
        const config = {
          params: {
            key: 'REALTIME_CONFIG_RETRY_PERIOD',
            value: this.activationcycleselected.val
          }
        }
        const body = {}
        this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.getpropertydetails()
        })
      }

      // Rescan on Require Fail

      if (this.rescanonrequiryfailselectededit === this.rescanonrequiryfailselected.val) {
      } else {
        const config = {
          params: {
            key: 'REALTIME_CONFIG_RETRY_COUNT',
            value: this.rescanonrequiryfailselected.val
          }
        }
        const body = {}
        this.$utils.callAxiosWithBody('put', url, body, config).then((res) => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.getpropertydetails()
        })
      }
      this.togetaliveinterval()
    }
  }
}
</script>

<style scoped>
.settingBox {
    width: 100%;
    padding: 30px 0px!important;
    border: 1px solid #f4f4f4;
}
.LedPatternClass + input{
  display: contents !important;
}
</style>
