import CryptoJS from 'crypto-js'

export function decryptAES (encryptedText) {
  if (!encryptedText) return
  const key = CryptoJS.enc.Base64.parse(process.env.VUE_APP_BASE64_KEY)
  // Decrypt the encrypted text
  const decrypted = CryptoJS.AES.decrypt(encryptedText.replace(/ /g, '+'), key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })

  return decrypted.toString(CryptoJS.enc.Utf8)
}
