var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-container" },
    [
      _c("p", { staticClass: "d-none" }, [
        _vm._v(
          " " +
            _vm._s(this.chartData.series) +
            _vm._s(this.chartOptions.xAxis) +
            " " +
            _vm._s(_vm.legend) +
            " "
        )
      ]),
      _c("line-chart", {
        ref: "lineChart",
        attrs: { options: _vm.chartOptions }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }