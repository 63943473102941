var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-label" }),
        _vm._v(_vm._s(_vm.$t("Label Info")) + " ")
      ]),
      _c(
        "section",
        { staticClass: "searchArea" },
        [
          _c("v-text-field", {
            staticClass: "form-input ml-4",
            style: { width: "10%", marginLeft: "0 !important" },
            attrs: {
              label: _vm.$t("Label ID"),
              placeholder: _vm.$t("Input label ID"),
              outlined: "",
              dense: "",
              "hide-details": "",
              clearable: ""
            },
            on: { keyup: _vm.handleSearchLabelIdKeyUp },
            model: {
              value: _vm.searchBy.labelParam,
              callback: function($$v) {
                _vm.$set(_vm.searchBy, "labelParam", $$v)
              },
              expression: "searchBy.labelParam"
            }
          }),
          _c("v-text-field", {
            staticClass: "form-input ml-3",
            staticStyle: { width: "10%" },
            attrs: {
              label: _vm.$t("Product ID"),
              placeholder: _vm.$t("Input product ID"),
              outlined: "",
              dense: "",
              "hide-details": "",
              clearable: ""
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.clickSearch()
              }
            },
            model: {
              value: _vm.searchBy.productIdParam,
              callback: function($$v) {
                _vm.$set(_vm.searchBy, "productIdParam", $$v)
              },
              expression: "searchBy.productIdParam"
            }
          }),
          _c("v-text-field", {
            staticClass: "form-input ml-3",
            staticStyle: { width: "10%" },
            attrs: {
              label: _vm.$t("Product Description"),
              placeholder: _vm.$t("Input product description"),
              outlined: "",
              dense: "",
              "hide-details": "",
              clearable: ""
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.clickSearch()
              }
            },
            model: {
              value: _vm.searchBy.productDescParam,
              callback: function($$v) {
                _vm.$set(_vm.searchBy, "productDescParam", $$v)
              },
              expression: "searchBy.productDescParam"
            }
          }),
          _c("v-select", {
            staticClass: "form-select ml-3",
            staticStyle: { width: "10%" },
            attrs: {
              items: _vm.gatewayItems,
              label: _vm.$t("Linked Gateway"),
              placeholder: _vm.$t("Select gateway"),
              clearable: "",
              outlined: "",
              dense: "",
              "persistent-hint": "",
              "hide-details": ""
            },
            scopedSlots: _vm._u([
              {
                key: "no-data",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "v-list-item theme--light",
                        attrs: { tabindex: "-1" }
                      },
                      [
                        _c("div", { staticClass: "v-list-item__content" }, [
                          _c("div", { staticClass: "v-list-item__title" }, [
                            _vm._v(_vm._s(_vm.$t("No data available")))
                          ])
                        ])
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.searchBy.gatewayParam,
              callback: function($$v) {
                _vm.$set(_vm.searchBy, "gatewayParam", $$v)
              },
              expression: "searchBy.gatewayParam"
            }
          }),
          _c("v-select", {
            ref: "selectRef",
            staticClass: "form-select ml-3",
            class: { labelTypeclass: _vm.labelTypelist.length !== 0 },
            staticStyle: { width: "10%" },
            attrs: {
              items: _vm.typeItems,
              label: _vm.$t("Type"),
              placeholder: _vm.$t("Select label type"),
              clearable: "",
              "item-value": "value",
              "item-text": "text",
              outlined: "",
              dense: "",
              multiple: "",
              "persistent-hint": "",
              "hide-details": "",
              chips: "",
              "menu-props": { maxHeight: "200" }
            },
            on: {
              change: function($event) {
                return _vm.onChangetype(_vm.searchBy.typeParam)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    index < 1
                      ? _c("span", { staticClass: "item" }, [
                          _vm._v(
                            " " + _vm._s(_vm.getResponsiveSubstring(item)) + " "
                          )
                        ])
                      : _vm._e(),
                    index === 1
                      ? _c("span", { staticClass: "grey--text caption" }, [
                          _vm._v(
                            "(+" +
                              _vm._s(_vm.searchBy.typeParam.length - 1) +
                              ") " +
                              _vm._s(_vm.$t("type"))
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ]),
            model: {
              value: _vm.searchBy.typeParam,
              callback: function($$v) {
                _vm.$set(_vm.searchBy, "typeParam", $$v)
              },
              expression: "searchBy.typeParam"
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "btn type-search ml-3",
              staticStyle: { width: "120px" },
              attrs: { text: "" },
              on: {
                click: function($event) {
                  return _vm.clickSearch()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("Search")) + " ")]
          )
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "searchArea", staticStyle: { width: "60%" } },
        [
          _c("v-select", {
            staticClass: "form-select ml-3",
            class: { statuslistclass: _vm.statuslist.length !== 0 },
            staticStyle: { width: "10%" },
            style: { marginLeft: "0 !important" },
            attrs: {
              items: _vm.statusItems,
              label: _vm.$t("Status"),
              placeholder: _vm.$t("Select label status"),
              "item-value": "value",
              "item-text": "text",
              outlined: "",
              dense: "",
              "hide-details": "",
              clearable: "",
              multiple: "",
              chips: "",
              "persistent-hint": "",
              "menu-props": { maxHeight: "200" }
            },
            on: {
              change: function($event) {
                return _vm.onChange(_vm.searchBy.statusParam)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    index < _vm.maxDisplay
                      ? _c("span", { staticClass: "item" }, [
                          _vm._v(_vm._s(_vm.$t(item.value)) + " ")
                        ])
                      : _vm._e(),
                    index === _vm.maxDisplay
                      ? _c("span", { staticClass: "grey--text caption" }, [
                          _vm._v(
                            "(+" +
                              _vm._s(
                                _vm.searchBy.statusParam.length - _vm.maxDisplay
                              ) +
                              " " +
                              _vm._s(_vm.$t("status")) +
                              ")"
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ]),
            model: {
              value: _vm.searchBy.statusParam,
              callback: function($$v) {
                _vm.$set(_vm.searchBy, "statusParam", $$v)
              },
              expression: "searchBy.statusParam"
            }
          }),
          _c("v-text-field", {
            staticClass: "form-input ml-3",
            staticStyle: { width: "10%" },
            attrs: {
              label: _vm.$t("Template Type Name"),
              placeholder: _vm.$t("Input template type name"),
              outlined: "",
              dense: "",
              "hide-details": "",
              clearable: ""
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.clickSearch()
              }
            },
            model: {
              value: _vm.searchBy.templateTypeParam,
              callback: function($$v) {
                _vm.$set(_vm.searchBy, "templateTypeParam", $$v)
              },
              expression: "searchBy.templateTypeParam"
            }
          }),
          _vm.CUSTOMER === "LCL" ||
          _vm.CUSTOMER === "METRO" ||
          _vm.CUSTOMER === "LV4BT"
            ? _c("v-select", {
                staticClass: "form-select ml-3",
                staticStyle: { width: "10%" },
                attrs: {
                  items: _vm.statusItemsBattery,
                  label: _vm.$t("Battery"),
                  placeholder: _vm.$t("Select Battery Level"),
                  "item-value": "value",
                  "item-text": "text",
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  clearable: ""
                },
                on: { change: _vm.clickCheckBox },
                model: {
                  value: _vm.searchParam.battery,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParam, "battery", $$v)
                  },
                  expression: "searchParam.battery"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "col-12 pl-0 pb-0 pt-0 pr-0 d-flex",
          staticStyle: { "margin-top": "10px" }
        },
        [
          _c(
            "div",
            { staticClass: "col-4 pr-0" },
            [
              _c(
                "v-data-table",
                {
                  staticClass: "tbl-type01",
                  attrs: {
                    headers: _vm.FixedlabelHeader,
                    items: _vm.labelItems,
                    "item-class": _vm.getRowClass,
                    "hide-default-footer": true,
                    "item-key": "id",
                    "show-select": "",
                    options: _vm.options,
                    "server-items-length": _vm.totalLabels
                  },
                  on: {
                    "update:options": function($event) {
                      _vm.options = $event
                    },
                    "click:row": _vm.openLabelModal
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.productdataId",
                        fn: function(ref) {
                          var item = ref.item
                          return _vm._l(item.productdataId, function(
                            items,
                            index
                          ) {
                            return _c("p", { key: index }, [
                              items.articleId.length > 13
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        items.articleId.substring(0, 4) +
                                          "..." +
                                          items.articleId.substring(
                                            items.articleId.length - 3,
                                            items.articleId.length
                                          )
                                      )
                                    )
                                  ])
                                : items.articleId.length == 0
                                ? _c("span", [_vm._v("-")])
                                : _c("span", [_vm._v(_vm._s(items.articleId))])
                            ])
                          })
                        }
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                },
                [
                  _c("template", { slot: "no-data" }, [
                    _c("p", [
                      _vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")
                    ])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-8 pl-0" },
            [
              _c(
                "v-data-table",
                {
                  staticClass: "tbl-type01 tblBorder",
                  attrs: {
                    headers: _vm.labelHeaders,
                    items: _vm.labelItems,
                    "item-class": _vm.getRowClass,
                    "hide-default-footer": true,
                    "item-key": "id",
                    options: _vm.options,
                    "server-items-length": _vm.totalLabels
                  },
                  on: {
                    "update:options": function($event) {
                      _vm.options = $event
                    },
                    "click:row": _vm.openLabelModal
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.productdataId",
                        fn: function(ref) {
                          var item = ref.item
                          return _vm._l(item.productdataId, function(
                            items,
                            index
                          ) {
                            return _c("p", { key: index }, [
                              items.articleId.length > 13
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        items.articleId.substring(0, 4) +
                                          "..." +
                                          items.articleId.substring(
                                            items.articleId.length - 3,
                                            items.articleId.length
                                          )
                                      )
                                    )
                                  ])
                                : items.articleId.length == 0
                                ? _c("span", [_vm._v("-")])
                                : _c("span", [_vm._v(_vm._s(items.articleId))])
                            ])
                          })
                        }
                      },
                      {
                        key: "item.productdataName",
                        fn: function(ref) {
                          var item = ref.item
                          return _vm._l(item.productdataName, function(
                            items,
                            index
                          ) {
                            return _c(
                              "p",
                              { key: index },
                              [
                                items.articleName &&
                                items.articleName.length > 20
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          top: "",
                                          "content-class": "v-tooltip-resize"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "span",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticStyle: {
                                                            width: "120px",
                                                            display: "block",
                                                            overflow: "hidden",
                                                            "text-overflow":
                                                              "ellipsis"
                                                          }
                                                        },
                                                        "span",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            items.articleName
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(items.articleName))
                                        ])
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          items.articleName
                                            ? items.articleName
                                            : "-"
                                        )
                                      )
                                    ])
                              ],
                              1
                            )
                          })
                        }
                      },
                      {
                        key: "item.battery",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("p", { class: _vm.batteryStyle(item.battery) }, [
                              _vm._v(_vm._s(_vm.$t(item.battery)))
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.signal",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("p", [_vm._v(_vm._s(_vm.$t(item.signal)))])
                          ]
                        }
                      },
                      _vm.networklabel
                        ? {
                            key: "item.network",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("p", { staticClass: "text-center" }, [
                                  _c("i", {
                                    staticClass: "state-icon",
                                    class: _vm.networkStatusStyle(item.network)
                                  })
                                ])
                              ]
                            }
                          }
                        : {
                            key: "default",
                            fn: function(undefined) {
                              return [
                                _c("p", { staticClass: "text-center" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t(_vm.item.network)) + " "
                                  )
                                ])
                              ]
                            }
                          },
                      {
                        key: "item.status",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("p", { class: _vm.statusStyle(item.status) }, [
                              _vm._v(_vm._s(_vm.$t(item.status)))
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.templateManual",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t(item.templateManual)))
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.requestDate",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("span", [_vm._v(_vm._s(item.requestDate))])
                          ]
                        }
                      },
                      {
                        key: "item.completedDate",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("span", [_vm._v(_vm._s(item.completedDate))])
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                },
                [
                  _c("template", { slot: "no-data" }, [
                    _c("p", [
                      _vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")
                    ])
                  ])
                ],
                2
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "opt-result d-flex justify-end align-center" },
        [
          _c("p", [_vm._v(_vm._s(_vm.$t("In-Result Search")))]),
          _c("v-checkbox", {
            staticClass: "form-checkbox ml-4",
            attrs: {
              "hide-details": "",
              label: "" + _vm.$t("Schedule"),
              value: "true"
            },
            on: { change: _vm.clickCheckBox },
            model: {
              value: _vm.searchParam.isSchedule,
              callback: function($$v) {
                _vm.$set(_vm.searchParam, "isSchedule", $$v)
              },
              expression: "searchParam.isSchedule"
            }
          }),
          _vm.LabelAlivestatus
            ? _c("v-checkbox", {
                staticClass: "form-checkbox ml-4",
                attrs: {
                  "hide-details": "",
                  label: "" + _vm.$t("Offline"),
                  value: "false"
                },
                on: { change: _vm.clickCheckBox },
                model: {
                  value: _vm.searchParam.network,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParam, "network", $$v)
                  },
                  expression: "searchParam.network"
                }
              })
            : _vm._e(),
          _vm.CUSTOMER !== "LCL" &&
          _vm.CUSTOMER !== "METRO" &&
          _vm.CUSTOMER !== "LV4BT"
            ? _c("v-checkbox", {
                staticClass: "form-checkbox ml-4",
                attrs: {
                  "hide-details": "",
                  label: "" + _vm.$t("Low Battery"),
                  value: "BAD"
                },
                on: { change: _vm.clickCheckBox },
                model: {
                  value: _vm.searchParam.battery,
                  callback: function($$v) {
                    _vm.$set(_vm.searchParam, "battery", $$v)
                  },
                  expression: "searchParam.battery"
                }
              })
            : _vm._e(),
          _c("v-checkbox", {
            staticClass: "form-checkbox ml-4",
            attrs: {
              "hide-details": "",
              label: "" + _vm.$t("Bad Signal"),
              value: "BAD"
            },
            on: { change: _vm.clickCheckBox },
            model: {
              value: _vm.searchParam.signal,
              callback: function($$v) {
                _vm.$set(_vm.searchParam, "signal", $$v)
              },
              expression: "searchParam.signal"
            }
          }),
          _c("v-checkbox", {
            staticClass: "form-checkbox ml-4",
            attrs: {
              "hide-details": "",
              label: "" + _vm.$t("Misused"),
              value: "All"
            },
            on: { change: _vm.clickCheckBox },
            model: {
              value: _vm.searchParam.misusedLabelType,
              callback: function($$v) {
                _vm.$set(_vm.searchParam, "misusedLabelType", $$v)
              },
              expression: "searchParam.misusedLabelType"
            }
          })
        ],
        1
      ),
      _c("popupLabel", {
        attrs: {
          item: _vm.detailItem,
          labelDetailDisabled: _vm.btnDisabledDetail
        },
        on: { updateLabelList: _vm.searchLabelList }
      }),
      _c("div", { staticClass: "table-options" }, [
        _c(
          "div",
          [
            _vm.CUSTOMER === "ROGERS"
              ? _c(
                  "v-btn",
                  {
                    staticClass: "btn",
                    attrs: { text: "" },
                    on: { click: _vm.openassignPopup }
                  },
                  [_vm._v(_vm._s(_vm.$t("Assign")))]
                )
              : _vm._e(),
            _c(
              "v-btn",
              {
                staticClass: "btn ml-2",
                attrs: {
                  disabled: _vm.btnDisabledDelete || _vm.checkSelected,
                  text: ""
                },
                on: { click: _vm.unassignLabels }
              },
              [_vm._v(_vm._s(_vm.$t("Unassign")))]
            ),
            _c("BlinkPopup", {
              attrs: {
                selectedLabel: _vm.selectedLabel,
                btnDisabledBlinking:
                  _vm.btnDisabledBlinking || _vm.checkSelected
              },
              on: { fireResetLabel: _vm.resetLabel }
            }),
            _c(
              "v-btn",
              {
                staticClass: "btn ml-2",
                attrs: {
                  disabled:
                    _vm.btnDisabledExport || _vm.btnDisabledExportCheckList,
                  text: ""
                },
                on: { click: _vm.exportLabelList }
              },
              [_vm._v(_vm._s(_vm.$t("Export")))]
            ),
            _c(
              "v-btn",
              {
                staticClass: "btn  ml-2",
                attrs: {
                  disabled: _vm.btnDisabledDelete || _vm.checkSelected,
                  text: ""
                },
                on: { click: _vm.deleteLabels }
              },
              [_vm._v(_vm._s(_vm.$t("Delete")))]
            ),
            _c(
              "v-btn",
              {
                staticClass: "btn  ml-2",
                attrs: {
                  disabled: _vm.checkSelected || _vm.btnDisabledBlinking,
                  text: ""
                },
                on: { click: _vm.OnDemandAlive }
              },
              [_vm._v(_vm._s(_vm.$t("On-Demand Alive")))]
            ),
            _vm.CUSTOMER === "LCL"
              ? _c(
                  "v-btn",
                  {
                    staticClass: "btn  ml-2",
                    attrs: {
                      disabled: _vm.checkSelected || _vm.btnDisabledBlinking,
                      text: ""
                    },
                    on: { click: _vm.ResendLabelsAssign }
                  },
                  [_vm._v(_vm._s(_vm.$t("Resend")))]
                )
              : _vm._e()
          ],
          1
        ),
        _c("br"),
        _c("br"),
        _c(
          "div",
          { staticStyle: { float: "left !important", display: "flex" } },
          [
            _vm.CUSTOMER === "LCL"
              ? _c(
                  "v-btn",
                  {
                    staticClass: "btn  ml-2",
                    attrs: { disabled: _vm.btnDisabledBlinking, text: "" },
                    on: { click: _vm.toresendAllLabels }
                  },
                  [_vm._v(_vm._s(_vm.$t("Resend All")))]
                )
              : _vm._e(),
            _vm.CUSTOMER === "LCL"
              ? _c(
                  "v-btn",
                  {
                    staticClass: "btn  ml-2",
                    attrs: { disabled: _vm.btnDisabledBlinking, text: "" },
                    on: { click: _vm.toblinkLedAllLabels }
                  },
                  [_vm._v(_vm._s(_vm.$t("Blink LED All")))]
                )
              : _vm._e(),
            _vm.CUSTOMER === "LCL"
              ? _c(
                  "v-btn",
                  {
                    staticClass: "btn  ml-2",
                    staticStyle: { width: "170px !important" },
                    attrs: { disabled: _vm.btnDisabledBlinking, text: "" },
                    on: { click: _vm.PageFlipAll }
                  },
                  [_vm._v(_vm._s(_vm.$t("Page Flip All")))]
                )
              : _vm._e(),
            _c("LabelSchedule", {
              attrs: {
                openScheduleModel1: "openScheduleModel1",
                selectedLabel: _vm.selectedLabel,
                PageListarray: _vm.PageListarray
              },
              on: { fireResetLabel: _vm.resetLabel }
            })
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "v-dialog",
              {
                attrs: { width: "300" },
                model: {
                  value: _vm.messageDialog,
                  callback: function($$v) {
                    _vm.messageDialog = $$v
                  },
                  expression: "messageDialog"
                }
              },
              [
                _c(
                  "v-card",
                  {
                    staticClass: "popup add_store_popup",
                    attrs: { align: "center", justify: "center" }
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c("v-col", [
                          _c("span", {
                            domProps: { textContent: _vm._s(_vm.messageText) }
                          })
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "d-flex justify-center" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn",
                            attrs: { text: "", icon: "" },
                            on: {
                              click: function($event) {
                                ;(_vm.messageDialog = false),
                                  _vm.searchLabelList()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("OK")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "col-12 pr-0", staticStyle: { padding: "0px" } },
        [
          _c("Pagination", {
            attrs: {
              pageInfoText: _vm.pageInfoText,
              pageIndex: _vm.pageIndex,
              rowPerPage: _vm.rowPerPage,
              goToPageInput: _vm.goToPageInput,
              totalPages: _vm.totalPages
            },
            on: {
              paging: function($event) {
                return _vm.paging($event)
              },
              updatePage: function($event) {
                return _vm.updatePage($event)
              }
            }
          })
        ],
        1
      ),
      _c("a", { ref: "link", style: { display: "none" } }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", scrollable: "", width: "500" },
          model: {
            value: _vm.assignpopup,
            callback: function($$v) {
              _vm.assignpopup = $$v
            },
            expression: "assignpopup"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _c("v-card-title", [
                _c("h3", { staticClass: "page-title-bar mb-2" }, [
                  _c("i", {
                    staticClass: "ico ico-label",
                    staticStyle: { "margin-right": "13px" }
                  }),
                  _vm._v(_vm._s(_vm.$t("Assign")) + " ")
                ])
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("p", { staticClass: "txt-s mb-2" }, [
                            _vm._v("MarketLandscape ID "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*")
                            ])
                          ]),
                          _c("v-text-field", {
                            staticClass: "form-file",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              "prepend-icon": "",
                              solo: "",
                              flat: "",
                              disabled: "",
                              "persistent-hint": "",
                              placeholder:
                                "" + _vm.$t("Input MarketLanscape ID")
                            },
                            model: {
                              value: _vm.selectedStore,
                              callback: function($$v) {
                                _vm.selectedStore = $$v
                              },
                              expression: "selectedStore"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("p", { staticClass: "txt-s mb-1" }, [
                            _vm._v(" " + _vm._s(_vm.$t("Product Type")) + " ")
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              on: {
                                change: function($event) {
                                  return _vm.producttype($event)
                                }
                              },
                              model: {
                                value: _vm.productType,
                                callback: function($$v) {
                                  _vm.productType = $$v
                                },
                                expression: "productType"
                              }
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: "Accessory",
                                  value: "Accessory"
                                }
                              }),
                              _c("v-radio", {
                                attrs: { label: "Device", value: "Device" }
                              }),
                              _c("v-radio", {
                                attrs: { label: "Name Tag", value: "Name" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticStyle: { "margin-top": "-23px" } },
                        [
                          _c("p", { staticClass: "txt-s mb-1" }, [
                            _vm._v(" " + _vm._s(_vm.$t("Product")) + " "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*")
                            ])
                          ]),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "5" } },
                                [
                                  _c("v-select", {
                                    staticClass: "form-select custom-select",
                                    staticStyle: { "padding-top": "0px" },
                                    attrs: {
                                      items: _vm.product,
                                      placeholder: _vm.$t("Product ID"),
                                      outlined: "",
                                      dense: "",
                                      filled: "",
                                      disabled: !_vm.producttypechange,
                                      "persistent-hint": "",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.productdataselect,
                                      callback: function($$v) {
                                        _vm.productdataselect = $$v
                                      },
                                      expression: "productdataselect"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticStyle: {
                                    "padding-top": "11px",
                                    "padding-left": "00px"
                                  }
                                },
                                [
                                  _vm.productdataselect === "Product ID"
                                    ? _c("v-autocomplete", {
                                        staticStyle: { "font-size": "14px" },
                                        attrs: {
                                          items: _vm.productlist,
                                          outlined: "",
                                          dense: "",
                                          "item-text": "articleId",
                                          "item-value": "articleId",
                                          placeholder: "Input",
                                          disabled: !_vm.producttypechange,
                                          "return-object": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function(data) {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "14px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          data.item.articleId
                                                        ) +
                                                          " - " +
                                                          _vm._s(
                                                            data.item
                                                              .articleName
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1202289693
                                        ),
                                        model: {
                                          value: _vm.productValue,
                                          callback: function($$v) {
                                            _vm.productValue = $$v
                                          },
                                          expression: "productValue"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.productdataselect !== "Product ID"
                                    ? _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.productlist,
                                          outlined: "",
                                          dense: "",
                                          "item-text": "articleName",
                                          "item-value": "articleName",
                                          placeholder: "Input",
                                          disabled: !_vm.producttypechange,
                                          "return-object": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function(data) {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "14px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          data.item.articleId
                                                        ) +
                                                          " - " +
                                                          _vm._s(
                                                            data.item
                                                              .articleName
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1202289693
                                        ),
                                        model: {
                                          value: _vm.productValue,
                                          callback: function($$v) {
                                            _vm.productValue = $$v
                                          },
                                          expression: "productValue"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticStyle: { "margin-top": "-30px" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("p", { staticClass: "txt-s mb-2" }, [
                            _vm._v(_vm._s(_vm.$t("Label Code")) + " "),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("*")
                            ])
                          ]),
                          _c("v-text-field", {
                            staticClass: "form-file",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              "prepend-icon": "",
                              solo: "",
                              flat: "",
                              "persistent-hint": "",
                              placeholder: "" + _vm.$t("Input Label Code")
                            },
                            model: {
                              value: _vm.labelCode,
                              callback: function($$v) {
                                _vm.labelCode = $$v
                              },
                              expression: "labelCode"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "ma-0 pa-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c("v-checkbox", {
                            staticClass: "ma-0 pa-0",
                            attrs: {
                              disabled: _vm.labelCode.length === 0,
                              label: "" + _vm.$t("Template Types by Label"),
                              "hide-details": ""
                            },
                            on: {
                              change: function($event) {
                                _vm.gettemplatesbylabel()
                                _vm.changeflag($event)
                              }
                            },
                            model: {
                              value: _vm.templatetypebylabel,
                              callback: function($$v) {
                                _vm.templatetypebylabel = $$v
                              },
                              expression: "templatetypebylabel"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("p", { staticClass: "txt-s mb-2" }, [
                            _vm._v("Template ")
                          ]),
                          _vm.templatetypebylabel
                            ? _c("v-select", {
                                staticClass: "form-file",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  items: _vm.templatelist,
                                  "item-text": "templateName",
                                  "hide-details": "",
                                  "prepend-icon": "",
                                  solo: "",
                                  flat: "",
                                  "persistent-hint": "",
                                  placeholder: "" + _vm.$t("Select Template"),
                                  "return-object": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function(data) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "14px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(data.item.templateName) +
                                                  " (" +
                                                  _vm._s(data.item.width) +
                                                  " X " +
                                                  _vm._s(data.item.height) +
                                                  ")"
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2313160238
                                ),
                                model: {
                                  value: _vm.templatelistvalue,
                                  callback: function($$v) {
                                    _vm.templatelistvalue = $$v
                                  },
                                  expression: "templatelistvalue"
                                }
                              })
                            : _vm._e(),
                          !_vm.templatetypebylabel
                            ? _c("v-select", {
                                staticClass: "form-file",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  items: _vm.templatelist,
                                  "item-text": "typeName",
                                  "hide-details": "",
                                  "prepend-icon": "",
                                  solo: "",
                                  flat: "",
                                  "persistent-hint": "",
                                  placeholder: "" + _vm.$t("Select Template"),
                                  "return-object": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function(data) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "14px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(data.item.typeName) +
                                                  " - " +
                                                  _vm._s(data.item.templateSize)
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3844561213
                                ),
                                model: {
                                  value: _vm.templatelistvalue,
                                  callback: function($$v) {
                                    _vm.templatelistvalue = $$v
                                  },
                                  expression: "templatelistvalue"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.ToassignLabel()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.assignpopup = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", persistent: "", width: "430" },
          model: {
            value: _vm.dialogBlinkLED,
            callback: function($$v) {
              _vm.dialogBlinkLED = $$v
            },
            expression: "dialogBlinkLED"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup" },
            [
              _c("v-card-title", [
                _c("h3", { staticClass: "page-title-bar" }, [
                  _c("i", { staticClass: "ico ico-label" }),
                  _vm._v(_vm._s(_vm.$t("BlinkLED All")) + " ")
                ])
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { staticStyle: { "padding-top": "10px" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0" },
                        [
                          _c("v-select", {
                            staticClass: "form-select",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("Pattern"),
                              items: _vm.patternItems,
                              "item-text": "name",
                              "item-value": "id",
                              disabled: _vm.selectBoxEnable
                            },
                            model: {
                              value: _vm.pattern,
                              callback: function($$v) {
                                _vm.pattern = $$v
                              },
                              expression: "pattern"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            staticClass: "form-select",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("Duration"),
                              placeholder: _vm.durationItems[0],
                              items: _vm.durationItems
                            },
                            model: {
                              value: _vm.duration,
                              callback: function($$v) {
                                _vm.duration = $$v
                              },
                              expression: "duration"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            staticClass: "form-select",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t(_vm.Label_Color),
                              placeholder: _vm.colorItems[0],
                              items: _vm.colorItems
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "0.8125rem"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t(item)))]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "selection",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "LedPatternClass",
                                        staticStyle: {
                                          display: "contents !important"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t(item)))]
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.color,
                              callback: function($$v) {
                                _vm.color = $$v
                              },
                              expression: "color"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.handleSaveBtnClick }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogBlinkLED = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", persistent: "", width: "400" },
          model: {
            value: _vm.pageFlipDialog,
            callback: function($$v) {
              _vm.pageFlipDialog = $$v
            },
            expression: "pageFlipDialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup" },
            [
              _c("v-card-title", [
                _c("h3", { staticClass: "page-title-bar" }, [
                  _c("i", { staticClass: "ico ico-label" }),
                  _vm._v(_vm._s(_vm.$t("PageFlip All")) + " ")
                ])
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { staticStyle: { "padding-top": "10px" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0" },
                        [
                          _c("v-select", {
                            staticClass: "form-select",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: _vm.$t("Page"),
                              items: _vm.PageListarray,
                              "item-text": "name",
                              "item-value": "id"
                            },
                            model: {
                              value: _vm.PageValue,
                              callback: function($$v) {
                                _vm.PageValue = $$v
                              },
                              expression: "PageValue"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.PageFlipAllSaveBtn }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.pageFlipDialog = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", persistent: "", width: "500" },
          model: {
            value: _vm.MessageBoardpopup,
            callback: function($$v) {
              _vm.MessageBoardpopup = $$v
            },
            expression: "MessageBoardpopup"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup messageClass" },
            [
              _c("span", [
                _vm._v(
                  "You are assigning the wrong ESL tag model to the current store brand. Please check the ESL tag model."
                )
              ]),
              _c("span", [_vm._v("Rogers can only use RED ESL tag model.")]),
              _c("span", [_vm._v("Fido can only use YELLOW ESL tag model")]),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.MessageBoardpopup = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("OK")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }