var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar mt-7" }, [
        _c("i", { staticClass: "ico ico-system" }),
        _vm._v(_vm._s(_vm.$t("Label Properties")) + " ")
      ]),
      _c(
        "div",
        [
          _c("v-row", { staticStyle: { "margin-top": "10px" } }, [
            _c("div", { staticClass: "col-8" }, [
              _c("div", { staticClass: "searchArea" }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("v-text-field", {
                      staticClass: "form-input ml-3 col mandatory upper-case",
                      attrs: {
                        label: _vm.$t("Label Code") + "*",
                        placeholder: "" + _vm.$t("Input The Label Code"),
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        clearable: ""
                      },
                      model: {
                        value: _vm.labelCode,
                        callback: function($$v) {
                          _vm.labelCode = $$v
                        },
                        expression: "labelCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col pl-3 pr-0" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn type-search ml-3",
                        attrs: {
                          text: "",
                          disabled:
                            _vm.labelCode === "" ||
                            _vm.labelCode === undefined ||
                            _vm.labelCode === null
                        },
                        on: { click: _vm.getLabelsTypeInfo }
                      },
                      [_vm._v(_vm._s(_vm.$t("Search")) + " ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col pl-0" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn type-search ml-3",
                        attrs: { text: "" },
                        on: { click: _vm.clearSearch }
                      },
                      [_vm._v(_vm._s(_vm.$t("Clear")) + " ")]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "700", persistent: "" },
          on: {
            "click:outside": function($event) {
              _vm.labelDetailsPopup = false
            }
          },
          model: {
            value: _vm.labelDetailsPopup,
            callback: function($$v) {
              _vm.labelDetailsPopup = $$v
            },
            expression: "labelDetailsPopup"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "cornerClose",
                  attrs: { icon: "", dark: "", "x-small": "" },
                  on: {
                    click: function($event) {
                      _vm.labelDetailsPopup = false
                    }
                  }
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("mdi-close")])],
                1
              ),
              _c("div", { staticClass: "popup-titbox align-items-center" }, [
                _c("h3", { staticClass: "page-title-bar" }, [
                  _c("i", { staticClass: "ico ico-template" }),
                  _vm._v(" " + _vm._s(_vm.$t("Label Properties")) + " ")
                ]),
                _c("div", { staticClass: "spacer" })
              ]),
              _c(
                "div",
                { staticClass: "v-data-table tbl-type01 mt-6 theme--light" },
                [
                  _c("div", { staticClass: "v-data-table__wrapper" }, [
                    _c(
                      "table",
                      { staticClass: "table" },
                      _vm._l(_vm.labelDetails, function(value, name) {
                        return _c("tr", { key: name }, [
                          _c("td", { attrs: { width: "30%" } }, [
                            _c("strong", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("removeSpacefromCapitalLetter")(
                                      _vm._f("camelCaseToProperCase")(name)
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _c("td", [_vm._v(_vm._s(value))])
                        ])
                      }),
                      0
                    )
                  ])
                ]
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.labelDetailsPopup = false
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }