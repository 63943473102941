<template>
    <div>
        <!-- Page Title -->
        <h2 class="page-title-bar">
      <i class="ico ico-powerrail"></i>{{ $t("Power Rails") }}
    </h2>
        <!-- Search Area -->
        <div class="searchArea">

            <!-- Power Rail Input -->
            <v-text-field style="max-width: 20%;" v-model="search.code" @keyup.enter="togetPowerRailList(1)" :label="$t('Power Rail ID')"
                :placeholder="$t('Power Rail ID')" class="form-input ml-3" outlined dense hide-details
                clearable></v-text-field>
            <!-- Network Select -->
            <v-select style="max-width: 20%;" v-model="search.state" :label="$t('Status')" :placeholder="$t('Select status')"
                :items="searchOptions.network" item-text="text" item-value="abbr" class="form-select ml-3" outlined
                dense hide-details clearable></v-select>
            <!-- Search Button -->
            <v-btn @click="togetPowerRailList(1)" class="btn type-search ml-3" text>
                {{ $t('Search') }}
            </v-btn>
        </div>
        <div class="col-12 pl-0 pb-0 pt-0 pr-0 d-flex" style="margin-top:10px">
            <div class="col-12 pl-0">
                <v-data-table v-model="selected" @item-selected="ConditionalCheck" @click:row="detailPopup" :headers="PowerRailInfoHeaders"
                    :hide-default-footer="true" :items="powerrails"  :singleSelect="true"
                    :options.sync="options" show-select :server-items-length="totalpowerrails" class="tbl-type01 mt-10"
                    item-key="code">
                    <!-- No Data Slot -->
                    <template slot="no-data">
                        <p>
                            {{ $t('No data available') }}
                        </p>
                    </template>
                    <!-- Network Status Slot -->
                    <template v-slot:[`item.assignedLabelCount`]="{ item }">
                        <p>
                          <i @click="GetLabelInfo(item)" style="cursor: pointer;">
                           <img src="@/assets/img/info-ico-custom.png" alt="" height="25" />
                          </i>
                        </p>
                    </template>
                      <!-- Connectivity state Slot -->
                    <template v-slot:[`item.status`]="{ item }">
                       <div class="d-flex justify-center" v-if="item.status !== '-'">
                         <p  class="circle"  :style="item.status && item.status.toLowerCase() === 'offline'.toLowerCase() ? 'background-color: #e27070' : 'background-color: #9bcde3' " ></p>
                       </div>
                       <div v-else>{{ item.status }}</div>
                    </template>
                </v-data-table>
            </div>
        </div>
        <PowerRailLabelDetailModel v-model="LabelDetailsPopup" :powerRailCode="powerRailCode" :ControllerID="ControllerID"  />
        <div class="table-options">
            <div>
                <v-btn :disabled="selected.length===0 || btnDisabledDetailed" @click="assignPowerRailPopup()" class="btn ml-2" text>{{
                    $t('Assign') }}
                </v-btn>
                <v-btn :disabled="selected.length===0 || btnDisabledDetailed|| unassignState" @click="UnlinkPowerRail()"  class="btn ml-2" text>{{
                    $t('Unassign') }}
                </v-btn>
                <v-btn :disabled="selected.length===0 || btnDisabledDetailed" @click="DeletePowerRail()"  class="btn ml-2" text>{{
                    $t('Delete') }}
                </v-btn>

            </div>

        </div>
        <!-- Pagination Component -->
        <Pagination @paging="paging($event)" :pageInfoText="pageInfoText" :pageIndex="pageIndex"
            :rowPerPage="rowPerPage" :goToPageInput="goToPageInput" :totalPages="totalPages"
            @updatePage="updatePage($event)"></Pagination>
        <!-- Hidden Link Element for Exporting -->
        <a ref="link" :style="{ display: 'none' }" />
        <!-- Message Box Component -->
        <msg-box :msgBox="msgBox" @closeMsgBox="closeMsgBox" />

 <!-- assignPopup -->
 <v-dialog v-model="assignPopup" persistent scrollable width="450">
      <v-card class="popup add_store_popup">
        <v-card-title>
          <h3 class="page-title-bar">
            <i class="ico ico-multi"></i>{{ $t("ASSIGN") }}
          </h3>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="controllerCode"
            outlined
            dense
            hide-details
            readonly
            :placeholder="`${$t('Input ID')}`"
            class="form-input mb-2"
            :label="$t('PowerController ID')"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            @click="assignPowerRail()"
            text
            icon
            class="btn"
            :disabled="controllerCode===null || controllerCode===''"
            >{{ $t("Assign") }}
          </v-btn>
          <v-btn @click="assignPopup=false" class="btn" text icon
            >{{ $t("Cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </div>
</template>

<script>
// libraries
import { mapGetters } from 'vuex'
// mixins
import MsgBoxParent from '@/mixins/MsgBoxParent'
// utils
import EventBus from '@/plugins/eventBus'
import codes from '@/plugins/codes'
// components
import PowerRailLabelDetailModel from './model/PowerRailLabelDetailModel.vue'
import commons from '@/plugins/commons'
import Pagenation from '@/mixins/Pagenation'
import Pagination from '@/components/pagination/Pagination.vue'
// Importing CSS
import '@/assets/css/freeze.css'

export default {
  name: 'GatewayInfo',
  mixins: [MsgBoxParent, Pagenation],
  components: {
    PowerRailLabelDetailModel,
    Pagination
  },
  data () {
    return {
      user: null,
      btnDisabledDetailed: false,
      btnDisabledAddDelete: false,
      btnDisabledReboot: false,
      btnDisabledExport: false,
      message: '',
      page: 1,
      pageIndex: 1,
      rowPerPage: '10',
      pageCount: 0,
      totalPages: 0,
      totalVisiblePages: 5,
      totalpowerrails: null,
      options: {},
      pageInfoText: '',
      requestConfig: {},
      selected: [],
      search: {
        code: null,
        state: null,
        sort: 'code,asc'
      },
      powerrails: [],
      ROW_CLASS: 'row-class',
      serverType: 'server',
      assignPopup: false,
      InputID: null,
      detailsPopup: false,
      LabelDetailsPopup: false,
      LabelInfoList: {},
      ControllerID: null,
      powerRailCode: null,
      controllerCode: null,
      unassignState: false
    }
  },
  computed: {
    PowerRailInfoHeaders () {
      return [
        {
          text: this.$t('POWER RAIL ID'),
          value: 'code',
          align: 'left',
          width: '10%',
          disabled: true,
          sortable: true
        },
        {
          text: this.$t('POWER RAIL MAC'),
          value: 'macAddress',
          align: 'left',
          width: '10%',
          disabled: false,
          sortable: true
        },
        {
          text: this.$t('TYPE'),
          value: 'type',
          align: 'left',
          width: '12%',
          disabled: false,
          sortable: true
        },
        {
          text: this.$t('BATTERY'),
          value: 'battery',
          align: 'left',
          width: '10%',
          disabled: false,
          sortable: false
        },
        {
          text: this.$t('SIGNAL'),
          value: 'signal',
          align: 'left',
          width: '10%',
          disabled: false,
          sortable: false
        },
        {
          text: this.$t('FW VERSION'),
          value: 'fwversion',
          align: 'left',
          width: '10%',
          disabled: false,
          sortable: false
        },
        {
          text: this.$t('CONNECTIVITY'),
          value: 'status',
          align: 'center',
          width: '10%',
          disabled: false,
          sortable: false
        },
        {
          text: this.$t('STATUS'),
          align: 'left',
          value: 'state',
          width: '10%',
          sortable: true,
          disabled: true
        },
        {
          text: this.$t('LABELS'),
          value: 'assignedLabelCount',
          disabled: true,
          align: 'center',
          width: '10%',
          sortable: false
        },

        {
          text: this.$t('LAST CONNECTION TIME'),
          align: 'left',
          value: 'statusUpdateTime',
          width: '10%',
          sortable: true,
          disabled: true
        }

      ]
    },
    searchOptions () {
      return {
        network: [
          { text: this.$t('PROCESSING'), abbr: 'PROCESSING' },
          { text: this.$t('SUCCESS'), abbr: 'SUCCESS' },
          { text: this.$t('TIMEOUT'), abbr: 'TIMEOUT' },
          { text: this.$t('UNASSIGNING'), abbr: 'UNASSIGNING' },
          { text: this.$t('UNASSIGNED'), abbr: 'UNASSIGNED' }

        ]
      }
    },
    selectedStore () {
      return this.$store.getters['dataStore/GET_SELECTED_STORE'].code
    },
    ...mapGetters({
      store: 'dataStore/GET_SELECTED_STORE'
    })

  },
  watch: {
    options: function () {
      this.togetPowerRailList(this.page)
    },
    pageIndex: {
      handler (page) {
        this.goToPageInput = page
      }
    }
  },

  created () {
    // Get the user from the auth store state
    this.user = this.$store.state.auth.user
  },
  mounted () {
    this.$store.dispatch('auth/getDisabledBtn', '12100').then(flag => {
      this.btnDisabledDetailed = flag
    })
    EventBus.$emit('enableSelectedStores', true)
    if (
      this.store === undefined ||
      !this.store.code ||
      this.store.code === ''
    ) {
      EventBus.$emit('openSelectedStores')
    } else {
      this.togetPowerRailList(1)
    }
  },
  methods: {
    // Perform pagination by updating the gateway list for the specified page
    paging (input) {
      this.goToPageInput = input
      this.togetPowerRailList(input)
    },
    // Update the number of items per page and update the gateway list accordingly
    updatePage (event) {
      this.rowPerPage = event.toString()
      this.paging(1)
    },
    // Build the search parameters for retrieving the gateway list
    buildSearchParams (params, search) {
      if (search.state !== null) params.state = search.state
      if (!commons.isNull(search.code)) params.code = search.code
      return params
    },
    getPowerRailSortKey (sortKey) {
      switch (sortKey) {
        case 'statusUpdateTime': sortKey = 'statusUpdateTime'
          break
        case 'controllerId': sortKey = 'controllerId'
          break
        default: break
      }
      return sortKey
    },
    buildGetPowerRailParams () {
      // const { itemsPerPage } = this.options
      const { sortBy, sortDesc, itemsPerPage } = this.options
      let params = { store: this.store.code }
      if (itemsPerPage > 0) params.size = this.rowPerPage
      params = this.buildSearchParams(params, this.search)
      let sort = null
      if (commons.isSorting(sortBy, sortDesc)) {
        const sortKey = this.getPowerRailSortKey(sortBy[0])
        const sortOrder = sortDesc[0]
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      params.company = this.$store.state.auth.user.company
      params.store = this.store.code
      return params
    },
    getRowClass () {
      return codes.ROW_CLASS
    },
    assignPowerRailPopup () {
      this.controllerCode = null
      this.controllerCode = this.selected[0].code
      this.assignPopup = true
    },
    detailPopup () {
      // if (this.btnDisabledDetailed) {
      this.detailsPopup = true
      // }
    },
    togetPowerRailList (page) {
      const params = this.buildGetPowerRailParams()
      if (commons.isNull(params.store)) return
      if (page !== null) params.page = page - 1
      const config = { params }
      this.$utils
        .callAxios(
          codes.requests.toGetPowerRailController.method,
          codes.requests.toGetPowerRailController.url,
          config
        )
        .then(res => {
          const headers = res.headers
          if (res.status === 200) {
            this.powerrails = res.data.powerRails.map((obj, idx) => {
              return {
                serialNo: headers['x-number'] * 1 * headers['x-size'] + idx + 1,
                ...obj,
                statusUpdateTime: commons.convertDate(obj.statusUpdateTime),
                macAddress: obj.macAddress ? obj.macAddress.split('_').join(' ') : obj.macAddress
              }
            })
            this.totalpowerrails = this.powerrails.length
            // Pagination
            this.pageInfoText = this.getPageInfoText(res.headers)
            if (commons.isValidStr(res.headers['x-totalpages'])) {
              this.totalPages = res.headers['x-totalpages'] * 1
            }
            this.page = page
            this.pageIndex = res.headers['x-number'] * 1 + 1
            this.pageCount = res.headers['x-totalpages'] * 1
          } else {
            this.powerrails = []
          }
        })
        .catch(error => {
          console.log(error)
          this.powerrails = []
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.returnMsg),
            'error'
          )
        })
    },
    // toGetPowerRailController

    getPageInfoText: function (headers) {
      if (headers['x-totalelements'] === 0) return '0 to 0, 0' + ' ' + this.$t('in total')
      return `${headers['x-number'] * 1 * headers['x-size'] + 1}
      ${this.$t('to')} ${(headers['x-number'] * 1 * headers['x-size']) + headers['x-total-count'] * 1},
       ${headers['x-totalelements'] * 1}` + ' ' + this.$t('in total')
    },
    GetLabelInfo (value) {
      this.ControllerID = value.controllerId
      this.powerRailCode = value.code
      this.LabelDetailsPopup = true
    },
    assignPowerRail () {
      const body = { }
      const params = {
        company: this.$store.state.auth.user.company,
        store: this.store.code,
        powerRailCode: this.controllerCode
      }
      const config = { params }
      this.$utils
        .callAxiosWithBody(
          codes.requests.toAssignPowerRail.method,
          codes.requests.toAssignPowerRail.url + '/' + this.store.code,
          body,
          config
        )
        .then(res => {
          if (res.status === 200) {
            EventBus.$emit('messageAlert', this.$t(res.data.returnMsg))
            this.assignPopup = false
            this.selected = []
          }
        })
        .catch(error => {
          console.log(error)
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.returnMsg),
            'error'
          )
          this.assignPopup = false
        })
    },
    UnlinkPowerRail () {
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore,
          powerRailCode: this.selected[0].code
        }
      }
      this.$utils
        .callAxios(
          codes.requests.toUnlinkPowerRail.method,
          codes.requests.toUnlinkPowerRail.url,
          config
        )
        .then(res => {
          if (res.status === 200) {
            EventBus.$emit('messageAlert', this.$t(res.data.returnMsg))
            this.paging(1)
          } else {
            EventBus.$emit(
              'messageAlert',
              this.$t('Something went wrong. Please try after some time.')
            )
          }
        })
        .catch(error => {
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    },
    DeletePowerRail () {
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore,
          powerRailCode: this.selected[0].code
        }
      }
      this.$utils
        .callAxios(
          codes.requests.todeletePowerRail.method,
          codes.requests.todeletePowerRail.url,
          config
        )
        .then(res => {
          if (res.status === 200) {
            EventBus.$emit('messageAlert', this.$t(res.data.returnMsg))
            this.paging(1)
          } else {
            EventBus.$emit(
              'messageAlert',
              this.$t('Something went wrong. Please try after some time.')
            )
          }
        })
        .catch(error => {
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    },
    // Button Actions based on conditions
    ConditionalCheck (data) {
      this.unassignState = false
      if (data.value === true) {
        if (data.item.state === 'UNASSIGNED' || data.item.state === 'UNASSIGNING') {
          this.unassignState = true
        }
      }
    }
    // End
  }

}
</script>
<style scoped>
.hidden {
    display: none;
}

.pageAlign {
    float: right;

}
.circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
</style>
