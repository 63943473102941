<template>
  <v-dialog v-model="addDialogPopup" width="1300" persistent>
    <v-card class="popup add_store_popup">
      <div>
        <div
          class="popup-titbox d-flex align-center justify-space-between w-100 mb-5"
        >
          <h3 class="page-title-bar align-start d-grid w-100 ">
            <div>
              <img
                src="@/assets/img/access-point.svg"
                style="margin-right: 15px; height: 31px;"
              />{{ $t(sensorDetails.name) }}
            </div>
            <div style="margin-left: 48px;" class="w-100 d-flex">
              <p class="info-text d-flex" style="color: #666666;">
                <span class="mr-1">{{ $t("Sensor ID") }} : </span>
                <span
                  ><b> {{ sensorDetails.sensorCode }} </b></span
                >
              </p>
              <div
                class="d-flex justify-space-around"
                style="width: calc(100% - 180px);"
              >
                <p class="info-text d-flex">
                  <span class="mr-1 d-flex align-end">
                    {{ $t("Temp") }} :
                    <img
                      src="@/assets/img/thermometer-lines.png"
                      alt="temp"
                      height="16"
                      class="ml-1"
                  /></span>
                  <span v-if="headersStatistics.temp">
                    <span style="color: #43425D;" v-if="toggleState"
                    >{{ headersStatistics.temp }}<span>°</span>C</span
                  >
                  <span style="color: #43425D;" v-else
                    >{{ (headersStatistics.temp).toFixed(2) }}<span>°</span>F</span
                  >
                  </span>
                  <span v-else style="color: #43425D;">-</span>
                </p>
                <p class="info-text d-flex">
                  <span class="mr-1 d-flex align-end">
                    {{ $t("Hum") }}  :
                    <img
                      src="@/assets/img/water-percent.png"
                      alt="temp"
                      height="16"
                      class="ml-1"
                  /></span>
                  <span style="color: #43425D;" v-if="headersStatistics.hum"
                    >{{ headersStatistics.hum }}%</span
                  >
                  <span style="color: #43425D;" v-else>-</span>
                </p>
                <p class="info-text d-flex">
                  <span class="mr-1 d-flex align-end">
                    {{ $t("Battery") }}  :
                    <img
                      src="@/assets/img/battery-good.png"
                      alt="temp"
                      height="16"
                      class="ml-1"
                      v-if="headersStatistics.battery === 'GOOD'"
                    />
                    <img
                      v-else
                      src="@/assets/img/ico-state-battery.png"
                      alt="temp"
                      height="16"
                      class="ml-1"
                    />
                  </span>
                  <span
                    style="color: #43425D;"
                    v-if="headersStatistics.battery"
                    >{{ headersStatistics.battery }}</span
                  >
                  <span style="color: #43425D;" v-else>-</span>
                </p>
                <p class="info-text d-flex">
                  <span class="mr-1 d-flex align-end">
                    {{ $t("Sensor Status") }}  :
                  </span>
                  <span
                    v-if="sensorDetails.status !== '-'"
                    class="d-flex justify-center align-center border-status"
                    :style="
                      sensorDetails.status === 'OFFLINE'
                        ? 'border-color: #FF8097'
                        : 'border-color: #6DA3F8'
                    "
                  >
                    <p
                      class="circle mr-1"
                      :style="
                        sensorDetails.status === 'OFFLINE'
                          ? 'background-color: #FF8097'
                          : 'background-color: #6DA3F8'
                      "
                    ></p>
                    <span
                      :style="
                        sensorDetails.status === 'OFFLINE'
                          ? 'color: #FF8097'
                          : 'color: #6DA3F8'
                      "
                      style="font-size: 12px;"
                      >{{ sensorDetails.status }}</span
                    >
                  </span>
                  <span v-else>-</span>
                </p>
                <p class="info-text d-flex">
                  <span
                    class="mr-1 d-flex align-end"
                    style="cursor: pointer;"
                    @click="openEditDialog(sensorDetails)"
                  >
                    <v-icon size="24" class=" darken-2" style="color: #A2ACBD;"
                      >mdi-square-edit-outline</v-icon
                    ></span
                  >
                </p>
              </div>
            </div>
          </h3>
        </div>
      </div>
      <div class="w-100 d-flex justify-end mb-3">
        <v-select
          label=""
          :placeholder="$t('Select Duration')"
          outlined
          dense
          hide-details
          v-model="selectedDuration"
          :items="durationList"
          item-text="text"
          item-value="val"
          class="form-select col-3 pt-0"
          @change="updateDuration"
        >
        </v-select>
      </div>
      <div
        class="fixedHeader"
        style="max-height: calc(100vh - 390px) !important;overflow: auto;"
      >
        <div style="width: 100%;">
          <div class="row mb-6 mx-0 justify-space-between col-12 px-0">
            <div class="px-0 py-0 col-6">
              <div class="card">
                <div class="card-header mb-3" style="font-weight: 600;">
                  {{ $t("Temperature") }} <span v-if="toggleState">(°C)</span
                  ><span v-else>(°F)</span>
                </div>
                <div
                  class="card-body"
                  v-if="
                    tempLineChartData.series[0].data.length !== 0 &&
                      tempLineChartData.xAxis.categories.length !== 0
                  "
                >
                  <LineChart
                    :chartData="tempLineChartData"
                    :legend="legend"
                    :height="250"
                    :colors="['#6DA3F8', '#9AC7DB']"
                  />
                  <div class="d-flex justify-end" style="font-size: 12px;">
                    <div class="mr-3">
                      <b>{{ $t("Min") }} :</b>
                      <span v-if="toggleState"
                        >{{
                          this.temperatureStatistics.minTemperature
                        }}
                        °C</span
                      >
                      <span v-else
                        >{{ (this.temperatureStatistics.minTemperature).toFixed(2) }}°F</span
                      >
                    </div>
                    <div class="mr-3">
                      <b>{{ $t("Max") }} :</b>
                      <span v-if="toggleState"
                        >{{ this.temperatureStatistics.maxTemperature }}°C</span
                      >
                      <span v-else
                        >{{ (this.temperatureStatistics.maxTemperature).toFixed(2) }}°F</span
                      >
                    </div>
                    <div class="mr-3">
                      <b>{{ $t("Avg") }} :</b>
                      <span v-if="toggleState"
                        >{{
                          this.temperatureStatistics.averageTemperature.toFixed(1)
                        }}°C</span
                      >
                      <span v-else
                        >{{
                          this.temperatureStatistics.averageTemperature
                        }} °F</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="wrapper-loading w-100"
                  v-else-if="
                    tempLineChartData.series[0].data.length === 0 && chartLoader &&  tempLineChartData.xAxis.categories.length === 0
                  "
                >
                  <div class="loader"></div>
                </div>
                <div class="w-100 px-5 mt-5" style="height: 100%;" v-else>
                  <v-alert
                    dense
                    color="#697A8A"
                    class="ma-0 d-flex justify-center"
                    style="line-height: 19px; color:#fff"
                  >
                    <v-icon class="mr-1" color="#fff">mdi-alert-outline</v-icon>
                    {{ $t("No data available") }}
                  </v-alert>
                </div>
              </div>
            </div>
            <div class="px-0 py-0 col-6">
              <div class="card">
                <div class="card-header mb-3" style="font-weight: 600;">
                  {{ $t("Humidity") }} (%)
                </div>
                <div
                  class="card-body"
                  v-if="
                    lineChartData.series[0].data.length !== 0 &&
                      lineChartData.xAxis.categories.length !== 0
                  "
                >
                  <LineChart
                    :chartData="lineChartData"
                    :legend="legend"
                    :height="250"
                    :colors="['#6DA3F8', '#9AC7DB']"
                  />
                  <div class="d-flex justify-end" style="font-size: 12px;">
                    <div class="mr-3">
                      <b>{{ $t("Min") }} :</b>
                      <span>{{ humidityStatistics.minHumidity }}%</span>
                    </div>
                    <div class="mr-3">
                      <b>{{ $t("Max") }} :</b>
                      <span>{{ humidityStatistics.maxHumidity }}%</span>
                    </div>
                    <div class="mr-3">
                      <b>{{ $t("Avg") }} :</b>
                      <span>{{ humidityStatistics.avergaeHumidity }}%</span>
                    </div>
                  </div>
                </div>
                <div
                  class="wrapper-loading w-100"
                  v-else-if="
                    lineChartData.series[0].data.length === 0 && chartLoader && lineChartData.xAxis.categories.length === 0
                  "
                >
                  <div class="loader"></div>
                </div>
                <div class="w-100 px-5 mt-5" style="height: 100%;" v-else>
                  <v-alert
                    dense
                    color="#697A8A"
                    class="ma-0 d-flex justify-center"
                    style="line-height: 19px; color:#fff"
                  >
                    <v-icon class="mr-1" color="#fff">mdi-alert-outline</v-icon>
                    {{ $t("No data available") }}
                  </v-alert>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" mt-5">
          <h4 class="d-flex">
            {{ $t("Alert History") }}
          </h4>
        </div>
        <div style="width: 100%;">
          <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
            item-key="serialNo"
            :hide-default-footer="true"
            :options.sync="options"
            :items-per-page="rowPerPage"
            :server-items-length="tableItems.length"
            :show-select="false"
            class="tbl-type01 mt-5"
            fixed-header
            :height="tableItems && tableItems.length > 8 ? '550px' : 'auto'"
            :loading="tableLoading"
          >
            <template slot="loading">
              <div class="wrapper-loading">
                <div class="loader"></div>
              </div>
            </template>
            <template slot="no-data" v-if="!tableLoading">
              <v-alert
                dense
                color="#697A8A"
                class="ma-0"
                style="line-height: 19px"
              >
                <v-icon class="mr-1" color="#fff">mdi-alert-outline</v-icon>
                {{ $t("No data available") }}
              </v-alert>
            </template>
            <template v-slot:[`item.value`]="{ item }">
              <div
                :style="
                  item.type.toUpperCase() === 'High Temperature'.toUpperCase() ||
                  item.type.toUpperCase() === 'High Humidity'.toUpperCase()
                    ? 'color:#FF8097'
                    : 'color: #6DA3F8'
                "
              >
                <div v-if="item.type.toUpperCase() === 'High Temperature'.toUpperCase() ||
                item.type.toUpperCase() === 'Low Temperature'.toUpperCase()
                 ">
                  <p v-if="toggleState">{{ item.value }} <span>°</span>C</p>
                <p v-else>
                  {{ ((item.value * 9) / 5 + 32) .toFixed(2) }} <span>°</span>F
                </p>
                </div>
                <div v-else>
                  <p>{{ item.value }}%</p>
                </div>
              </div>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              <div>
                <div
                :style="
                  item.type.toUpperCase() === 'High Temperature'.toUpperCase() ||
                  item.type.toUpperCase() === 'High Humidity'.toUpperCase()
                    ? 'color:#FF8097'
                    : 'color: #6DA3F8'
                "
              >
                <p>{{ item.type }}</p>
              </div>
              </div>
            </template>
            <template v-slot:[`item.battery`]="{ item }">
              <p
                :style="
                  item.battery.toUpperCase() === 'Good'.toUpperCase() ? 'color:#51b0ff' : 'color: #ffa99e'
                "
              >
                {{ item.battery }}
              </p>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <span
                class="d-flex justify-center align-center border-status"
                :style="
                  item.status === 'Low'
                    ? 'border-color: orangered'
                    : 'border-color: aquamarine'
                "
              >
                <p
                  class="circle mr-1"
                  :style="
                    item.status === 'Low'
                      ? 'background-color: orangered'
                      : 'background-color: aquamarine'
                  "
                ></p>
                <span>{{ item.status }}</span>
              </span>
            </template>
          </v-data-table>
        </div>
      </div>
      <div class="table-options">
        <div>
          <v-btn
            text
            icon
            @click="exportHistory()"
            class="btn mr-3"
            :disabled="tableItems.length === 0"
            >{{ $t("Export") }}</v-btn
          >
          <v-btn text icon @click="closeModal()" class="btn">{{
            $t("Close")
          }}</v-btn>
        </div>
        <div v-if="tableItems.length > 0">
          <div class="pageInfo">{{ pageInfoText }}</div>
          <div class="pagination2">
            <label class="pageInfo mr-1" style="position:relative !important;">
              {{ $t("Show") }}
            </label>
            <v-select
              v-model="rowPerPage"
              :items="rowPerPageList"
              :placeholder="$t('Limit')"
              class="form-input pt-0 col rowPerPage"
              dense
              persistent-hint
              outlined
              hide-details
              @change="paging(1)"
            >
              <template v-slot:item="{ item }">
                {{ `${item}` }} {{$t('Rows')}}
              </template>
              <template v-slot:selection="{ item }">
                <span class="selection-v-select"> {{ `${item}` }}{{$t('Rows')}}</span>
              </template>
            </v-select>

            <div class="mr-2">
              <v-text-field
                v-model="goToPageInput"
                class="form-input pt-0 col goToPage ml-1"
                placeholder="Page"
                outlined
                dense
                hide-details
                solo
                @keyup.enter="goToPage(goToPageInput, paging, totalPages)"
                append-icon="mdi-chevron-right"
                @keypress="checkIsNumber($event)"
                @click:append="goToPage(goToPageInput, paging, totalPages)"
              ></v-text-field>
            </div>
            <button
              @click="goTenPageBackwards(page, paging)"
              :class="page < 10 ? 'pagination-nav-disabled' : null"
              class="pagination-nav pagination-nav-prev"
              type="button"
            >
              <img
                src="@/assets//img/ico-paging-prev.png"
                alt="이전 10페이지 이동"
              />
            </button>
            <v-pagination
              v-model="pageIndex"
              @input="paging"
              :length="totalPages * 1 || 1"
              :total-visible="8"
              color="#2f3b4c"
              style="display: block"
            ></v-pagination>
            <button
              @click="goTenPageForward(pageIndex, paging, totalPages)"
              :class="
                pageIndex + 10 > totalPages ? 'pagination-nav-disabled' : null
              "
              class="pagination-nav pagination-nav-next"
              type="button"
            >
              <img
                src="@/assets//img/ico-paging-next.png"
                alt="다음 10페이지 이동"
              />
            </button>
          </div>
        </div>
        <a ref="link" :style="{ display: 'none' }" />
      </div>
    </v-card>
    <!--export confirm Dialog -->
    <v-dialog v-model="confirmDownload" width="450" persistent>
      <v-card
        class="popup popup-success add_store_popup"
        align="center"
        justify="center"
      >
        <i class="ico-success"><img src="@/assets/img/ico-info.png" alt=""/></i>
        <div class="msg">
          <h4 class="mb-0" style="font-size:22px">{{ $t("Export Labels") }}</h4>
        </div>
        <v-row>
          <v-col cols="12">
            <span
              v-html="
                'Label list will be delivered in email.<br/>Report can take upto 30 minutes.<br/>Please enter email address.'
              "
              style="font-size: 15px"
            ></span>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="emailId"
              :label="`${$t('Email Id')} *`"
              :placeholder="$t('Enter your email id')"
              class="form-input ml-3 col mandatory"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions class="d-flex justify-center">
          <v-btn text icon @click="downloadAlertHistory" class="btn">{{
            $t("Send")
          }}</v-btn>
          <v-btn text icon @click="confirmDownload = false" class="btn">{{
            $t("Cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import commons from '@/plugins/commons'
import Pagenation from '@/mixins/Pagenation'
import LineChart from '@/components/Charts/SensorLineChart'
import codes from '@/plugins/codes'
import EventBus from '@/plugins/eventBus'
import moment from 'moment'
import { exportFiles } from '@/plugins/exporter'

export default {
  name: 'SensorDetails',
  mixins: [Pagenation],
  props: {
    addDialogPopup: {
      type: Boolean,
      default: false
    },
    sensorDetails: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    LineChart
  },
  data () {
    return {
      emailId: this.$store.state.auth.user.accountEmail,
      chartLoader: false,
      confirmDownload: false,
      tableLoading: false,
      temperatureStatistics: {
        averageTemperature: null,
        minTemperature: null,
        maxTemperature: null
      },
      humidityStatistics: {
        minHumidity: null,
        avergaeHumidity: null,
        maxHumidity: null
      },
      headersStatistics: {
        temp: null,
        hum: null,
        battery: null
      },
      isMounted: true,
      toggleState: true,
      options: {},
      duration: 'Last 2 Hours',
      status: 'Connected',
      durationlist: [
        'Last 2 Hours',
        'Last 5 Hours',
        'Last 2 Days',
        'Last 5 Days'
      ],
      durationList: [
        {
          text: this.$t('Last 1 Hour'),
          val: 1
        },
        {
          text: this.$t('Last 2 Hours'),
          val: 2
        },
        {
          text: this.$t('Last 6 Hours'),
          val: 6
        },
        {
          text: this.$t('Last 12 Hours'),
          val: 12
        },
        {
          text: this.$t('Last 1 Day'),
          val: 24
        },
        {
          text: this.$t('Last 2 Days'),
          val: 48
        },
        {
          text: this.$t('Last 1 Week'),
          val: 168
        }
      ],
      selectedDuration: 1,
      startDate: null,
      endDate: null,
      lineChartData: {
        series: [
          {
            name: 'Humidity',
            data: [],
            type: 'spline'
          }
        ],
        xAxis: {
          categories: [],
          tickInterval: 0,
          crosshair: {
            color: '#cccccc',
            width: 1
          },
          labels: {
            enabled: true,
            formatter: function () {
              if (typeof this.value !== 'string') return
              const dateString = this.value
              const dateTime = dateString.split(/[- :.]/)
              const formatedDate = new Date(dateTime[2], dateTime[1] - 1, dateTime[0], dateTime[3], dateTime[4], dateTime[5])
              const duration = Number(localStorage.getItem('sensorDuration'))
              if (duration === 1 ||
                  duration === 2 ||
                  duration === 6 ||
                  duration === 12) {
                return moment(formatedDate).format('HH:mm')
              } else if (duration === 168) {
                return moment(formatedDate).format('DD-MM-YYYY')
              }
              return moment(formatedDate).format('DD-MM-YYYY HH:mm')
            }
          },
          title: {
            text: '',
            style: { color: '#8394B4', fontSize: '9px' },
            y: 0
          }
        },
        yAxis: {
          plotLines: [
            {
              color: '#6DA3F8',
              width: 2,
              value: 40,
              zIndex: 5
            },
            {
              color: '#EF726B',
              width: 2,
              value: 58,
              zIndex: 5
            }
          ],
          tickAmount: 6,
          labels: {
            style: {
              fontSize: '9px'
            }
          },
          title: {
            text: '',
            style: { color: '#8394B4', fontSize: '9px' },
            y: 12
          }
        }
      },
      tempLineChartData: {
        series: [
          {
            name: 'Temperature',
            data: [],
            type: 'spline'
          }
        ],
        xAxis: {
          categories: [],
          tickInterval: 0,
          crosshair: {
            color: '#cccccc',
            width: 1
          },
          labels: {
            enabled: true,
            formatter: function () {
              if (typeof this.value !== 'string') return
              const dateString = this.value
              const dateTime = dateString.split(/[- :.]/)
              var formatedDate = new Date(dateTime[2], dateTime[1] - 1, dateTime[0], dateTime[3], dateTime[4], dateTime[5])
              const duration = Number(localStorage.getItem('sensorDuration'))
              if (duration === 1 ||
                  duration === 2 ||
                  duration === 6 ||
                  duration === 12) {
                return moment(formatedDate).format('HH:mm')
              } else if (duration === 168) {
                return moment(formatedDate).format('DD-MM-YYYY')
              }
              return moment(formatedDate).format('DD-MM-YYYY HH:mm')
            }
          },
          title: {
            text: '',
            style: { color: '#8394B4', fontSize: '9px' },
            y: 0
          }
        },
        yAxis: {
          plotLines: [
            {
              color: '#6DA3F8',
              width: 2,
              value: 40,
              zIndex: 5
            },
            {
              color: '#EF726B',
              width: 2,
              value: 58,
              zIndex: 5
            }
          ],
          tickAmount: 6,
          labels: {
            style: {
              fontSize: '9px'
            }
          },
          title: {
            text: '',
            style: { color: '#8394B4', fontSize: '9px' },
            y: 12
          }
        }
      },
      legend: {
        enabled: false
      },
      pageInfoText: '1 to 2, 2 in total',
      goToPageInput: 1,
      page: 1,
      totalPages: 0,
      pageIndex: 1,
      rowPerPage: 10,
      sensorStatus: true,
      temperatureList: [
        {
          icon: require('@/assets/img/thermometer-lines.png'),
          value: '-3',
          message: 'Average'
        },
        {
          icon: require('@/assets/img/arrow-top-right.png'),
          value: '23',
          message: 'Maximum'
        },
        {
          icon: require('@/assets/img/arrow-bottom-left.png'),
          value: '13',
          message: 'Minimum'
        }
      ],
      humidityList: [
        {
          icon: require('@/assets/img/water-percent.png'),
          value: '5',
          message: 'Average'
        },
        {
          icon: require('@/assets/img/arrow-top-right.png'),
          value: '15',
          message: 'Maximum'
        },
        {
          icon: require('@/assets/img/arrow-bottom-left.png'),
          value: '25',
          message: 'Minimum'
        }
      ],
      tableItems: []
    }
  },
  computed: {
    rowPerPageList () {
      return [5, 10, 20, 50, 100]
    },
    selectedStore () {
      return this.$store.getters['dataStore/GET_SELECTED_STORE'].code
    },
    tableHeaders () {
      return [
        {
          text: this.$t('#'),
          sortable: false,
          value: 'serialNo'
        },
        {
          text: this.$t('TIME'),
          sortable: false,
          value: 'alertTime'
        },
        {
          text: this.$t('ALERT TYPE'),
          sortable: false,
          value: 'type'
        },
        {
          text: this.$t('VALUE'),
          sortable: false,
          value: 'value'
        },
        {
          text: this.$t('FW. VER'),
          sortable: false,
          value: 'fwVersion'
        },
        {
          text: this.$t('SIGNAL STRENGTH'),
          sortable: false,
          value: 'signalStrength'
        },

        {
          text: this.$t('BATTERY'),
          sortable: false,
          value: 'battery'
        }
      ]
    }
  },
  watch: {
    pageIndex: {
      handler (page) {
        this.goToPageInput = page
      }
    }
  },
  created () {
    if (localStorage.getItem('tempIndicator') !== null) {
      this.toggleState = JSON.parse(localStorage.getItem('tempIndicator'))
    } else {
      this.toggleState = true
      localStorage.setItem('tempIndicator', 'true')
    }
  },
  mounted () {
    const currentDate = new Date()
    // this.startDate = moment(currentDate.setHours(currentDate.getHours() - 1))
    //   .utc()
    //   .format('YYYY-MM-DDTHH:mm:ss')
    // this.endDate = moment(new Date())
    //   .utc()
    //   .format('YYYY-MM-DDTHH:mm:ss')
    this.startDate = moment(currentDate.setHours(currentDate.getHours() - 1))
      .format('YYYY-MM-DDTHH:mm:ss')
    this.endDate = moment(new Date())
      .format('YYYY-MM-DDTHH:mm:ss')
    localStorage.setItem('sensorDuration', 1)
    this.getSensorDetails()
    this.getAlertHistory(1)
  },
  beforeDestroy () {
    localStorage.removeItem('sensorDuration')
  },
  methods: {
    convertTemp (val) {
      const temp = (val * 9) / 5 + 32
      const result = temp.toFixed(1)
      return Number(result)
    },
    updateDuration (e) {
      let start = null
      let end = null
      if (e === 1) {
        start = new Date().setHours(new Date().getHours() - 1)
        end = new Date()
      } else if (e === 2) {
        start = new Date().setHours(new Date().getHours() - 2)
        end = new Date()
      } else if (e === 6) {
        start = new Date().setHours(new Date().getHours() - 6)
        end = new Date()
      } else if (e === 12) {
        start = new Date().setHours(new Date().getHours() - 12)
        end = new Date()
      } else if (e === 24) {
        start = new Date().setHours(new Date().getHours() - 24)
        end = new Date()
      } else if (e === 48) {
        start = new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000)
        end = new Date()
      } else if (e === 168) {
        start = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
        end = new Date()
      }
      this.startDate = moment(start)
        .format('YYYY-MM-DDTHH:mm:ss')
      this.endDate = moment(end)
        .format('YYYY-MM-DDTHH:mm:ss')
      localStorage.setItem('sensorDuration', e)
      this.getSensorDetails()
      this.getAlertHistory(1)
    },
    getSensorDetails () {
      this.chartLoader = true
      this.lineChartData.series[0].data = []
      this.tempLineChartData.series[0].data = []
      this.lineChartData.xAxis.categories = []
      this.tempLineChartData.xAxis.categories = []
      this.lineChartData.xAxis.tickInterval = 0
      this.tempLineChartData.xAxis.tickInterval = 0
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore,
          sensorCode: this.sensorDetails.sensorCode,
          startTime: this.startDate,
          endTime: this.endDate
        }
      }
      this.$utils
        .callAxios(
          codes.requests.getSensorLogsDetails.method,
          codes.requests.getSensorLogsDetails.url,
          config
        )
        .then(res => {
          this.chartLoader = false
          const humCategory = []
          if (res.status === 200) {
            this.temperatureStatistics.averageTemperature = this.toggleState
              ? res.data.statistics.avgTemperature
              : this.convertTemp(res.data.statistics.avgTemperature)
            this.temperatureStatistics.minTemperature = this.toggleState
              ? res.data.statistics.minTemperature
              : this.convertTemp(res.data.statistics.minTemperature)
            this.temperatureStatistics.maxTemperature = this.toggleState
              ? res.data.statistics.maxTemperature
              : this.convertTemp(res.data.statistics.maxTemperature)
            this.humidityStatistics.avergaeHumidity =
              +res.data.statistics.avgHumidity.toFixed(1)
            this.humidityStatistics.minHumidity =
              +res.data.statistics.minHumidity.toFixed(1)
            this.humidityStatistics.maxHumidity =
              +res.data.statistics.maxHumidity.toFixed(1)
            if (res.data.sensorLogs.length !== 0) {
              this.headersStatistics.temp = this.toggleState
                ? res.data.sensorLogs[0].temperature
                : this.convertTemp(res.data.sensorLogs[0].temperature)

              this.headersStatistics.hum = res.data.sensorLogs[0].humidity
              this.headersStatistics.battery = res.data.sensorLogs[0].battery
              this.tempLineChartData.yAxis.plotLines[0].value = this.toggleState
                ? res.data.statistics.minTemperature
                : this.convertTemp(res.data.statistics.minTemperature)
              this.tempLineChartData.yAxis.plotLines[1].value = this.toggleState
                ? res.data.statistics.maxTemperature
                : this.convertTemp(res.data.statistics.maxTemperature)
              this.lineChartData.yAxis.plotLines[0].value =
                res.data.statistics.minHumidity
              this.lineChartData.yAxis.plotLines[1].value =
                res.data.statistics.maxHumidity
              this.lineChartData.series[0].data = []
              this.tempLineChartData.series[0].data = []

              for (let i = 0; i < res.data.sensorLogs.length; i++) {
                if (
                  res.data.sensorLogs[i].humidity ===
                  res.data.statistics.minHumidity
                ) {
                  this.lineChartData.series[0].data.push({
                    y: res.data.sensorLogs[i].humidity,
                    marker: {
                      radius: 4,
                      fillColor: '#6DA3F8',
                      lineWidth: 0
                    }
                  })
                } else if (
                  res.data.sensorLogs[i].humidity ===
                  res.data.statistics.maxHumidity
                ) {
                  this.lineChartData.series[0].data.push({
                    y: res.data.sensorLogs[i].humidity,
                    marker: {
                      radius: 4,
                      fillColor: '#EF726B',
                      lineWidth: 0
                    }
                  })
                } else {
                  this.lineChartData.series[0].data.push(
                    res.data.sensorLogs[i].humidity
                  )
                }
                const temp = this.toggleState
                  ? res.data.sensorLogs[i].temperature
                  : this.convertTemp(res.data.sensorLogs[i].temperature)
                if (
                  res.data.sensorLogs[i].temperature ===
                  res.data.statistics.minTemperature
                ) {
                  this.tempLineChartData.series[0].data.push({
                    y: Number(temp),
                    marker: {
                      radius: 4,
                      fillColor: '#6DA3F8',
                      lineWidth: 0
                    }
                  })
                } else if (
                  res.data.sensorLogs[i].temperature ===
                  res.data.statistics.maxTemperature
                ) {
                  this.tempLineChartData.series[0].data.push({
                    y: Number(temp),
                    marker: {
                      radius: 4,
                      fillColor: '#EF726B',
                      lineWidth: 0
                    }
                  })
                } else {
                  this.tempLineChartData.series[0].data.push(Number(temp))
                }
                humCategory.push(
                  res.data.sensorLogs[i].insertTime
                )
              }
              if (res.data.sensorLogs.length === 1) {
                // we need both min and max data for able to show data in chart
                // if there is only one log in sensorLogs
                // only one min will be pushed so pushing it manually
                this.lineChartData.series[0].data.push({
                  y: res.data.sensorLogs[0].humidity,
                  marker: {
                    radius: 4,
                    fillColor: '#EF726B',
                    lineWidth: 0
                  }
                })
                this.tempLineChartData.series[0].data.push({
                  y: this.toggleState ? res.data.sensorLogs[0].temperature : this.convertTemp(res.data.sensorLogs[0].temperature),
                  marker: {
                    radius: 4,
                    fillColor: '#EF726B',
                    lineWidth: 0
                  }
                })
                humCategory.push(
                  res.data.sensorLogs[0].insertTime
                )
              }
              if (humCategory.length > 10) {
                if (
                  this.selectedDuration === 1 ||
                      this.selectedDuration === 2 ||
                      this.selectedDuration === 6 ||
                      this.selectedDuration === 12
                ) {
                  this.lineChartData.xAxis.tickInterval = Math.ceil(
                    humCategory.length / 10
                  )
                  this.tempLineChartData.xAxis.tickInterval = Math.ceil(
                    humCategory.length / 10
                  )
                } else if (this.selectedDuration === 168) {
                  this.lineChartData.xAxis.tickInterval = Math.ceil(
                    humCategory.length / 4
                  )
                  this.tempLineChartData.xAxis.tickInterval = Math.ceil(
                    humCategory.length / 4
                  )
                } else {
                  this.lineChartData.xAxis.tickInterval = Math.ceil(
                    humCategory.length / 3
                  )
                  this.tempLineChartData.xAxis.tickInterval = Math.ceil(
                    humCategory.length / 3
                  )
                }
              } else {
                this.tempLineChartData.xAxis.tickInterval = 0
                this.lineChartData.xAxis.tickInterval = 0
              }
              this.lineChartData.xAxis.categories = humCategory
              this.lineChartData.series[0].data.reverse()
              this.tempLineChartData.xAxis.categories = this.lineChartData.xAxis.categories
              this.tempLineChartData.series[0].data.reverse()
            }
          }
        })
        .catch(error => {
          this.chartLoader = false
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    },
    getAlertHistory (page) {
      this.tableLoading = true
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore,
          size: this.rowPerPage,
          sensorCode: this.sensorDetails.sensorCode,
          startTime: this.startDate,
          endTime: this.endDate
        }
      }
      this.tableItems = []
      if (page !== null) config.params.page = page - 1
      this.$utils
        .callAxios(
          codes.requests.getAlertHistory.method,
          codes.requests.getAlertHistory.url,
          config
        )
        .then(res => {
          this.saveRequestConfig(res.config)
          this.tableLoading = false
          const headers = res.headers
          if (res.status === 200) {
            this.tableItems = res.data.alertHistory.map((obj, idx) => {
              return {
                serialNo: headers['x-number'] * 1 * headers['x-size'] + idx + 1,
                ...obj,
                alertTime: commons.convertDate(obj.alertTime),
                type: obj.type ? obj.type.split('_').join(' ') : obj.type,
                fwVersion: obj.fwVersion ? obj.fwVersion : '-'
              }
            })
          } else {
            this.tableItems = []
          }
          this.pageInfoText = commons.getPageInfoText(headers)
          this.totalPages = headers['x-totalpages']
        })
        .catch(error => {
          this.tableLoading = false
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    },
    downloadAlertHistory () {
      if (!this.emailId) {
        EventBus.$emit(
          'messageAlert',
          this.$t('Email is required.'),
          'error',
          '350'
        )
        return
      } else if (commons.validEmail(this.emailId) === false) {
        EventBus.$emit(
          'messageAlert',
          this.$t('Enter a valid email address'),
          'error'
        )
        return
      }
      const msg = 'Loading...'
      EventBus.$emit('toggleLoadingModal', msg, true)
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore,
          sensorMac: this.sensorDetails.sensorMac,
          startTime: this.startDate,
          endTime: this.endDate,
          export: true,
          email: this.emailId
        }
      }
      this.$utils
        .callAxios(
          codes.requests.getAlertHistory.method,
          codes.requests.getAlertHistory.url,
          config
        )
        .then(res => {
          EventBus.$emit('toggleLoadingModal', msg, false)
          if (res.status === 200) {
            EventBus.$emit('toggleLoadingModal', msg, false)
            this.confirmDownload = false
            this.emailId = this.$store.state.auth.user.accountEmail
            EventBus.$emit('messageAlert', this.$t('Success'), '')
          }
        })
        .catch(error => {
          EventBus.$emit('toggleLoadingModal', msg, false)
          this.confirmDownload = false
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    },
    checkIsNumber: commons.isNumber,
    goToPage (goToPageInput, paging, totalPages) {
      if (totalPages < goToPageInput) {
      } else {
        paging(Number(goToPageInput))
      }
    },
    paging (page) {
      this.page = Number(page)
      this.pageIndex = this.page
      this.goToPageInput = page
      this.getAlertHistory(page)
    },
    closeModal () {
      this.$emit('closeAddModal')
    },
    saveRequestConfig (config) {
      const requestConfig = {
        url: config.url,
        method: config.method,
        params: config.params
      }
      this.requestConfig = requestConfig
    },
    exportHistory () {
      if (this.tableItems.length < 1) {
        EventBus.$emit('messageAlert', this.$t('No data to export.'))
        return
      }
      exportFiles(this.requestConfig, this.$refs.link, 'SensorDetailList.xlsx')
    },
    openEditDialog (data) {
      this.$emit('openEditDialog', data)
    }
  }
}
</script>
<style scoped>
::v-deep .v-dialog {
  overflow-x: hidden !important;
}
.circle {
  height: 6px;
  width: 6px;
  border-radius: 50%;
}
.circle-1 {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.border-status {
  border: 1px solid;
  width: fit-content;
  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.card-box {
  height: 120px;
  border: 1px solid #dbd9d9;
  padding: 0px;
  border-radius: 10px;
  width: 130px;
}
.icon,
.value,
.box-info {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  align-items: end !important;
}
.value {
  font-size: 26px;
  font-weight: 600;
}
.box-info {
  color: #9597a3 !important;
  white-space: normal;
  font-size: 15px;
  letter-spacing: -0.5px;
  align-items: baseline !important;
}
.d-grid {
  display: grid !important;
}
.info-text {
  font-size: 15px;
  color: #a2acbd;
  letter-spacing: 0px;
  font-weight: normal;
  align-items: end !important;
}
.d-flex {
  display: flex !important;
}
.w-100 {
  width: 100% !important;
}
.loader {
  border: 5px solid #eee;
  border-radius: 50%;
  border-top: 5px solid #ccc;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.wrapper-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex-direction: column;
}
.fixedHeader{ overflow-y:auto;}
/* width */
.fixedHeader::-webkit-scrollbar {width: 5px;}
/* Track */
.fixedHeader::-webkit-scrollbar-track {background: transparent;}
/* Handle */
.fixedHeader::-webkit-scrollbar-thumb {background: #818181; border-radius:5px;}
/* Handle on hover */
.fixedHeader::-webkit-scrollbar-thumb:hover {background: #8e8d8d;}
</style>
