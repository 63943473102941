<template>
  <div style="margin-top: 20px">
    <v-row>
      <v-col>
        <div>
          <div>
            <h3
              class="tit-group-bg"
              style="color: rgba(0, 0, 0, 0.6); line-height: 1"
            >
              {{ $t("Import Existing Setting Values") }}
            </h3>
          </div>
          <br />
          <v-col class="exportsetting">
            <v-row>
              <v-col align="start" class="label-txt" style="margin-top: -30px">
                <div class="row" style="margin: 0">
                  <v-checkbox
                    hide-details
                    v-model="templateimport"
                  ></v-checkbox>
                  <span class="label-txt" style="margin-top: 23px"
                  >{{ $t('Template')}}</span
                  >
                </div>
                <div class="row" style="margin: 0" v-if="templatemapping === 'true'">
                  <v-checkbox
                    hide-details
                    v-model="templatemappingimport"
                  ></v-checkbox>
                  <span class="label-txt" style="margin-top: 23px"
                  >{{ $t('Template Group & Mapping')}}</span
                  >
                </div>
                <div class="row" style="margin: 0">
                  <v-checkbox
                    hide-details
                    v-model="ledpromotionimport"
                  ></v-checkbox>
                  <span class="label-txt" style="margin-top: 23px"
                    >{{ $t('LED Promotion')}}</span
                  >
                </div>
                <div class="row" style="margin: 0">
                  <v-checkbox
                    hide-details
                    v-model="ButtonConfigimport"
                  ></v-checkbox>
                  <span class="label-txt" style="margin-top: 23px"
                    >{{ $t('Button Config')}}</span
                  >
                </div>
              </v-col>
              <v-col align="start" class="label-txt" style="margin-top: -30px">
                <div class="row" style="margin: 0">
                  <v-checkbox
                    hide-details
                    v-model="userlevelimport"
                  ></v-checkbox>
                  <span class="label-txt" style="margin-top: 23px"
                    >{{ $t('User Levels')}}</span
                  >
                </div>
                <div class="row" style="margin: 0">
                  <v-checkbox
                    hide-details
                    v-model="languageimport"
                  ></v-checkbox>
                  <span class="label-txt" style="margin-top: 23px"
                    >{{ $t('Language')}}</span
                  >
                </div>
                <div class="row" style="margin: 0">
                  <v-checkbox
                    hide-details
                    v-model="systemconfigimport"
                  ></v-checkbox>
                  <span class="label-txt" style="margin-top: 23px"
                    >{{ $t('System Config')}}</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-col>
        </div>
        <br />
        <div class="row" style="margin: 0px">
          <v-file-input
            outlined
            v-model="zipFile"
            dense
            accept=".zip"
            hide-details
            prepend-icon
            solo
            flat
            style="max-width: 230px"
            :placeholder="`${$t('File Upload')}`"
            class="form-file"
          >
            <template v-slot:append>
              <img
                width="24"
                height="24"
                src="@/assets/img/ico-upload-file.gif"
              />
            </template>
          </v-file-input>

          <span class="label-txt" style="margin: 18px"
            >* . {{ $t("zip") }}</span
          >
        </div>

        <div class="mt-5">
          <v-btn class="btn-s" :disabled='btnDisabledDashboardSettings' @click="uploadZip" text>
            {{ $t("Import") }}
          </v-btn>
        </div>
      </v-col>

      <v-col>
        <div>
          <div>
            <h3
              class="tit-group-bg"
              style="color: rgba(0, 0, 0, 0.6); line-height: 1"
            >
              {{ $t("Export Existing Setting Values") }}
            </h3>
          </div>
          <br />

          <v-col class="exportsetting">
            <v-row>
              <v-col align="start" class="label-txt" style="margin-top: -30px">
                <div class="row" style="margin: 0">
                  <v-checkbox
                    hide-details
                    v-model="templateexport"
                  ></v-checkbox>
                  <span class="label-txt" style="margin-top: 23px"
                    >Template</span
                  >
                </div>
                <div class="row" style="margin: 0" v-if="templatemapping === 'true'">
                  <v-checkbox
                    hide-details
                    v-model="templatemappingexport"
                  ></v-checkbox>
                  <span class="label-txt" style="margin-top: 23px"
                    >Template Group & Mapping</span
                  >
                </div>
                <div class="row" style="margin: 0">
                  <v-checkbox
                    hide-details
                    v-model="ledpromotionexport"
                  ></v-checkbox>
                  <span class="label-txt" style="margin-top: 23px"
                    >{{ $t('LED Promotion')}}</span
                  >
                </div>
                <div class="row" style="margin: 0">
                  <v-checkbox
                    hide-details
                    v-model="ButtonConfigexport"
                  ></v-checkbox>
                  <span class="label-txt" style="margin-top: 23px"
                    >{{ $t('Button Config')}}</span
                  >
                </div>
              </v-col>
              <v-col align="start" class="label-txt" style="margin-top: -30px">
                <div class="row" style="margin: 0">
                  <v-checkbox
                    hide-details
                    v-model="userlevelexport"
                  ></v-checkbox>
                  <span class="label-txt" style="margin-top: 23px"
                    >{{ $t('User Levels')}}</span
                  >
                </div>
                <div class="row" style="margin: 0">
                  <v-checkbox
                    hide-details
                    v-model="languageexport"
                  ></v-checkbox>
                  <span class="label-txt" style="margin-top: 23px"
                    >{{ $t('Language')}}</span
                  >
                </div>
                <div class="row" style="margin: 0">
                  <v-checkbox
                    hide-details
                    v-model="systemconfigexport"
                  ></v-checkbox>
                  <span class="label-txt" style="margin-top: 23px"
                    >{{ $t('System Config')}}</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-col>
          <div class="mt-5">
            <v-btn @click="gettoGetsettingValues" :disabled='btnDisabledDashboardSettings' class="btn-s" text>
              {{ $t("Export") }}
            </v-btn>
          </div>
        </div>
      </v-col>
      <a ref="link1" :style="{ display: 'none' }" />
    </v-row>
  </div>
</template>
<script>
import EventBus from '@/plugins/eventBus'
// // import commons from '@/plugins/commons'
import { exportFiles } from '@/plugins/exporter'
import codes from '@/plugins/codes'
export default {
  components: {},
  props: {
    Templatemap: [Array, Object, String, Number, Boolean]
  },
  data () {
    return {
      // Export
      templateexport: false,
      ledpromotionexport: false,
      userlevelexport: false,
      templatemappingexport: false,
      systemconfigexport: false,
      languageexport: false,
      ButtonConfigexport: false,

      // import
      templateimport: false,
      ledpromotionimport: false,
      userlevelimport: false,
      templatemappingimport: false,
      systemconfigimport: false,
      languageimport: false,
      ButtonConfigimport: false,
      zipFile: undefined,

      // Template mapping enable or Disable
      templatemapping: 'true',
      // button disabled
      btnDisabledDashboardSettings: null
    }
  },
  computed: {
    togetcustomerinfo () {
      return this.Templatemap
    }
  },
  watch: {
    togetcustomerinfo () {
      console.log('sdsadasdada', this.Templatemap)
      if (this.Templatemap === 'false') {
        this.templatemapping = this.Templatemap
      }
      if (this.Templatemap === 'true') {
        this.templatemapping = this.Templatemap
      }
    }
  },
  mounted () {
    this.$store.dispatch('auth/getDisabledBtn', '9100').then((flag) => {
      this.btnDisabledDashboardSettings = flag
    })
    // this.gettoGetsettingValues()
  },
  methods: {
    gettoGetsettingValues () {
      if (this.ledpromotionexport === true || this.templateexport === true || this.userlevelexport === true || this.systemconfigexport === true ||
      this.templatemappingexport === true || this.languageexport === true || this.ButtonConfigexport === true) {
        console.log(this.templateexport)
        console.log(this.ledpromotionexport)
        console.log(this.userlevelexport)
        const config = {
          params: {
            export: true,
            led: this.ledpromotionexport,
            template: this.templateexport,
            userRole: this.userlevelexport,
            systemConfig: this.systemconfigexport,
            templateMapping: this.templatemappingexport,
            language: this.languageexport,
            buttonConfig: this.ButtonConfigexport

          }
        }
        this.$utils
          .callAxios(
            codes.requests.toGetsettingValues.method,
            codes.requests.toGetsettingValues.url,
            config
          )
          .then((res) => {
            console.log(res)
            this.saveRequestConfig(res.config)
            this.downloadfile()
          })
          .catch((error) => {
            console.warn(`Could not find any Settings Value : ${error}`)
          })
      } else {
        EventBus.$emit('messageAlert', this.$t('Please Select File'))
      }
    },
    // Setting config
    saveRequestConfig: function (config) {
      const obj = {
        // export: true,
        led: this.ledpromotionexport,
        template: this.templateexport,
        userRole: this.userlevelexport,
        systemConfig: this.systemconfigexport,
        templateMapping: this.templatemappingexport,
        language: this.languageexport,
        buttonConfig: this.ButtonConfigexport
      }
      const requestConfig = {
        url: config.url,
        method: config.method,
        params: obj
      }
      this.requestConfig = requestConfig
    },

    // to download language file
    downloadfile () {
      exportFiles(this.requestConfig, this.$refs.link1, 'SettingsValue.zip')
    },

    //  toImportsettingValues
    uploadZip () {
      console.log(this.zipFile)
      if (this.zipFile !== undefined) {
        const file = this.zipFile
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (evt) => {
          var base64data = evt.target.result
          base64data = base64data.replace(
            'data:application/x-zip-compressed;base64,',
            ''
          )
          this.toimportsettingValues(base64data)
        }
        reader.onerror = (evt) => {
          console.error(evt)
        }
      } else {
        EventBus.$emit('messageAlert', this.$t('Please Select File'))
      }
    },
    toimportsettingValues (data) {
      if (this.ledpromotionimport === true || this.templateimport === true || this.userlevelimport === true || this.systemconfigimport === true ||
      this.templatemappingimport === true || this.languageimport === true || this.ButtonConfigimport === true) {
        const accountData = {
          data: data
        }
        const config = {
          params: {
            led: this.ledpromotionimport,
            template: this.templateimport,
            userRole: this.userlevelimport,
            systemConfig: this.systemconfigimport,
            templateMapping: this.templatemappingimport,
            language: this.languageimport,
            buttonConfig: this.ButtonConfigimport
          }
        }
        this.$utils
          .callAxiosWithBody(
            codes.requests.toImportsettingValues.method,
            codes.requests.toImportsettingValues.url,
            accountData,
            config
          )
          .then((res) => {
            EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
            this.templateimport = false
            this.ledpromotionimport = false
            this.userlevelimport = false
            this.templatemappingimport = false
            this.systemconfigimport = false
            this.languageimport = false
            this.ButtonConfigimport = false
            this.zipFile = undefined
          })
          .catch(error => {
            EventBus.$emit(
              'messageAlert',
              this.$t(error.response.data.responseMessage)
            )
            return false
          })
      } else {
        EventBus.$emit('messageAlert', this.$t('Please Select File'))
      }
    }
  }
}
</script>
<style>
li {
  padding: 10px;
  text-align: center;
}
.exportsetting {
  /* height: 180px; */
  height: auto;
  background: rgb(255, 255, 255) !important;
  border: 1px solid rgb(245, 245, 245);
  box-shadow: rgb(220 212 212) 0px 1px 5px;
  padding: 30px;
  border-radius: 5px;
}
</style>
