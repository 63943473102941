var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("img", {
          staticStyle: { "margin-right": "15px", height: "31px" },
          attrs: { src: require("@/assets/img/bell-ring.png") }
        }),
        _vm._v(_vm._s(_vm.$t("Alert List")) + " ")
      ]),
      _c("div", {}, [
        _c("div", { staticClass: "col-9" }, [
          _c(
            "div",
            { staticClass: "searchArea", staticStyle: { width: "80%" } },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "d-flex col-12 align-baseline px-0 py-0" },
                  [
                    _c("v-text-field", {
                      staticClass: "form-select col-3",
                      attrs: {
                        label: _vm.$t("Sensor ID"),
                        outlined: "",
                        "hide-details": "",
                        dense: "",
                        placeholder: _vm.$t("Input Sensor ID"),
                        clearable: ""
                      },
                      model: {
                        value: _vm.searchBy.sensorCode,
                        callback: function($$v) {
                          _vm.$set(_vm.searchBy, "sensorCode", $$v)
                        },
                        expression: "searchBy.sensorCode"
                      }
                    }),
                    _c("v-select", {
                      staticClass: "form-select col-3 ml-3",
                      attrs: {
                        label: _vm.$t("Type"),
                        placeholder: _vm.$t("Select Type"),
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        items: _vm.typeList,
                        "item-text": "text",
                        "item-value": "val"
                      },
                      model: {
                        value: _vm.searchBy.alertType,
                        callback: function($$v) {
                          _vm.$set(_vm.searchBy, "alertType", $$v)
                        },
                        expression: "searchBy.alertType"
                      }
                    }),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn type-search ml-3",
                        attrs: { text: "" },
                        on: {
                          click: function($event) {
                            return _vm.clickSearch()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Search")) + " ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "btn type-search ml-3",
                        attrs: { text: "" },
                        on: { click: _vm.clearSearch }
                      },
                      [_vm._v(_vm._s(_vm.$t("Clear")) + " ")]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "mt-10" },
        [
          _c(
            "v-data-table",
            {
              staticClass: "tbl-type01 mt-5",
              attrs: {
                headers: _vm.tableHeaders,
                items: _vm.tableItems,
                "item-key": "serialNo",
                "hide-default-footer": true,
                options: _vm.options,
                "items-per-page": _vm.rowPerPage,
                "server-items-length": _vm.tableItems.length,
                "show-select": false,
                "fixed-header": "",
                "must-sort": "",
                height:
                  _vm.tableItems && _vm.tableItems.length > 8 ? "550px" : "auto"
              },
              on: {
                "update:options": function($event) {
                  _vm.options = $event
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "item.value",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          {
                            style:
                              item.type.toUpperCase() ===
                                "HIGH_TEMPERATURE".toUpperCase() ||
                              item.type.toUpperCase() ===
                                "HIGH_HUMIDITY".toUpperCase()
                                ? "color:#FF8097"
                                : "color: #6DA3F8"
                          },
                          [
                            item.type.toUpperCase() ===
                              "HIGH_TEMPERATURE".toUpperCase() ||
                            item.type.toUpperCase() ===
                              "LOW_TEMPERATURE".toUpperCase()
                              ? _c("div", [
                                  _vm.toggleState
                                    ? _c("p", [
                                        _vm._v(_vm._s(item.value) + " "),
                                        _c("span", [_vm._v("°")]),
                                        _vm._v("C")
                                      ])
                                    : _c("p", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (
                                                (item.value * 9) / 5 +
                                                32
                                              ).toFixed(2)
                                            ) +
                                            " "
                                        ),
                                        _c("span", [_vm._v("°")]),
                                        _vm._v("F ")
                                      ])
                                ])
                              : _c("div", [
                                  _c("p", [_vm._v(_vm._s(item.value) + "%")])
                                ])
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.deliveryStatus",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function($event) {
                                return _vm.openStatusDialog(item)
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/img/info-ico-custom.png"),
                                alt: "",
                                height: "25"
                              }
                            })
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.assignedTo",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.assignedTo !== null
                                ? _vm.convertToString(item.assignedTo)
                                : "-"
                            ) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.sensorName",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(item.sensorName ? item.sensorName : "-") +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.duration",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.alertTime !== null &&
                                item.alertOffTime !== null
                                ? _vm.getUTCtoLocalTimeDuration(item)
                                : "-"
                            ) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.alertOffTime",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.alertOffTime
                                ? _vm.getUTCtoLocal(item.alertOffTime)
                                : "-"
                            ) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.alertTime",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              item.alertTime
                                ? _vm.getUTCtoLocal(item.alertTime)
                                : "-"
                            )
                          )
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.type",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("p", { style: _vm.getColor(item.type) }, [
                          _vm._v(" " + _vm._s(item.type) + " ")
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.status",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "p",
                          {
                            style:
                              item.status === "ON"
                                ? "color: #EF726B"
                                : "color: #9DEB8F"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.status === "ON" ? "Active" : "Completed"
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            },
            [
              _c(
                "template",
                { slot: "no-data" },
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "ma-0",
                      staticStyle: { "line-height": "19px" },
                      attrs: { dense: "", color: "#697A8A" }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { color: "#fff" } },
                        [_vm._v("mdi-alert-outline")]
                      ),
                      _vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          ),
          _vm.tableItems.length > 0
            ? _c("div", { staticClass: "table-options" }, [
                _c("div"),
                _c("div", [
                  _c("div", { staticClass: "pageInfo" }, [
                    _vm._v(_vm._s(_vm.pageInfoText))
                  ]),
                  _c(
                    "div",
                    { staticClass: "pagination2" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "pageInfo mr-1",
                          staticStyle: { position: "relative !important" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Show")) + " ")]
                      ),
                      _c("v-select", {
                        staticClass: "form-input pt-0 col rowPerPage",
                        attrs: {
                          items: _vm.rowPerPageList,
                          placeholder: _vm.$t("Limit"),
                          dense: "",
                          "persistent-hint": "",
                          outlined: "",
                          "hide-details": ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.paging(1)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s("" + item) +
                                      _vm._s(_vm.$t("Rows")) +
                                      " "
                                  )
                                ]
                              }
                            },
                            {
                              key: "selection",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "selection-v-select" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s("" + item) +
                                          _vm._s(_vm.$t("Rows"))
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2266113876
                        ),
                        model: {
                          value: _vm.rowPerPage,
                          callback: function($$v) {
                            _vm.rowPerPage = $$v
                          },
                          expression: "rowPerPage"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "mr-2" },
                        [
                          _c("v-text-field", {
                            staticClass: "form-input pt-0 col goToPage ml-1",
                            attrs: {
                              placeholder: "Page",
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              solo: "",
                              "append-icon": "mdi-chevron-right"
                            },
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.goToPage(
                                  _vm.goToPageInput,
                                  _vm.paging,
                                  _vm.totalPages
                                )
                              },
                              keypress: function($event) {
                                return _vm.checkIsNumber($event)
                              },
                              "click:append": function($event) {
                                return _vm.goToPage(
                                  _vm.goToPageInput,
                                  _vm.paging,
                                  _vm.totalPages
                                )
                              }
                            },
                            model: {
                              value: _vm.goToPageInput,
                              callback: function($$v) {
                                _vm.goToPageInput = $$v
                              },
                              expression: "goToPageInput"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "pagination-nav pagination-nav-prev",
                          class:
                            _vm.page < 10 ? "pagination-nav-disabled" : null,
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.goTenPageBackwards(
                                _vm.page,
                                _vm.paging
                              )
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets//img/ico-paging-prev.png"),
                              alt: "이전 10페이지 이동"
                            }
                          })
                        ]
                      ),
                      _c("v-pagination", {
                        staticStyle: { display: "block" },
                        attrs: {
                          length: _vm.totalPages * 1 || 1,
                          "total-visible": 8,
                          color: "#2f3b4c"
                        },
                        on: { input: _vm.paging },
                        model: {
                          value: _vm.page,
                          callback: function($$v) {
                            _vm.page = $$v
                          },
                          expression: "page"
                        }
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "pagination-nav pagination-nav-next",
                          class:
                            _vm.page + 10 > _vm.totalPages
                              ? "pagination-nav-disabled"
                              : null,
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.goTenPageForward(
                                _vm.page,
                                _vm.paging,
                                _vm.totalPages
                              )
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets//img/ico-paging-next.png"),
                              alt: "다음 10페이지 이동"
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e()
        ],
        1
      ),
      _vm.notiDialogPopup
        ? _c("AlarmNotification", {
            attrs: {
              notiDialogPopup: _vm.notiDialogPopup,
              alertIdData: _vm.alertIdInfo
            },
            on: { closeNotiModal: _vm.closeNotiModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }