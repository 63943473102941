<template>
  <div class="chart-container">
    <div class="no-data" v-if="noData">No Data available</div>
    <highcharts  v-else class="hc" :options="chartOptions" ref="stackchart"></highcharts>
  </div>
</template>

<script>
// import Highcharts from 'highcharts'
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true,
      default: ''
    },
    categories: {
      type: Array,
      required: true,
      default: () => []
    },
    noData: {
      type: Boolean,
      default: false
    },
    xTitle: {
      type: String,
      default: ''
    },
    colors: {
      type: Array,
      default: () => ['#F0806F', '#75B0DD', '#FBAF1E', '#6D9852']
    },
    height: {
      type: Number,
      default: 350
    }
  },
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'column',
          height: this.height
        },
        colors: this.colors,
        title: {
          text: this.title
        },
        tooltip: {
          hideDelay: 100,
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        xAxis: {
          categories: this.categories,
          title: {
            text: this.xTitle,
            align: 'high',
            x: 10,
            y: -20
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: this.title
          },
          stackLabels: {
            enabled: false
          }
        },
        // legend: {
        //   align: 'left',
        //   x: 70,
        //   verticalAlign: 'top',
        //   y: -30,
        //   floating: true,
        //   borderColor: '#CCC',
        //   borderWidth: 1,
        //   shadow: false
        // },
        plotOptions: {
          column: {
            stacking: 'normal',
            showInLegend: false,
            maxPointWidth: 20,
            dataLabels: {
              enabled: false
            }
          }
        },
        credits: {
          enabled: false
        },
        series: this.data
      }
    }
  },
  watch: {
    data (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.chartOptions.series = this.data
        this.chartOptions.xAxis.categories = this.categories
        this.chartOptions.xAxis.title.text = this.xTitle
      }
    }
  },
  created () {
    setTimeout(() => {
      this.chartOptions.series = this.data
      this.chartOptions.xAxis.categories = this.categories
      this.chartOptions.xAxis.title.text = this.xTitle
    }, 100)
  }
}
</script>
<style>
.chart-container {
  position:relative
}
.no-data {
    font-size: 16px;
    display: flex;
    justify-content: center;
}
/* .chart-container {
  max-height: 588px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
}

.highcharts-markers {
  display: none;
}

.highcharts-root {
  padding: 0px;
}

.make-bold {
  font-weight: 600;
} */
</style>
