var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "1000", persistent: "" },
      model: {
        value: _vm.addDialogPopup,
        callback: function($$v) {
          _vm.addDialogPopup = $$v
        },
        expression: "addDialogPopup"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "cornerClose",
                  attrs: { icon: "", dark: "", "x-small": "" },
                  on: { click: _vm.closeModal }
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("mdi-close")])],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "popup-titbox d-flex align-center justify-space-between"
                },
                [
                  _c("h3", { staticClass: "page-title-bar align-start" }, [
                    _c("img", {
                      staticStyle: { "margin-right": "15px", height: "31px" },
                      attrs: { src: require("@/assets/img/access-point.svg") }
                    }),
                    _vm._v(_vm._s(_vm.$t("Add Sensor")) + " ")
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "fixedHeader",
              staticStyle: { "max-height": "calc(100vh - 330px) !important" }
            },
            [
              _c("div", { staticStyle: { width: "100%" } }, [
                _c(
                  "div",
                  { staticClass: "row mx-0 mb-10 align-baseline" },
                  [
                    _c("v-text-field", {
                      staticClass: "form-input col  mandatory",
                      attrs: {
                        outlined: "",
                        dense: "",
                        type: "text",
                        clearable: "",
                        label: _vm.$t("Sensor ID") + " *",
                        placeholder: "" + _vm.$t("Input Sensor ID")
                      },
                      model: {
                        value: _vm.sensorMac,
                        callback: function($$v) {
                          _vm.sensorMac = $$v
                        },
                        expression: "sensorMac"
                      }
                    }),
                    _c("v-select", {
                      staticClass: "form-select ml-6 col mandatory",
                      attrs: {
                        label: _vm.$t("Sensing Period") + " *",
                        placeholder: _vm.$t("Select Sensing Period"),
                        items: _vm.selectOptions.period,
                        "item-text": "text",
                        readonly: "",
                        "item-value": "abbr",
                        outlined: "",
                        dense: "",
                        "hide-details": ""
                      },
                      model: {
                        value: _vm.sensingPeriod,
                        callback: function($$v) {
                          _vm.sensingPeriod = _vm._n($$v)
                        },
                        expression: "sensingPeriod"
                      }
                    }),
                    _c("v-select", {
                      staticClass: "form-select ml-6 col mandatory",
                      attrs: {
                        label: _vm.$t("Update Period") + " *",
                        placeholder: _vm.$t("Select Update Period"),
                        items: _vm.selectOptionsUpdate.period,
                        "item-text": "text",
                        "item-value": "abbr",
                        outlined: "",
                        dense: "",
                        "hide-details": ""
                      },
                      on: { change: _vm.AddSensingPeriodValue },
                      model: {
                        value: _vm.updatePeriod,
                        callback: function($$v) {
                          _vm.updatePeriod = _vm._n($$v)
                        },
                        expression: "updatePeriod"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row mx-0 mb-10 align-baseline" },
                  [
                    _c("v-text-field", {
                      staticClass: "form-input col  mandatory",
                      attrs: {
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        type: "text",
                        clearable: "",
                        label: _vm.$t("Sensor Name") + " *",
                        placeholder: "" + _vm.$t("Input Sensor Name")
                      },
                      model: {
                        value: _vm.name,
                        callback: function($$v) {
                          _vm.name = $$v
                        },
                        expression: "name"
                      }
                    }),
                    _vm.toggleState
                      ? _c("v-text-field", {
                          ref: "highTempField",
                          staticClass: "form-input col ml-6 mandatory",
                          attrs: {
                            outlined: "",
                            dense: "",
                            type: "number",
                            clearable: "",
                            label: _vm.$t("High Temperature Alarm (°C)") + " *",
                            placeholder: "" + _vm.$t("Input High Temperature"),
                            rules: _vm.highTempRules
                          },
                          model: {
                            value: _vm.highTempThreshold,
                            callback: function($$v) {
                              _vm.highTempThreshold = _vm._n($$v)
                            },
                            expression: "highTempThreshold"
                          }
                        })
                      : _vm._e(),
                    !_vm.toggleState
                      ? _c("v-text-field", {
                          ref: "highTempField",
                          staticClass: "form-input col ml-6 mandatory",
                          attrs: {
                            outlined: "",
                            dense: "",
                            type: "number",
                            clearable: "",
                            label: _vm.$t("High Temperature Alarm (°F)") + " *",
                            placeholder: "" + _vm.$t("Input High Temperature"),
                            rules: _vm.highTempRules1
                          },
                          model: {
                            value: _vm.highTempThreshold,
                            callback: function($$v) {
                              _vm.highTempThreshold = _vm._n($$v)
                            },
                            expression: "highTempThreshold"
                          }
                        })
                      : _vm._e(),
                    _vm.toggleState
                      ? _c("v-text-field", {
                          ref: "lowTempField",
                          staticClass: "form-input col ml-6 mandatory",
                          attrs: {
                            outlined: "",
                            dense: "",
                            type: "number",
                            clearable: "",
                            label: _vm.$t("Low Temperature Alarm (°C)") + " *",
                            placeholder: "" + _vm.$t("Input Low Temperature"),
                            rules: _vm.lowTempRules
                          },
                          model: {
                            value: _vm.lowTempThreshold,
                            callback: function($$v) {
                              _vm.lowTempThreshold = _vm._n($$v)
                            },
                            expression: "lowTempThreshold"
                          }
                        })
                      : _vm._e(),
                    !_vm.toggleState
                      ? _c("v-text-field", {
                          ref: "lowTempField",
                          staticClass: "form-input col ml-6 mandatory",
                          attrs: {
                            outlined: "",
                            dense: "",
                            type: "number",
                            clearable: "",
                            label: _vm.$t("Low Temperature Alarm (°F)") + " *",
                            placeholder: "" + _vm.$t("Input Low Temperature"),
                            rules: _vm.lowTempRules1
                          },
                          model: {
                            value: _vm.lowTempThreshold,
                            callback: function($$v) {
                              _vm.lowTempThreshold = _vm._n($$v)
                            },
                            expression: "lowTempThreshold"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row mx-0 mb-6 align-baseline" },
                  [
                    _c("v-text-field", {
                      staticClass: "form-input col mandatory",
                      attrs: {
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        type: "text",
                        clearable: "",
                        label: _vm.$t("Location") + " *",
                        placeholder: "" + _vm.$t("Input Location")
                      },
                      model: {
                        value: _vm.assignedTo,
                        callback: function($$v) {
                          _vm.assignedTo = $$v
                        },
                        expression: "assignedTo"
                      }
                    }),
                    _c("v-text-field", {
                      ref: "highHumField",
                      staticClass: "form-input col ml-6 mandatory",
                      attrs: {
                        outlined: "",
                        dense: "",
                        type: "number",
                        clearable: "",
                        label: _vm.$t("High Humidity Alarm (%)") + " *",
                        placeholder: "" + _vm.$t("Input High Humidity"),
                        rules: _vm.highHumdyRules
                      },
                      model: {
                        value: _vm.highHmdtThreshold,
                        callback: function($$v) {
                          _vm.highHmdtThreshold = _vm._n($$v)
                        },
                        expression: "highHmdtThreshold"
                      }
                    }),
                    _c("v-text-field", {
                      ref: "lowHumField",
                      staticClass: "form-input col ml-6 mandatory",
                      attrs: {
                        outlined: "",
                        dense: "",
                        type: "number",
                        clearable: "",
                        label: _vm.$t("Low Humidity Alarm (%)") + " *",
                        placeholder: "" + _vm.$t("Input Low Humidity"),
                        rules: _vm.lowHumdyRules
                      },
                      model: {
                        value: _vm.lowHmdtThreshold,
                        callback: function($$v) {
                          _vm.lowHmdtThreshold = _vm._n($$v)
                        },
                        expression: "lowHmdtThreshold"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row mx-0 mb-3" },
                  [
                    _c("v-switch", {
                      staticClass: "form-switch pl-1",
                      attrs: {
                        label: _vm.$t("Temperature Threshold Notification"),
                        inset: "",
                        "hide-details": ""
                      },
                      model: {
                        value: _vm.tempAlarmStatus,
                        callback: function($$v) {
                          _vm.tempAlarmStatus = $$v
                        },
                        expression: "tempAlarmStatus"
                      }
                    }),
                    _c("v-switch", {
                      staticClass: "form-switch ml-6",
                      attrs: {
                        label: _vm.$t("Humidity Threshold Notification"),
                        inset: "",
                        "hide-details": ""
                      },
                      model: {
                        value: _vm.hmdtAlarmStatus,
                        callback: function($$v) {
                          _vm.hmdtAlarmStatus = $$v
                        },
                        expression: "hmdtAlarmStatus"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center pb-8" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "", disabled: _vm.isDisable },
                  on: {
                    click: function($event) {
                      return _vm.clickSave()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Add Sensor")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeModal()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Close")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }