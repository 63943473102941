<template>
  <div>
    <v-dialog v-model="dialogPopup" width="650" persistent>
      <v-card class="popup add_store_popup">
        <h3 class="page-title-bar">
          <i class="ico ico-multi"></i>{{ $t("Add Button Config") }}
        </h3>
        <div class="fixedHeader">
          <v-row>
            <v-col class="pt-0">
              <v-text-field outlined dense hide-details :label="'Button Config Name'"
                v-model="buttonConfig.buttonConfigName" ref="buttonConfigName" placeholder="Input The Name" width="48%"
                class="form-input mandatory col-6" :error-messages="errors.buttonConfigName"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select outlined dense hide-details :label="'Button 1 (Main)'" v-model="buttonConfig.Button1main"
                ref="main" placeholder="Select Button 1 (main)" class="form-select mandatory" clearable
                :items="getButtonsOptions" :item-text="'name'" :item-value="'value'"
                :error-messages="errors.Button1main"></v-select>
            </v-col>
            <v-col>
              <v-select outlined dense hide-details :label="'Button 1 (Main) Long Press'"
                v-model="buttonConfig.Button1mainLong" ref="mainLong" placeholder="Select Button 1 (Main)Long Press"
                class="form-select mandatory" clearable :items="getButtonsOptions" :item-text="'name'"
                :item-value="'value'" :error-messages="errors.Button1mainLong"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select outlined dense hide-details :label="'Button 2 (Sub)'" v-model="buttonConfig.Button2sub"
                ref="sub" placeholder="Select Button 2 (Sub)" class="form-select mandatory" clearable
                :items="getButtonsOptions" :item-text="'name'" :item-value="'value'"
                :error-messages="errors.Button2sub"></v-select>
            </v-col>
            <v-col>
              <v-select outlined dense hide-details :label="'Button 2 (Sub) Long Press'"
                v-model="buttonConfig.Button2subLong" ref="subLong" placeholder="Select Button 2 (Sub)Long Press"
                class="form-select mandatory" clearable :items="getButtonsOptions" :item-text="'name'"
                :item-value="'value'" :error-messages="errors.Button2subLong"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select outlined dense hide-details :label="'Button 3 (Extend1)'" v-model="buttonConfig.Button3extend"
                ref="extend1" placeholder="Select Button 3 (Extend1)" class="form-select mandatory" clearable
                :items="getButtonsOptions" :item-text="'name'" :item-value="'value'"
                :error-messages="errors.Button3extend"></v-select>
            </v-col>
            <v-col>
              <v-select outlined dense hide-details :label="'Button 3 (Extend1) Long Press'"
                v-model="buttonConfig.Button3extendLong" ref="extend1Long"
                placeholder="Select Button 3 (Extend1) Long Press" class="form-select mandatory" clearable
                :items="getButtonsOptions" :item-text="'name'" :item-value="'value'"
                :error-messages="errors.Button3extendLong"></v-select>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>
              <v-select outlined dense hide-details :label="'Button 4 (Extend2)'" v-model="buttonConfig.Button4extend"
                ref="extend2" placeholder="Select Button 4 (Extend2)" class="form-select mandatory" clearable
                :items="getButtonsOptions" :item-text="'name'" :item-value="'value'"
                :error-messages="errors.Button4extend"></v-select>
            </v-col>
            <v-col>
              <v-select outlined dense hide-details :label="'Button 4 (Extend2) Long Press'"
                v-model="buttonConfig.Button4extendLong" ref="extend2Long"
                placeholder="Select Button 4 (Extend2) Long Press" class="form-select mandatory" clearable
                :items="getButtonsOptions" :item-text="'name'" :item-value="'value'"
                :error-messages="errors.Button4extendLong"></v-select>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col>
              <v-select outlined dense hide-details :label="'Low Power Mode'" v-model="buttonConfig.realtimeConfigHibernate"
                ref="onTime" placeholder="Select low power mode" width="48%" class="form-input mandatory col-6"
                :items="[{ 'name': 'ON', 'value': true }, { 'name': 'OFF', 'value': false }]" :item-text="'name'"
                :item-value="'value'" :error-messages="errors.realtimeConfigHibernate"></v-select>
            </v-col>
          </v-row>

        </div>
        <v-card-actions class="d-flex justify-center">
          <v-btn text icon @click="save()" class="btn">{{
            $t("Save")
          }}</v-btn>
          <v-btn text icon @click="closeModal()" class="btn">{{
            $t("Cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventBus from '@/plugins/eventBus.js'
import codes from '@/plugins/codes'

export default {
  name: 'AddLedPopup',
  props: {
    dialogPopup: Boolean
  },
  watch: {
    dialogPopup: function () {
      this.buttonConfig = {
        buttonConfigName: '',
        Button1main: '',
        Button1mainLong: '',
        Button2sub: '',
        Button2subLong: '',
        Button3extend: 'DEFAULT',
        Button3extendLong: 'DEFAULT',
        Button4extend: 'DEFAULT',
        Button4extendLong: 'DEFAULT',
        realtimeConfigHibernate: true
      }
    }
  },
  computed: {
    getButtonsOptions () {
      return [
        { name: this.$t('Default'), value: 'DEFAULT' },
        { name: this.$t('Led Off'), value: 'LED_OFF' },
        { name: this.$t('Alarm'), value: 'ALARM' },
        { name: this.$t('Clear'), value: 'CLEAR' },
        { name: this.$t('Left Page Back'), value: 'LEFT_PAGE_BACK' },
        { name: this.$t('Right Page Back'), value: 'RIGHT_PAGE_BACK' },
        { name: this.$t('Left Page'), value: 'LEFT_PAGE' },
        { name: this.$t('Right Page'), value: 'RIGHT_PAGE' },
        { name: this.$t('Sync Loss Alarm'), value: 'SYNCLOSS_ALARM_1' },
        { name: this.$t('Picking Report'), value: 'PICKING_REPORT' },
        { name: this.$t('Changeable Left Page'), value: 'CHANGEABLE_LEFT_PAGE' },
        { name: this.$t('Changeable Right Page'), value: 'CHANGEABLE_RIGHT_PAGE' }
      ]
    }
  },
  methods: {
    closeModal (refresh) {
      if (refresh) {
        this.$emit('reload', true)
      } else {
        this.$emit('reload', false)
      }
    },
    validateFields () {
      let isValid = true

      // Reset all error messages
      for (const key in this.errors) {
        this.errors[key] = ''
      }

      if (!this.buttonConfig.buttonConfigName) {
        this.errors.buttonConfigName = 'Button Config Name is required'
        isValid = false
      }
      if (!this.buttonConfig.Button1main) {
        this.errors.Button1main = 'Button 1 (Main) is required'
        isValid = false
      }
      if (!this.buttonConfig.Button1mainLong) {
        this.errors.Button1mainLong = 'Button 1 (Main) Long Press is required'
        isValid = false
      }
      if (!this.buttonConfig.Button2sub) {
        this.errors.Button2sub = 'Button 2 (Sub) is required'
        isValid = false
      }
      if (!this.buttonConfig.Button2subLong) {
        this.errors.Button2subLong = 'Button 2 (Sub) Long Press is required'
        isValid = false
      }
      if (!this.buttonConfig.Button3extend) {
        this.errors.Button3extend = 'Button 3 (Extend1) is required'
        isValid = false
      }
      if (!this.buttonConfig.Button3extendLong) {
        this.errors.Button3extendLong = 'Button 3 (Extend1) Long Press is required'
        isValid = false
      }
      if (!this.buttonConfig.Button4extend) {
        this.errors.Button4extend = 'Button 4 (Extend2) is required'
        isValid = false
      }
      if (!this.buttonConfig.Button4extendLong) {
        this.errors.Button4extendLong = 'Button 4 (Extend2) Long Press is required'
        isValid = false
      }
      if (this.buttonConfig.realtimeConfigHibernate === null) {
        this.errors.realtimeConfigHibernate = 'Low Power Mode is required'
        isValid = false
      }

      return isValid
    },
    save () {
      if (this.validateFields()) {
        const params = {}
        var finalData = {
          id: 0,
          configName: this.buttonConfig.buttonConfigName,
          buttonSet: {
            SHORT_MAIN: this.buttonConfig.Button1main,
            SHORT_SUB: this.buttonConfig.Button2sub,
            SHORT_EXTEND1: this.buttonConfig.Button3extend,
            SHORT_EXTEND2: this.buttonConfig.Button1main,
            LONG_MAIN: this.buttonConfig.Button1mainLong,
            LONG_SUB: this.buttonConfig.Button2subLong,
            LONG_EXTEND1: this.buttonConfig.Button3extendLong,
            LONG_EXTEND2: this.buttonConfig.Button1mainLong
          },
          realtimeConfigHibernate: this.buttonConfig.realtimeConfigHibernate
        }
        console.log(finalData)
        const config = { params }
        this.$utils
          .callAxiosWithBody(
            codes.requests.toaddButtonConfig.method,
            codes.requests.toaddButtonConfig.url,
            finalData,
            config
          )
          .then((res) => {
            if (res.status === 200) {
              EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
              this.closeModal(true)
            } else {
              EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
            }
          })
          .catch((error) => {
            if (error.response.data.responseCode === '405') {
              EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
            } else {
              this.closeModal(true)
              EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
            }
          })
      }
    }

  },
  data () {
    return {
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      buttonConfig: {
        buttonConfigName: '',
        Button1main: '',
        Button1mainLong: '',
        Button2sub: '',
        Button2subLong: '',
        Button3extend: 'DISABLED',
        Button3extendLong: 'DISABLED',
        Button4extend: 'DISABLED',
        Button4extendLong: 'DISABLED',
        realtimeConfigHibernate: true
      },
      errors: {
        buttonConfigName: '',
        Button1main: '',
        Button1mainLong: '',
        Button2sub: '',
        Button2subLong: '',
        Button3extend: '',
        Button3extendLong: '',
        Button4extend: '',
        Button4extendLong: '',
        realtimeConfigHibernate: ''
      }
    }
  }
}
</script>
<style scoped>
::v-deep .mandatory .v-label.v-label--active:after,
.mandatory .v-label:after {
  margin-left: 3px;
}
</style>
