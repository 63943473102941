<template>
  <div>
    <h2 class="page-title-bar mt-7">
      <i class="ico ico-system"></i>{{ $t("Label Properties") }}
    </h2>
    <div>
      <v-row style="margin-top: 10px">
        <div class="col-8">
          <div class="searchArea">
            <div class="row">
              <v-text-field
                :label="`${$t('Label Code')}*`"
                :placeholder="`${$t('Input The Label Code')}`"
                class="form-input ml-3 col mandatory upper-case"
                outlined
                dense
                hide-details
                v-model="labelCode"
                clearable
              ></v-text-field>
            </div>
            <div class="col pl-3 pr-0">
              <v-btn
                text
                class="btn type-search ml-3"
                @click="getLabelsTypeInfo"
                :disabled="
                  labelCode === '' ||
                    labelCode === undefined ||
                    labelCode === null
                "
                >{{ $t("Search") }}
              </v-btn>
            </div>
            <div class="col pl-0">
              <v-btn text class="btn type-search ml-3" @click="clearSearch"
                >{{ $t("Clear") }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-row>
    </div>
    <v-dialog v-model="labelDetailsPopup" @click:outside="labelDetailsPopup = false" width="700" persistent>
      <v-card class="popup add_store_popup">
        <v-btn
          icon
          dark
          x-small
          class="cornerClose"
          @click="labelDetailsPopup = false"
        >
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
        <div class="popup-titbox align-items-center">
          <h3 class="page-title-bar">
            <i class="ico ico-template"></i>
            {{ $t("Label Properties") }}
          </h3>
          <div class="spacer"></div>
        </div>
        <div class="v-data-table tbl-type01 mt-6 theme--light">
          <div class="v-data-table__wrapper">
            <table class="table">
              <tr v-for="(value, name) of labelDetails" :key="name">
                <td width="30%">
                  <strong>
                    {{
                      name
                        | camelCaseToProperCase
                        | removeSpacefromCapitalLetter
                    }}
                  </strong>
                </td>

                <td>{{ value }}</td>
              </tr>
            </table>
          </div>
        </div>
        <v-card-actions class="d-flex justify-center">
          <v-btn text icon @click="labelDetailsPopup = false" class="btn"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import codes from '@/plugins/codes'
import EventBus from '@/plugins/eventBus'

const requests = {
  getlabeltype: {
    method: 'get',
    url: '/api/common/labels/type/one'
  }
}
export default {
  data () {
    return {
      labelDetails: {
        labelCode: '',
        name: '',
        displayWidth: 0,
        displayHeight: 0,
        totalPage: 0,
        colorType: '',
        resolution: 0,
        nfc: false
      },
      labelDetailsPopup: false,
      labelCode: '',
      btnDisabled: null
    }
  },
  filters: {
    // Filter definitions
    camelCaseToProperCase (value) {
      return value.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase()
      })
    },
    removeSpacefromCapitalLetter (value) {
      return value.replace(/([A-Z])\s(?=[A-Z])/g, '$1')
    }
  },
  mounted () {
    EventBus.$emit('enableSelectedStores', false)
    this.$store.dispatch('auth/getDisabledBtn', '2601').then(flag => {
      this.btnDisabled = flag
    })
  },
  methods: {
    clearSearch () {
      this.labelCode = ''
    },
    getLabelsTypeInfo () {
      const config = { params: { labelCode: this.labelCode } }
      this.$utils
        .callAxios(
          requests.getlabeltype.method,
          requests.getlabeltype.url,
          config
        )
        .then(res => {
          if (res.status === 200 && res.data) {
            console.log(res)
            this.labelDetailsPopup = true
            const labelData = res.data
            this.labelDetails.labelCode = labelData.labelCode
            this.labelDetails.name = labelData.name
            this.labelDetails.displayWidth = labelData.displayWidth
            this.labelDetails.displayHeight = labelData.displayHeight
            this.labelDetails.totalPage = labelData.totalPage
            this.labelDetails.colorType = labelData.colorType
            this.labelDetails.resolution = labelData.resolution
            this.labelDetails.nfc = labelData.nfc
          }
        })
        .catch(error => {
          this.labelDetails.labelCode = ''
          this.labelDetails.name = ''
          this.labelDetails.displayWidth = ''
          this.labelDetails.displayHeight = ''
          this.labelDetails.totalPage = ''
          this.labelDetails.colorType = ''
          this.labelDetails.resolution = ''
          this.labelDetails.nfc = false
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    }
  }
}
</script>
<style scoped>
.table tr td {
  border: 1px solid #ccc;
  padding: 3px;
  border-collapse: collapse;
}
.popup {
  padding: 20px;
}
.tit-section {
  background-image: linear-gradient(#ececec, #ececec);
  color: rgba(0, 0, 0, 0.7);
}
::v-deep.theme--light.v-select .v-select__selection--comma {
  /* color: #fafafa !important; */
}
::v-deep.v-select.v-input--dense .v-select__selection--comma {
    /* margin: 5px 4px 5px 19px; */
    font-size: var(--font17);
}
</style>
