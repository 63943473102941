var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "650", persistent: "" },
          model: {
            value: _vm.dialogPopup,
            callback: function($$v) {
              _vm.dialogPopup = $$v
            },
            expression: "dialogPopup"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "popup add_store_popup" },
            [
              _c("h3", { staticClass: "page-title-bar" }, [
                _c("i", { staticClass: "ico ico-multi" }),
                _vm._v(_vm._s(_vm.$t("Add Button Config")) + " ")
              ]),
              _c(
                "div",
                { staticClass: "fixedHeader" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0" },
                        [
                          _c("v-text-field", {
                            ref: "buttonConfigName",
                            staticClass: "form-input mandatory col-6",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Button Config Name",
                              placeholder: "Input The Name",
                              width: "48%",
                              "error-messages": _vm.errors.buttonConfigName
                            },
                            model: {
                              value: _vm.buttonConfig.buttonConfigName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.buttonConfig,
                                  "buttonConfigName",
                                  $$v
                                )
                              },
                              expression: "buttonConfig.buttonConfigName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            ref: "main",
                            staticClass: "form-select mandatory",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Button 1 (Main)",
                              placeholder: "Select Button 1 (main)",
                              clearable: "",
                              items: _vm.getButtonsOptions,
                              "item-text": "name",
                              "item-value": "value",
                              "error-messages": _vm.errors.Button1main
                            },
                            model: {
                              value: _vm.buttonConfig.Button1main,
                              callback: function($$v) {
                                _vm.$set(_vm.buttonConfig, "Button1main", $$v)
                              },
                              expression: "buttonConfig.Button1main"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            ref: "mainLong",
                            staticClass: "form-select mandatory",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Button 1 (Main) Long Press",
                              placeholder: "Select Button 1 (Main)Long Press",
                              clearable: "",
                              items: _vm.getButtonsOptions,
                              "item-text": "name",
                              "item-value": "value",
                              "error-messages": _vm.errors.Button1mainLong
                            },
                            model: {
                              value: _vm.buttonConfig.Button1mainLong,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.buttonConfig,
                                  "Button1mainLong",
                                  $$v
                                )
                              },
                              expression: "buttonConfig.Button1mainLong"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            ref: "sub",
                            staticClass: "form-select mandatory",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Button 2 (Sub)",
                              placeholder: "Select Button 2 (Sub)",
                              clearable: "",
                              items: _vm.getButtonsOptions,
                              "item-text": "name",
                              "item-value": "value",
                              "error-messages": _vm.errors.Button2sub
                            },
                            model: {
                              value: _vm.buttonConfig.Button2sub,
                              callback: function($$v) {
                                _vm.$set(_vm.buttonConfig, "Button2sub", $$v)
                              },
                              expression: "buttonConfig.Button2sub"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            ref: "subLong",
                            staticClass: "form-select mandatory",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Button 2 (Sub) Long Press",
                              placeholder: "Select Button 2 (Sub)Long Press",
                              clearable: "",
                              items: _vm.getButtonsOptions,
                              "item-text": "name",
                              "item-value": "value",
                              "error-messages": _vm.errors.Button2subLong
                            },
                            model: {
                              value: _vm.buttonConfig.Button2subLong,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.buttonConfig,
                                  "Button2subLong",
                                  $$v
                                )
                              },
                              expression: "buttonConfig.Button2subLong"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            ref: "extend1",
                            staticClass: "form-select mandatory",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Button 3 (Extend1)",
                              placeholder: "Select Button 3 (Extend1)",
                              clearable: "",
                              items: _vm.getButtonsOptions,
                              "item-text": "name",
                              "item-value": "value",
                              "error-messages": _vm.errors.Button3extend
                            },
                            model: {
                              value: _vm.buttonConfig.Button3extend,
                              callback: function($$v) {
                                _vm.$set(_vm.buttonConfig, "Button3extend", $$v)
                              },
                              expression: "buttonConfig.Button3extend"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            ref: "extend1Long",
                            staticClass: "form-select mandatory",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Button 3 (Extend1) Long Press",
                              placeholder:
                                "Select Button 3 (Extend1) Long Press",
                              clearable: "",
                              items: _vm.getButtonsOptions,
                              "item-text": "name",
                              "item-value": "value",
                              "error-messages": _vm.errors.Button3extendLong
                            },
                            model: {
                              value: _vm.buttonConfig.Button3extendLong,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.buttonConfig,
                                  "Button3extendLong",
                                  $$v
                                )
                              },
                              expression: "buttonConfig.Button3extendLong"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            ref: "onTime",
                            staticClass: "form-input mandatory col-6",
                            attrs: {
                              outlined: "",
                              dense: "",
                              "hide-details": "",
                              label: "Low Power Mode",
                              placeholder: "Select low power mode",
                              width: "48%",
                              items: [
                                { name: "ON", value: true },
                                { name: "OFF", value: false }
                              ],
                              "item-text": "name",
                              "item-value": "value",
                              "error-messages":
                                _vm.errors.realtimeConfigHibernate
                            },
                            model: {
                              value: _vm.buttonConfig.realtimeConfigHibernate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.buttonConfig,
                                  "realtimeConfigHibernate",
                                  $$v
                                )
                              },
                              expression: "buttonConfig.realtimeConfigHibernate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Save")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.closeModal()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }