<template>
  <div>
    <h2 class="page-title-bar">
      <i class="ico ico-multi"></i>{{ $t("Button Configuration") }}
    </h2>
    <div class="col-12 pl-0 pb-0 pt-0 pr-0 d-flex" style="margin-top:10px">

      <div class="col-3 pr-0 pt-0">
        <v-data-table v-model="selected" :server-items-length="totalProducts" :headers="tableHeadersFreeze"
          :hide-default-footer="true" :item-class="getRowClass" :items="ButtonConfigTableList" :single-select="true"
          :options.sync="options" @click:row="openButtonConfigEditPopup" class="tbl-type01 mt-10 products" show-select
          item-key="configName">
          <!-- No Data Slot -->
          <template slot="no-data">
            <p>
              {{ $t('No data available') }}
            </p>
          </template>
          <template v-slot:[`item.sno`]="{ item }">
            {{ ButtonConfigTableList.indexOf(item) + 1 }}
          </template>
          <template v-slot:item.data-table-select="{ item }">
    <v-checkbox
    class="custom-checkbox "
      :value="selected.includes(item)"
      :disabled="item.id === 1"
      style="height: 40px;"
      @click.stop="toggleSelect(item);"
    />
  </template>
        </v-data-table>
      </div>
      <!-- Main Table -->
      <div class="col-9 pl-0 pt-0 pr-1">
        <v-data-table v-model="selected" :server-items-length="totalProducts" :headers="tableHeaders"
          :hide-default-footer="true" :item-class="getRowClass" :items="ButtonConfigTableList" :single-select="true"
          :options.sync="options" @click:row="openButtonConfigEditPopup" class="tbl-type01 mt-10 tblBorder"
          item-key="configName">
          <!-- No Data Slot -->
          <template slot="no-data">
            <p>
              {{ $t('No data available') }}
            </p>
          </template>
          <template v-slot:[`item.realtimeConfigHibernate`]="{ item }">
            {{ item.realtimeConfigHibernate === true ? 'ON' : 'OFF' }}
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="table-options">
      <div>
        <v-btn class="btn ml-2" text :disabled="btnDisabledNewDelete" @click="openNewButtonConfigPopup">{{ $t("New") }}
        </v-btn>
        <v-btn :disabled="btnDisabledNewDelete || checkSelected" @click="clickDelete" class="btn ml-2" text>{{
          $t('Delete')
        }}
        </v-btn>
      </div>
    </div>
    <EditButtonConfig :selectedItem="selectedItem" :dialogPopup="editDialogPopup" @reload="reload"></EditButtonConfig>
    <AddButtonConfig :dialogPopup="addDialogPopup" @reload="reload"></AddButtonConfig>
    <a ref="link" :style="{ display: 'none' }" />
  </div>
</template>

<script>
import EventBus from '@/plugins/eventBus'
import codes from '@/plugins/codes'
import AddButtonConfig from './modal/AddButtonConfig.vue'
import EditButtonConfig from './modal/EditButtonConfig.vue'

export default {
  name: 'ButtonConfig',
  components: {
    AddButtonConfig,
    EditButtonConfig
  },
  data () {
    return {
      btnDisabledNewDelete: null,
      btnDisabledDetail: null,
      btnDisabledExport: null,
      page: 0,
      ButtonConfigTableList: [],
      selected: [],
      options: {},
      // New Button Config
      addDialogPopup: false,
      editDialogPopup: false,
      totalProducts: 10,
      selectedItem: null
    }
  },
  computed: {
    tableHeadersFreeze () {
      return [
        { text: '#', sortable: false, align: 'center', value: 'sno' },
        {
          text: this.$t('BUTTON CONFIG NAME'),
          sortable: false,
          value: 'configName',
          width: '60%'
        }
      ]
    },
    tableHeaders () {
      return [
        { text: this.$t('BUTTON 1 (MAIN)'), sortable: false, value: 'SHORT_MAIN' },
        { text: this.$t('BUTTON 1 (MAIN)Long'), sortable: false, value: 'LONG_MAIN' },

        {
          text: this.$t('BUTTON 2 (SUB)'),
          sortable: false,
          value: 'SHORT_SUB'
        },
        {
          text: this.$t('BUTTON 2 (SUB)Long'),
          sortable: false,
          value: 'LONG_SUB'
        },
        {
          text: this.$t('BUTTON 3 (EXTEND1)'),
          sortable: false,
          value: 'SHORT_EXTEND1'
        },
        {
          text: this.$t('BUTTON 3 (EXTEND1)Long'),
          sortable: false,
          value: 'LONG_EXTEND1'
        },
        // {
        //   text: this.$t('BUTTON 4 (EXTEND2)'),
        //   sortable: false,
        //   value: 'SHORT_EXTEND2'
        // },
        // {
        //   text: this.$t('BUTTON 4 (EXTEND2)Long'),
        //   sortable: false,
        //   value: 'LONG_EXTEND2'
        // },
        {
          text: this.$t('LOW POWER MODE'),
          sortable: false,
          value: 'realtimeConfigHibernate'
        }
      ]
    },
    checkSelected () {
      var flag = true
      if (this.selected.length === 1) flag = false
      return flag
    }
  },
  watch: {},
  mounted () {
    this.clearTabindex()
    // button  권한 체크
    // button Config : New / Delete / Edit
    this.$store.dispatch('auth/getDisabledBtn', '6401').then(flag => {
      this.btnDisabledNewDelete = flag
    })

    EventBus.$emit('enableSelectedStores', false)
    // 검색조건 설정
    this.togetButtonConfigList()
  },
  methods: {
    openNewButtonConfigPopup () {
      this.addDialogPopup = true
    },
    openButtonConfigEditPopup (item) {
      if (!this.btnDisabledNewDelete) {
        console.log(item)
        this.editDialogPopup = false
        this.selectedItem = item
        this.editDialogPopup = true
      }
    },
    getRowClass (item) {
      // return item.configName === 'DEFAULT' ? 'disabled-row' : '' || item.id === 1 ? 'disabled-row' : ''
    },
    reload (refresh) {
      console.log(refresh)
      this.dialogPopup = false
      this.addDialogPopup = false
      this.editDialogPopup = false
      if (refresh) this.togetButtonConfigList()
    },
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    togetButtonConfigList () {
      const params = {}
      const config = { params }
      this.$utils
        .callAxios(
          codes.requests.toGetButtonConfig.method,
          codes.requests.toGetButtonConfig.url,
          config
        )
        .then(res => {
          console.log(res)
          var data = res.data.configList
          this.ButtonConfigTableList = data.map(config => ({
            id: config.id,
            configName: config.configName,
            SHORT_MAIN: config.buttonSet.SHORT_MAIN,
            SHORT_SUB: config.buttonSet.SHORT_SUB,
            SHORT_EXTEND1: config.buttonSet.SHORT_EXTEND1,
            SHORT_EXTEND2: config.buttonSet.SHORT_EXTEND2,
            LONG_MAIN: config.buttonSet.LONG_MAIN,
            LONG_SUB: config.buttonSet.LONG_SUB,
            LONG_EXTEND1: config.buttonSet.LONG_EXTEND1,
            LONG_EXTEND2: config.buttonSet.LONG_EXTEND2,
            realtimeConfigHibernate: config.realtimeConfigHibernate,
            created: config.created,
            lastModified: config.lastModified
          }))
        }).catch(error => {
          if (error.response !== undefined) {
            EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
          } else {
            EventBus.$emit('messageAlert', this.$t('Failed to delete an anchor.'))
          }
          console.log(`Failed to delete anchors ${error}`)
        })
    },
    toggleSelect (item) {
      this.editDialogPopup = false
      this.selected = []
      const index = this.selected.indexOf(item)
      if (item.id !== 1) {
        if (index === -1) {
          this.selected.push(item)
        } else {
          this.selected.splice(index, 1)
        }
      } else {
        console.log('Selection disabled for this item')
      }
    },
    clickDelete () {
      console.log(this.selected[0].id)
      const config = { params: {} }
      this.$utils.callAxios(
        codes.requests.toDeleteButtonConfig.method,
        codes.requests.toDeleteButtonConfig.url + '/' + `${this.selected[0].id}`,
        config
      )
        .then(res => {
          EventBus.$emit('messageAlert', this.$t(res.data.responseMessage))
          this.togetButtonConfigList()
        }).catch(error => {
          if (error.response !== undefined) {
            EventBus.$emit('messageAlert', this.$t(error.response.data.responseMessage))
          } else {
            EventBus.$emit('messageAlert', this.$t('Failed to delete a Config.'))
          }
          console.log(`Failed to delete Config ${error}`)
        })
    },

    paging (page) { this.searchAccountList(page) }

  }
}
</script>
<style>
.row-class:hover {
  cursor: pointer;
}

.pageAlign {
  float: right;

}

::-webkit-scrollbar {
  height: 8px;
}

.v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
}

.tblBorder th {
  min-width: 180px !important;
}
.disabled-row {
  pointer-events: none;
  opacity: 0.5;
}
.custom-checkbox {
  /* height: 30px !important;      Adjust this value to change the height */
  width: 24px;       /* Adjust width if necessary */
  line-height: 24px; /* This aligns the content vertically */
}

.custom-checkbox .v-input--selection-controls__input {
  height: 24px;
  margin-top: 10px !important;     /* Adjust height of the input */
}
.custom-checkbox .v-input--selection-controls {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
.v-input--selection-controls {
    margin-top: 0px !important;
    padding-top: 0px !important;
}

</style>
