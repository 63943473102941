import { render, staticRenderFns } from "./LabelPopupLabel.vue?vue&type=template&id=1138ff95&scoped=true&"
import script from "./LabelPopupLabel.vue?vue&type=script&lang=js&"
export * from "./LabelPopupLabel.vue?vue&type=script&lang=js&"
import style0 from "./LabelPopupLabel.vue?vue&type=style&index=0&id=1138ff95&scoped=true&lang=css&"
import style1 from "./LabelPopupLabel.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1138ff95",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCheckbox,VCol,VDataTable,VDialog,VDivider,VFileInput,VIcon,VLabel,VList,VListItem,VListItemContent,VListItemTitle,VRow,VSelect,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/aims-dashboard/aims-dashboard/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1138ff95')) {
      api.createRecord('1138ff95', component.options)
    } else {
      api.reload('1138ff95', component.options)
    }
    module.hot.accept("./LabelPopupLabel.vue?vue&type=template&id=1138ff95&scoped=true&", function () {
      api.rerender('1138ff95', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Label/modal/LabelPopupLabel.vue"
export default component.exports