var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "306", persistent: "" },
      model: {
        value: _vm.dialogConfirm,
        callback: function($$v) {
          _vm.dialogConfirm = $$v
        },
        expression: "dialogConfirm"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "popup add_store_popup popup-success",
          attrs: { align: "center", justify: "center" }
        },
        [
          _c("i", { staticClass: "ico-success" }, [
            _c("img", {
              attrs: { src: require("@/assets/img/ico-caution.png"), alt: "" }
            })
          ]),
          _c("div", { staticClass: "msg" }, [
            _c("h3", { staticClass: "mb-0" }, [
              _vm._v(_vm._s(_vm.$t(_vm.title)))
            ])
          ]),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("span", {
                  staticStyle: { "font-size": "15px" },
                  domProps: { textContent: _vm._s(_vm.confirmMessageText) }
                })
              ])
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.moveTap("Yes")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Yes")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.moveTap("No")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("No")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }