<template>
  <div>
    <div class="d-flex justify-space-between">
      <h2 class="page-title-bar">
        <i class="ico ico-overview"></i>{{ $t("Overview") }}
      </h2>
      <!-- <div class="d-flex align-center">
        <span><b>Temperature Unit</b></span>
        <Toggle
          :toggleState="toggleState"
          @valueChanged="valueChanged($event)"
          class="ml-2"
        />
      </div> -->
    </div>
    <v-row class="">
      <v-col sm="12" md="12" lg="12" xl="12" class="py-0 px-0 mt-8">
        <v-row class="ma-0">
          <v-col cols="12" class="d-flex" style="padding: 0px !important;">
            <!-- Device Status  -->
            <v-col cols="6" class="px-0 py-0 d-flex">
              <v-col cols="12" class="pr-3">
                <div class="heading w-100">
                  <h2
                    class="page-title-bar"
                    style="font-weight: normal;font-size: 20px;color: #001E38;"
                  >
                    {{ $t("Device Status") }}
                  </h2>
                </div>
                <v-card
                  class="summary-card d-flex"
                  elevation-2
                  outlined
                  style="cursor: pointer"
                >
                  <div class="col-12 px-0 py-0 d-flex" v-if="!deviceLoading">
                    <div class="col-6 px-0 py-0 row mx-0">
                      <div class="heading w-100">
                        <h3 class="title-head">Connectivity</h3>
                      </div>
                      <div
                        class="w-100 d-flex justify-center"
                        v-if="connectivity.length !== 0"
                      >
                        <GradientDonutChart
                          :chartSeries="connectivity"
                          :chartLabels="connectivityLabels"
                          :chartType="'donut'"
                          :height="250"
                          :chartPlotOptions="donutChartPlotOptions"
                          :colorOption="color"
                          :dataLabelsOption="optionEnable"
                          :chartLegendOption="legendPosBottom"
                          :tooltip="tooltip"
                        ></GradientDonutChart>
                      </div>
                      <div
                        class="w-100 d-flex justify-center"
                        v-if="connectivity.length !== 0"
                      >
                        <div
                          class="mr-3"
                          v-for="(label, idx) in connectivityLabels"
                          :key="idx"
                        >
                          <div class="d-flex align-baseline">
                            <div
                              class="square-icon mr-2"
                              :style="{ backgroundColor: color[idx] }"
                            ></div>
                            <div class="name">{{ label }}</div>
                          </div>
                          <span class="name" style="margin-left: 18px;"
                            ><b>{{ connectivity[idx] }}</b></span
                          >
                        </div>
                      </div>
                      <div class="w-100 mx-5 mt-5" style="height: 100%;" v-else>
                        <v-alert
                          dense
                          color="#697A8A"
                          class="ma-0 d-flex justify-center"
                          style="line-height: 19px; color:#fff"
                        >
                          <v-icon class="mr-1" color="#fff"
                            >mdi-alert-outline</v-icon
                          >
                          {{ $t("No data available") }}
                        </v-alert>
                      </div>
                    </div>
                    <div class="col-6 px-0 py-0 row mx-0">
                      <div class="heading w-100">
                        <h3 class="title-head">Battery</h3>
                      </div>
                      <div
                        class="w-100 d-flex justify-center"
                        v-if="battery.length !== 0"
                      >
                        <GradientDonutChart
                          :chartSeries="battery"
                          :chartLabels="batteryLabels"
                          :chartType="'donut'"
                          :height="250"
                          :chartPlotOptions="donutChartPlotOptions"
                          :colorOption="batteryColor"
                          :dataLabelsOption="optionEnable"
                          :chartLegendOption="legendPosBottom"
                          :tooltip="tooltip"
                        ></GradientDonutChart>
                      </div>
                      <div
                        class="w-100 d-flex justify-center"
                        v-if="battery.length !== 0"
                      >
                        <div
                          class="mr-3"
                          v-for="(label, idx) in batteryLabels"
                          :key="idx"
                        >
                          <div class="d-flex align-baseline">
                            <div
                              class="square-icon mr-2"
                              :style="{ backgroundColor: batteryColor[idx] }"
                            ></div>
                            <div class="name">{{ label }}</div>
                          </div>
                          <span class="name" style="margin-left: 18px;"
                            ><b>{{ battery[idx] }}</b></span
                          >
                        </div>
                      </div>
                      <div class="w-100 mx-5 mt-5" style="height: 100%;" v-else>
                        <v-alert
                          dense
                          color="#697A8A"
                          class="ma-0 d-flex justify-center"
                          style="line-height: 19px; color:#fff"
                        >
                          <v-icon class="mr-1" color="#fff"
                            >mdi-alert-outline</v-icon
                          >
                          {{ $t("No data available") }}
                        </v-alert>
                      </div>
                    </div>
                  </div>
                  <div class="wrapper-loading w-100" v-else>
                    <div class="loader"></div>
                  </div>
                </v-card>
              </v-col>
            </v-col>
            <!-- Alert  -->
            <v-col cols="6" class="px-0 py-0 d-flex">
              <v-col cols="12" class="pr-3">
                <div class="heading w-100">
                  <h2
                    class="page-title-bar"
                    style="font-weight: normal;font-size: 20px;color: #001E38;"
                  >
                    {{ $t("Alert") }}
                  </h2>
                </div>
                <v-card
                  class="summary-card d-flex"
                  elevation-2
                  outlined
                  style="cursor: pointer"
                >
                  <div class="col-12 px-0 py-0 row mx-0">
                    <div class="heading w-100 d-flex mx-3 mt-3">
                      <h3
                        class="title-head pr-2 pt-0"
                        @click="handleAlertBox('Active')"
                        :class="alertActive === 'Active' ? 'active' : ''"
                      >
                        Active
                      </h3>
                      <h3 class="title-head pr-3 pt-0">|</h3>
                      <div class="w-100 d-flex justify-space-between">
                        <h3
                          class="title-head pl-2 pt-0"
                          @click="handleAlertBox('History')"
                          :class="alertActive === 'History' ? 'active' : ''"
                        >
                          History
                        </h3>

                        <v-select
                          label=""
                          :placeholder="$t('Select Duration')"
                          outlined
                          dense
                          hide-details
                          v-model="selectedDuration"
                          :items="durationList"
                          item-text="text"
                          item-value="val"
                          class="form-select col-3 pt-0"
                          v-if="alertActive === 'History'"
                          @change="updateDuration"
                        >
                        </v-select>
                      </div>
                    </div>
                    <div
                      class="w-100 d-flex"
                      v-if="alertActive === 'Active' && !alertLoading"
                      style="height: 100%; margin-top: 6px;"
                    >
                      <div
                        class="col-9 d-flex justify-center"
                        v-if="activeAlert.length !== 0"
                      >
                        <GradientDonutChart
                          :chartSeries="activeAlert"
                          :chartLabels="activeAlertLabels"
                          :chartType="'donut'"
                          :height="250"
                          :chartPlotOptions="donutChartPlotOptions"
                          :colorOption="activeAlertColor"
                          :dataLabelsOption="optionEnable"
                          :chartLegendOption="legendPosBottom"
                          :tooltip="tooltip"
                        ></GradientDonutChart>
                      </div>
                      <div class="col-3" v-if="activeAlert.length !== 0">
                        <div
                          class="mt-3"
                          v-for="(label, idx) in activeAlertLabels"
                          :key="idx"
                          style="cursor:pointer"
                          @click="openAlertDialog(idx, 'donut')"
                        >
                          <div class="d-flex align-baseline">
                            <div
                              class="square-icon mr-2"
                              :style="{
                                backgroundColor: activeAlertColor[idx]
                              }"
                            ></div>
                            <div class="name">{{ label }}</div>
                          </div>
                          <span class="name" style="margin-left: 18px;"
                            ><b>{{ activeAlert[idx] }}</b></span
                          >
                        </div>
                      </div>
                      <div class="w-100 mx-5" v-else>
                        <v-alert
                          dense
                          color="#697A8A"
                          class="ma-0 d-flex justify-center"
                          style="line-height: 19px; color:#fff"
                        >
                          <v-icon class="mr-1" color="#fff"
                            >mdi-alert-outline</v-icon
                          >
                          {{ $t("No data available") }}
                        </v-alert>
                      </div>
                    </div>
                    <div
                      class="w-100"
                      v-else-if="alertActive === 'History' && !alertLoading"
                      :style="categories.length === 0 ? 'height: 100%;' : ''"
                    >
                      <div class="d-flex w-100" v-if="categories.length !== 0">
                        <div class="" style="width: calc(100% - 135px);">
                          <StackChart
                            :data="stackChartData"
                            :categories="categories"
                            :xTitle="''"
                            :noData="noData"
                            :colors="stackColor"
                            :height="320"
                          />
                        </div>
                        <div class="px-0 d-flex align-center">
                          <div style="width: 125px;">
                            <div
                              class="mt-6 ml-3"
                              v-for="(label, idx) in stackChartData"
                              :key="idx"
                              style="cursor:pointer"
                              @click="openAlertDialog(idx, 'stack')"
                            >
                              <div class="d-flex align-baseline">
                                <div
                                  class="square-icon mr-2"
                                  :style="{
                                    backgroundColor: stackColor[idx]
                                  }"
                                ></div>
                                <div class="name">{{ label.name }}</div>
                              </div>
                              <span
                                class="name"
                                style="margin-left: 18px;"
                                v-if="label.data.lenght !== 0"
                                ><b>{{
                                  label.data.reduce(
                                    (accumulator, currentValue) =>
                                      accumulator + currentValue,
                                    0
                                  )
                                }}</b></span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="w-100 px-5 pt-5" style="height: 100%;" v-else>
                        <v-alert
                          dense
                          color="#697A8A"
                          class="ma-0 d-flex justify-center"
                          style="line-height: 19px; color:#fff"
                        >
                          <v-icon class="mr-1" color="#fff"
                            >mdi-alert-outline</v-icon
                          >
                          {{ $t("No data available") }}
                        </v-alert>
                      </div>
                    </div>
                    <div class="wrapper-loading w-100" style="height: 87%;" v-else>
                    <div class="loader"></div>
                  </div>
                  </div>
                </v-card>
              </v-col>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="row">
      <div class="col-12 pb-0">
        <!-- SEARCH AREA  -->
        <div class="searchArea col">
          <div class="row">
            <div class="d-flex col-12 align-baseline px-0 py-0">
              <!-- Sensor Name -->
              <v-text-field
                :label="$t('Sensor Name')"
                outlined
                hide-details
                dense
                :placeholder="$t('Input Sensor Name')"
                v-model="searchBy.sensorName"
                clearable
                class="form-select col"
              >
              </v-text-field>
              <!--Sensor ID-->
              <v-text-field
                :label="$t('Sensor ID')"
                outlined
                hide-details
                dense
                :placeholder="$t('Input Sensor ID')"
                v-model="searchBy.sensorId"
                clearable
                class="form-select col ml-3"
              >
              </v-text-field>
              <!-- Sensor Status -->
              <v-select
                :label="$t('Sensor Status')"
                :placeholder="$t('Select Sensor Status')"
                outlined
                dense
                hide-details
                v-model="searchBy.sensorStatus"
                :items="sensorStatusList"
                item-text="text"
                item-value="val"
                class="form-select col ml-3"
                clearable
              >
              </v-select>
              <!--Shelf ID -->
              <v-text-field
                :label="$t('Location')"
                outlined
                hide-details
                dense
                :placeholder="$t('Input Location')"
                v-model="searchBy.location"
                clearable
                class="form-select col ml-3"
              >
              </v-text-field>

              <v-btn class="btn type-search ml-3" text @click="clickSearch()"
                >{{ $t("Search") }}
              </v-btn>
              <v-btn class="btn type-search ml-3" text @click="clearSearch"
                >{{ $t("Clear") }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-1">
      <v-data-table
        :headers="tableHeaders"
        :items="tableItems"
        item-key="serialNo"
        :hide-default-footer="true"
        :options.sync="options"
        :server-items-length="tableItems.length"
        :show-select="true"
        v-model="SelectedsensorValue"
        class="tbl-type01 mt-5 row-class"
        fixed-header
        single-select
        @click:row="openSensorDetails($event)"
        :height="tableItems && tableItems.length > 8 ? '550px' : 'auto'"
      >
        <template slot="loading">
          <div class="wrapper-loading">
            <div class="loader"></div>
          </div>
        </template>
        <template slot="no-data">
          <v-alert dense color="#697A8A" class="ma-0" style="line-height: 19px">
            <v-icon class="mr-1" color="#fff">mdi-alert-outline</v-icon>
            {{ $t("No data available") }}
          </v-alert>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div class="d-flex justify-center" v-if="item.status !== '-'">
            <p
              class="circle"
              :style="
                item.status.toLowerCase() === 'offline'.toLowerCase()
                  ? 'background-color: #e27070'
                  : 'background-color: #9bcde3'
              "
            ></p>
          </div>
          <div v-else>{{ item.status }}</div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div class="d-flex">
            <p
              @click.stop="editDialogOpen(item)"
              class="d-inline-block pointer mr-2"
            >
              <v-icon size="24" class=" darken-2" style="color: #001e38;"
                >mdi-square-edit-outline</v-icon
              >
            </p>
            <p @click.stop="deleteItem(item)" class="d-inline-block pointer">
              <v-icon size="24" class="red--text darken-2"
                >mdi-trash-can-outline</v-icon
              >
            </p>
          </div>
        </template>
      </v-data-table>
      <div class="table-options" >
        <div>
          <v-btn @click="addSensor" :disabled="btnAction" text class="btn">{{
            $t("Add Sensor")
          }}</v-btn>

        <v-btn :disabled="SelectedsensorValue.length===0 || btnAction" @click="unassignSensor()" text class="btn ml-3">{{
            $t("Unassign")
          }}</v-btn>
        </div>

      </div>
      <div class="col-12 pr-0" style="padding:0px;margin: auto">
      <Pagination @paging="paging($event)" :pageInfoText="pageInfoText" :pageIndex="pageIndex" :rowPerPage="rowPerPage"
        :goToPageInput="goToPageInput" :totalPages="totalPages" @updatePage="updatePage($event)"></Pagination>
    </div>
    </div>
    <DeleteConfirmation
      v-if="deleteConfirmation"
      :dialogConfirm="deleteConfirmation"
      @confirmAction="deleteAction($event)"
    />
    <AddSensor
      v-if="addSensorDialog"
      :addDialogPopup="addSensorDialog"
      @closeAddModal="addSensorDialog = false"
    />
    <SensorDetails
      v-if="sensorDetailsDialog"
      :addDialogPopup="sensorDetailsDialog"
      @closeAddModal="closeSensorDetailsDialog"
      :sensorDetails="sensorDetails"
      @openEditDialog="editDialogOpen($event)"
    />
    <AlertDetails
      v-if="alertDetailsDialog"
      :alertDialogPopup="alertDetailsDialog"
      @closeAlertModal="closeAlertDetailsDialog"
      :alertDetails="alertDetails"
    />
    <EditSensor
      v-if="editSensorDialog"
      :addDialogPopup="editSensorDialog"
      :editList="editData"
      @closeEditModal="closeEditSensorDialog"
    />
    <!-- <RedirectOverview
      v-if="redirectEnable"
      :messageDialog="redirectEnable"
      @redirect="goToOverviewPage"
    /> -->
  </div>
</template>

<script>
import EventBus from '@/plugins/eventBus'
import Pagenation from '@/mixins/Pagenation'
import DeleteConfirmation from '@/components/modal/DeleteConfirmtion.vue'
import AddSensor from './model/AddSensor.vue'
import SensorDetails from './model/SensorDetails.vue'
import AlertDetails from './model/AlertDetails.vue'
import EditSensor from './model/EditSensor.vue'
import GradientDonutChart from '@/components/ApexCharts/GradientDonutChart.vue'
// import Toggle from '@/components/Toggle.vue'
import StackChart from '@/components/Charts/StackChart'
import { mapGetters } from 'vuex'
import codes from '@/plugins/codes'
import moment from 'moment'
import commons from '@/plugins/commons'
import Pagination from '@/components/pagination/Pagination.vue'

export default {
  mixins: [Pagenation],
  components: {
    DeleteConfirmation,
    AddSensor,
    SensorDetails,
    AlertDetails,
    EditSensor,
    GradientDonutChart,
    // Toggle,
    StackChart,
    Pagination
  },
  data () {
    return {
      deviceLoading: false,
      alertLoading: false,
      noData: false,
      redirectEnable: false,
      stackColor: ['#001E38', '#001E3880', '#FF8097', '#6DA3F8'],
      categories: [],
      stackChartData: [
        {
          name: 'High Temperature',
          data: [],
          borderRadiusTopLeft: '10px',
          borderRadiusTopRight: '10px'
        },
        {
          name: 'Low Temperature',
          data: []
        },
        {
          name: 'High Humidity',
          data: []
        },
        {
          name: 'Low Humidity',
          data: []
        }
      ],
      toggleState: true,
      alertActive: 'Active',
      legendPosBottom: {
        show: false,
        position: 'bottom',
        formatter: function (val, opts) {
          return val + ' - ' + opts.w.globals.series[opts.seriesIndex]
        },
        fontFamily: "'Source Sans Pro', sans-serif'",
        fontSize: '12px',
        itemMargin: {
          horizontal: 10
        },
        markers: {
          offsetX: -5 // Adjust the horizontal offset between the symbol and text
        },
        style: {
          fontSize: '14px',
          fontWeight: 'bold'
        }
      },
      donutChartPlotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '60',
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: '26px',
                fontFamily: "'Source Sans Pro', sans-serif'",
                fontWeight: 'bold',
                offsetY: 10
              },
              total: {
                show: true,
                showAlways: true,
                label: '',
                fontSize: '14px',
                fontWeight: 'bold',
                fontFamily: "'Source Sans Pro', sans-serif'",
                color: '#8394b4',
                formatter: function (w) {
                  const totalValue = w.globals.seriesTotals.reduce(
                    (a, b) => a + b,
                    0
                  )
                  if (totalValue === 0) return 0
                  return Number(totalValue)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
                }
              }
            }
          }
        }
      },
      optionEnable: {
        enabled: false,
        style: {
          fontSize: '10px',
          fontFamily: "'Source Sans Pro', sans-serif'",
          fontWeight: 'bold'
        }
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="arrow_box1">' +
            '<span>' +
            w.config.labels[seriesIndex] +
            ' : ' +
            series[seriesIndex] +
            '</span>' +
            '</div>'
          )
        }
      },
      connectivity: [],
      battery: [],
      activeAlert: [],
      activeAlertLabels: [
        'High Temperature',
        'Low Temperature',
        'High Humidity',
        'Low Humidity'
      ],
      batteryLabels: ['Good', 'Bad'],
      batteryColor: ['#001E38', '#FF8097'],
      activeAlertColor: ['#001E38', '#001E3880', '#FF8097', '#6DA3F8'],
      color: ['#001E38', '#EAEEF7'],
      connectivityLabels: ['Connected', 'Disconnected'],
      editSensorDialog: false,
      sensorDetails: {},
      alertDetails: {},
      editData: {},
      deleteConfirmation: false,
      addSensorDialog: false,
      sensorDetailsDialog: false,
      alertDetailsDialog: false,
      deleteData: {},
      rowPerPage: '10',
      page: 1,
      totalPages: 1,
      pageIndex: 1,
      rowPerPageList: [5, 10, 20, 50, 100],
      pageInfoText: '',
      totalVisiblePages: 5,
      itemsPerPage: 10,
      totalProducts: null,

      goToPageInput: 1,
      sensorStatusList: [
        {
          text: 'Online',
          val: 'Online'
        },
        {
          text: 'Offline',
          val: 'Offline'
        }
      ],
      durationList: [
        {
          text: 'Last 1 Hour',
          val: 1
        },
        {
          text: 'Last 2 Hours',
          val: 2
        },
        {
          text: 'Last 6 Hours',
          val: 6
        },
        {
          text: 'Last 12 Hours',
          val: 12
        },
        {
          text: 'Last 1 Day',
          val: 24
        },
        {
          text: 'Last 2 Days',
          val: 48
        },
        {
          text: 'Last 1 Week',
          val: 168
        }
      ],
      selectedDuration: 1,
      startDate: null,
      endDate: null,
      searchBy: {
        sensorName: null,
        sensorId: null,
        sensorStatus: null,
        location: null,
        sort: null
      },
      options: {
        sortBy: [],
        sortDesc: []
      },
      tableItems: [],
      SelectedsensorValue: [],
      btnAction: false
    }
  },
  computed: {
    // isSensorEnable () {
    //   if (
    //     Object.keys(this.$store.getters['dataStore/GET_FUNCTIONS']).length === 0
    //   ) {
    //     return false
    //   }
    //   return this.$store.getters['dataStore/GET_FUNCTIONS'].sensors
    // },
    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),
    selectedStore () {
      return this.$store.getters['dataStore/GET_SELECTED_STORE'].code
    },
    tableHeaders () {
      return [
        {
          text: this.$t('#'),
          align: 'center',
          sortable: false,
          value: 'serialNo'
        },
        {
          text: this.$t('SENSOR NAME'),
          align: 'center',
          sortable: false,
          value: 'name'
        },
        {
          text: this.$t('SENSOR ID'),
          align: 'center',
          sortable: false,
          value: 'sensorCode'
        },
        {
          text: this.$t('STATUS'),
          align: 'center',
          sortable: false,
          value: 'status'
        },
        {
          text: this.$t('LOCATION'),
          align: 'center',
          sortable: false,
          value: 'assignedTo'
        },
        {
          text: this.$t('BATTERY'),
          align: 'center',
          sortable: false,
          value: 'battery'
        },
        {
          text: this.$t('SIGNAL STRENGTH'),
          align: 'center',
          sortable: false,
          value: 'signalStrength'
        },
        {
          text: this.$t('FW. VER'),
          align: 'center',
          sortable: false,
          value: 'fwVersion'
        },
        {
          text: this.$t('GATEWAY'),
          align: 'center',
          sortable: false,
          value: 'gatewayIpAddress'
        },
        {
          text: this.$t('LAST RESPONSE TIME'),
          align: 'center',
          sortable: true,
          value: 'statusUpdateTime'
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'action'
        }
      ]
    }
  },
  watch: {
    options: {
      handler (newVal, oldVal) {
        if (oldVal !== undefined) {
          if (newVal.sortDesc !== oldVal.sortDesc) {
            let sortParam = ''
            if (newVal.sortBy.length && newVal.sortDesc[0]) {
              sortParam += newVal.sortBy[0] + ',DESC'
            } else if (newVal.sortBy.length && !newVal.sortDesc[0]) {
              sortParam += newVal.sortBy[0] + ',ASC'
            }
            this.searchBy.sort = sortParam
            this.getSensorList(1)
          }
        }
      },
      deep: true,
      immediate: true
    },
    page: {
      handler (page) {
        this.goToPageInput = page
      }
    },
    store: {
      handler () {
        this.getSensorList(1)
        this.getDeviceStatus()
        if (this.alertActive === 'History') {
          this.getAlertHistory()
        } else {
          this.getActiveAlert()
        }
      }
    }

    // isSensorEnable (val) {
    //   if (!val) {
    //     this.redirectEnable = true
    //   }
    // }
  },
  mounted () {
    this.$store.dispatch('auth/getDisabledBtn', '13101').then(flag => {
      this.btnAction = flag
    })
    this.toGetSensorScaleValue()
    // if (localStorage.getItem('tempIndicator') !== null) {
    //   this.toggleState = JSON.parse(localStorage.getItem('tempIndicator'))
    // } else {
    //   this.toggleState = true
    //   localStorage.setItem('tempIndicator', 'true')
    // }
    EventBus.$emit('enableSelectedStores', true)
    if (
      this.store === undefined ||
      !this.store.code ||
      this.store.code === ''
    ) {
      EventBus.$emit('openSelectedStores')
    } else {
      const currentDate = new Date()
      // this.startDate = moment(
      //   currentDate.setHours(currentDate.getHours() - 1)
      // ).utc().format('YYYY-MM-DDTHH:mm:ss')
      // this.endDate = moment(new Date()).utc().format('YYYY-MM-DDTHH:mm:ss')
      this.startDate = moment(
        currentDate.setHours(currentDate.getHours() - 1)).format('YYYY-MM-DDTHH:mm:ss')
      this.endDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
      this.getSensorList(1)
      this.getDeviceStatus()
      this.getActiveAlert()
    }
  },
  created () {
    if (localStorage.getItem('tempIndicator') !== null) {
      this.toggleState = JSON.parse(localStorage.getItem('tempIndicator'))
    } else {
      this.toggleState = true
      localStorage.setItem('tempIndicator', 'true')
    }
    EventBus.$on('updateSensorListEvent', () => {
      this.getSensorList(1)
    })
  },
  methods: {
    updateDuration (e) {
      let start = null
      let end = null
      if (e === 1) {
        start = new Date().setHours(new Date().getHours() - 1)
        end = new Date()
      } else if (e === 2) {
        start = new Date().setHours(new Date().getHours() - 2)
        end = new Date()
      } else if (e === 6) {
        start = new Date().setHours(new Date().getHours() - 6)
        end = new Date()
      } else if (e === 12) {
        start = new Date().setHours(new Date().getHours() - 12)
        end = new Date()
      } else if (e === 24) {
        start = new Date().setHours(new Date().getHours() - 24)
        end = new Date()
      } else if (e === 48) {
        start = new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000)
        end = new Date()
      } else if (e === 168) {
        start = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
        end = new Date()
      }
      // this.startDate = moment(start).utc().format('YYYY-MM-DDTHH:mm:ss')
      // this.endDate = moment(end).utc().format('YYYY-MM-DDTHH:mm:ss')
      this.startDate = moment(start).format('YYYY-MM-DDTHH:mm:ss')
      this.endDate = moment(end).format('YYYY-MM-DDTHH:mm:ss')
      this.getAlertHistory()
    },
    goToOverviewPage () {
      this.$emit('closeMainStoreModal', null, false)
      this.messageDialog = false
      EventBus.$emit('returnToOverviewPage')
    },
    updatePage (event) {
      this.rowPerPage = event.toString()
      this.paging(1)
    },
    buildSensorListParams () {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      const params = {
        company: this.$store.state.auth.user.company,
        store: this.selectedStore,
        name: this.searchBy.sensorName,
        id: this.searchBy.sensorId,
        status: this.searchBy.sensorStatus,
        location: this.searchBy.location,
        sort: this.searchBy.sort
      }

      if (sortBy && sortBy.length === 1 && sortDesc && sortDesc.length === 1) {
        const sortKey = sortBy[0]
        const sortOrder = sortDesc[0] ? 'desc' : 'asc'
        params.sort = `${sortKey},${sortOrder}`
      }

      if (itemsPerPage > 0) {
        params.size = Number(this.rowPerPage)
      }

      return params
    },

    getSensorList (page) {
      this.tableItems = []
      const params = this.buildSensorListParams()
      if (page !== null) params.page = page - 1
      const config = { params: params }
      this.$utils
        .callAxios(
          codes.requests.togetSensorList.method,
          codes.requests.togetSensorList.url,
          config
        )
        .then(res => {
          const headers = res.headers
          if (res.status === 200) {
            this.tableItems = res.data.sensorsList.map((obj, idx) => {
              return {
                serialNo: headers['x-number'] * 1 * headers['x-size'] + idx + 1,
                // Spread the object properties and handle null values
                ...Object.keys(obj).reduce((acc, key) => {
                  acc[key] = obj[key] == null ? '-' : obj[key]
                  return acc
                }, {}),
                statusUpdateTime: commons.convertDate(obj.statusUpdateTime)
                // status: res.data.sensorsList
              }
            })
          } else {
            this.tableItems = []
          }
          this.pageInfoText = commons.getPageInfoText(res.headers)
          this.totalPages = Number(res.headers['x-totalpages'])
          this.pageIndex = res.headers['x-number'] * 1 + 1
          this.pageCount = res.headers['x-totalpages'] * 1
        }).catch(error => {
          this.tableItems = []
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.responseMessage
          ) {
            EventBus.$emit(
              'messageAlert',
              this.$t(error.response.data.responseMessage),
              'error'
            )
          } else {
            EventBus.$emit('messageAlert', this.$t(error), 'error')
          }
          console.log(this.tableItems)
        })
    },

    getDeviceStatus () {
      this.deviceLoading = true
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore
        }
      }
      this.$utils
        .callAxios(
          codes.requests.togetSensorListSummary.method,
          codes.requests.togetSensorListSummary.url,
          config
        )
        .then(res => {
          this.deviceLoading = false
          if (res.status === 200) {
            this.connectivity = [
              res.data.summary.connected,
              res.data.summary.disconnected
            ]
            this.battery = [
              res.data.summary.goodBattery,
              res.data.summary.badBattery
            ]
          } else {
            this.connectivity = []
            this.battery = []
          }
        })
        .catch(error => {
          this.deviceLoading = false
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    },

    getActiveAlert () {
      this.alertLoading = true
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore
        }
      }
      this.$utils
        .callAxios(
          codes.requests.getactiveAlertHistory.method,
          codes.requests.getactiveAlertHistory.url,
          config
        )
        .then(res => {
          this.alertLoading = false
          if (res.status === 200) {
            this.activeAlert = [
              res.data.alertResponse.highTemperature,
              res.data.alertResponse.lowTemperature,
              res.data.alertResponse.highHmdt,
              res.data.alertResponse.lowHmdt
            ]
          } else {
            this.activeAlert = []
          }
        })
        .catch(error => {
          this.alertLoading = false
          this.activeAlert = []
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    },
    getAlertHistory () {
      console.log(this.endDate)
      this.alertLoading = true
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore,
          startTime: this.startDate,
          endTime: this.endDate,
          hours: this.selectedDuration
        }
      }
      this.stackChartData[0].data = []
      this.stackChartData[1].data = []
      this.stackChartData[2].data = []
      this.stackChartData[3].data = []
      this.categories = []
      this.$utils
        .callAxios(
          codes.requests.getactiveAlertHistorySummary.method,
          codes.requests.getactiveAlertHistorySummary.url,
          config
        )
        .then(res => {
          this.alertLoading = false
          if (res.status === 200) {
            this.stackChartData[0].data = res.data.alertHistorySummary.map(
              obj => obj.highTemperature
            )
            this.stackChartData[1].data = res.data.alertHistorySummary.map(
              obj => obj.lowTemperature
            )
            this.stackChartData[2].data = res.data.alertHistorySummary.map(
              obj => obj.highHmdt
            )
            this.stackChartData[3].data = res.data.alertHistorySummary.map(
              obj => obj.lowHmdt
            )
            if (this.selectedDuration === 1 || this.selectedDuration === 2 || this.selectedDuration === 6 || this.selectedDuration === 12) {
              this.categories = res.data.alertHistorySummary.map(obj => moment(commons.convertDate(obj.duration)).format('HH:mm'))
            } else if (this.selectedDuration === 168) {
              this.categories = res.data.alertHistorySummary.map(obj => moment(commons.convertDate(obj.duration)).format('DD-MM-YYYY'))
            } else {
              this.categories = res.data.alertHistorySummary.map(obj => moment(commons.convertDate(obj.duration)).format('DD-MM-YYYY HH:mm'))
            }
          }
        })
        .catch(error => {
          this.alertLoading = false
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    },
    handleAlertBox (active) {
      if (active === this.alertActive) return
      this.alertActive = active
      if (active === 'History') {
        this.getAlertHistory()
      } else {
        this.getActiveAlert()
      }
    },
    // valueChanged (e) {
    //   localStorage.setItem('tempIndicator', e)
    //   this.toggleState = e
    //   this.toPutSensorScaleValue(e)
    // },
    goToPage (goToPageInput, paging, totalPages) {
      if (totalPages < goToPageInput) {
      } else {
        paging(Number(goToPageInput))
      }
    },
    openSensorDetails (data) {
      // if (this.btnAction) {
      this.sensorDetails = data
      this.sensorDetailsDialog = true
      // }
    },
    closeSensorDetailsDialog () {
      this.sensorDetails = {}
      this.sensorDetailsDialog = false
    },
    openAlertDialog (data, chart) {
      if (chart === 'donut') {
        this.alertDetails = {
          name: this.activeAlertLabels[data],
          value: this.activeAlert[data],
          type: 'active'
        }
      } else {
        this.alertDetails = {
          name: this.stackChartData[data].name,
          value: this.stackChartData[data].data.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          ),
          type: 'history',
          startTime: this.startDate,
          endTime: this.endDate
        }
      }

      this.alertDetailsDialog = true
    },
    closeAlertDetailsDialog () {
      this.alertDetails = {}
      this.alertDetailsDialog = false
    },
    clickSearch () {
      this.paging(1)
    },
    clearSearch () {
      this.searchBy = {
        sensorName: null,
        sensorId: null,
        sensorStatus: null,
        location: null,
        sort: null
      }
      this.paging(1)
    },
    paging (page) {
      this.page = Number(page)
      this.goToPageInput = page
      this.options.itemsPerPage = Number(this.rowPerPage)
      this.getSensorList(page)
    },
    editDialogOpen (data) {
      this.editData = data
      this.editSensorDialog = true
    },
    closeEditSensorDialog () {
      this.editData = {}
      this.editSensorDialog = false
    },
    deleteItem (data) {
      this.deleteConfirmation = true
      this.deleteData = data
    },
    deleteAction (action) {
      if (action === 'Yes') {
        this.deleteConfirmation = false
        this.deleteSensor()
      } else {
        this.deleteConfirmation = false
      }
      this.deleteData = {}
    },
    unassignSensor () {
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore,
          sensorCode: this.SelectedsensorValue[0].sensorCode
        }
      }
      this.$utils
        .callAxios(
          codes.requests.toUnlinkSensorList.method,
          codes.requests.toUnlinkSensorList.url,
          config
        )
        .then(res => {
          if (res.status === 200) {
            EventBus.$emit('messageAlert', this.$t(res.data.returnMsg))
            this.paging(1)
            this.SelectedsensorValue = []
          } else {
            EventBus.$emit(
              'messageAlert',
              this.$t('Something went wrong. Please try after some time.')
            )
            this.SelectedsensorValue = []
          }
        })
        .catch(error => {
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
          this.SelectedsensorValue = []
        })
    },
    deleteSensor () {
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore,
          sensorCode: this.deleteData.sensorCode
        }
      }
      this.$utils
        .callAxios(
          codes.requests.toDeleteSensorList.method,
          codes.requests.toDeleteSensorList.url,
          config
        )
        .then(res => {
          if (res.status === 200) {
            EventBus.$emit('messageAlert', this.$t(res.data.returnMsg))
            this.paging(1)
          } else {
            EventBus.$emit(
              'messageAlert',
              this.$t('Something went wrong. Please try after some time.')
            )
          }
        })
        .catch(error => {
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    },
    addSensor () {
      this.addSensorDialog = true
    },
    toGetSensorScaleValue () {
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore
        }
      }
      this.$utils
        .callAxios(
          codes.requests.toGetSensorScale.method,
          codes.requests.toGetSensorScale.url,
          config
        )
        .then(res => {
          if (res.status === 200) {
            if (res.data.scale === 'F') {
              this.toggleState = false
              localStorage.setItem('tempIndicator', this.toggleState)
            } else {
              this.toggleState = true
              localStorage.setItem('tempIndicator', this.toggleState)
            }
          }
        })
        .catch(error => {
          this.toggleState = true
          localStorage.setItem('tempIndicator', this.toggleState)
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    }

  }
}
</script>

<style scoped>
.circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
.summary-card {
  min-width: 243px;
  width: 100%;
  border-radius: 5px;
  height: 400px;
  padding: 0 !important;
  background: #fff !important;
  border: 1px solid #f5f5f5;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
.summary-card dl {
  width: 100%;
}
.w-100 {
  width: 100% !important;
}
.summary-card dt {
  display: flex;
  align-items: center;
  height: 40px;
  letter-spacing: 0;
  text-transform: none;
  text-indent: 0;
  text-align: left;
  padding-left: 10px;
  border-bottom: 2px solid #cbcbcb;
  line-height: 1.2;
  font-size: var(--font16);
  color: #001e38;
  font-weight: 400;
}
.summary-card dd {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  height: 85px;
  padding: 0 10px;
  text-align: right;
  font-size: var(--font18);
  font-weight: 300;
  color: #4d4f5c;
  letter-spacing: -0.05em;
}
.summary-card dd p {
  line-height: 1;
}
.summary-card dd p + p {
  margin-top: 5px;
}
.summary-card dd strong {
  font-size: var(--font40);
  font-weight: 700;
}
.page-title-bar.custom {
  color: #fff;
  background-color: #001e38;
  padding: 8px;
  margin-top: 15px;
  border-radius: 5px;
}
.title-head {
  color: #a2acbd;
  padding-left: 18px;
  padding-top: 7px;
  font-size: 18px;
  font-weight: normal;
}
.title-head.active {
  color: #001e38;
}
.title-value {
  color: #4d4f5c;
  font-size: 28px;
  font-weight: bold;
}
.name {
  color: #4d4f5c;
  font-size: 12px;
}
.square-icon {
  height: 10px;
  width: 10px;
  border-radius: 2px;
}
.notification-title {
  color: #001e38;
  font-size: 18px;
  font-weight: normal;
}
.heading {
  width: 100%;
  height: 37px;
}
.scroll-box {
  height: calc(100% - 37px);
  width: 100%;
}
.notification-box {
  box-shadow: 0px 0px 20px 0px #00000029;
  height: 77px;
  background-color: #fff;
  border-radius: 5px;
  margin: 10px;
}
.summary-card.custom {
  box-shadow: none !important;
  border: 0px !important;
}
.ps {
  height: 217px;
  overflow: auto;
}
.icon {
  width: 50px !important;
  height: 100%;
}
.title-area {
  color: #4d4f5c;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.info-area {
  color: #4d4f5c;
  font-size: 13px;
  display: flex;
}
.loader {
  border: 5px solid #eee;
  border-radius: 50%;
  border-top: 5px solid #ccc;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.wrapper-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex-direction: column;
}
</style>
