<template>
    <div>
      <h2 class="page-title-bar">
        <img
          src="@/assets/img/bell-ring.png"
          style="margin-right: 15px; height: 31px;"
        />{{ $t("Alert Settings") }}
      </h2>
      <div class="row align-items-center">
  <!-- Search Section -->
  <div class="col-8 d-flex">
    <div class="searchArea w-100">
      <div class="row">
        <v-text-field
          v-model="search.email"
          @keyup.enter="getAlarmSubscriptionList(1)"
          :label="$t('Email ID')"
          :placeholder="$t('Input Email ID')"
          class="form-input col"
          outlined
          dense
          hide-details
          clearable
        ></v-text-field>
        <v-text-field
          v-model="search.userName"
          @keyup.enter="getAlarmSubscriptionList(1)"
          :label="$t('User Name')"
          :placeholder="$t('Input UserName')"
          class="form-input ml-3 col"
          outlined
          dense
          hide-details
          clearable
        ></v-text-field>
        <div class="mt-6">
          <v-btn @click="paging(1)" class="btn type-search ml-4" text>
            {{ $t("Search") }}
          </v-btn>
          <v-btn @click="clearSearch" class="btn ml-3 type-search" text>
            {{ $t("Clear") }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</div>
  <!-- Temperature Unit Section -->
  <div class="d-flex justify-content-end align-items-center" style="margin-top: -50px;float: right;margin-right: 8px;">
    <div class="d-flex align-items-center">
      <span class="mr-2"><b>{{$t('Temperature Unit')}}</b></span>
      <Toggle
        :toggleState="toggleState"
        @valueChanged="valueChanged($event)"
        class="ml-2"
      />
    </div>
  </div>

      <div class="mt-10">
        <div style="width: 100%; display: flex;">
          <div style="width:100%;">
            <div class="sub-header">
              {{$t('Alert Subscription List')}}
            </div>
            <v-data-table
              :headers="tableHeaders"
              :items="tableItems"
              item-key="serialNo"
              :hide-default-footer="true"
              :options.sync="options"
              :items-per-page="rowPerPage"
              :server-items-length="tableItems.length"
              :show-select="false"
              @click:row="addNotiPopup($event,'EDIT')"
              class="tbl-type01 mt-5 row-class"
              fixed-header
              must-sort
            >
              <template slot="no-data">
                <v-alert
                  dense
                  color="#697A8A"
                  class="ma-0"
                  style="line-height: 19px"
                >
                  <v-icon class="mr-1" color="#fff">mdi-alert-outline</v-icon>
                  {{ $t("No data available") }}
                </v-alert>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                {{ item.email ? item.email : '-' }}
              </template>
              <template v-slot:[`item.userName`]="{ item }">
                {{ item.userName ? item.userName : '-' }}
              </template>
              <template v-slot:[`item.emailNoti`]="{ item }">
                {{ item.emailNoti ? 'TRUE' : 'FALSE' }}
                <!-- <v-switch
													v-model="item.emailNoti"
													class="form-switch"
                          style="display: inline-block;margin-top: 0px !important;"
													inset
                          readonly
													hide-details
													solo
												></v-switch> -->
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <div>
                  <p
                    @click.stop="deleteItem(item)"
                    class="d-inline-block pointer pr-3"
                  >
                    <v-icon
                      size="24"
                      class="red--text darken-2 pointer"
                      style="color: #001e38;"
                      >mdi-trash-can-outline
                    </v-icon>
                  </p>
                </div>
              </template>
            </v-data-table>
          </div>
        </div>
        <div class="table-options">
          <div>
            <!-- <v-btn text icon @click="SaveEditChanges()" class="btn">{{
              $t("Save")
            }}</v-btn> -->
             <v-btn text icon :disabled="btnActionAlertSettings" @click="addNotiPopup($event,'ADD')" class="btn">{{
              $t("Add")
            }}</v-btn>
          </div>
          <div>
            <div class="pageInfo">{{ pageInfoText }}</div>
            <div class="pagination2">
              <label class="pageInfo mr-1" style="position:relative !important;">
                {{$t('Show')}}
              </label>
              <v-select
                v-model="rowPerPage"
                :items="rowPerPageList"
                :placeholder="$t('Limit')"
                class="form-input pt-0 col rowPerPage"
                dense
                persistent-hint
                outlined
                hide-details
                @change="paging(1)"
              >
                <template v-slot:item="{ item }">
                  {{ `${item}` }}{{$t('Rows')}}
                </template>
                <template v-slot:selection="{ item }">
                  <span class="selection-v-select"> {{ `${item}` }}{{$t('Rows')}}</span>
                </template>
              </v-select>

              <div class="">
                <v-text-field
                  v-model="goToPageInput"
                  class="form-input pt-0 col goToPage ml-1"
                  placeholder="Page"
                  outlined
                  dense
                  hide-details
                  solo
                  @keyup.enter="goToPage(goToPageInput, paging, totalPages)"
                  append-icon="mdi-chevron-right"
                  @keypress="checkIsNumber($event)"
                  @click:append="goToPage(goToPageInput, paging, totalPages)"
                ></v-text-field>
              </div>
              <button
                @click="goTenPageBackwards(page, paging)"
                :class="page < 10 ? 'pagination-nav-disabled' : null"
                class="pagination-nav pagination-nav-prev"
                type="button"
              >
                <img
                  src="@/assets//img/ico-paging-prev.png"
                  alt="이전 10페이지 이동"
                />
              </button>
              <v-pagination
                v-model="pageIndex"
                @input="paging"
                :length="totalPages * 1 || 1"
                :total-visible="8"
                color="#2f3b4c"
                style="display: block"
              ></v-pagination>
              <button
                @click="goTenPageForward(pageIndex, paging, totalPages)"
                :class="
                  pageIndex + 10 > totalPages ? 'pagination-nav-disabled' : null
                "
                class="pagination-nav pagination-nav-next"
                type="button"
              >
                <img
                  src="@/assets//img/ico-paging-next.png"
                  alt="다음 10페이지 이동"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <DeleteConfirmation
        v-if="deleteConfirmation"
        :dialogConfirm="deleteConfirmation"
        @confirmAction="deleteAction($event)"
      />
 <!--Add Noti Dialog -->
 <v-dialog
      v-model="addNotiPopupModel"
      width="700"
    >

      <v-card class="popup add_store_popup">
        <h3 v-if="notiEdit===false" class="page-title-bar"><i class="ico ico-template"></i>{{$t('Add Email Notifications')}}</h3>
        <h3 v-if="notiEdit===true" class="page-title-bar"><i class="ico ico-template"></i>{{$t('Update Email Notifications')}}</h3>

        <v-row>
          <v-col>
            <v-text-field outlined dense hide-details
                  :label="$t('User Name')"
                  :placeholder="`${$t('Enter UserName')}`"
                  v-model="username"
                  class="form-input"
                  clearable
                >
                </v-text-field>
          </v-col>
          <v-col>
            <v-text-field outlined dense hide-details
                  :label="$t('Email ID')"
                  :rules="[rules.email]"
                  :placeholder="`${$t('Enter Email ID')}`"
                  v-model="emailID"
                  class="form-input"
                  clearable
                >
                </v-text-field>
          </v-col>
          <v-col>
            <v-select
              v-model="EmailNofi"
              :label="$t('Email Notification')"
              :placeholder="`${$t('Select')}`"
              :items="[{ text: 'TRUE', value: true }, { text: 'FALSE', value: false }]"
              item-text="text"
              item-value="value"
              class="form-select"
              clearable
              outlined dense hide-details
            ></v-select>
          </v-col>
        </v-row>
        <v-card-actions class="d-flex justify-center">
          <v-btn v-if="notiEdit===false" :disabled="username ===null || emailID ===null " @click="toSaveNewNotification()" text icon class="btn" >{{ $t('Save') }}</v-btn>
          <v-btn v-if="notiEdit===true" :disabled="username ===null || emailID ===null " @click="toSaveEditNotification()" text icon class="btn" >{{ $t('Update') }}</v-btn>
          <v-btn text icon @click="addNotiPopupModel = false" class="btn">{{ $t('Cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </div>
  </template>

<script>
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import { mapGetters } from 'vuex'
import Pagenation from '@/mixins/Pagenation'
import DeleteConfirmation from '@/components/modal/DeleteConfirmtion.vue'
import moment from 'moment-timezone'
import Toggle from '@/components/Toggle.vue'

export default {
  mixins: [Pagenation],
  components: {
    DeleteConfirmation,
    Toggle
  },
  data () {
    return {
      deleteConfirmation: false,
      rowPerPage: 10,
      page: 1,
      totalPages: 0,
      pageIndex: 1,
      pageInfoText: '1 to 1, 1 in total',
      goToPageInput: 1,
      options: {
        sortBy: ['lastModified'],
        sortDesc: [true],
        itemsPerPage: 10
      },
      search: {
        email: null,
        userName: null
      },
      tableItems: [],
      isMounted: false,
      addNotiPopupModel: false,
      EmailNofi: false,
      username: null,
      emailID: null,
      rules: {
        email: value => {
          if (commons.isNull(value)) return true
          const pattern = /^$|^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
          return (
            pattern.test(value) || 'Not a valid Email'
          )
        }
      },
      notiEdit: false,
      notiId: null,
      toggleState: true,
      btnActionAlertSettings: false
    }
  },
  computed: {
    rowPerPageList () {
      return [5, 10, 20, 50, 100]
    },
    ...mapGetters({
      store: 'dataStore/GET_SELECTED_STORE'
    }),
    tableHeaders () {
      return [
        {
          text: this.$t('#'),
          align: 'center',
          sortable: false,
          value: 'serialNo'
        },
        {
          text: this.$t('USER NAME'),
          align: 'center',
          sortable: false,
          value: 'userName'
        },
        {
          text: this.$t('EMAIL ID'),
          align: 'center',
          sortable: false,
          value: 'email'
        },

        {
          text: this.$t('LAST UPDATED'),
          align: 'center',
          sortable: true,
          value: 'lastModified'
        },
        {
          text: this.$t('EMAIL NOTIFICATION'),
          align: 'center',
          sortable: false,
          value: 'emailNoti'
        },

        {
          text: this.$t('ACTION'),
          align: 'center',
          sortable: false,
          value: 'action'
        }
      ]
    }

  },
  watch: {
    options: {
      handler () {
        if (!this.isMounted) return
        this.getAlertSettingDetails(this.page)
      },
      deep: true
    },
    pageIndex: {
      handler (page) {
        this.goToPageInput = page
      }
    }
  },
  created () {
    if (localStorage.getItem('tempIndicator') !== null) {
      this.toggleState = JSON.parse(localStorage.getItem('tempIndicator'))
    } else {
      this.toggleState = true
      localStorage.setItem('tempIndicator', 'true')
    }
  },
  mounted () {
    this.$store.dispatch('auth/getDisabledBtn', '13104').then(flag => {
      this.btnActionAlertSettings = flag
    })
    EventBus.$emit('enableSelectedStores', false)
    this.getAlertSettingDetails(1)
  },
  methods: {
    goToPage (goToPageInput, paging, totalPages) {
      if (totalPages < goToPageInput) {
      } else {
        paging(Number(goToPageInput))
      }
    },
    clearSearch () {
      this.search = {
        email: null,
        userName: null
      }
      this.paging(1)
    },
    paging (page) {
      this.page = Number(page)
      this.goToPageInput = page
      this.pageIndex = this.page
      this.isMounted = false
      this.getAlertSettingDetails(this.page)
    },
    closeSensorDetailsDialog () {
      this.sensorDetails = {}
      this.sensorDetailsDialog = false
    },
    getPageInfoText (headers, tabelItems) {
      return (
        headers['x-number'] * 1 * headers['x-size'] +
          1 +
          ' to ' +
          (headers['x-number'] * 1 * headers['x-size'] + tabelItems.length) +
          ', ' +
          headers['x-totalelements'] * 1 +
          ' in total'
      )
    },
    valueChanged (e) {
      localStorage.setItem('tempIndicator', e)
      this.toggleState = e
      this.toPutSensorScaleValue(e)
    },
    toPutSensorScaleValue (data) {
      var scaleValue = ''
      if (data === false) {
        scaleValue = 'F'
      } else {
        scaleValue = 'C'
      }
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore,
          scale: scaleValue
        }
      }
      const body = {}
      this.$utils
        .callAxiosWithBody(
          codes.requests.toPutSensorScale.method,
          codes.requests.toPutSensorScale.url,
          body,
          config
        )
        .then(res => {
          if (res.status === 200) {
            this.toGetSensorScaleValue()
          }
        })
        .catch(error => {
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    },
    toGetSensorScaleValue () {
      const config = {
        params: {
          company: this.$store.state.auth.user.company,
          store: this.selectedStore
        }
      }
      this.$utils
        .callAxios(
          codes.requests.toGetSensorScale.method,
          codes.requests.toGetSensorScale.url,
          config
        )
        .then(res => {
          if (res.status === 200) {
            if (res.data.scale === 'F') {
              this.toggleState = false
              localStorage.setItem('tempIndicator', this.toggleState)
            } else {
              this.toggleState = true
              localStorage.setItem('tempIndicator', this.toggleState)
            }
          }
        })
        .catch(error => {
          this.toggleState = true
          localStorage.setItem('tempIndicator', this.toggleState)
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.responseMessage),
            'error'
          )
        })
    },
    handleGetPrResponse (res) {
      const headers = res.headers
      this.tableItems = res.data.sensorNotiList.map((obj, idx) => {
        return {
          serialNo: headers['x-number'] * 1 * headers['x-size'] + idx + 1,
          id: obj.id,
          email: obj.email,
          userName: obj.userName,
          emailNoti: obj.emailNoti,
          lastModified: moment(obj.lastUpdated).format('YYYY-MM-DD HH:mm:ss')
        }
      })
      this.pageInfoText = commons.getPageInfoText(res.headers)
      if (commons.isValidStr(res.headers['x-totalpages'])) {
        this.totalPages = res.headers['x-totalpages'] * 1
      }
      this.selected = []
    },
    handleGetPrError (error) {
      this.tableItems = []
      const headers = {}
      headers['x-totalelements'] = 0
      this.pageInfoText = commons.getPageInfoText(headers)
      this.totalPages = 0
      this.selected = []
      EventBus.$emit(
        'messageAlert',
        this.$t(error.response.data.responseMessage),
        'error'
      )
      console.debug(`Could not find any Alert List. ${error}`)
    },
    buildSearchParams (params, search) {
      if (search.email !== null) {
        params.email = search.email
      }
      if (!commons.isNull(search.userName)) params.userName = search.userName
      return params
    },
    getSortKey (sortKey) {
      switch (sortKey) {
        case 'lastModified':
          sortKey = 'lastModified'
          break
        default:
          break
      }
      return sortKey
    },
    buildParams () {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      this.options.itemsPerPage = Number(this.rowPerPage)
      let params = { store: this.store.code }
      let sort = null
      if (commons.isSorting(sortBy, sortDesc)) {
        let sortKey = this.getSortKey(sortBy[0])
        const sortOrder = sortDesc[0]
        if (sortKey === 'lastModified') sortKey = 'lastModified'
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      if (itemsPerPage > 0) params.size = this.rowPerPage
      params = this.buildSearchParams(params, this.search)
      params.company = this.$store.state.auth.user.company
      params.store = this.store.code
      return params
    },
    getAlertSettingDetails (page) {
      const params = this.buildParams()
      if (page !== null) params.page = page - 1
      const config = { params }
      this.$utils
        .callAxios(
          codes.requests.toGetSensorNotification.method,
          codes.requests.toGetSensorNotification.url,
          config
        )
        .then(res => {
          this.isMounted = true
          if (res.data && res.status === 200) {
            this.handleGetPrResponse(res)
          } else {
            this.tableItems = []
            this.totalPages = 0
            this.selected = []
            this.pageInfoText = ''
          }
        })
        .catch(error => {
          this.isMounted = true
          this.handleGetPrError(error)
          console.log(error)
        })
    },
    addNotiPopup (event, type) {
      // if (this.btnActionAlertSettings) {
      console.log(event)
      console.log(type)
      this.notiEdit = false
      this.notiId = null
      this.addNotiPopupModel = true
      if (type === 'EDIT') {
        this.notiEdit = true
        this.notiId = event.id
        console.log(event.emailNoti.toString().toUpperCase())
        this.emailID = event.email
        this.username = event.userName
        this.emailNoti = event.emailNoti
        this.EmailNofi = event.emailNoti
      } else {
        this.emailID = null
        this.username = null
        this.emailNoti = 'FALSE'
        this.EmailNofi = false
        // }
      }
    },
    toSaveNewNotification () {
      console.log(this.username)
      console.log(this.emailID)
      if (this.username === '' || this.emailID === '') {
        EventBus.$emit('messageAlert', this.$t('Enter valid details to proceed'))
        return
      }
      if (this.emailID) {
        if (commons.isNull(this.emailID)) return true

        const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        const isValid = pattern.test(this.emailID)

        if (!isValid) {
          EventBus.$emit('messageAlert', this.$t('Enter valid Email ID to proceed'))
          return
        }
      }

      const body = {
        userName: this.username,
        email: this.emailID,
        emailNoti: this.EmailNofi
      }

      const params = {
        company: this.$store.state.auth.user.company,
        store: this.store.code
      }
      const config = { params }
      this.$utils
        .callAxiosWithBody(
          codes.requests.toaddSensorNotification.method,
          codes.requests.toaddSensorNotification.url,
          body,
          config
        )
        .then(res => {
          if (res.status === 200) {
            EventBus.$emit('messageAlert', this.$t(res.data.returnMsg))
            this.addNotiPopupModel = false
            this.paging(1)
          }
        })
        .catch(error => {
          console.log(error)
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.returnMsg),
            'error'
          )
          this.addNotiPopupModel = false
        })
    },
    toSaveEditNotification () {
      console.log(this.username)
      console.log(this.emailID)
      if (this.username === '' || this.emailID === '') {
        EventBus.$emit('messageAlert', this.$t('Enter valid details to proceed'))
        return
      }
      if (this.emailID) {
        if (commons.isNull(this.emailID)) return true

        const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        const isValid = pattern.test(this.emailID)

        if (!isValid) {
          EventBus.$emit('messageAlert', this.$t('Enter valid Email ID to proceed'))
          return
        }
      }

      const body = {
        userName: this.username,
        email: this.emailID,
        emailNoti: this.EmailNofi
      }

      const params = {
        company: this.$store.state.auth.user.company,
        store: this.store.code,
        id: this.notiId
      }
      const config = { params }
      this.$utils
        .callAxiosWithBody(
          codes.requests.toEditSensorNotification.method,
          codes.requests.toEditSensorNotification.url,
          body,
          config
        )
        .then(res => {
          if (res.status === 200) {
            EventBus.$emit('messageAlert', this.$t(res.data.returnMsg))
            this.addNotiPopupModel = false
            this.paging(1)
          }
        })
        .catch(error => {
          console.log(error)
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.returnMsg),
            'error'
          )
          this.addNotiPopupModel = false
        })
    },
    deleteItem (data) {
      // if (this.btnActionAlertSettings) {
      console.log(data)
      this.deleteConfirmation = true
      this.deleteData = data
      // }
    },
    deleteAction (action) {
      if (action === 'Yes') {
        this.deleteDeviceAlert()
        this.deleteConfirmation = false
      } else {
        this.deleteConfirmation = false
      }
      this.deleteData = {}
    },
    deleteDeviceAlert () {
      const params = {
        store: this.store.code,
        company: this.$store.state.auth.user.company,
        id: this.deleteData.id
      }
      const config = { params }
      this.$utils
        .callAxios(
          codes.requests.toDeleteSensorNotification.method,
          codes.requests.toDeleteSensorNotification.url,
          config
        )
        .then(res => {
          EventBus.$emit('messageAlert', this.$t(res.data.returnMsg))
          this.paging(1)
        })
        .catch(error => {
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.returnMsg),
            'error'
          )
          console.debug(`Failed to delete Device. error: ${error}`)
        })
    }
  }
}
</script>

  <style scoped>
  .circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }
  .sub-header {
    background-color: rgb(0, 30, 56);
    color: rgb(255, 255, 255);
    height: 42px;
    display: flex;
    padding-left: 25px;
    align-items: center;
  }
  ::v-deep .v-input--radio-group__input{
    display: flex !important;
    justify-content: space-between !important;
  }
  ::v-deep .v-input--radio-group__input .v-radio.theme--light{
    margin-right: 0px !important;
  }
  ::v-deep .v-input__slot{
    margin-bottom: 0px !important
  }
  ::v-deep .v-messages.theme--light{
    display: none !important;
  }
  ::v-deep .v-data-table .v-data-table__wrapper table tbody tr,
  ::v-deep .v-data-table .v-data-table__wrapper table thead tr{
    height: 60px !important;
  }
  </style>
