var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "-77px" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "h3",
                {
                  staticClass: "tit-group-bg mt-10",
                  staticStyle: {
                    color: "rgba(0, 0, 0, 0.6)",
                    "line-height": "1"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Report Setting")))]
              ),
              _c("table", { staticClass: "tbl-custom-type01 max-100" }, [
                _c("colgroup", [
                  _c("col", { staticStyle: { width: "25%" } }),
                  _c("col", { staticStyle: { width: "25%" } }),
                  _c("col", { staticStyle: { width: "25%" } }),
                  _c("col")
                ]),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("Range")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("On/Off")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("Cycle")))]),
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("Reported Time"))),
                      _c("span", [
                        _vm._v("(" + _vm._s(_vm.$t("Account Time Zone")) + ")")
                      ])
                    ])
                  ])
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.$t(_vm.range)))]),
                    _c(
                      "td",
                      [
                        _c("v-switch", {
                          staticClass: "form-switch",
                          attrs: { inset: "", "hide-details": "", solo: "" },
                          model: {
                            value: _vm.use,
                            callback: function($$v) {
                              _vm.use = $$v
                            },
                            expression: "use"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("v-select", {
                          ref: "cycle",
                          staticClass: "form-select",
                          attrs: {
                            dense: "",
                            "hide-details": "",
                            solo: "",
                            items: _vm.cycleItems,
                            width: "50",
                            rules: [_vm.rules.required]
                          },
                          on: { keydown: _vm.clearTabindex },
                          model: {
                            value: _vm.cycle,
                            callback: function($$v) {
                              _vm.cycle = $$v
                            },
                            expression: "cycle"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("v-select", {
                          ref: "reportedTime",
                          staticClass: "form-select",
                          attrs: {
                            dense: "",
                            "hide-details": "",
                            solo: "",
                            items: _vm.getReportedTimeItems,
                            width: "50"
                          },
                          on: { keydown: _vm.clearTabindex },
                          scopedSlots: _vm._u([
                            {
                              key: "item",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "font-size": "0.8125rem" }
                                    },
                                    [_vm._v(_vm._s(item) + " ")]
                                  )
                                ]
                              }
                            },
                            {
                              key: "selection",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "LedPatternClass",
                                      staticStyle: {
                                        display: "contents !important"
                                      }
                                    },
                                    [_vm._v(_vm._s(item))]
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.reportedTime,
                            callback: function($$v) {
                              _vm.reportedTime = $$v
                            },
                            expression: "reportedTime"
                          }
                        })
                      ],
                      1
                    ),
                    _c("td", [_vm._v(":")]),
                    _c(
                      "td",
                      [
                        _c("v-select", {
                          ref: "reportMinute",
                          staticClass: "form-select",
                          attrs: {
                            dense: "",
                            "hide-details": "",
                            solo: "",
                            items: _vm.getReportedTimeItemsMint,
                            "item-text": "txt",
                            "item-value": "val"
                          },
                          on: { keydown: _vm.clearTabindex },
                          model: {
                            value: _vm.reportMinute,
                            callback: function($$v) {
                              _vm.reportMinute = $$v
                            },
                            expression: "reportMinute"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "btn-s mt-5",
                  attrs: { disabled: _vm.buttonDisabled, text: "" },
                  on: {
                    click: function($event) {
                      return _vm.setReport()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn-s mt-5 ml-2",
                  attrs: { disabled: _vm.buttonDisabled, text: "" },
                  on: {
                    click: function($event) {
                      return _vm.toSendnow()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Send now")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-15" },
        [
          _c("v-col", { staticClass: "pb-0" }, [
            _c(
              "h3",
              {
                staticClass: "tit-group-bg",
                staticStyle: { color: "rgba(0, 0, 0, 0.6)", "line-height": "1" }
              },
              [_vm._v(_vm._s(_vm.$t("Mail Setting")))]
            ),
            _c(
              "div",
              { staticClass: "halpBox" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("p", { staticClass: "label-txt mt-4" }, [
                          _vm._v(_vm._s(_vm.$t("Email Host")))
                        ]),
                        _c("v-text-field", {
                          ref: "host",
                          staticClass: "form-input",
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            solo: "",
                            placeholder: _vm.$t("Mail Server Host Address"),
                            rules: [_vm.rules.required],
                            clearable: ""
                          },
                          on: { keydown: _vm.clearTabindex },
                          model: {
                            value: _vm.host,
                            callback: function($$v) {
                              _vm.host = $$v
                            },
                            expression: "host"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c("p", { staticClass: "label-txt mt-4" }, [
                          _vm._v(_vm._s(_vm.$t("Email Port")))
                        ]),
                        _c("v-text-field", {
                          ref: "port",
                          staticClass: "form-input",
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            solo: "",
                            type: "number",
                            placeholder: _vm.$t("Mail Server Port Number"),
                            rules: [_vm.rules.required],
                            clearable: ""
                          },
                          on: {
                            keyup: function($event) {
                              $event.preventDefault()
                              return _vm.handleKeyUp($event)
                            },
                            keydown: _vm.clearTabindex
                          },
                          model: {
                            value: _vm.port,
                            callback: function($$v) {
                              _vm.port = $$v
                            },
                            expression: "port"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("p", { staticClass: "label-txt mt-4" }, [
                          _vm._v(_vm._s(_vm.$t("Email Address")))
                        ]),
                        _c("v-text-field", {
                          ref: "userName",
                          staticClass: "form-input",
                          attrs: {
                            rules: [_vm.rules.email],
                            placeholder: _vm.$t("Mail Address"),
                            clearable: "",
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            solo: ""
                          },
                          on: { keydown: _vm.clearTabindex },
                          model: {
                            value: _vm.userName,
                            callback: function($$v) {
                              _vm.userName = $$v
                            },
                            expression: "userName"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c("p", { staticClass: "label-txt mt-4" }, [
                          _vm._v(_vm._s(_vm.$t("Email Password")))
                        ]),
                        _c("v-text-field", {
                          ref: "password",
                          staticClass: "form-input",
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            solo: "",
                            placeholder: _vm.$t("Mail Password"),
                            type: "password",
                            rules: [_vm.rules.required],
                            clearable: ""
                          },
                          on: { keydown: _vm.clearTabindex },
                          model: {
                            value: _vm.password,
                            callback: function($$v) {
                              _vm.password = $$v
                            },
                            expression: "password"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("p", { staticClass: "label-txt mt-4" }, [
                          _vm._v(_vm._s(_vm.$t("Email Encoding")))
                        ]),
                        _c("v-text-field", {
                          ref: "encoding",
                          staticClass: "form-input",
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            solo: "",
                            placeholder: _vm.$t("UTF-8"),
                            rules: [_vm.rules.required],
                            clearable: ""
                          },
                          on: { keydown: _vm.clearTabindex },
                          model: {
                            value: _vm.encoding,
                            callback: function($$v) {
                              _vm.encoding = $$v
                            },
                            expression: "encoding"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        _c("p", { staticClass: "label-txt mt-4" }, [
                          _vm._v(_vm._s(_vm.$t("Email Auth")))
                        ]),
                        _c("v-select", {
                          ref: "auth",
                          staticClass: "form-select",
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            solo: "",
                            placeholder: _vm.$t("Email Auth"),
                            rules: [_vm.rules.required],
                            items: ["SSL", "TLS"]
                          },
                          model: {
                            value: _vm.auth,
                            callback: function($$v) {
                              _vm.auth = $$v
                            },
                            expression: "auth"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("br"),
      _c(
        "v-btn",
        {
          staticClass: "btn-s mt-5",
          attrs: { disabled: _vm.serverDisabled, text: "" },
          on: {
            click: function($event) {
              return _vm.setServer()
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("Save")))]
      ),
      _c(
        "v-btn",
        {
          staticClass: "btn-s mt-5 ml-2",
          attrs: { disabled: _vm.serverDisabled, text: "" },
          on: {
            click: function($event) {
              return _vm.FieldValidation()
            }
          }
        },
        [_vm._v(_vm._s(_vm.$t("Check")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }