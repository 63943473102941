import { render, staticRenderFns } from "./Defaultset.vue?vue&type=template&id=75642b37&"
import script from "./Defaultset.vue?vue&type=script&lang=js&"
export * from "./Defaultset.vue?vue&type=script&lang=js&"
import style0 from "./Defaultset.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VCol,VLabel,VMenu,VRow,VSelect,VSwitch,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/aims-dashboard/aims-dashboard/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('75642b37')) {
      api.createRecord('75642b37', component.options)
    } else {
      api.reload('75642b37', component.options)
    }
    module.hot.accept("./Defaultset.vue?vue&type=template&id=75642b37&", function () {
      api.rerender('75642b37', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/System/Defaultset.vue"
export default component.exports