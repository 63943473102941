<template>
  <div>
    <apexChart
    class="chart"
      :type="chartType"
      :width="width"
      :height="height"
      :options="chartOptions"
      :series="series"
      @dataPointSelection="handleDataPointSelection"
    ></apexChart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  name: 'GradientDonutChart',
  components: {
    apexChart: VueApexCharts
  },
  props: {
    annotation: {
      type: Object,
      default: () => {}
    },
    tooltip: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 255
    },
    height: {
      type: Number,
      default: 255
    },
    chartType: {
      type: String,
      default: ''
    },
    chartLabels: {
      type: Array,
      required: true
    },
    chartPlotOptions: {
      type: Object
    },
    dataLabelsOption: {
      type: Object
    },
    chartLegendOption: {
      type: Object
    },
    colorOption: {
      type: Array,
      required: true
    },
    chartSeries: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedSlice: '',
      series: [],
      chartOptions: {
        chart: {
          type: this.chartType
        },
        legend: this.chartLegendOption,
        dataLabels: this.dataLabelsOption,
        plotOptions: this.chartPlotOptions,
        colors: this.colorOption,
        labels: this.chartLabels
      }
    }
  },
  watch: {
    chartSeries (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.series = this.chartSeries
      }
    }
  },
  methods: {
    // FUNCTION TO GET DATA FOR THE RESPECTIVE SLICE
    handleDataPointSelection (event, chartContext, config) {
      if (config.seriesIndex !== undefined && config.dataPointIndex !== undefined) {
        this.selectedSlice = this.chartOptions.labels[config.dataPointIndex]
        // const selectedValue = this.chartSeries[config.dataPointIndex]
        this.$emit('select-slice', this.selectedSlice)
      }
    }
  },
  created () {
    if (this.annotation !== undefined && Object.keys(this.annotation).length !== 0) {
      this.chartOptions.annotations = this.annotation
    }
    if (this.tooltip !== undefined && Object.keys(this.tooltip).length !== 0) {
      this.chartOptions.tooltip = this.tooltip
    }
    this.series = this.chartSeries
  },
  mounted () {
    console.log(this.chartLegendOption)
    console.log(this.dataLabelsOption)
    console.log(this.series)
  }
}
</script>

<style scoped>
.chart:hover {
  cursor: pointer;
}
::v-deep .apexcharts-tooltip .arrow_box1 {
  color: #626262 !important; /* Tooltip background color */
  border: 1px solid #626262 !important; /* Tooltip border color */
  background-color: #FFFFFF !important; /* Tooltip text color */
  padding: 4px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  border-radius: 5px !important;
}
</style>
