import { render, staticRenderFns } from "./AddSensor.vue?vue&type=template&id=91ee8556&scoped=true&"
import script from "./AddSensor.vue?vue&type=script&lang=js&"
export * from "./AddSensor.vue?vue&type=script&lang=js&"
import style0 from "./AddSensor.vue?vue&type=style&index=0&id=91ee8556&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91ee8556",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VDialog,VIcon,VSelect,VSwitch,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/aims-dashboard/aims-dashboard/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('91ee8556')) {
      api.createRecord('91ee8556', component.options)
    } else {
      api.reload('91ee8556', component.options)
    }
    module.hot.accept("./AddSensor.vue?vue&type=template&id=91ee8556&scoped=true&", function () {
      api.rerender('91ee8556', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Sensors/model/AddSensor.vue"
export default component.exports