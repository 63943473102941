import { render, staticRenderFns } from "./LCD.vue?vue&type=template&id=170e805f&scoped=true&"
import script from "./LCD.vue?vue&type=script&lang=js&"
export * from "./LCD.vue?vue&type=script&lang=js&"
import style0 from "./LCD.vue?vue&type=style&index=0&id=170e805f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "170e805f",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCombobox,VDataTable,VDialog,VIcon,VPagination,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/aims-dashboard/aims-dashboard/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('170e805f')) {
      api.createRecord('170e805f', component.options)
    } else {
      api.reload('170e805f', component.options)
    }
    module.hot.accept("./LCD.vue?vue&type=template&id=170e805f&scoped=true&", function () {
      api.rerender('170e805f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/lcd/views/LCD/LCD.vue"
export default component.exports