<template>
  <v-dialog v-model="addDialogPopup" width="1000" persistent>
    <v-card class="popup add_store_popup">
      <div>
        <v-btn icon dark x-small class="cornerClose" @click="closeModal">
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
        <div class="popup-titbox d-flex align-center justify-space-between">
          <h3 class="page-title-bar align-start">
            <img
              src="@/assets/img/access-point.svg"
              style="margin-right: 15px; height: 31px;"
            />{{ $t("Edit Sensor") }}
          </h3>
          <p><b>Sensor ID :</b> {{ sensorMac }}</p>
        </div>
      </div>
      <div
        class="fixedHeader"
        style="max-height: calc(100vh - 330px) !important;"
      >
      <div style="width: 100%;">
        <div class="row mx-0 mb-10 align-baseline">
          <v-text-field
            outlined
            dense
            type="text"
            clearable
            v-model="name"
            :label="`${$t(`Sensor Name`)} *`"
            :placeholder="`${$t(`Input Sensor Name`)}`"
            class="form-input col  mandatory"
          ></v-text-field>
          <v-select
            v-model.number="sensingPeriod"
            :label="`${$t(`Sensing Period`)} *`"
            :placeholder="$t('Select Sensing Period')"
            :items="selectOptions.period"
            item-text="text"
            item-value="abbr"
            readonly
            class="form-select ml-6 col mandatory"
            outlined
            dense
            hide-details
          ></v-select>
          <v-select
            v-model.number="updatePeriod"
            :label="`${$t(`Update Period`)} *`"
            :placeholder="$t('Select Update Period')"
            :items="selectOptionsUpdate.period"
            item-text="text"
            @change="AddSensingPeriodValue"
            item-value="abbr"
            class="form-select ml-6 col mandatory"
            outlined
            dense
            hide-details
          ></v-select>
        </div>
        <div class="row mx-0 mb-10 align-baseline">
          <v-text-field
            outlined
            dense
            hide-details
            type="text"
            clearable
            v-model="assignedTo"
            :label="`${$t(`Location`)} *`"
            :placeholder="`${$t(`Input Location`)}`"
            class="form-input col mandatory"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            type="number"
            clearable
            v-if="toggleState"
            v-model.number="highTempThreshold"
            :label="`${$t(`High Temperature Alarm (°C)`)} *`"
            :placeholder="`${$t(`Input High Temperature`)}`"
            class="form-input col ml-6 mandatory"
            :rules="highTempRules"
            ref="highTempField"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            type="number"
            clearable
            v-if="!toggleState"
            v-model.number="highTempThreshold"
            :label="`${$t(`High Temperature Alarm (°F)`)} *`"
            :placeholder="`${$t(`Input High Temperature`)}`"
            class="form-input col ml-6 mandatory"
            :rules="highTempRules1"
            ref="highTempField"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            type="number"
            clearable
            v-if="toggleState"
            v-model.number="lowTempThreshold"
            :label="`${$t(`Low Temperature Alarm (°C)`)} *`"
            :placeholder="`${$t(`Input Low Temperature`)}`"
            class="form-input col ml-6 mandatory"
            :rules="lowTempRules"
            ref="lowTempField"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            type="number"
            clearable
            v-if="!toggleState"
            v-model.number="lowTempThreshold"
            :label="`${$t(`Low Temperature Alarm (°F)`)} *`"
            :placeholder="`${$t(`Input Low Temperature`)}`"
            class="form-input col ml-6 mandatory"
            :rules="lowTempRules1"
            ref="lowTempField"
          ></v-text-field>
        </div>
        <div class="row mx-0">
          <div style="margin-top: -20px;">
            <v-switch
              v-model="tempAlarmStatus"
              :label="$t('Temperature Threshold Notification')"
              class="form-switch pl-1"
              inset
              hide-details
            ></v-switch>
            <v-switch
              v-model="hmdtAlarmStatus"
              :label="$t('Humidity Threshold Notification')"
              class="form-switch pl-1"
              inset
              hide-details
            ></v-switch>
          </div>
          <v-text-field
            outlined
            dense
            type="number"
            clearable
            v-model.number="highHmdtThreshold"
            :label="`${$t(`High Humidity Alarm (%)`)} *`"
            :placeholder="`${$t(`Input High Humidity`)}`"
            class="form-input col ml-6 mandatory"
            :rules="highHumdyRules"
            ref="highHumField"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            type="number"
            clearable
            v-model.number="lowHmdtThreshold"
            :label="`${$t(`Low Humidity Alarm (%)`)} *`"
            :placeholder="`${$t(`Input Low Humidity`)}`"
            class="form-input col ml-6 mandatory"
            :rules="lowHumdyRules"
            ref="lowHumField"
          ></v-text-field>
        </div>
      </div>
      </div>
      <v-card-actions class="d-flex justify-center py-8">
        <v-btn
          text
          icon
          @click="clickSave()"
          class="btn"
          :disabled="isDisable"
          >{{ $t("Save") }}</v-btn
        >
        <v-btn text icon @click="closeModal()" class="btn">{{
          $t("Close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import EventBus from '@/plugins/eventBus'
import codes from '@/plugins/codes'
export default {
  name: 'AddSensor',
  props: {
    addDialogPopup: {
      type: Boolean,
      default: false
    },
    editList: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      lowHmdtThreshold: 0,
      highHmdtThreshold: 0,
      assignedTo: '',
      sensorId: '',
      name: '',
      sensingPeriod: 5,
      updatePeriod: 60,
      highTempThreshold: 0,
      lowTempThreshold: 0,
      tempAlarmStatus: true,
      hmdtAlarmStatus: false,
      toggleState: true
    }
  },
  computed: {
    isDisable () {
      if (
        this.lowHmdtThreshold !== null &&
        this.highHmdtThreshold !== null &&
        this.highTempThreshold !== null &&
        this.lowTempThreshold !== null &&
        this.name &&
        this.sensingPeriod &&
        this.updatePeriod &&
        this.assignedTo
      ) { return false }
      return true
    },
    ...mapGetters({ store: 'dataStore/GET_SELECTED_STORE' }),
    selectOptions () {
      return {
        period: [
          { text: this.$t('3 Minutes'), abbr: 3 },
          { text: this.$t('6 Minutes'), abbr: 6 },
          { text: this.$t('9 Minutes'), abbr: 9 },
          { text: this.$t('12 Minutes'), abbr: 12 }
        ]
      }
    },
    selectOptionsUpdate () {
      return {
        period: [
          { text: this.$t('60 Minutes'), abbr: 60 },
          { text: this.$t('120 Minutes'), abbr: 120 },
          { text: this.$t('180 Minutes'), abbr: 180 },
          { text: this.$t('240 Minutes'), abbr: 240 }
        ]
      }
    },
    highTempRules () {
      return [
        v => (v !== null && v !== undefined) || `${this.$t('High Temperature is required')}`,
        v => (v >= -28.9 && v <= 60) || `${this.$t('High Temperature must be between -28.9(°C) and 60(°C)')}`,
        v => v >= this.lowTempThreshold || `${this.$t('High Temperature must be greater than to Low Temperature')}`,
        v => (this.highTempThreshold - this.lowTempThreshold > 1) || `${this.$t('High Temperature must be at least 1 greater than Low Temperature')}`
        // v => (this.highTempThreshold !== this.lowTempThreshold && Math.abs(this.highTempThreshold - this.lowTempThreshold) > 1) || `${this.$t('High Temperature and Low Temperature difference must be greater than 1')}`

      ]
    },
    highTempRules1 () {
      return [
        v => (v !== null && v !== undefined) || `${this.$t('High Temperature is required')}`,
        v => (v >= -20.2 && v <= 140) || `${this.$t('High Temperature must be between -20.2(°F) and 140(°F)')}`,
        v => v >= this.lowTempThreshold || `${this.$t('High Temperature must be greater than to Low Temperature')}`,
        v => (this.highTempThreshold - this.lowTempThreshold > 1.8) || `${this.$t('High Temperature must be at least 1.8 greater than Low Temperature')}`
        // v => (this.highTempThreshold !== this.lowTempThreshold && Math.abs(this.highTempThreshold - this.lowTempThreshold) > 1) || `${this.$t('High Temperature and Low Temperature difference must be greater than 1')}`
      ]
    },
    lowTempRules () {
      return [
        v => (v !== null && v !== undefined) || `${this.$t('Low Temperature is required')}`,
        v => (v >= -30 && v <= 58.9) || `${this.$t('Low Temperature  must be between -30(°C) and 58.9(°C)')}`,
        v => v <= this.highTempThreshold || `${this.$t('Low Temperature must be less than to High Temperature')}`,
        v => (this.highTempThreshold - this.lowTempThreshold > 1) || `${this.$t('Low Temperature must be at least 1 less than High Temperature')}`
        // v => (this.highTempThreshold !== this.lowTempThreshold && Math.abs(this.highTempThreshold - this.lowTempThreshold) > 1) || `${this.$t('High Temperature and Low Temperature difference must be greater than 1')}`
      ]
    },
    lowTempRules1 () {
      return [
        v => (v !== null && v !== undefined) || `${this.$t('Low Temperature is required')}`,
        v => (v >= -22 && v <= 138.2) || `${this.$t('Low Temperature must be between -22(°F) and 138.2(°F)')}`,
        v => v <= this.highTempThreshold || `${this.$t('Low Temperature must be less than to High Temperature')}`,
        v => (this.highTempThreshold - this.lowTempThreshold > 1.8) || `${this.$t('Low Temperature must be at least 1.8 less than High Temperature')}`
        // v => (this.highTempThreshold !== this.lowTempThreshold && Math.abs(this.highTempThreshold - this.lowTempThreshold) > 1) || `${this.$t('High Temperature and Low Temperature difference must be greater than 1')}`
      ]
    },
    highHumdyRules () {
      return [
        v => (v !== null && v !== undefined) || `${this.$t('High Humidity is required')}`,
        v => (v > 15.1 && v <= 90) || `${this.$t('High Humidity must be between 15.1% and 90%')}`,
        v => v >= this.lowHmdtThreshold || `${this.$t('High Humidity must be greater than Low Humidity')}`,
        v => (this.highHmdtThreshold !== this.lowHmdtThreshold && Math.abs(this.highHmdtThreshold - this.lowHmdtThreshold) > 5) || `${this.$t('High and Low Humidity difference must be greater than 5%')}`
      ]
    },
    lowHumdyRules () {
      return [
        v => (v !== null && v !== undefined) || `${this.$t('Low Humidity is required')}`,
        v => (v >= 10 && v <= 84.9) || `${this.$t('Low Humidity must be between 10% and 84.9%')}`,
        v => v <= this.highHmdtThreshold || `${this.$t('Low Humidity must be less than High Humidity')}`,
        v => (this.highHmdtThreshold !== this.lowHmdtThreshold && Math.abs(this.highHmdtThreshold - this.lowHmdtThreshold) > 5) || `${this.$t('High and Low Humidity difference must be greater than 5%')}`
      ]
    }
  },
  watch: {
    lowTempThreshold (val) {
    // Trigger validation for highTempThreshold when lowTempThreshold changes
      this.$refs.highTempField.validate()
    },
    highTempThreshold (val) {
    // Trigger validation for lowTempThreshold when highTempThreshold changes
      this.$refs.lowTempField.validate()
    },
    lowHmdtThreshold (val) {
    // Trigger validation for highTempThreshold when lowTempThreshold changes
      this.$refs.highHumField.validate()
    },
    highHmdtThreshold (val) {
    // Trigger validation for lowTempThreshold when highTempThreshold changes
      this.$refs.lowHumField.validate()
    }

  },
  methods: {
    closeModal () {
      this.$emit('closeEditModal')
    },
    AddSensingPeriodValue () {
      this.sensingPeriod = this.updatePeriod / 20
    },
    clickSave () {
      var highTemp = null
      var lowTemp = null
      if (this.toggleState === false) {
        highTemp = ((this.highTempThreshold - 32) * 5) / 9
        lowTemp = ((this.lowTempThreshold - 32) * 5) / 9
      } else {
        highTemp = this.highTempThreshold
        lowTemp = this.lowTempThreshold
      }
      const body = {
        lowHmdtThreshold: this.lowHmdtThreshold,
        highHmdtThreshold: this.highHmdtThreshold,
        assignedTo: [this.assignedTo],
        sensorCode: this.sensorMac,
        name: this.name,
        sensingPeriod: this.sensingPeriod,
        updatePeriod: this.updatePeriod,
        highTempThreshold: highTemp,
        lowTempThreshold: lowTemp,
        tempAlarmStatus: this.tempAlarmStatus,
        hmdtAlarmStatus: this.hmdtAlarmStatus
      }
      const params = {
        store: this.store.code,
        sensorCode: this.sensorMac
      }
      const config = { params }
      this.$utils
        .callAxiosWithBody(
          codes.requests.togetSensorListUpdate.method,
          codes.requests.togetSensorListUpdate.url,
          body,
          config
        )
        .then(res => {
          if (res.status === 200) {
            EventBus.$emit('messageAlert', this.$t(res.data.returnMsg))
            EventBus.$emit('updateSensorListEvent')
            this.closeModal()
          }
        })
        .catch(error => {
          console.log(error)
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.returnMsg),
            'error'
          )
        })
    }
  },
  created () {
    if (localStorage.getItem('tempIndicator') !== null) {
      this.toggleState = JSON.parse(localStorage.getItem('tempIndicator'))
    } else {
      this.toggleState = true
      localStorage.setItem('tempIndicator', 'true')
    }
    var highTemp = null
    var lowTemp = null
    if (this.toggleState === false) {
      highTemp = ((this.editList.highTempThreshold * 9) / 5 + 32).toFixed(2)
      lowTemp = ((this.editList.lowTempThreshold * 9) / 5 + 32).toFixed(2)
    } else {
      highTemp = this.editList.highTempThreshold.toFixed(2)
      lowTemp = this.editList.lowTempThreshold.toFixed(2)
    }

    this.lowHmdtThreshold = this.editList.lowHmdtThreshold
    this.highHmdtThreshold = this.editList.highHmdtThreshold
    this.assignedTo = this.editList.assignedTo.toString()
    this.sensorId = this.editList.sensorId
    this.name = this.editList.name
    this.sensorMac = this.editList.sensorCode
    // this.sensingPeriod = this.AddSensingPeriodValue()
    this.updatePeriod = this.editList.updatePeriod
    this.highTempThreshold = highTemp
    this.lowTempThreshold = lowTemp
    this.tempAlarmStatus = this.editList.tempAlarmStatus
    this.hmdtAlarmStatus = this.editList.hmdtAlarmStatus
    this.AddSensingPeriodValue()
  }
}
</script>
<style scoped>
::v-deep .v-dialog {
  overflow-x: hidden !important;
}
</style>
