<template>
    <div>
      <h2 class="page-title-bar">
        <img
          src="@/assets/img/bell-ring.png"
          style="margin-right: 15px; height: 31px;"
        />{{ $t("Alert List") }}
      </h2>
      <div class="">
        <div class="col-9">
          <!-- SEARCH AREA  -->
          <div class="searchArea" style="width: 80%">
            <div class="row">
              <div class="d-flex col-12 align-baseline px-0 py-0">
                <!-- Sensor Name -->
                <v-text-field
                  :label="$t('Sensor ID')"
                  outlined
                  hide-details
                  dense
                  :placeholder="$t('Input Sensor ID')"
                  v-model="searchBy.sensorCode"
                  clearable
                  class="form-select col-3"
                >
                </v-text-field>
                <v-select
                  :label="$t('Type')"
                  :placeholder="$t('Select Type')"
                  outlined
                  dense
                  hide-details
                  v-model="searchBy.alertType"
                  :items="typeList"
                  item-text="text"
                  item-value="val"
                  class="form-select col-3 ml-3"
                >
                </v-select>
                <v-btn class="btn type-search ml-3" text @click="clickSearch()"
                  >{{ $t("Search") }}
                </v-btn>
                <v-btn class="btn type-search ml-3" text @click="clearSearch"
                  >{{ $t("Clear") }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-10">
        <v-data-table
          :headers="tableHeaders"
          :items="tableItems"
          item-key="serialNo"
          :hide-default-footer="true"
          :options.sync="options"
          :items-per-page="rowPerPage"
          :server-items-length="tableItems.length"
          :show-select="false"
          class="tbl-type01 mt-5"
          fixed-header
          must-sort
          :height="
                tableItems && tableItems.length > 8
                  ? '550px'
                  : 'auto'
              "
        >
          <template slot="no-data">
            <v-alert dense color="#697A8A" class="ma-0" style="line-height: 19px">
              <v-icon class="mr-1" color="#fff">mdi-alert-outline</v-icon>
              {{ $t("No data available") }}
            </v-alert>
          </template>
          <template v-slot:[`item.value`]="{ item }">
              <div
                :style="
                  item.type.toUpperCase() === 'HIGH_TEMPERATURE'.toUpperCase() ||
                  item.type.toUpperCase() === 'HIGH_HUMIDITY'.toUpperCase()
                    ? 'color:#FF8097'
                    : 'color: #6DA3F8'
                "
              >
                <div v-if="item.type.toUpperCase() === 'HIGH_TEMPERATURE'.toUpperCase() ||
                item.type.toUpperCase() === 'LOW_TEMPERATURE'.toUpperCase()
                 ">
                  <p v-if="toggleState">{{ item.value }} <span>°</span>C</p>
                <p v-else>
                  {{ ((item.value * 9) / 5 + 32) .toFixed(2)}} <span>°</span>F
                </p>
                </div>
                <div v-else>
                  <p>{{ item.value }}%</p>
                </div>
              </div>
            </template>
          <template v-slot:[`item.deliveryStatus`]="{ item }">
            <div @click="openStatusDialog(item)" style="cursor: pointer;">
              <img src="@/assets/img/info-ico-custom.png" alt="" height="25" />
            </div>
          </template>
          <template v-slot:[`item.assignedTo`]="{ item }">
            {{ item.assignedTo !== null ? convertToString(item.assignedTo) : '-'  }}
          </template>
          <template v-slot:[`item.sensorName`]="{ item }">
            {{ item.sensorName ? item.sensorName : '-' }}
          </template>
          <template v-slot:[`item.duration`]="{ item }">
            {{ item.alertTime !== null && item.alertOffTime !== null ? getUTCtoLocalTimeDuration(item) : '-' }}
          </template>
          <template v-slot:[`item.alertOffTime`]="{ item }">
            {{ item.alertOffTime ? getUTCtoLocal(item.alertOffTime) : '-' }}
          </template>
          <template v-slot:[`item.alertTime`]="{ item }">
            <span>{{ item.alertTime ? getUTCtoLocal(item.alertTime) : '-' }}</span>
          </template>

          <template v-slot:[`item.type`]="{ item }">
            <p
              :style="getColor(item.type)"
            >
              {{ item.type }}
            </p>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <p
              :style="
                item.status === 'ON'
                  ? 'color: #EF726B'
                  : 'color: #9DEB8F'
              "
            >
              {{ item.status === 'ON' ? 'Active' : 'Completed' }}
            </p>
          </template>
        </v-data-table>
        <div class="table-options" v-if="tableItems.length > 0">
          <div></div>
          <div>
            <div class="pageInfo">{{ pageInfoText }}</div>
            <div class="pagination2">
              <label class="pageInfo mr-1" style="position:relative !important;">
                {{ $t("Show") }}
              </label>
              <v-select
                v-model="rowPerPage"
                :items="rowPerPageList"
                :placeholder="$t('Limit')"
                class="form-input pt-0 col rowPerPage"
                dense
                persistent-hint
                outlined
                hide-details
                @change="paging(1)"
              >
                <template v-slot:item="{ item }">
                  {{ `${item}` }}{{$t('Rows')}}
                </template>
                <template v-slot:selection="{ item }">
                  <span class="selection-v-select"> {{ `${item}` }}{{$t('Rows')}}</span>
                </template>
              </v-select>

              <div class="mr-2">
                <v-text-field
                  v-model="goToPageInput"
                  class="form-input pt-0 col goToPage ml-1"
                  placeholder="Page"
                  outlined
                  dense
                  hide-details
                  solo
                  @keyup.enter="goToPage(goToPageInput, paging, totalPages)"
                  append-icon="mdi-chevron-right"
                  @keypress="checkIsNumber($event)"
                  @click:append="goToPage(goToPageInput, paging, totalPages)"
                ></v-text-field>
              </div>
              <button
                @click="goTenPageBackwards(page, paging)"
                :class="page < 10 ? 'pagination-nav-disabled' : null"
                class="pagination-nav pagination-nav-prev"
                type="button"
              >
                <img
                  src="@/assets//img/ico-paging-prev.png"
                  alt="이전 10페이지 이동"
                />
              </button>
              <v-pagination
                v-model="page"
                @input="paging"
                :length="totalPages * 1 || 1"
                :total-visible="8"
                color="#2f3b4c"
                style="display: block"
              ></v-pagination>
              <button
                @click="goTenPageForward(page, paging, totalPages)"
                :class="
                  page + 10 > totalPages ? 'pagination-nav-disabled' : null
                "
                class="pagination-nav pagination-nav-next"
                type="button"
              >
                <img
                  src="@/assets//img/ico-paging-next.png"
                  alt="다음 10페이지 이동"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <AlarmNotification
        v-if="notiDialogPopup"
        :notiDialogPopup="notiDialogPopup"
        :alertIdData="alertIdInfo"
        @closeNotiModal="closeNotiModal"
      />
    </div>
  </template>

<script>
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import { mapGetters } from 'vuex'
import Pagenation from '@/mixins/Pagenation'
import AlarmNotification from './model/AlarmNotification.vue'
import moment from 'moment-timezone'
export default {
  mixins: [Pagenation],
  components: {
    AlarmNotification
  },
  data () {
    return {
      toggleState: true,
      rowPerPage: 10,
      notiDialogPopup: false,
      alertIdInfo: {},
      page: 1,
      totalPages: 0,
      pageInfoText: '1 to 1, 1 in total',
      goToPageInput: 1,
      options: {
        sortBy: ['alertTime'],
        sortDesc: [true],
        itemsPerPage: 10
      },
      searchBy: {
        sensorCode: null,
        alertType: ''
      },
      typeList: [
        {
          text: this.$t('High Temperature'),
          val: 'HIGH_TEMPERATURE'
        },
        {
          text: this.$t('Low Temperature'),
          val: 'LOW_TEMPERATURE'
        },
        {
          text: this.$t('High Humidity'),
          val: 'HIGH_HUMIDITY'
        },
        {
          text: this.$t('Low Humidity'),
          val: 'LOW_HUMIDITY'
        }
      ],
      tableItems: [],
      isMounted: false,
      btnActionAlert: false
    }
  },
  computed: {
    rowPerPageList () {
      return [5, 10, 20, 50, 100]
    },
    ...mapGetters({
      store: 'dataStore/GET_SELECTED_STORE'
    }),
    tableHeaders () {
      return [
        {
          text: this.$t('#'),
          sortable: false,
          value: 'serialNo'
        },
        {
          text: this.$t('ALERT TIME'),
          sortable: true,
          value: 'alertTime'
        },
        {
          text: this.$t('ALERT OFF TIME'),
          sortable: true,
          value: 'alertOffTime'
        },
        {
          text: this.$t('DURATION (Hrs:Min)'),
          sortable: false,
          value: 'duration'
        },
        {
          text: this.$t('SENSOR ID'),
          sortable: false,
          value: 'sensorCode'
        },
        {
          text: this.$t('SENSOR NAME'),
          sortable: false,
          value: 'sensorName'
        },
        {
          text: this.$t('LOCATION'),
          sortable: false,
          value: 'assignedTo'
        },
        {
          text: this.$t('TYPE'),
          sortable: false,
          value: 'type'
        },
        {
          text: this.$t('VALUE'),
          sortable: false,
          value: 'value'
        },
        {
          text: this.$t('DELIVERY STATUS'),
          sortable: false,
          value: 'deliveryStatus'
        },
        {
          text: this.$t('STATUS'),
          sortable: false,
          value: 'status'
        }
      ]
    }
  },
  watch: {
    options: {
      handler () {
        if (!this.isMounted) return
        this.getAlertListDetails(this.page)
      },
      deep: true
    },
    page: {
      handler (page) {
        this.goToPageInput = page
      }
    }
  },
  created () {
    if (localStorage.getItem('tempIndicator') !== null) {
      this.toggleState = JSON.parse(localStorage.getItem('tempIndicator'))
    } else {
      this.toggleState = true
      localStorage.setItem('tempIndicator', 'true')
    }
  },
  mounted () {
    this.$store.dispatch('auth/getDisabledBtn', '13102').then(flag => {
      this.btnActionAlert = flag
    })
    EventBus.$emit('enableSelectedStores', false)
    this.getAlertListDetails(1)
  },
  methods: {
    goToPage (goToPageInput, paging, totalPages) {
      if (totalPages < goToPageInput) {
      } else {
        paging(Number(goToPageInput))
      }
    },
    convertToString (data) {
      return data.toString()
    },
    clickSearch () {
      this.paging(1)
    },
    getColor (type) {
      if (type === 'HIGH_TEMPERATURE') return 'color: #EF726B'
      if (type === 'LOW_TEMPERATURE') return 'color: #6DA3F8'
      if (type === 'HIGH_HUMIDITY') return 'color: #EF726B'
      if (type === 'LOW_HUMIDITY') return 'color: #7D98DA'
      else return '#EF726B'
    },
    getUTCtoLocal (date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    getUTCtoLocalTimeDuration (item) {
      const startTime = moment(item.alertTime).format('YYYY-MM-DD HH:mm:ss')
      const endTime = moment(item.alertOffTime).format('YYYY-MM-DD HH:mm:ss')
      const start = moment(startTime, 'YYYY-MM-DD HH:mm:ss')
      const end = moment(endTime, 'YYYY-MM-DD HH:mm:ss')
      const duration = moment.duration(end.diff(start))
      const hours = Math.floor(duration.asHours())
      const minutes = duration.minutes()
      const formattedDuration = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
      return formattedDuration
    },
    clearSearch () {
      this.searchBy = {
        sensorCode: null,
        alertType: null
      }
      this.paging(1)
    },
    paging (page) {
      this.page = Number(page)
      this.goToPageInput = page
      this.isMounted = false
      this.getAlertListDetails(this.page)
    },
    openStatusDialog (data) {
      // if (this.btnActionAlert) {
      this.notiDialogPopup = true
      this.alertIdInfo = data
      // }
    },
    closeNotiModal () {
      this.notiDialogPopup = false
    },
    getPageInfoText (headers, tabelItems) {
      return (
        headers['x-number'] * 1 * headers['x-size'] +
          1 +
          ' to ' +
          (headers['x-number'] * 1 * headers['x-size'] + tabelItems.length) +
          ', ' +
          headers['x-totalelements'] * 1 +
          ' in total'
      )
    },
    handleGetPrResponse (res) {
      const headers = res.headers
      this.tableItems = res.data.alertHistory.map((obj, idx) => {
        return {
          serialNo: headers['x-number'] * 1 * headers['x-size'] + idx + 1,
          ...obj
        }
      })
      this.pageInfoText = commons.getPageInfoText(res.headers)
      if (commons.isValidStr(res.headers['x-totalpages'])) {
        this.totalPages = res.headers['x-totalpages'] * 1
      }
      this.selected = []
    },
    handleGetPrError (error) {
      this.tableItems = []
      const headers = {}
      headers['x-totalelements'] = 0
      this.pageInfoText = commons.getPageInfoText(headers)
      this.totalPages = 0
      this.selected = []
      EventBus.$emit(
        'messageAlert',
        this.$t(error.response.data.responseMessage),
        'error'
      )
      console.debug(`Could not find any Alert. ${error}`)
    },
    buildSearchParams (params, searchBy) {
      if (searchBy.sensorCode !== null) {
        params.sensorCode = searchBy.sensorCode
      }
      if (!commons.isNull(searchBy.alertType)) params.alertType = searchBy.alertType
      return params
    },
    getSortKey (sortKey) {
      switch (sortKey) {
        case 'alertTime':
          sortKey = 'alertTime'
          break
        default:
          break
      }
      return sortKey
    },
    buildParams () {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      this.options.itemsPerPage = Number(this.rowPerPage)
      let params = { store: this.store.code }
      let sort = null
      if (commons.isSorting(sortBy, sortDesc)) {
        let sortKey = this.getSortKey(sortBy[0])
        const sortOrder = sortDesc[0]
        if (sortKey === 'alertTime') sortKey = 'alertTime'
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      if (itemsPerPage > 0) params.size = this.rowPerPage
      params = this.buildSearchParams(params, this.searchBy)
      params.company = this.$store.state.auth.user.company
      params.store = this.store.code
      return params
    },
    getAlertListDetails (page) {
      const params = this.buildParams()
      if (page !== null) params.page = page - 1
      const config = { params }
      this.$utils
        .callAxios(
          codes.requests.getAlertHistory.method,
          codes.requests.getAlertHistory.url,
          config
        )
        .then(res => {
          this.isMounted = true
          if (res.data && res.status === 200) {
            this.handleGetPrResponse(res)
          } else {
            this.tableItems = []
            this.totalPages = 0
            this.selected = []
            this.pageInfoText = ''
          }
        })
        .catch(error => {
          this.isMounted = true
          this.handleGetPrError(error)
          console.log(error)
        })
    }
  }
}
</script>

  <style scoped>
  .circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }
  </style>
