<template>
    <v-dialog v-model="dialogConfirm" width="306" persistent>
      <v-card
        class="popup add_store_popup popup-success"
        align="center"
        justify="center"
      >
        <i class="ico-success"
          ><img src="@/assets/img/ico-caution.png" alt=""
        /></i>
        <div class="msg">
          <h3 class="mb-0">{{ $t(title) }}</h3>
        </div>
        <v-row>
          <v-col>
            <span v-text="confirmMessageText" style="font-size: 15px"></span>
          </v-col>
        </v-row>
        <v-card-actions class="d-flex justify-center">
          <v-btn text icon @click="moveTap('Yes')" class="btn">{{
            $t("Yes")
          }}</v-btn>
          <v-btn text icon @click="moveTap('No')" class="btn">{{
            $t("No")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Delete'
    },
    confirmMessageText: {
      type: String,
      default: 'Are You Sure you want to delete?'
    },
    dialogConfirm: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    moveTap (action) {
      this.$emit('confirmAction', action)
    }
  }
}
</script>
