<template>
  <div class="chart-container">
    <p class="d-none">
      {{ this.chartData.series }}{{ this.chartOptions.xAxis }}
      {{ legend }}
    </p>
    <line-chart :options="chartOptions" ref="lineChart"></line-chart>
  </div>
</template>
<script>
import { Chart } from 'highcharts-vue'
export default {
  name: 'SensorLineChart',
  components: {
    lineChart: Chart
  },
  props: {
    chartData: {
      type: Object,
      required: true
    },
    boxshadow: {
      type: Boolean,
      required: false,
      default: false
    },
    colors: {
      type: Array,
      default: () => ['#9AC7DB', '#98BF64']
    },
    height: {
      type: Number,
      default: 300
    },
    legend: {
      type: Object,
      default: () => ({
        align: 'right',
        verticalAlign: 'top',
        y: -10,
        symbolPadding: 10,
        symbolWidth: 6,
        symbolHeight: 30,
        itemStyle: {
          color: '#8394b4',
          fontSize: '9px',
          fontWeight: '500'
        }
      })
    },
    title: {
      type: Object,
      default: () => {}
    }

  },
  data () {
    return {
      chartOptions: {
        colors: this.colors,
        chart: {
          height: this.height
        },
        title: this.title,
        // subtitle: this.chartData.subtitle,
        yAxis: this.chartData.yAxis,
        xAxis: this.chartData.xAxis,
        series: this.chartData.series,
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            marker: {
              fillColor: 'yellow',
              lineWidth: 0,
              radius: 0,
              lineColor: null
            }
          },
          line: {
            dataLabels: {
              enabled: true
            },
            enableMouseTracking: true
          }
        },
        legend: this.legend,
        tooltip: {
          borderColor: '#FFFFFF',
          backgroundColor: '#fff',
          color: '#626262',
          borderWidth: 0,
          style: {
            color: '#626262'
          },
          formatter: function () {
            const x = this.x
            var value = this.series.data.find(obj => obj.category === x).options
              .y
            const min = this.series.yAxis.plotLinesAndBands[0].options.value
            const max = this.series.yAxis.plotLinesAndBands[1].options.value
            if (this.series.name === 'Temperature') {
              if (value === min) {
                return `<b> ${x}</b>  <br/>
              Min : ${value} ${JSON.parse(localStorage.getItem('tempIndicator')) ? '°C' : '°F'}`
              } else if (value === max) {
                return `<b> ${x}</b>  <br/> Max : ${value} ${JSON.parse(localStorage.getItem('tempIndicator')) ? '°C' : '°F'}`
              } else {
                return `
              <b> ${x}</b>  <br/>
              ${value} ${JSON.parse(localStorage.getItem('tempIndicator')) ? '°C' : '°F'}`
              }
            } else if (this.series.name === 'Humidity') {
              if (value === min) {
                return `<b> ${x}</b>  <br/> Min : ${value} %`
              } else if (value === max) {
                return `<b> ${x}</b>  <br/> Max : ${value} %`
              } else {
                return `<b> ${x}</b>  <br/> ${value} %`
              }
            } else {
              return `${value}`
            }
          }
        }
      }
    }
  },
  methods: {
    fetchNewData (series) {
      this.chartOptions.series = series
    }
  },
  beforeUpdate () {
    this.fetchNewData(this.chartData.series)
    this.chartOptions.xAxis = this.chartData.xAxis
  },
  created () {
    this.fetchNewData(this.chartData.series)
    this.chartOptions.xAxis = this.chartData.xAxis
  }
}
</script>

<style scoped>
.chart-container {
  /* max-width: 1446px; */
  max-height: 588px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
  overflow: hidden;
  /* padding: 0 0 30px 0; */
  background: #fff;
}
/*
.highcharts-markers {
  display: none;
} */

.highcharts-root {
  padding: 0px;
}

.make-bold {
  font-weight: 600;
}
</style>
