<template>
  <!-- 09.28 팝업추가 -->
  <v-dialog
    v-model="addTemplateVisible"
    width="445"
    persistent scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="addBtnDisabled"
        text
        v-bind="attrs"
        v-on="on"
        class="btn"
        @click="resetColor"
        >{{ $t('New') }}</v-btn
      >
    </template>
    <v-card class="popup add_store_popup" :key="addTemplateVisible">
      <v-card-title>
      <h3 class="page-title-bar">
        <i class="ico ico-template"></i>{{ $t('New Template Type') }}
      </h3>
    </v-card-title>
    <v-card-text>
       <v-row>
        <v-col class="pt-0">
          <v-text-field
            v-model="template.typeName"
            outlined
            dense
            hide-details
            :label="`${$t('Type Name')} *`"
            :placeholder="`${$t('Input the type name')}`"
            class="form-input"
            clearable
            style="padding-top: 35px;"
            @keypress="handleKeyUp"
            @keydown="clearTabindex"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <v-select
            outlined
            dense
            hide-details
            v-model="tagType"
            :items="tagTypes"
            :label="`${$t('Template Size')} *`"
            :placeholder="`${$t('Input the template Size')}`"
            class="form-select"
             @keydown="clearTabindex"
             @change="handleChangeColor()"
          ></v-select>
        </v-col>
      </v-row>
      <!-- template color  -->
      <v-row>
  <v-col class="pt-0">
    <v-select
      outlined
      dense
      hide-details
      v-model="tempColor"
      :items="TempColorItem"
      :label="`${$t('Template Color')} *`"
      :placeholder="`${$t('Select template color')}`"
      class="form-select"
      @keydown="clearTabindex"
    >
    <template v-slot:selection="data">
  <div style="font-size: 12px;">
    <span v-if="data.item.length > 0" style="color:#676565">{{ data.item[0] }}</span>
    <span v-if="data.item.length > 1" style="color:#d1c3c3">{{ data.item[1] }}</span>
    <span v-if="data.item.length > 2"
          :style="data.item[2] === 'R' ? 'color:red' :
                  (data.item[2] === 'P' ? 'color:pink' :
                  (data.item[2] === 'W' ? 'color:white' : 'color:yellow'))">{{ data.item[2] }}</span>
    <span v-if="data.item.length > 3"
          :style="data.item[3] === 'Y' ? 'color:yellow' :
                  (data.item[3] === 'W' ? 'color:white' : 'color:orange')">{{ data.item[3] }}</span>
    <span v-if="data.item.length > 4" :style="data.item[4] === 'O' ? 'color:orange' : 'color:green'">{{ data.item[4] }}</span>
    <span v-if="data.item.length > 5"
          :style="(data.item[5] === 'G' && data.item.length === 7) ? 'color:green' :
                  (data.item[5] === 'G' ? 'color:darkgrey' : 'color:blue')">{{ data.item[5] }}</span>
    <span v-if="data.item.length > 6"
          :style="data.item[6] === 'B' ? 'color:blue' : 'color:darkgrey'">{{ data.item[6] }}</span>
  </div>
</template>

<template v-slot:item="data">
  <div style="font-size: 12px;">
    <span v-if="data.item.length > 0" style="color:#676565">{{ data.item[0] }}</span>
    <span v-if="data.item.length > 1" style="color:#d1c3c3">{{ data.item[1] }}</span>
    <span v-if="data.item.length > 2"
          :style="data.item[2] === 'R' ? 'color:red' :
                  (data.item[2] === 'P' ? 'color:pink' :
                  (data.item[2] === 'W' ? 'color:white' : 'color:yellow'))">{{ data.item[2] }}</span>
    <span v-if="data.item.length > 3"
          :style="data.item[3] === 'Y' ? 'color:yellow' :
                  (data.item[3] === 'W' ? 'color:white' : 'color:orange')">{{ data.item[3] }}</span>
    <span v-if="data.item.length > 4" :style="data.item[4] === 'O' ? 'color:orange' : 'color:green'">{{ data.item[4] }}</span>
    <span v-if="data.item.length > 5"
          :style="(data.item[5] === 'G' && data.item.length === 7) ? 'color:green' :
                  (data.item[5] === 'G' ? 'color:darkgrey' : 'color:blue')">{{ data.item[5] }}</span>
    <span v-if="data.item.length > 6"
          :style="data.item[6] === 'B' ? 'color:blue' : 'color:darkgrey'">{{ data.item[6] }}</span>
  </div>
</template>
    </v-select>
  </v-col>
</v-row>
      <!-- end -->
      <div style="text-align:center;font-weight:bold">(OR)</div>
      <v-row>
        <v-col class="pt-0 col" style="margin-top: 20px;">
          <v-text-field
            v-model="labelCode"
            outlined
            dense
            hide-details
            :label="`${$t('Label type finder')} *`"
            :placeholder="`${$t('Input label code')}`"
            class="form-input pr-0"
            clearable
            @keypress="handleKeyUp"
            @keydown="clearTabindex"
          >
      <template v-slot:append>
        <div class="searchIcon">
            <img src="@/assets/img/btn-search.jpg" @click="searchLabeltype()" alt="" /></div>
        </template>
      </v-text-field>
      </v-col>
      </v-row>

      <v-row>
        <v-col class="pt-0 mt-0" >
          <v-text-field
            v-model="templateType"
            outlined
            dense
            hide-details
            :placeholder="`${$t(placeholdertemplatetype)}`"
            class="form-input mt-0 pt-0"
            clearable
            @keypress="handleKeyUp"
            @keydown="clearTabindex"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
</v-card-text>
      <!-- <p style="font-size: var(--font14);color: #4d4f5c;">* Scan or input the last 4 digits of the label for label type information.</p> -->

      <v-card-actions class="d-flex justify-center">
        <v-btn text icon @click="addTemplate" class="btn">{{
          $t('Save')
        }}</v-btn>
        <v-btn text icon @click="cancelAddTemplate" class="btn">{{
          $t('Cancel')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/plugins/eventBus.js'
import commons from '@/plugins/commons'

const requests = {
  getTagTypes: {
    method: 'get',
    url: '/api/common/templates/data'
  },
  addTemplate: {
    method: 'post',
    url: '/api/common/templates/type'
  },
  getLabelType: {
    method: 'get',
    url: '/api/common/labels/type/one'
  },
  getTemplateColor: {
    method: 'get',
    url: '/api/common/templates/color'
  },
  getColoroftempSize: {
    method: 'get',
    url: '/api/common/templates/size/color'
  }
}

export default {
  props: ['user', 'addBtnDisabled'],
  data () {
    return {
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      addTemplateVisible: false,
      addTemplateInputKey: true,
      template: {
        typeName: null,
        templateSize: null
      },
      tagType: null,
      tagTypes: [],
      tempColor: null,
      TempColorItem: [],
      sizesByTagTypes: [],
      placeholdertemplatetype: 'ex) Newton 6.0" 348 * 168',
      labelCode: null,
      templateType: null,
      tagTypelistData: [],
      enableLableFinder: false,
      storeLabelFinderValue: '',
      newColorFinder: [],
      finalClrSet: [],
      templateClrSize: ''
    }
  },
  methods: {
    handleKeyUp (e) {
      commons.specialCharRest(e)
    },

    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    cancelAddTemplate () {
      this.addTemplateVisible = false
      this.template = {
        typeName: null,
        templateSize: null
      }
      this.tagType = null
      this.labelCode = null
      this.templateType = null
      this.tempColor = null
    },
    setSizesByTagTypes (tagTypeList) {
      const sizesByTagTypes = {}
      for (const tagType of tagTypeList) {
        sizesByTagTypes[tagType.name] = tagType
      }
      this.sizesByTagTypes = sizesByTagTypes
    },
    getTagTypes () {
      const params = { company: this.user.company }
      const config = { params: params }

      this.$utils
        .callAxios(
          requests.getTagTypes.method,
          requests.getTagTypes.url,
          config
        )
        .then(res => {
          this.setSizesByTagTypes(res.data.tagTypeList)
          this.tagTypelistData = res.data.tagTypeList
          this.tagTypes = res.data.tagTypeList.map(tl => tl.name)
        })
    },

    // get template color
    getTempColor () {
      const params = { company: this.user.company }
      const config = { params: params }

      this.$utils
        .callAxios(
          requests.getTemplateColor.method,
          requests.getTemplateColor.url,
          config
        )
        .then(res => {
          this.TempColorItem = res.data.tagColorList
        })
    },
    // end
    // handle change event for template size (get color based on template size)
    handleChangeColor () {
      this.TempColorItem = []
      this.templateType = null
      let value = ''
      if (!this.enableLableFinder) {
        this.labelCode = null
        this.tagTypelistData.map((item) => {
          if (item.name === this.tagType) {
            value = item
          }
        })
        this.tempColor = ''
      } else {
        this.enableLableFinder = false

        value = { width: this.storeLabelFinderValue[0].width, height: this.storeLabelFinderValue[0].height }
      }
      const params = { width: value.width, height: value.height }
      const config = { params: params }
      this.$utils
        .callAxios(
          requests.getColoroftempSize.method,
          requests.getColoroftempSize.url,
          config

        )
        .then(res => {
          const result = res.data
          this.TempColorItem = result
          if (this.labelCode !== null) {
          // get clr based on size
            var templateClrValue = JSON.stringify(this.templateClrSize)
            var matches = templateClrValue.match(/\b(\w)/g)
            var templateClrMatches = matches.join('')
            var stringSplit = templateClrMatches.split('')

            var tempColorSplit = []
            this.finalClrSet = []

            // spliting color value based on template selection
            this.TempColorItem.map((items) => {
              tempColorSplit.push(items.split(''))
            })

            // map color split with template clr(api value)
            // tempColorSplit.map((items) => {
            //   var newValue = ''
            //   if (items.length === stringSplit.length) {
            //     newValue = items.join('')
            //     this.newColorFinder.push(newValue)
            //   }
            // })

            // Function to check if two arrays have the same elements in any order
            const arraysMatch = (arr1, arr2) => {
              if (arr1.length !== arr2.length) return false
              return arr1.every(item => arr2.includes(item)) && arr2.every(item => arr1.includes(item))
            }

            // Filter the arrays that exactly match the elements of targetArray
            const filteredArrays = tempColorSplit.filter(arr => arraysMatch(arr, stringSplit))
            var newValue = ''
            newValue = filteredArrays.join('')
            this.newColorFinder.push(newValue)

            // push value based on clr
            if (this.newColorFinder.length > 1) {
              this.newColorFinder.map((items) => {
                if (items === templateClrMatches) {
                  this.finalClrSet.push(items)
                }
              })
              console.log(this.finalClrSet[0])
              this.tempColor = this.finalClrSet[0]
            } else {
              this.tempColor = this.newColorFinder[0]
            }
            this.tempColor = this.tempColor.replace(/,/g, '')
            console.log(this.tempColor)
            console.log(this.TempColorItem)
            if (this.tempColor) {
              this.TempColorItem = this.TempColorItem.filter(item => item === this.tempColor)
            }
          }
          if (res.data.length === 0) {
            this.TempColorItem = []
            EventBus.$emit('messageAlert', this.$t('No data availabe'))
          }
        })
        .catch(error => {
          console.log(error)
          this.TempColorItem = []
          EventBus.$emit('messageAlert', this.$t('No data availabe'))

          // console.log(error.status.code)
          // const result = error.status
          // EventBus.$emit('messageAlert', this.$t(result.responseMessage))
        })
    },
    // end

    // save template
    addTemplate () {
      this.template.typeName =
        this.template.typeName === null
          ? null
          : this.template.typeName.trim()
      this.template.templateSize = this.tagType
      // param for template color
      this.template.templateColor = this.tempColor
      // end
      const template = this.template
      const isValidData = commons.isValidData(template)
      if (!isValidData.result) {
        EventBus.$emit('messageAlert', this.$t(isValidData.msg))
        return
      }
      const params = { company: this.user.company }
      // const config = { params }
      params.company = this.$store.state.auth.user.company

      if (this.productionType === 'cloud') {
        template.labelType = this.tagType
        template.templateColor = this.tempColor
      }
      this.$utils
        .callAxiosWithBody(
          requests.addTemplate.method,
          requests.addTemplate.url,
          template

        )
        .then(res => {
          const result = res.data
          EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          this.cancelAddTemplate()
          this.$emit('refreshTemplates')
        })
        .catch(error => {
          const result = error.response.data
          if (error.response.status === 409) {
            EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          } else {
            EventBus.$emit('messageAlert', this.$t('Failed to add new template'))
          }
          this.cancelAddTemplate()
        })
    },

    searchLabeltype () {
      this.newColorFinder = []
      if (!this.labelCode) {
        EventBus.$emit('messageAlert', this.$t('Please enter the label code.'))
        this.templateType = null
        this.tagType = null
        return
      }
      const params = { labelCode: this.labelCode }
      const config = { params: params }

      this.$utils
        .callAxios(
          requests.getLabelType.method,
          requests.getLabelType.url,
          config
        )
        .then((res) => {
          this.enableLableFinder = true
          this.templateClrSize = res.data.templateColor
          this.storeLabelFinderValue = res.data
          console.log(this.storeLabelFinderValue)
          var result = this.tagTypelistData.filter(obj => {
            return obj.width === res.data.displayWidth && obj.height === res.data.displayHeight
          })
          this.storeLabelFinderValue = result
          // const initials = this.templateClrSize.map(color => color[0]).join('')
          // console.log(initials) // Output: BWYRBG
          // this.tempColor = initials
          // calling color api based on size
          this.handleChangeColor()
          this.tagType = result[0].name
          this.templateType = res.data.name + ' ' + res.data.displayWidth + ' * ' + res.data.displayHeight
        })
        .catch(error => {
          console.error(error)
          EventBus.$emit('messageAlert', this.$t('Enter the valid label code'))
          this.labelCode = null
          this.templateType = null
          this.tagType = null
        })
    },
    resetColor () {
      this.getTempColor()
    }

  },
  computed: {
    tagTypeSelected () {
      return this.tagType !== null
    }
  },
  mounted () {
    this.getTagTypes()
    this.tempColor = null
    this.getTempColor()
    this.labelCode = null
    this.templateType = null
  }
}
</script>

<style scoped>
  .searchIcon{
    margin-right: -12px;
    display: inline-block;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
    cursor: pointer;
  }
  ::v-deep .form-select.v-text-field--outlined .v-select__selections{
  display: flex !important;
}

</style>
