<template>
  <label class="switch ml-1"
    ><input type="checkbox" id="togBtn" v-model="toggleValue" @change="valueChanged"
    />
    <div class="slider round" :style="leftStyle">
      <span class="on">°C</span><span class="off">°F</span>
    </div></label
  >
</template>
<script>
export default {
  name: 'Toggle',
  props: {
    toggleState: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      toggleValue: false
    }
  },
  computed: {
    leftStyle () {
      if (this.toggleValue) {
        return { '--left': '-10px' }
      }
      return { '--left': '4px' }
    }
  },
  created () {
    this.toggleValue = this.toggleState
  },
  methods: {
    valueChanged () {
      this.$emit('valueChanged', this.toggleValue)
    }
  }
}
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 75px;
  height: 34px;
}

.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000029;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: var(--left);
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #001e38;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.on
{
  display: none;
}

.on
{
  color: white;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  top: 50%;
  left: 30%;
  font-size: 16px;
  font-weight: 600;
}
.off
{
  color: white;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 70%;
  font-size: 16px;
  font-weight: 600;
}

input:checked+ .slider .on
{display: block;}

input:checked + .slider .off
{display: none;}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  background-color: #001e38;
}

.slider.round:before {
  border-radius: 50%;}
/*
.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000029;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: var(--bg-color);
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #001e38;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(55px);
} */

/*------ ADDED CSS ---------*/
/* .on {
  display: none;
}

.on {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 27%;
  font-size: 11px;
  font-weight: 500;
}
.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 68%;
  font-size: 11px;
  font-weight: 500;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
} */

/*--------- END --------*/

/* Rounded sliders */
/* .slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
} */
</style>
