import { render, staticRenderFns } from "./AddTemplateType.vue?vue&type=template&id=32ec3078&scoped=true&"
import script from "./AddTemplateType.vue?vue&type=script&lang=js&"
export * from "./AddTemplateType.vue?vue&type=script&lang=js&"
import style0 from "./AddTemplateType.vue?vue&type=style&index=0&id=32ec3078&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32ec3078",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VDialog,VRow,VSelect,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/aims-dashboard/aims-dashboard/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32ec3078')) {
      api.createRecord('32ec3078', component.options)
    } else {
      api.reload('32ec3078', component.options)
    }
    module.hot.accept("./AddTemplateType.vue?vue&type=template&id=32ec3078&scoped=true&", function () {
      api.rerender('32ec3078', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Template/modal/AddTemplateType.vue"
export default component.exports