import { render, staticRenderFns } from "./SensorAlertSetting.vue?vue&type=template&id=3dca27de&scoped=true&"
import script from "./SensorAlertSetting.vue?vue&type=script&lang=js&"
export * from "./SensorAlertSetting.vue?vue&type=script&lang=js&"
import style0 from "./SensorAlertSetting.vue?vue&type=style&index=0&id=3dca27de&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dca27de",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VBtn,VCard,VCardActions,VCol,VDataTable,VDialog,VIcon,VPagination,VRow,VSelect,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/aims-dashboard/aims-dashboard/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3dca27de')) {
      api.createRecord('3dca27de', component.options)
    } else {
      api.reload('3dca27de', component.options)
    }
    module.hot.accept("./SensorAlertSetting.vue?vue&type=template&id=3dca27de&scoped=true&", function () {
      api.rerender('3dca27de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Sensors/SensorAlertSetting.vue"
export default component.exports