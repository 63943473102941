<template>
<div>
<!-- <v-row><v-subheader><b>{{ $t('SELECTED TEMPLATE TYPE') }}</b></v-subheader></v-row>
<v-row>
<v-col cols="4"><v-subheader>Type Name</v-subheader></v-col>
<v-col cols="8" >
<v-text-field
outlined dense hide-details
v-model="selectedTempalteType.typeName"
class=" form-input ml-3"
readonly
></v-text-field>
</v-col>
</v-row>

<v-row>
<v-col cols="4" ><v-subheader>{{ $t('Template Size') }}</v-subheader></v-col>
<v-col cols="8" >
<v-text-field
v-model="selectedTempalteType.templateSize"
outlined dense hide-details
disabled
class=" form-input ml-3"
></v-text-field>
</v-col>
</v-row> -->

<v-row style="margin-top:-20px"><v-subheader><b>{{ $t('TEMPLATE LIST') }}</b></v-subheader></v-row>
<v-data-table
    v-model="template_list"
    :headers="templateHeaders"
    :items="initaldata"
    item-key="uniqueid"
    :hide-default-footer="true"
    height="417"
    class="tbl-type01 type3"
    :items-per-page="2000"
    @click:row="getpreview"
    single-select

>
    <template slot="no-data">
    <p>{{ $t('No data available') }}</p>
    </template>
    <template  v-slot:[`item.icon`]="{ item }">
       <v-icon v-if="permissionSetValue=='company' && item.templateName!=''" style="color:red" @click="deleteSelectedTemplate(item)"   medium>mdi-close-circle </v-icon>
        <v-icon v-else-if="permissionSetValue=='country'  && item.areaKey!=='company'  && item.templateName!=''" style="color:red" @click="deleteSelectedTemplate(item)"   medium>mdi-close-circle </v-icon>
        <v-icon v-else-if="permissionSetValue=='region' && item.areaKey!=='country' && item.areaKey!=='company' && item.templateName!=''" style="color:red" @click="deleteSelectedTemplate(item)"   medium>mdi-close-circle </v-icon>
        <v-icon v-else-if="permissionSetValue=='city' && item.areaKey!=='country'&& item.areaKey!=='company' && item.areaKey!=='region' && item.templateName!='' " style="color:red" @click="deleteSelectedTemplate(item)"   medium>mdi-close-circle </v-icon>
        <v-icon v-else-if="permissionSetValue=='store' && item.templateName!='' && item.areaKey!=='country'&& item.areaKey!=='company' && item.areaKey!=='region' &&  item.area!=='City'" style="color:red"  @click="deleteSelectedTemplate(item)"  medium>mdi-close-circle </v-icon>
       <span v-else>
        <v-icon  ></v-icon>
        </span>
  <span style="cursor:text"  v-if="item.templateName=='' "><img  width="24" height="24" src="@/assets/img/ico-upload-file.gif"/></span>
    </template>
    <template readonly v-slot:[`item.templateName`]="{item}">
        <v-file-input
            v-if="item.templateName===''"
            accept="text/xml"
             @change="encodeFile"
            @click:clear="clearFile(item)"
            dense
            prepend-icon
            :clearable="false"
            :placeholder="`${$t('Select File')}`"
            class="form-file fileinput"
            :disabled=item.enable

        >
        </v-file-input>
  <span >{{item.templateName}}</span>
    </template>
    <template  v-slot:[`item.areaKey`]="{item}">
  <span >{{$t(item.areaKey)}}</span>
    </template>
</v-data-table>
<v-row style="margin-top:20px;">
    <v-btn :disabled="saveButtonDisabled || btndisabled "   @click="saveSelectedTemplateList()" text class="btn ml-2">{{$t('Save')}}</v-btn>
    <v-btn  :disabled="!saveButtonDisabled || btndisabled"  @click="ReapplySelectedTemplateList()" text class="btn ml-2">{{$t('Reapply')}}</v-btn>
</v-row>
<div>
      <v-dialog
        v-model="UploadOptions"
        persistent
        width="500"
      >
        <v-card class="popup add_store_popup" align="center" justify="center">
          <v-card-title>
      <h3 class="page-title-bar">
        <i class="ico ico-template"></i>{{ $t('Template Upload Option') }}
      </h3>
    </v-card-title>
          <v-row >
            <v-col>
              <v-container style="display: flex;justify-content: center;margin: -50px;">
    <v-radio-group v-model="selectedOption" row>
      <v-radio
        :label="`${$t('Upload Now')}`"
        value="false"
      ></v-radio>
      <v-radio
        :label="`${$t('Upload Later')}`"
        value="true"
      ></v-radio>
    </v-radio-group>
  </v-container>
            </v-col>
          </v-row>
          <v-card-actions class="d-flex justify-center">
            <v-btn @click="handleClickConfirm()" text icon class="btn">{{ $t('Save') }}</v-btn>
            <v-btn @click="UploadOptions=false" text icon class="btn">{{ $t('Cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</div>
</template>
<script>
import EventBus from '@/plugins/eventBus.js'
import l from 'lodash'
import { v4 as uuidv4 } from 'uuid'
// import commons from '@/plugins/commons'
const requests = {
  putTemplateArea: {
    method: 'put',
    url: '/api/common/templates/area'
  },
  ReapplyTemplateArea: {
    method: 'put',
    url: '/api/common/templates/area/reapply'
  },
  getTemplateArea: {
    method: 'get',
    url: '/api/common/templates/area'
  },
  getImagepreviewData: {
    method: 'get',
    url: '/api/common/templates/preview'
  }
}
export default {
  name: 'SelectedTemplateType',
  props: {
    getselectedValue: [Array, Object, String],
    selectedStoreCode: [Array, Object, String],
    selectedArticleId: [Array, Object, String],
    selectedTemplateType: [Array, Object, String, Number],
    checkdeleteFlag: Boolean
  },
  data () {
    return {
      initaldata: [],
      template_list: [],
      selectedTypename: null,
      selectedModelType: null,
      permissionSetValue: '',
      getselectedTemplateRow: '',
      template: [],
      templateFile: null,
      fileDeleteFlag: false,
      deleteTemplateparam: [],
      saveButtonDisabled: true,
      template_file_name: null,
      templateAreaClick: false,
      btndisabled: false,
      UploadOptions: false,
      selectedOption: 'false'
      // reapplybuttondisabled: false
    }
  },
  computed: {
    templateHeaders () {
      return [
        { text: this.$t('Area'), sortable: false, align: 'left', value: 'areaKey', width: '5%' },
        { text: this.$t('Title'), sortable: false, align: 'left', value: 'areaValue', width: '5%' },
        { text: this.$t('File'), sortable: false, align: 'left', value: 'templateName', width: '10%' },
        { text: '', sortable: false, align: 'center', value: 'icon', width: '20%' }

      ]
    },
    selectedTempalteType () {
      return this.getselectedValue ? this.getselectedValue : ''
    },
    selectedareaTopSearch () {
      var areaValue = ''
      var company = this.$store.state.dataStore.selectedStore.company
      var country = this.$store.state.dataStore.selectedStore.country
      var region = this.$store.state.dataStore.selectedStore.region
      var city = this.$store.state.dataStore.selectedStore.city
      var store = this.$store.state.dataStore.selectedStore.code
      if (!l.isEmpty(store)) {
        areaValue = store
      } else if (!l.isEmpty(city)) {
        areaValue = city
      } else if (!l.isEmpty(region)) {
        areaValue = region
      } else if (!l.isEmpty(country)) {
        areaValue = country
      } else if (!l.isEmpty(company)) {
        areaValue = company
      }
      return areaValue
    },

    getdeleteflag () {
      return this.checkdeleteFlag
    }

  },
  watch: {
    selectedTempalteType () {
      this.getTemplateList()
    },
    selectedareaTopSearch () {
      this.getTemplateList()
    },
    getdeleteflag () {
      if (this.checkdeleteFlag) { this.initaldata = [] }
      this.selectedTempalteType.typeName = null
      this.selectedTempalteType.templateSize = null
      this.selectedTempalteType.templateColor = null
    }

  },
  mounted () {
    this.$store.dispatch('auth/getDisabledBtn', '5100').then(flag => {
      this.btndisabled = flag
    })
  },
  methods: {
    // clear template file
    clearFile (item) {
      var removeIndex = this.template.map(function (item) { return item.areaValue }).indexOf(item.areaValue)
      this.template.splice(removeIndex, 1)

      if (this.template.length > 0) {
        this.saveButtonDisabled = false
      } else {
        if (this.fileDeleteFlag === true) {
          this.saveButtonDisabled = false
        } else {
          this.saveButtonDisabled = true
        }
      }
    },
    // get template list
    getTemplateList () {
      this.templateAreaClick = false
      if (this.selectedTempalteType === undefined || this.selectedTempalteType === '') {
        this.initaldata = []
        this.$emit('getpreviewImage', { imageContent: '', areaData: '', templateName: this.selectedTempalteType })
        return
      }
      var areaKey = ''
      var areaValue = ''
      var company = this.$store.state.dataStore.selectedStore.company
      var country = this.$store.state.dataStore.selectedStore.country
      var region = this.$store.state.dataStore.selectedStore.region
      var city = this.$store.state.dataStore.selectedStore.city
      var store = this.$store.state.dataStore.selectedStore.code
      if (!l.isEmpty(store)) {
        areaKey = 'store'
        areaValue = store
      } else if (!l.isEmpty(city)) {
        areaKey = 'city'
        areaValue = city
      } else if (!l.isEmpty(region)) {
        areaKey = 'region'
        areaValue = region
      } else if (!l.isEmpty(country)) {
        areaKey = 'country'
        areaValue = country
      } else if (!l.isEmpty(company)) {
        areaKey = 'company'
        areaValue = company
      }
      this.$emit('getpreviewImage', { imageContent: '', areaData: '', templateName: this.selectedTempalteType.typeName })
      this.$emit('selectedStoreValue', { selectedAreaKey: areaKey, selectedAreaValue: areaValue, randomvalue: uuidv4() })
      this.permissionSetValue = this.$store.state.dataStore.permissionKey
      // this.permissionSetValue = 'region'
      this.initaldata = []
      this.template_list = []
      this.deleteTemplateparam = []
      this.template = []
      this.saveButtonDisabled = true
      // const params = {
      //   typeName: this.selectedTempalteType.typeName,
      //   templateSize: this.selectedTempalteType.templateSize,
      //   areaKey: areaKey,
      //   areaValue: areaValue
      // }
      var params = {}
      if (store) {
        params = {
          typeName: this.selectedTempalteType.typeName,
          templateSize: this.selectedTempalteType.templateSize,
          templateColor: this.selectedTempalteType.templateColor,
          areaKey: areaKey,
          areaValue: areaValue
        }
      }
      if (!store) {
        params = {
          typeName: this.selectedTempalteType.typeName,
          templateSize: this.selectedTempalteType.templateSize,
          templateColor: this.selectedTempalteType.templateColor,
          areaKey: this.$store.state.dataStore.permissionKey,
          areaValue: this.$store.state.dataStore.permissionValue
        }
      }

      const config = { params: params }
      this.$utils
        .callAxios(
          requests.getTemplateArea.method,
          requests.getTemplateArea.url,
          config
        )
        .then(res => {
          this.handleGetTempaltesResponse(res)
        })
        .catch(error => {
          this.handleGetTempaltesError(error)
        })
    },
    handleGetTempaltesResponse (res) {
      if (res.status === 200 && res.data.length > 0) {
        var selectedList = res.data
        this.initaldata = []
        for (var i = 0; i < selectedList.length; i++) {
          var enable = ''
          if (this.permissionSetValue === 'company') {
            enable = false
          }
          if (this.permissionSetValue === 'country') {
            if (selectedList[i].id.areaKey === 'company') {
              enable = true
            } else {
              enable = false
            }
          }
          if (this.permissionSetValue === 'region') {
            if (selectedList[i].id.areaKey === 'company' || selectedList[i].id.areaKey === 'country') {
              enable = true
            } else {
              enable = false
            }
          }
          if (this.permissionSetValue === 'city') {
            if (selectedList[i].id.areaKey === 'company' || selectedList[i].id.areaKey === 'country' || selectedList[i].id.areaKey === 'region') {
              enable = true
            } else {
              enable = false
            }
          }
          if (this.permissionSetValue === 'store') {
            if (selectedList[i].id.areaKey === 'company' || selectedList[i].id.areaKey === 'country' || selectedList[i].id.areaKey === 'region' || selectedList[i].id.areaKey === 'city') {
              enable = true
            } else {
              enable = false
            }
          }
          this.initaldata.push({
            areaKey: selectedList[i].id.areaKey,
            areaValue: selectedList[i].id.areaValue,
            templateName: selectedList[i].templateName,
            icon: false,
            enable: enable,
            // to generate unique id
            uniqueid: `template_key_${i}`
          })
        }
        this.$emit('getareaKeyValue', { getareaDetails: this.initaldata[0] })

        this.template_list.push(this.initaldata[0])
        setTimeout(() =>
          this.getpreview(this.initaldata[0])
        , 1000)
        // if (this.selectedTemplateType === 1) {
        //   this.getpreview(this.initaldata[0])
        // }
      }
    },
    handleGetTempaltesError (error) {
      console.log(error)
      this.initaldata = []
      this.selectedTempalteType.typeName = null
      this.selectedTempalteType.templateSize = null
      this.selectedTempalteType.templateColor = null
    },
    getpreview (item, row) {
      this.getselectedTemplateRow = ''
      this.template_file_name = item.templateName
      if (row !== undefined) {
        row.select(true)
        this.templateAreaClick = true
      }

      this.$emit('getareaKeyValue', { getareaDetails: item })
      this.$emit('areaClickEvent', { templateAreaClick: this.templateAreaClick })
      this.getselectedTemplateRow = item
      // if (item.enable && item.templateName === '') {
      //   this.$emit('getpreviewImage', { imageContent: '', areaData: '' })
      //   return
      // }
      // if (item.templateName === '') {
      //   this.$emit('getpreviewImage', { imageContent: '', areaData: '' })
      //   return
      // }
      // if (this.selectedStoreCode === '' || this.selectedStoreCode === undefined) {
      //   return
      // }
      const params1 = { areaKey: item.areaKey, areaValue: item.areaValue, typeName: this.selectedTempalteType.typeName, storeCode: this.selectedStoreCode, articleId: this.selectedArticleId, fileName: item.templateName }

      this.$emit('getpreviewImage', { imageContent: '', areaData: params1, templateName: this.selectedTempalteType.typeName })

      setTimeout(() => {
        const params = { areaKey: item.areaKey, areaValue: item.areaValue, typeName: this.selectedTempalteType.typeName, storeCode: this.selectedStoreCode, articleId: this.selectedArticleId, fileName: item.templateName }
        const config = { params: params }

        this.changestoreFlag = false
        if (this.selectedTempalteType.typeName !== '') {
          this.$utils
            .callAxios(
              requests.getImagepreviewData.method,
              requests.getImagepreviewData.url,
              config
            )
            .then(res => {
              this.templateAreaClick = false
              const result = res.data
              this.$emit('getpreviewImage', { imageContent: result, areaData: params, templateName: this.selectedTempalteType.typeName })
              this.$emit('areaClickEvent', { templateAreaClick: this.templateAreaClick })
            })
            .catch(error => {
              this.$emit('getpreviewImage', { imageContent: '', areaData: '', templateName: this.selectedTempalteType.typeName })
              console.log(error)
            })
        } else {
          this.$emit('getpreviewImage', { imageContent: '', areaData: '', templateName: this.selectedTempalteType.typeName })
        }
      }, 1000)
    },
    deleteSelectedTemplate (item) {
      this.saveButtonDisabled = false
      const objIndex = this.initaldata.findIndex(obj => obj.areaValue === item.areaValue)
      this.initaldata[objIndex].templateName = ''

      this.fileDeleteFlag = true

      this.deleteTemplateparam.push({
        typeName: this.selectedTempalteType.typeName,
        templateSize: this.selectedTempalteType.templateSize,
        templateColor: this.selectedTempalteType.templateColor,
        areaKey: item.areaKey,
        areaValue: item.areaValue,
        fileName: '',
        templateData: ''

      })
    },
    async encodeFile (event) {
      if (event === null || event === undefined) {
        // this.saveButtonDisabled = true
        return
      }
      // this.reapplybuttondisabled = true

      if (event.type !== 'text/xml' && event.type !== 'application/xml' && event.type !== 'application/xslt+xml') {
        EventBus.$emit(
          'messageAlert',
          this.$t("Template file's filename extension must be XSL.")
        )
        this.$nextTick(() => {
          this.templateFile = null
        })
        return
      }
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          let result = reader.result
          if (result.startsWith('data:')) {
            const base64Idx = 'base64,'
            const strToRemove = result.substring(
              0,
              result.indexOf(base64Idx, 0) + base64Idx.length
            )
            result = result.substring(strToRemove.length, result.length)
          }
          // this.template.templateData = result
          // this.template.fileName = event.name
          if (this.fileDeleteFlag) {
            var removeIndex = this.deleteTemplateparam.map(function (item) { return item.areaValue }).indexOf(this.getselectedTemplateRow.areaValue)
            if (removeIndex !== -1) {
              this.deleteTemplateparam.splice(removeIndex, 1)
            }
            this.deleteTemplateparam.push({
              typeName: this.selectedTempalteType.typeName,
              templateSize: this.selectedTempalteType.templateSize,
              templateColor: this.selectedTempalteType.templateColor,
              areaKey: this.getselectedTemplateRow.areaKey,
              areaValue: this.getselectedTemplateRow.areaValue,
              templateData: result,
              fileName: event.name
            })
          } else {
            var removeIndex1 = this.template.map(function (item) { return item.areaValue }).indexOf(this.getselectedTemplateRow.areaValue)
            if (removeIndex1 !== -1) {
              this.template.splice(removeIndex1, 1)
            }
            this.template.push({
              typeName: this.selectedTempalteType.typeName,
              templateSize: this.selectedTempalteType.templateSize,
              templateColor: this.selectedTempalteType.templateColor,
              areaKey: this.getselectedTemplateRow.areaKey,
              areaValue: this.getselectedTemplateRow.areaValue,
              templateData: result,
              fileName: event.name
            })
          }
          if (this.template.length > 0) {
            this.saveButtonDisabled = false
          } else {
            if (this.fileDeleteFlag === true) {
              this.saveButtonDisabled = false
            } else {
              this.saveButtonDisabled = true
            }
          }
        },
        false
      )
      if (event) {
        await reader.readAsDataURL(event)
      }
    },
    // save method
    saveSelectedTemplateList () {
      this.selectedOption = 'false'
      this.UploadOptions = true
    },
    handleClickConfirm () {
      var template = this.template.concat(this.deleteTemplateparam)
      const data = this.selectedOption.toLowerCase()
      var finalFlag = null
      if (data === 'true') {
        finalFlag = true
      } else {
        finalFlag = false
      }
      const params = { updateLater: finalFlag }
      const config = { params: params }
      console.log(config)
      this.$utils
        .callAxiosWithBody(
          requests.putTemplateArea.method,
          requests.putTemplateArea.url,
          template,
          config
        )
        .then(res => {
          const result = res.data
          EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          this.template = []
          this.deleteTemplateparam = []
          this.getselectedTemplateRow = ''
          this.fileDeleteFlag = false
          this.saveButtonDisabled = true
          if (result.responseCode === '200') {
            this.getTemplateList()
            this.UploadOptions = false
          }
          // this.template = []
        })
        .catch(error => {
          var errormsg = error.response
          this.template = []
          this.deleteTemplateparam = []
          this.fileDeleteFlag = false
          this.getTemplateList()
          this.UploadOptions = false
          this.saveButtonDisabled = true
          if (errormsg.status === 405) {
            EventBus.$emit('messageAlert', this.$t(errormsg.data.responseMessage))
          } else {
            EventBus.$emit('messageAlert', this.$t('Fail to update template area'))
          }
        })
    },

    // ReApply
    ReapplySelectedTemplateList () {
      // console.log(this.template_file_name)
      if (this.template_file_name === null || this.template_file_name === undefined || this.template_file_name === '') {
        EventBus.$emit('messageAlert', this.$t('There is no file to Reapply'))
        return
      }
      const params = {
        typeName: this.selectedTempalteType.typeName,
        templateSize: this.selectedTempalteType.templateSize,
        templateColor: this.selectedTempalteType.templateColor,
        areaKey: this.getselectedTemplateRow.areaKey,
        areaValue: this.getselectedTemplateRow.areaValue,
        fileName: this.template_file_name,
        updateLater: false

      }
      // console.log(params)
      this.$utils
        .callAxiosWithBody(
          requests.ReapplyTemplateArea.method,
          requests.ReapplyTemplateArea.url,
          params
        )
        .then(res => {
          const result = res.data
          EventBus.$emit('messageAlert', this.$t(result.responseMessage))
          this.getTemplateList()
          // this.reapplybuttondisabled = true
          // this.template = []
        })
        .catch(error => {
          var errormsg = error.response
          this.getTemplateList()
          if (errormsg.status === 405) {
            EventBus.$emit('messageAlert', this.$t(errormsg.data.responseMessage))
          } else {
            EventBus.$emit('messageAlert', this.$t('Fail to update template area'))
          }
        })
    }
  }
}
</script>
<style scoped>
::v-deep tr.v-data-table__selected {
  background:#09263f !important
}
::v-deep tr.v-data-table__selected td{
  color:#ffffff !important
}
::v-deep tr.v-data-table__selected td .v-input__control{
  color:#ffffff !important
}
::v-deep tr.v-data-table__selected td .v-file-input .v-file-input__text--placeholder{
  color:#ffffff !important
}
::v-deep tr.v-data-table__selected td .v-file-input .v-file-input__text{
  color:#ffffff !important
}

::v-deep .type3{
    border: 1px solid #001e38;
}
::v-deep  .fileinput{
  font-size: 12px !important;
}
::v-deep  .form-file.v-input--is-disabled .v-input__control{
  background: transparent !important;
}
</style>
