<template>
  <div>
    <v-dialog v-model="show" width="900" persistent scrollable>
      <v-card class="popup add_store_popup" style="padding: 30px;">
        <v-card-title>
          <h3 class="page-title-bar">
            <i class="ico ico-gateway"></i>{{ $t("PowerRail Label Details") }}
          </h3>
          <div class="d-grid" style="position: absolute; right: 90px; top: 58px;color: darkgrey;">
            <p class="info-text" style="font-size: 14px;">
              {{ $t("PowerRail ID") }} :  {{powerRailCode}}
            </p>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="searchArea col-3" style="margin-top: -15px;">
            <div class="row">
              <div class="d-flex col-12 align-baseline px-0 py-0">
                <!-- Sensor Name -->
                <v-text-field
                  :label="$t('Label ID')"
                  outlined
                  hide-details
                  dense
                  :placeholder="$t('Input Label ID')"
                  v-model="searchBy.LabelCode"
                  clearable
                  class="form-select col-3"
                >
                </v-text-field>
                <v-select
                  :label="$t('Status')"
                  :placeholder="$t('Select Status')"
                  outlined
                  dense
                  hide-details
                  v-model="searchBy.status"
                  :items="StausList"
                  item-text="text"
                  item-value="val"
                  clearable
                  class="form-select col-3 ml-3"
                >
                </v-select>
                <v-btn class="btn type-search ml-3" text @click="paging(1)"
                  >{{ $t("Search") }}
                </v-btn>
                <v-btn class="btn type-search ml-3" text @click="clearSearch"
                  >{{ $t("Clear") }}
                </v-btn>
              </div>
            </div>
          </div>
          <div class="col-12 pl-0 pb-0 pt-0 pr-0 d-flex">
              <!-- SEARCH AREA  -->

            <div class="col-12 pl-0">
                <v-data-table  :headers="PowerRailLabelInfoHeaders"
                    :hide-default-footer="true" :items="powerrailsLabelList" :item-class="getRowClass" :singleSelect="false"
                    :options.sync="options" :server-items-length="totalpowerrails" class="tbl-type01"
                    item-key="name">
                    <!-- No Data Slot -->
                    <template slot="no-data">
                        <p>
                            {{ $t('No data available') }}
                        </p>
                    </template>
                </v-data-table>
            </div>
        </div>
             <!-- Pagination Component -->
             <Pagination @paging="paging($event)" :pageInfoText="pageInfoText" :pageIndex="pageIndex"
            :rowPerPage="rowPerPage" :goToPageInput="goToPageInput" :totalPages="totalPages"
            @updatePage="updatePage($event)"></Pagination>
        <div class="table-options">
            <div>
                <v-btn class="btn ml-2" @click="show = false;reset()" text>{{
                    $t('Close') }}
                </v-btn>
            </div>

        </div>
        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import EventBus from '@/plugins/eventBus.js'
import commons from '@/plugins/commons'
import Pagenation from '@/mixins/Pagenation'
import Pagination from '@/components/pagination/Pagination.vue'
import codes from '@/plugins/codes'

export default {
  name: 'GatewayInfo',
  mixins: [Pagenation],
  components: {
    Pagination
  },
  props: {
    value: Boolean,
    powerRailCode: String,
    ControllerID: Number
  },
  data () {
    return {
      dialog: false,
      message: '',
      page: 1,
      pageIndex: 1,
      rowPerPage: '10',
      pageCount: 0,
      totalPages: 0,
      totalVisiblePages: 5,
      totalpowerrails: null,
      options: {},
      pageInfoText: '',
      powerrailsLabelList: [],
      searchBy: {
        LabelCode: null,
        status: null
      },
      StausList: [
        {
          text: this.$t('SUCCESS'),
          val: 'SUCCESS'
        },
        {
          text: this.$t('UNASSIGNED'),
          val: 'UNASSIGNED'
        },
        {
          text: this.$t('PROCESSING'),
          val: 'PROCESSING'
        },
        {
          text: this.$t('UNASSIGNING'),
          val: 'UNASSIGNING'
        },
        {
          text: this.$t('TIMEOUT'),
          val: 'TIMEOUT'
        }
      ]
    }
  },
  computed: {
    PowerRailLabelInfoHeaders () {
      return [
        {
          text: this.$t('LABEL ID'),
          value: 'code',
          align: 'left',
          width: '20%',
          disabled: true,
          sortable: true
        },
        {
          text: this.$t('LABEL MAC'),
          value: 'macAddress',
          align: 'left',
          width: '20%',
          disabled: false,
          sortable: true
        },
        {
          text: this.$t('TYPE'),
          value: 'type',
          disabled: true,
          width: '20%',
          sortable: true
        },

        {
          text: this.$t('STATUS'),
          align: 'left',
          value: 'state',
          width: '20%',
          sortable: true,
          disabled: true
        },

        {
          text: this.$t('LAST CONNECTION TIME'),
          align: 'left',
          value: 'statusUpdateTime',
          width: '20%',
          sortable: true,
          disabled: true
        }

      ]
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }

  },
  watch: {
    show (newVal) {
      if (newVal) {
        this.togetPowerRailsLabelList(1)
      }
    },
    options: function () {
      this.togetPowerRailsLabelList(this.page)
    },
    pageIndex: {
      handler (page) {
        this.goToPageInput = page
      }
    }
  },

  mounted () {
    this.powerrailsLabelList = []
  },
  methods: {
    // Perform pagination by updating the gateway list for the specified page
    paging (input) {
      this.goToPageInput = input
      this.togetPowerRailsLabelList(input)
    },
    clearSearch () {
      this.searchBy = {
        LabelCode: null,
        status: null
      }
      this.paging(1)
    },
    reset () {
      this.searchBy = {
        LabelCode: null,
        status: null
      }
    },
    // Update the number of items per page and update the gateway list accordingly
    updatePage (event) {
      this.rowPerPage = event.toString()
      this.paging(1)
    },
    getRowClass () {
      return codes.ROW_CLASS
    },
    getPageInfoText: function (headers) {
      if (headers['x-totalelements'] === 0) return '0 to 0, 0' + ' ' + this.$t('in total')
      return `${headers['x-number'] * 1 * headers['x-size'] + 1}
      ${this.$t('to')} ${(headers['x-number'] * 1 * headers['x-size']) + headers['x-total-count'] * 1},
       ${headers['x-totalelements'] * 1}` + ' ' + this.$t('in total')
    },
    buildSearchParams (params, searchBy) {
      if (searchBy.LabelCode !== null) {
        params.labelId = searchBy.LabelCode
      }
      if (!commons.isNull(searchBy.status)) params.status = searchBy.status
      return params
    },
    getPowerRailSortKey (sortKey) {
      switch (sortKey) {
        case 'statusUpdateTime': sortKey = 'statusUpdateTime'
          break
        case 'controllerId': sortKey = 'controllerId'
          break
        default: break
      }
      return sortKey
    },
    togetPowerRailsLabelList (page) {
      var params = {}
      const { sortBy, sortDesc, itemsPerPage } = this.options
      if (itemsPerPage > 0) params.size = this.rowPerPage
      params = this.buildSearchParams(params, this.searchBy)
      let sort = null
      if (commons.isSorting(sortBy, sortDesc)) {
        const sortKey = this.getPowerRailSortKey(sortBy[0])
        const sortOrder = sortDesc[0]
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      params.company = this.$store.state.auth.user.company
      params.controllerId = this.ControllerID
      if (page !== null) params.page = page - 1
      const config = { params }
      this.$utils
        .callAxios(
          codes.requests.toGetPowerRailControllerConnectedDev.method,
          codes.requests.toGetPowerRailControllerConnectedDev.url,
          config
        )
        .then(res => {
          const headers = res.headers
          if (res.status === 200) {
            this.powerrailsLabelList = res.data.powerRailEndDevices.map((obj, idx) => {
              return {
                serialNo: headers['x-number'] * 1 * headers['x-size'] + idx + 1,
                ...obj,
                statusUpdateTime: commons.convertDate(obj.statusUpdateTime),
                macAddress: obj.macAddress ? obj.macAddress.split('_').join(' ') : obj.macAddress
              }
            })
            this.totalpowerrails = this.powerrailsLabelList.length
            // Pagination
            this.pageInfoText = this.getPageInfoText(res.headers)
            if (commons.isValidStr(res.headers['x-totalpages'])) {
              this.totalPages = res.headers['x-totalpages'] * 1
            }
            this.page = page
            this.pageIndex = res.headers['x-number'] * 1 + 1
            this.pageCount = res.headers['x-totalpages'] * 1
          } else {
            this.powerrailsLabelList = []
            this.totalpowerrails = this.powerrailsLabelList.length
            const headers = {}
            headers['x-totalelements'] = 0
            this.pageInfoText = this.getPageInfoText(headers)
            this.totalPages = 0
          }
        })
        .catch(error => {
          console.log(error)
          this.powerrailsLabelList = []
          this.totalpowerrails = this.powerrailsLabelList.length
          const headers = {}
          headers['x-totalelements'] = 0
          this.pageInfoText = this.getPageInfoText(headers)
          this.totalPages = 0
          EventBus.$emit(
            'messageAlert',
            this.$t(error.response.data.returnMsg),
            'error'
          )
        })
    }

  }
}
</script>
