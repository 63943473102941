var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      key: _vm.newTemplateGroupVisible,
      attrs: { width: "1000" },
      on: { "click:outside": _vm.cancelNewTemplateGroup },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "btn-s",
                      attrs: { disabled: _vm.newBtnDisabled, text: "" }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("Add")))]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.newTemplateGroupVisible,
        callback: function($$v) {
          _vm.newTemplateGroupVisible = $$v
        },
        expression: "newTemplateGroupVisible"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("h3", { staticClass: "page-title-bar" }, [
            _c("i", { staticClass: "ico ico-template" }),
            _vm._v(_vm._s(_vm.$t("ADD TEMPLATE GROUP")) + " ")
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "section",
                    { staticClass: "searchArea" },
                    [
                      _c("v-select", {
                        staticClass: "form-select ml-2",
                        staticStyle: { "padding-right": "13px !important" },
                        attrs: {
                          items: _vm.tagTypes,
                          "item-text": "name",
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          placeholder: "" + _vm.$t("Select template size"),
                          label: _vm.$t("Template Size")
                        },
                        on: { change: _vm.selectTemplateSearch },
                        model: {
                          value: _vm.searchtemplateTypelist,
                          callback: function($$v) {
                            _vm.searchtemplateTypelist = $$v
                          },
                          expression: "searchtemplateTypelist"
                        }
                      }),
                      _c("v-select", {
                        staticClass: "form-select ml-2",
                        staticStyle: { "padding-right": "13px !important" },
                        attrs: {
                          items: _vm.tempColorType,
                          "item-text": "name",
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          placeholder: "" + _vm.$t("Select template color"),
                          label: _vm.$t("Template Color")
                        },
                        on: { change: _vm.selectTemplateSearch },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function(data) {
                              return [
                                _c(
                                  "div",
                                  { staticStyle: { "font-size": "12px" } },
                                  [
                                    data.item == "All"
                                      ? _c("div", [_vm._v(_vm._s(data.item))])
                                      : _vm._e(),
                                    data.item != "All"
                                      ? _c("div", [
                                          data.item.length > 0
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#676565"
                                                  }
                                                },
                                                [_vm._v(_vm._s(data.item[0]))]
                                              )
                                            : _vm._e(),
                                          data.item.length > 1
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#d1c3c3"
                                                  }
                                                },
                                                [_vm._v(_vm._s(data.item[1]))]
                                              )
                                            : _vm._e(),
                                          data.item.length > 2
                                            ? _c(
                                                "span",
                                                {
                                                  style:
                                                    data.item[2] === "R"
                                                      ? "color:red"
                                                      : data.item[2] === "P"
                                                      ? "color:pink"
                                                      : data.item[2] === "W"
                                                      ? "color:white"
                                                      : "color:yellow"
                                                },
                                                [_vm._v(_vm._s(data.item[2]))]
                                              )
                                            : _vm._e(),
                                          data.item.length > 3
                                            ? _c(
                                                "span",
                                                {
                                                  style:
                                                    data.item[3] === "Y"
                                                      ? "color:yellow"
                                                      : data.item[3] === "W"
                                                      ? "color:white"
                                                      : "color:orange"
                                                },
                                                [_vm._v(_vm._s(data.item[3]))]
                                              )
                                            : _vm._e(),
                                          data.item.length > 4
                                            ? _c(
                                                "span",
                                                {
                                                  style:
                                                    data.item[4] === "O"
                                                      ? "color:orange"
                                                      : "color:green"
                                                },
                                                [_vm._v(_vm._s(data.item[4]))]
                                              )
                                            : _vm._e(),
                                          data.item.length > 5
                                            ? _c(
                                                "span",
                                                {
                                                  style:
                                                    data.item[5] === "G" &&
                                                    data.item.length === 7
                                                      ? "color:green"
                                                      : data.item[5] === "G"
                                                      ? "color:darkgrey"
                                                      : "color:blue"
                                                },
                                                [_vm._v(_vm._s(data.item[5]))]
                                              )
                                            : _vm._e(),
                                          data.item.length > 6
                                            ? _c(
                                                "span",
                                                {
                                                  style:
                                                    data.item[6] === "B"
                                                      ? "color:blue"
                                                      : "color:darkgrey"
                                                },
                                                [_vm._v(_vm._s(data.item[6]))]
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function(data) {
                              return [
                                _c(
                                  "div",
                                  { staticStyle: { "font-size": "12px" } },
                                  [
                                    data.item == "All"
                                      ? _c("div", [_vm._v(_vm._s(data.item))])
                                      : _vm._e(),
                                    data.item != "All"
                                      ? _c("div", [
                                          data.item.length > 0
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#676565"
                                                  }
                                                },
                                                [_vm._v(_vm._s(data.item[0]))]
                                              )
                                            : _vm._e(),
                                          data.item.length > 1
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#d1c3c3"
                                                  }
                                                },
                                                [_vm._v(_vm._s(data.item[1]))]
                                              )
                                            : _vm._e(),
                                          data.item.length > 2
                                            ? _c(
                                                "span",
                                                {
                                                  style:
                                                    data.item[2] === "R"
                                                      ? "color:red"
                                                      : data.item[2] === "P"
                                                      ? "color:pink"
                                                      : data.item[2] === "W"
                                                      ? "color:white"
                                                      : "color:yellow"
                                                },
                                                [_vm._v(_vm._s(data.item[2]))]
                                              )
                                            : _vm._e(),
                                          data.item.length > 3
                                            ? _c(
                                                "span",
                                                {
                                                  style:
                                                    data.item[3] === "Y"
                                                      ? "color:yellow"
                                                      : data.item[3] === "W"
                                                      ? "color:white"
                                                      : "color:orange"
                                                },
                                                [_vm._v(_vm._s(data.item[3]))]
                                              )
                                            : _vm._e(),
                                          data.item.length > 4
                                            ? _c(
                                                "span",
                                                {
                                                  style:
                                                    data.item[4] === "O"
                                                      ? "color:orange"
                                                      : "color:green"
                                                },
                                                [_vm._v(_vm._s(data.item[4]))]
                                              )
                                            : _vm._e(),
                                          data.item.length > 5
                                            ? _c(
                                                "span",
                                                {
                                                  style:
                                                    data.item[5] === "G" &&
                                                    data.item.length === 7
                                                      ? "color:green"
                                                      : data.item[5] === "G"
                                                      ? "color:darkgrey"
                                                      : "color:blue"
                                                },
                                                [_vm._v(_vm._s(data.item[5]))]
                                              )
                                            : _vm._e(),
                                          data.item.length > 6
                                            ? _c(
                                                "span",
                                                {
                                                  style:
                                                    data.item[6] === "B"
                                                      ? "color:blue"
                                                      : "color:darkgrey"
                                                },
                                                [_vm._v(_vm._s(data.item[6]))]
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.searchtemplateColor,
                          callback: function($$v) {
                            _vm.searchtemplateColor = $$v
                          },
                          expression: "searchtemplateColor"
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "form-input",
                        attrs: {
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          placeholder: "" + _vm.$t("Input Type Name"),
                          clearable: "",
                          label: _vm.$t("Type Name")
                        },
                        on: { keydown: _vm.clearTabindex },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-data-table",
                    {
                      staticClass: "tbl-type01 flat-type02 style03 grayline ",
                      staticStyle: {
                        "padding-left": "10px",
                        "padding-top": "30px",
                        cursor: "pointer"
                      },
                      attrs: {
                        headers: _vm.templateHeadersLeft,
                        items: _vm.templateTypeList,
                        "item-key": "typeName",
                        "hide-default-footer": true,
                        height: "250",
                        "items-per-page": 2000,
                        "single-select": "",
                        search: _vm.search
                      },
                      on: { "click:row": _vm.selectTemplateype },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item.templateColor",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                item.templateColor.length > 0
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#676565" } },
                                      [_vm._v(_vm._s(item.templateColor[0]))]
                                    )
                                  : _vm._e(),
                                item.templateColor.length > 1
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#d1c3c3" } },
                                      [_vm._v(_vm._s(item.templateColor[1]))]
                                    )
                                  : _vm._e(),
                                item.templateColor.length > 2
                                  ? _c(
                                      "span",
                                      {
                                        style:
                                          item.templateColor[2] === "R"
                                            ? "color:red"
                                            : item.templateColor[2] === "P"
                                            ? "color:pink"
                                            : item.templateColor[2] === "W"
                                            ? "color:white"
                                            : "color:yellow"
                                      },
                                      [_vm._v(_vm._s(item.templateColor[2]))]
                                    )
                                  : _vm._e(),
                                item.templateColor.length > 3
                                  ? _c(
                                      "span",
                                      {
                                        style:
                                          item.templateColor[3] === "Y"
                                            ? "color:yellow"
                                            : item.templateColor[3] === "W"
                                            ? "color:white"
                                            : "color:orange"
                                      },
                                      [_vm._v(_vm._s(item.templateColor[3]))]
                                    )
                                  : _vm._e(),
                                item.templateColor.length > 4
                                  ? _c(
                                      "span",
                                      {
                                        style:
                                          item.templateColor[4] === "O"
                                            ? "color:orange"
                                            : "color:green"
                                      },
                                      [_vm._v(_vm._s(item.templateColor[4]))]
                                    )
                                  : _vm._e(),
                                item.templateColor.length > 5
                                  ? _c(
                                      "span",
                                      {
                                        style:
                                          item.templateColor[5] === "G" &&
                                          item.templateColor.length === 7
                                            ? "color:green"
                                            : item.templateColor[5] === "G"
                                            ? "color:grey"
                                            : "color:blue"
                                      },
                                      [_vm._v(_vm._s(item.templateColor[5]))]
                                    )
                                  : _vm._e(),
                                item.templateColor.length > 6
                                  ? _c(
                                      "span",
                                      {
                                        style:
                                          item.templateColor[6] === "B"
                                            ? "color:blue"
                                            : "color:green"
                                      },
                                      [_vm._v(_vm._s(item.templateColor[6]))]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("template", { slot: "no-data" }, [
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("No data available")) + " "
                          )
                        ])
                      ])
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "red",
                      staticStyle: {
                        height: "248px",
                        "margin-top": "100px",
                        width: "1px"
                      },
                      attrs: { text: "", outlined: "", dark: "" },
                      on: { click: _vm.handleAddClick }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Add")))]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c(
                    "section",
                    { staticClass: "searchArea" },
                    [
                      _c("v-text-field", {
                        staticClass: "form-input",
                        attrs: {
                          placeholder: "" + _vm.$t("Input the group name"),
                          label: _vm.$t("TEMPLATE GROUP NAME"),
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          clearable: ""
                        },
                        on: {
                          keypress: _vm.handleKeyUp,
                          keydown: _vm.clearTabindex
                        },
                        model: {
                          value: _vm.groupName,
                          callback: function($$v) {
                            _vm.groupName = $$v
                          },
                          expression: "groupName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-data-table",
                    {
                      staticClass: "tbl-type01 flat-type02 style03 grayline ",
                      staticStyle: {
                        "padding-left": "10px",
                        "padding-top": "30px"
                      },
                      attrs: {
                        headers: _vm.templateHeaders,
                        items: _vm.addTemplateTypeLIst,
                        "item-key": "sno",
                        "hide-default-footer": true,
                        height: "250",
                        "items-per-page": 2000,
                        "single-select": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item.templateColor",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                item.templateColor.length > 0
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#676565" } },
                                      [_vm._v(_vm._s(item.templateColor[0]))]
                                    )
                                  : _vm._e(),
                                item.templateColor.length > 1
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "#d1c3c3" } },
                                      [_vm._v(_vm._s(item.templateColor[1]))]
                                    )
                                  : _vm._e(),
                                item.templateColor.length > 2
                                  ? _c(
                                      "span",
                                      {
                                        style:
                                          item.templateColor[2] === "R"
                                            ? "color:red"
                                            : item.templateColor[2] === "P"
                                            ? "color:pink"
                                            : item.templateColor[2] === "W"
                                            ? "color:white"
                                            : "color:yellow"
                                      },
                                      [_vm._v(_vm._s(item.templateColor[2]))]
                                    )
                                  : _vm._e(),
                                item.templateColor.length > 3
                                  ? _c(
                                      "span",
                                      {
                                        style:
                                          item.templateColor[3] === "Y"
                                            ? "color:yellow"
                                            : item.templateColor[3] === "W"
                                            ? "color:white"
                                            : "color:orange"
                                      },
                                      [_vm._v(_vm._s(item.templateColor[3]))]
                                    )
                                  : _vm._e(),
                                item.templateColor.length > 4
                                  ? _c(
                                      "span",
                                      {
                                        style:
                                          item.templateColor[4] === "O"
                                            ? "color:orange"
                                            : "color:green"
                                      },
                                      [_vm._v(_vm._s(item.templateColor[4]))]
                                    )
                                  : _vm._e(),
                                item.templateColor.length > 5
                                  ? _c(
                                      "span",
                                      {
                                        style:
                                          item.templateColor[5] === "G" &&
                                          item.templateColor.length === 7
                                            ? "color:green"
                                            : item.templateColor[5] === "G"
                                            ? "color:grey"
                                            : "color:blue"
                                      },
                                      [_vm._v(_vm._s(item.templateColor[5]))]
                                    )
                                  : _vm._e(),
                                item.templateColor.length > 6
                                  ? _c(
                                      "span",
                                      {
                                        style:
                                          item.templateColor[6] === "B"
                                            ? "color:blue"
                                            : "color:green"
                                      },
                                      [_vm._v(_vm._s(item.templateColor[6]))]
                                    )
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "item.icon",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { medium: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteSelectedTemplate(item)
                                      }
                                    }
                                  },
                                  [_vm._v("mdi-close-circle ")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.selectedGroupedTemplates,
                        callback: function($$v) {
                          _vm.selectedGroupedTemplates = $$v
                        },
                        expression: "selectedGroupedTemplates"
                      }
                    },
                    [
                      _c("template", { slot: "no-data" }, [
                        _c("p", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("No data available")) + " "
                          )
                        ])
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.handleSaveClick }
                },
                [_vm._v(_vm._s(_vm.$t("Save")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.cancelNewTemplateGroup }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }