var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chart-container" },
    [
      _vm.noData
        ? _c("div", { staticClass: "no-data" }, [_vm._v("No Data available")])
        : _c("highcharts", {
            ref: "stackchart",
            staticClass: "hc",
            attrs: { options: _vm.chartOptions }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }