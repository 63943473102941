// Axios 요청에 관련된 기능을 담은 모듈.
// import main from '@/main'
import store from '@/store'
import configs from '@/plugins/configs'
import EventBus from '@/plugins/eventBus'
import codes from '@/plugins/codes'
import Cookies from 'js-cookie'

// import { getCloudSessionValidation, getSessionValidation } from '@/plugins/healthCheck'
// import router from '../router'

// const axios = require('axios')

// // Interceptors
// axios.interceptors.request.use(
//   // 요청 인터셉터
//   function (config) {
//     // 요청 전에 로딩 오버레이 띄우기
//     EventBus.$emit('loadingOn')
//     return config
//   },
//   function (error) {
//     // 에러 나면 로딩 끄기
//     EventBus.$emit('loadingOff')
//     return Promise.reject(error)
//   }
// )
// axios.interceptors.response.use(
//   // 응답 인터셉터
//   function (response) {
//     // 응답 받으면 로딩 끄기
//     if (response) {
//       EventBus.$emit('loadingOff')
//       return response
//     }
//   },
//   function (error) {
//     // 응답 에러 시에도 로딩 끄기
//     EventBus.$emit('loadingOff')
//     return Promise.reject(error)
//   }
// )

// for Multiple API Request at the time (Loading)
const axios = require('axios')
var reqresArr = []
// Interceptors
axios.interceptors.request.use(
  // 요청 인터셉터
  function (config) {
    // 요청 전에 로딩 오버레이 띄우기
    if (config) {
      reqresArr.push(config.url)
      EventBus.$emit('loadingOn')
      return config
    }
  },
  function (error) {
    // 에러 나면 로딩 끄기
    reqresArr = []
    EventBus.$emit('loadingOff')
    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
  // 응답 인터셉터
  function (response) {
    // 응답 받으면 로딩 끄기
    if (response) {
      reqresArr.forEach((el, index) => {
        if (el === response.config.url) {
          reqresArr.splice(index, 1)
        }
      })
      if (reqresArr.length === 0) {
        EventBus.$emit('loadingOff')
      }
      return response
    }
  },
  function (error) {
    // 응답 에러 시에도 로딩 끄기
    reqresArr = []
    EventBus.$emit('loadingOff')
    return Promise.reject(error)
  }
)

// =======

const Utils = (() => {
  return {
    updateSelectedStoreKey (key, value) {
      const selectedStore = store.state.dataStore.selectedStore
      selectedStore[key] = value
    },
    getCompany (company) {
      const config = { params: {} }
      if (company) config.params.company = company
      return this.callAxios(
        codes.requests.getAreas.method,
        codes.requests.getAreas.url,
        config
      ).then(res => {
        if (res.status === 204) return null
        return res.data.areaParamList.pop().company
      })
    },
    getCompanies (company) {
      const config = { params: {} }
      if (company) config.params.company = company
      return this.callAxios(
        codes.requests.getAreas.method,
        codes.requests.getAreas.url,
        config
      ).then(res => {
        if (res.status === 204) return null
        return res.data.areaParamList.map(area => area.company)
      })
    },
    getCustomerInfo (stationCode, usage) {
      const config = {
        params: {
          usage // Default || Bakery
        }
      }
      return this.callAxios(
        codes.requests.getCustomerInfo.method,
        `${codes.requests.getCustomerInfo.url}/${stationCode}`,
        config
      )
        .then(res => {
          return res.data.customerInfoList
        })
        .catch(error => {
          console.debug(`Could not find customer information. error: ${error}`)
        })
    },
    getRealUrl (url) {
      return service.getRealUrl(url)
    },
    async callAxios (method, url, config = {}) {
      url = service.getRealUrl(url)
      config.headers = await service.getRequestHeader()
      return new Promise(function (resolve, reject) {
        if (method.toLowerCase() === 'get') {
          service
            .getWithAxios(url, config)
            .then(res => {
              return resolve(res)
            })
            .catch(error => {
              if (!url.endsWith('common/logout')) service.checkSessionOut(error)
              if (!url.endsWith('common/logout')) {
                if (
                  (error.response && error.response.status === 423) ||
                  error.message === 'Network Error'
                ) {
                  EventBus.$emit('autoLogout')
                }
              }
              reject(error)
            })
        } else if (method.toLowerCase() === 'delete') {
          service
            .deleteWithAxios(url, config)
            .then(res => {
              return resolve(res)
            })
            .catch(error => {
              if (!url.endsWith('common/logout')) {
                if (
                  (error.response && error.response.status === 423) ||
                  error.message === 'Network Error'
                ) {
                  EventBus.$emit('autoLogout')
                }
              }
              service.checkSessionOut(error)
              reject(error)
            })
        }
      })
    },
    async callAxiosWithBody (method, url, data, config = {}) {
      url = service.getRealUrl(url)
      config.headers = await service.getRequestHeader()
      return new Promise(function (resolve, reject) {
        if (method.toLowerCase() === 'post') {
          service
            .postWithAxios(url, data, config)
            .then(res => {
              return resolve(res)
            })
            .catch(error => {
              if (!url.endsWith('common/login')) service.checkSessionOut(error)
              if (!url.endsWith('common/logout')) {
                if (
                  (error.response && error.response.status === 423) ||
                  error.message === 'Network Error'
                ) {
                  EventBus.$emit('autoLogout')
                }
              }
              reject(error)
            })
        } else if (method.toLowerCase() === 'put') {
          service
            .putWithAxios(url, data, config)
            .then(res => {
              return resolve(res)
            })
            .catch(error => {
              service.checkSessionOut(error)
              if (!url.endsWith('common/logout')) {
                if (
                  (error.response && error.response.status === 423) ||
                  error.message === 'Network Error'
                ) {
                  EventBus.$emit('autoLogout')
                }
              }
              reject(error)
            })
        } else if (method.toLowerCase() === 'patch') {
          service
            .patchWithAxios(url, data, config)
            .then(res => {
              return resolve(res)
            })
            .catch(error => {
              service.checkSessionOut(error)
              if (!url.endsWith('common/logout')) {
                if (
                  (error.response && error.response.status === 423) ||
                  error.message === 'Network Error'
                ) {
                  EventBus.$emit('autoLogout')
                }
              }
              reject(error)
            })
        }
      })
    },
    getBlobFromUrl (url) {
      const vuex = JSON.parse(localStorage.getItem('vuex'))
      // const user = vuex.auth.user

      // For cookies
      const session = Cookies.get('sessionId')

      const headers = {
        sessionId: session,
        'api-key': configs.ApiKey,
        Authorization: `Bearer ${vuex.auth.tokenAPI}`
      }
      if (process.env.VUE_APP_PRODUCTION_TYPE === 'cloud') {
        headers.Authorization = `Bearer ${vuex.auth.tokenAPI.accessToken}`
      }
      if (process.env.VUE_APP_PRODUCTION_TYPE === 'local-server') {
        headers.Authorization = `Bearer ${vuex.auth.tokenAPI}`
        // hearder['api-key'] = configs.ApiKey
        // hearder.sessionId = `${vuex.auth.tokenAPI}`
      }
      return fetch(url, { method: 'GET', headers })
        .then(response => response.blob())
        .then(blob => blob)
    }
  }
})()

const service = {
  productionType: process.env.VUE_APP_PRODUCTION_TYPE,
  getRealUrl (url) {
    if (url.startsWith('/api')) {
      return url.replace('/api', configs.ApiUrl)
    }
    if (url.startsWith('/metro')) {
      url = configs.ServerAddress + url
    }
    if (url.startsWith('/sensor')) {
      url = configs.ServerAddress + url
    }
    console.log(url)
    return url
  },
  async getCallApi_Get (url, params) {
    return axios
      .get(url, { params: params }, await service.getRequestHeader())
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(ex => {
        return Promise.reject(ex)
      })
  },
  getWithAxios (url, config) {
    // for keycloak
    if (config.headers.Authorization !== 'Bearer undefined') {
      return axios
        // .get(url, config)
        .get(url, {
          params: config.params,
          headers: config.headers ? config.headers : '',
          paramsSerializer: (params) => {
            let result = ''
            Object.keys(params).forEach(key => {
              if (params[key] !== undefined && params[key] !== null) {
                result += `${key}=${encodeURIComponent(params[key])}&`
              }
            })
            return result.substring(0, result.length - 1)
          }
        })
        .then(response => {
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    } else { // end
      return axios
        // .get(url, config)
        .get(url, {
          params: config.params,
          headers: config.headers ? config.headers : '',
          paramsSerializer: (params) => {
            let result = ''
            Object.keys(params).forEach(key => {
              if (params[key] !== undefined && params[key] !== null) {
                result += `${key}=${encodeURIComponent(params[key])}&`
              }
            })
            return result.substring(0, result.length - 1)
          }
        })
        .then(response => {
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    }
  },

  deleteWithAxios (url, config) {
    if (!config.data) {
      return axios
        .delete(url, {
          params: config.params,
          headers: config.headers ? config.headers : '',
          paramsSerializer: (params) => {
            let result = ''
            Object.keys(params).forEach(key => {
              if (params[key] !== undefined && params[key] !== null) {
                result += `${key}=${encodeURIComponent(params[key])}&`
              }
            })
            return result.substring(0, result.length - 1)
          }
        })
        .then(response => {
          return Promise.resolve(response)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    } else {
      return axios

        .delete(url, config)
        .then(response => {
          return Promise.resolve(response)
        })
        .catch(ex => {
          return Promise.reject(ex)
        })
    }
  },
  postWithAxios (url, data, config) {
    return axios
      .post(url, data, config)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(ex => {
        return Promise.reject(ex)
      })
  },
  putWithAxios (url, data, config) {
    return axios
      .put(url, data, {
        params: config.params,
        headers: config.headers ? config.headers : '',
        paramsSerializer: (params) => {
          let result = ''
          Object.keys(params).forEach(key => {
            if (params[key] !== undefined && params[key] !== null) {
              result += `${key}=${encodeURIComponent(params[key])}&`
            }
          })
          return result.substring(0, result.length - 1)
        }
      })
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      // .put(url, data, config)
      // .then(response => {
      //   return Promise.resolve(response)
      // })
      // .catch(ex => {
      //   return Promise.reject(ex)
      // })
  },
  patchWithAxios (url, data, config) {
    return axios
      .patch(url, data, config)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(ex => {
        return Promise.reject(ex)
      })
  },
  checkSessionOut (error) {
    console.log(error)
    // const sessionValidator = this.productionType === codes.productionTypes.CLOUD
    //   ? getCloudSessionValidation : getSessionValidation
    // if (!sessionValidator(error)) {
    //   store.dispatch('dataStore/CLEAN')
    //   router.push('/login')
    // }
  },
  async getRequestHeader () {
    const vuex = await JSON.parse(localStorage.getItem('vuex'))
    // const user = vuex.auth.user
    // for cookies
    const session = Cookies.get('sessionId')

    const loginState = vuex.auth.loginState
    const hearder = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8;'
    }
    const url = configs.ApiUrl
    // https 요청인 경우 API key 추가.
    if (url && url.toLowerCase().includes('https://')) {
      hearder['api-key'] = configs.ApiKey
    }
    // login 상태라면 sessionId 추가.
    if (loginState === true) hearder.sessionId = session
    // Cloud 버전이라면 Token추가.
    if (process.env.VUE_APP_PRODUCTION_TYPE === 'cloud') {
      hearder.Authorization = `Bearer ${vuex.auth.tokenAPI.accessToken}`
    }
    if (configs.keycloakLogin === true) {
      hearder.Authorization = `Bearer ${vuex.auth.tokenAPI}`
    }
    return hearder
  }
}

Utils.install = function (Vue) {
  Vue.prototype.$utils = Utils
}

export default Utils
