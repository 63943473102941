var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticStyle: { "margin-top": "-20px" } },
        [
          _c("v-subheader", [
            _c("b", [_vm._v(_vm._s(_vm.$t("TEMPLATE LIST")))])
          ])
        ],
        1
      ),
      _c(
        "v-data-table",
        {
          staticClass: "tbl-type01 type3",
          attrs: {
            headers: _vm.templateHeaders,
            items: _vm.initaldata,
            "item-key": "uniqueid",
            "hide-default-footer": true,
            height: "417",
            "items-per-page": 2000,
            "single-select": ""
          },
          on: { "click:row": _vm.getpreview },
          scopedSlots: _vm._u(
            [
              {
                key: "item.icon",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm.permissionSetValue == "company" &&
                    item.templateName != ""
                      ? _c(
                          "v-icon",
                          {
                            staticStyle: { color: "red" },
                            attrs: { medium: "" },
                            on: {
                              click: function($event) {
                                return _vm.deleteSelectedTemplate(item)
                              }
                            }
                          },
                          [_vm._v("mdi-close-circle ")]
                        )
                      : _vm.permissionSetValue == "country" &&
                        item.areaKey !== "company" &&
                        item.templateName != ""
                      ? _c(
                          "v-icon",
                          {
                            staticStyle: { color: "red" },
                            attrs: { medium: "" },
                            on: {
                              click: function($event) {
                                return _vm.deleteSelectedTemplate(item)
                              }
                            }
                          },
                          [_vm._v("mdi-close-circle ")]
                        )
                      : _vm.permissionSetValue == "region" &&
                        item.areaKey !== "country" &&
                        item.areaKey !== "company" &&
                        item.templateName != ""
                      ? _c(
                          "v-icon",
                          {
                            staticStyle: { color: "red" },
                            attrs: { medium: "" },
                            on: {
                              click: function($event) {
                                return _vm.deleteSelectedTemplate(item)
                              }
                            }
                          },
                          [_vm._v("mdi-close-circle ")]
                        )
                      : _vm.permissionSetValue == "city" &&
                        item.areaKey !== "country" &&
                        item.areaKey !== "company" &&
                        item.areaKey !== "region" &&
                        item.templateName != ""
                      ? _c(
                          "v-icon",
                          {
                            staticStyle: { color: "red" },
                            attrs: { medium: "" },
                            on: {
                              click: function($event) {
                                return _vm.deleteSelectedTemplate(item)
                              }
                            }
                          },
                          [_vm._v("mdi-close-circle ")]
                        )
                      : _vm.permissionSetValue == "store" &&
                        item.templateName != "" &&
                        item.areaKey !== "country" &&
                        item.areaKey !== "company" &&
                        item.areaKey !== "region" &&
                        item.area !== "City"
                      ? _c(
                          "v-icon",
                          {
                            staticStyle: { color: "red" },
                            attrs: { medium: "" },
                            on: {
                              click: function($event) {
                                return _vm.deleteSelectedTemplate(item)
                              }
                            }
                          },
                          [_vm._v("mdi-close-circle ")]
                        )
                      : _c("span", [_c("v-icon")], 1),
                    item.templateName == ""
                      ? _c("span", { staticStyle: { cursor: "text" } }, [
                          _c("img", {
                            attrs: {
                              width: "24",
                              height: "24",
                              src: require("@/assets/img/ico-upload-file.gif")
                            }
                          })
                        ])
                      : _vm._e()
                  ]
                }
              },
              {
                key: "item.templateName",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.templateName === ""
                      ? _c("v-file-input", {
                          staticClass: "form-file fileinput",
                          attrs: {
                            accept: "text/xml",
                            dense: "",
                            "prepend-icon": "",
                            clearable: false,
                            placeholder: "" + _vm.$t("Select File"),
                            disabled: item.enable
                          },
                          on: {
                            change: _vm.encodeFile,
                            "click:clear": function($event) {
                              return _vm.clearFile(item)
                            }
                          }
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(item.templateName))])
                  ]
                }
              },
              {
                key: "item.areaKey",
                fn: function(ref) {
                  var item = ref.item
                  return [_c("span", [_vm._v(_vm._s(_vm.$t(item.areaKey)))])]
                }
              }
            ],
            null,
            true
          ),
          model: {
            value: _vm.template_list,
            callback: function($$v) {
              _vm.template_list = $$v
            },
            expression: "template_list"
          }
        },
        [
          _c("template", { slot: "no-data" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("No data available")))])
          ])
        ],
        2
      ),
      _c(
        "v-row",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "btn ml-2",
              attrs: {
                disabled: _vm.saveButtonDisabled || _vm.btndisabled,
                text: ""
              },
              on: {
                click: function($event) {
                  return _vm.saveSelectedTemplateList()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("Save")))]
          ),
          _c(
            "v-btn",
            {
              staticClass: "btn ml-2",
              attrs: {
                disabled: !_vm.saveButtonDisabled || _vm.btndisabled,
                text: ""
              },
              on: {
                click: function($event) {
                  return _vm.ReapplySelectedTemplateList()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("Reapply")))]
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: "500" },
              model: {
                value: _vm.UploadOptions,
                callback: function($$v) {
                  _vm.UploadOptions = $$v
                },
                expression: "UploadOptions"
              }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "popup add_store_popup",
                  attrs: { align: "center", justify: "center" }
                },
                [
                  _c("v-card-title", [
                    _c("h3", { staticClass: "page-title-bar" }, [
                      _c("i", { staticClass: "ico ico-template" }),
                      _vm._v(_vm._s(_vm.$t("Template Upload Option")) + " ")
                    ])
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-container",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                                margin: "-50px"
                              }
                            },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  attrs: { row: "" },
                                  model: {
                                    value: _vm.selectedOption,
                                    callback: function($$v) {
                                      _vm.selectedOption = $$v
                                    },
                                    expression: "selectedOption"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      label: "" + _vm.$t("Upload Now"),
                                      value: "false"
                                    }
                                  }),
                                  _c("v-radio", {
                                    attrs: {
                                      label: "" + _vm.$t("Upload Later"),
                                      value: "true"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn",
                          attrs: { text: "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.handleClickConfirm()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Save")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn",
                          attrs: { text: "", icon: "" },
                          on: {
                            click: function($event) {
                              _vm.UploadOptions = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Cancel")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }