<template>
  <div>
  <nav class="menuList">
    <sidebar-menu
      :menu="getActiveMenu"
      :SystemMenu="getActiveMenu"
      :collapsed="collapsed"
      :lcdItem="getActiveMenu"
      :theme="selectedTheme"
      :show-one-child="true"
      @toggle-collapse="onToggleCollapse"
      @item-click="onItemClick"
    />
  </nav>
</div>
</template>

<script>
import commons from '@/plugins/commons'
import configs from '@/plugins/configs'
import lcdItem from '@/lcd/mixin/lcdItem'

const CUSTOMER = configs.Customer
export default {

  mixins: [lcdItem],
  data () {
    return {
      productionType: process.env.VUE_APP_PRODUCTION_TYPE,
      user: null,
      activeMenu: [], // 활성화 메뉴 리스트
      // 전체 메뉴 리스트
      menu: [
        {
          href: '/',
          title: 'Overview', // 09.24 메뉴명 수정
          code: '1000',
          icon: {
            element: 'span',
            class: 'menu-overview'
          }
        },
        {
          href: '/Label/LabelInfo',
          title: 'Label',
          code: '2000',
          icon: {
            element: 'span',
            class: 'menu-label'
          },
          child: [
            {
              href: '/Label/LabelInfo',
              title: 'Label Info',
              code: '2100'
            },
            {
              href: '/Label/FailRecodes',
              title: 'Fail Records',
              code: '2200'
            },
            {
              href: '/Label/Firmware',
              title: 'Label Firmware', // 09.24 메뉴명 수정
              code: '2300'
            },
            {
              href: '/Label/LabelSettings',
              title: 'Label Properties', // 09.24 메뉴명 수정
              code: '2600'
            }
          ]
        },
        {
          href: '/Product/ProductInfo',
          title: 'Product',
          code: '3000',
          icon: {
            element: 'span',
            class: 'menu-product'
          },
          child: [
            {
              href: '/Product/ProductInfo',
              title: 'Product Info',
              code: '3100'
            },
            {
              href: '/Product/UpdateRecords',
              title: 'Update Records',
              code: '3200'
            }
          ]
        },
        {
          href: '/Gateway/GatewayInfo',
          title: 'Gateway', // 09.24 메뉴명 수정
          code: '4000',
          icon: {
            element: 'span',
            class: 'menu-gateway'
          },
          child: [
            {
              href: '/Gateway/GatewayInfo',
              title: 'Gateway Info', // 09.24 메뉴명 수정
              code: '4100'
            },
            {
              href: '/Gateway/GatewayFirmware',
              title: 'Gateway Firmware',
              code: '4200'
            }
          ]
        },
        {
          href: '/PowerRail/PowerRailInfo',
          title: 'Power Rail', // 09.24 메뉴명 수정
          code: '12000',
          icon: {
            element: 'span',
            class: 'menu-power-rail'
          },
          child: [
            {
              href: '/PowerRail/PowerRailInfo',
              title: 'Power Rail', // 09.24 메뉴명 수정
              code: '12000'
            }
          ]
        },
        {
          href: '/Template/TemplateInfo',
          title: 'Template',
          code: '5000',
          icon: {
            element: 'span',
            class: 'menu-template'
          },
          child: [
            // {
            //   href: '/Template/TemplateInfo',
            //   title: 'Template Info',
            //   code: '5100'
            // },
            {
              href: '/Template/TemplateTypeList',
              title: 'Template Type List',
              code: '5100'
            },
            {
              href: '/Template/TemplateGrouping',
              title: 'Template Grouping',
              code: '5200'
            },
            {
              href: '/Template/TemplateModelGrouping',
              title: 'Template Model Grouping',
              code: '5500'
            },
            {
              href: '/Template/TemplateMapping',
              title: 'Template Mapping',
              code: '5300'
            },
            {
              href: '/Template/ImageonTemplate',
              title: 'Image on Template',
              code: '5400'
            }
          ]
        },
        {
          href: '/Multifunction/LBS',
          title: 'Multifunction',
          code: '6000',
          icon: {
            element: 'span',
            class: 'menu-multifunction'
          },
          child: [
            {
              href: '/Multifunction/LBS',
              title: 'LBS Configuration',
              code: '6100'
            },
            {
              href: '/Multifunction/LBS_Shelf',
              title: 'LBS Shelf Location',
              code: '6600'
            },
            {
              href: '/Multifunction/LED',
              title: 'LED Configuration',
              code: '6200'
            },
            {
              href: '/Multifunction/Button_Config',
              title: 'Button Configuration',
              code: '6400'
            }
          ]
        },
        {
          href: '/Firmware/LabelFirmware',
          title: 'Firmware',
          code: '10000',
          icon: {
            element: 'span',
            class: 'menu-firmware'
          },
          child: [
            {
              href: '/Firmware/LabelFirmware',
              title: 'Label Firmware',
              code: '10100'
            },
            {
              href: '/Firmware/GatewayFirmware',
              title: 'Gateway Firmware',
              code: '10300'
            },
            {
              href: '/Firmware/LabelOta',
              title: 'Label OTA',
              code: '10200'
            }
          ]
        },
        {
          href: '/Sensors/SensorList',
          title: 'Sensor',
          code: '13000',
          icon: {
            element: 'span',
            class: 'menu-sensor'
          },
          child: [
            {
              href: '/Sensors/SensorList',
              title: 'Sensor List',
              code: '13100'
            },
            {
              href: '/Sensors/SensorAlert',
              title: 'Sensor Alert',
              code: '13200'
            },
            {
              href: '/Sensors/SensorAlertSetting',
              title: 'Sensor Alert Setting',
              code: '13300'
            }
          ]
        },
        {
          href: '/Store/StoreInfo',
          title: 'Stores',
          code: '7000',
          icon: {
            element: 'span',
            class: 'menu-store'
          },
          child: [
            {
              href: '/Store/StoreInfo',
              title: 'Store Info',
              code: '7100'
            },
            {
              href: '/Store/WhiteList',
              title: 'Whitelisting',
              code: '7200'
            }
          ]
        },
        {
          href: '/User/UserInfo',
          title: 'User Management',
          code: '8000',
          icon: {
            element: 'span',
            class: 'menu-user'
          },
          child: [
            {
              href: '/User/UserInfo',
              title: 'User Info',
              code: '8100'
            },
            {
              href: '/User/UserMapping',
              title: 'User Access',
              code: '8200'
            },
            {
              href: '/User/UserLevel',
              title: 'User Roles',
              code: '8300'
            },
            {
              href: '/User/MyAccount',
              title: 'My Account', // 09.24 메뉴명 수정
              code: '8400'
            }
          ]
        },
        // Report Menu
        {
          href: '/Reports/ErrorList',
          title: 'Reports',
          code: '11000',
          icon: {
            element: 'span',
            class: 'menu-report'
          },
          child: [
            {
              href: '/Reports/ErrorList',
              title: 'Error List',
              code: '11100'
            },
            {
              href: '/Reports/ESLProductInfo',
              title: 'ESL Product Information',
              code: '11200'
            }
          ]
        }
      ],
      SystemMenu: [
        {
          href: '/System/DashboardConfig',
          title: 'System Config',
          code: '9000',
          icon: {
            element: 'span',
            class: 'menu-system'
          },
          child: [
            {
              href: '/System/DashboardConfig',
              title: 'Dashboard Settings',
              code: '9100'
            },
            // {
            //   href: '/System/ReportConfig',
            //   title: 'Reporting Settings',
            //   code: '9200'
            // },
            {
              href: '/System/HardwareSetting',
              title: 'Hardware Settings',
              code: '9200'
            },
            {
              href: '/System/AppConfig',
              title: 'App Config',
              code: '9300'
            },
            {
              href: '/System/ProductFileConfig',
              title: 'Product File Config',
              code: '9500'
            },
            {
              href: '/System/SystemLicense',
              title: 'System License',
              code: '9400'
            }
          ]
        }
      ],
      collapsed: false,
      themes: [
        {
          name: 'Default theme',
          input: ''
        },
        {
          name: 'White theme',
          input: 'white-theme'
        }
      ],
      selectedTheme: '',
      isOnMobile: false,
      tabActiveIndex: ''
    }
  },
  mounted () {
    this.user = this.$store.state.auth.user
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },

  computed: {
    getActiveMenu () {
      // Null check
      if (commons.isNull(this.user)) return []
      if (commons.isNull(this.$store.state.auth.user.accountInfo)) return []
      const accessMenu = this.$store.state.auth.user.accountInfo.accessMenu
      if (!commons.isValidStr(accessMenu)) return []

      // Disable links refer to the user information.
      var noAccMenu
      if (localStorage.getItem('MSPStatus') === true) {
        noAccMenu = ['2200', '2300', '4200', '5500']
      } else {
        noAccMenu = ['2200', '2300', '4200']
      }
      // disabled by the user information. ex: user.lbsEnable..etc
      let disabeldLinks = [
        {
          key: 'lbsEnable',
          parentCode: '6000',
          codeList: ['6100', '6600']
        },
        {
          key: 'templateGroupEnable',
          parentCode: '5000',
          codeList: ['5200', '5300']
        },
        {
          key: 'otaEnable',
          parentCode: '10000',
          codeList: ['10100', '10200', '10300']
        }
      ]
      // if (commons.isNull(this.$store.state.auth.user.otaEnable)) {
      //   noAccMenu.push('2300')
      // }
      disabeldLinks = this.setDisabled(disabeldLinks, accessMenu)
      noAccMenu = this.pushDisabledLinks(noAccMenu, disabeldLinks)

      // const essentialMenu = ['1000', '8400', '10000', '10100', '10200']
      // const essentialMenu = ['1000', '8400', '21000']

      // For My Account menu "8400"
      const essentialMenu = ['1000', '8400', '21000']

      // Delete non-accessable items
      let activeMenu = this.menu.map(parent => {
        if (parent.child !== undefined) {
          parent.child = parent.child.filter(childItem => {
            // 하위 요소가 accessMenu에 포함되고 noAccessMenu에 포함되지 않는 경우만 남기고 나머지는 삭제.

            var accessable =
              (accessMenu.includes(childItem.code) &&
                !noAccMenu.includes(childItem.code)) ||
              essentialMenu.includes(childItem.code)
              // Cloud인 경우 페이지 링크 변경
            if (this.productionType === 'cloud') {
              if (
                childItem.code === '4100' || // Gateway Info
                childItem.code === '8100' || // UserInfo
                childItem.code === '8200' || // UserMapping
                childItem.code === '8400' || // MyAccount
                childItem.code === '9100'
              ) {
                // DashboardConfig
                childItem.href = childItem.href + 'Cloud'
              }
            }
            // For metro Report menu
            if (CUSTOMER !== 'METRO') {
              const essentialMenuformetro = ['11000', '11100', '11200', '5500']
              const accessMenuformetro = accessMenu.filter(val => !essentialMenuformetro.includes(val))
              accessable = (accessMenuformetro.includes(childItem.code) && !noAccMenu.includes(childItem.code)) ||
                              essentialMenu.includes(childItem.code)
            }
            // end
            return accessable
          })
        }
        return parent
      })
      // }
      if (this.$store.state.dataStore.sideMenu === 'SYSTEM_CONFIG') {
        activeMenu = this.SystemMenu.map(parent => {
          if (parent.child !== undefined) {
            // 하위요소 존재하면 사용자의 Access Menu와 대조하여 접근불가한 메뉴 삭제.
            parent.child = parent.child.filter(childItem => {
              // 하위 요소가 accessMenu에 포함되고 noAccessMenu에 포함되지 않는 경우만 남기고 나머지는 삭제.
              var accessable =
              (accessMenu.includes(childItem.code) &&
                !noAccMenu.includes(childItem.code)) ||
              essentialMenu.includes(childItem.code)
              // Cloud인 경우 페이지 링크 변경
              if (this.productionType === 'cloud') {
                if (
                  childItem.code === '4100' || // Gateway Info
                childItem.code === '8100' || // UserInfo
                childItem.code === '8200' || // UserMapping
                childItem.code === '8400' || // MyAccount
                childItem.code === '9100'
                ) {
                  // DashboardConfig
                  childItem.href = childItem.href + 'Cloud'
                }
              }
              // For metro Report menu
              if (CUSTOMER !== 'METRO') {
                const essentialMenuformetro = ['11000', '11100', '11200', '5500']
                const accessMenuformetro = accessMenu.filter(val => !essentialMenuformetro.includes(val))
                accessable = (accessMenuformetro.includes(childItem.code) && !noAccMenu.includes(childItem.code)) ||
                              essentialMenu.includes(childItem.code)
              }
              // end
              return accessable
            })
          }
          return parent
        })
      } else if (this.$store.state.dataStore.sideMenu === 'LCD') {
        activeMenu = this.lcdItem.map(parent => {
          if (parent.child !== undefined) {
            // 하위요소 존재하면 사용자의 Access Menu와 대조하여 접근불가한 메뉴 삭제.
            parent.child = parent.child.filter(childItem => {
              // 하위 요소가 accessMenu에 포함되고 noAccessMenu에 포함되지 않는 경우만 남기고 나머지는 삭제.
              var accessable =
              (accessMenu.includes(childItem.code) &&
                !noAccMenu.includes(childItem.code)) ||
              essentialMenu.includes(childItem.code)
              // Cloud인 경우 페이지 링크 변경
              if (this.productionType === 'cloud') {
                if (
                  childItem.code === '4100' || // Gateway Info
                childItem.code === '8100' || // UserInfo
                childItem.code === '8200' || // UserMapping
                childItem.code === '8400' || // MyAccount
                childItem.code === '9100'
                ) {
                  // DashboardConfig
                  childItem.href = childItem.href + 'Cloud'
                }
              }
              // For metro Report menu
              if (CUSTOMER !== 'METRO') {
                const essentialMenuformetro = ['11000', '11100', '11200', '5500']
                const accessMenuformetro = accessMenu.filter(val => !essentialMenuformetro.includes(val))
                accessable = (accessMenuformetro.includes(childItem.code) && !noAccMenu.includes(childItem.code)) ||
                              essentialMenu.includes(childItem.code)
              }
              // end
              return accessable
            })
          }
          return parent
        })
      }
      activeMenu = activeMenu.filter(parent => {
        return (
          (!commons.isNull(parent.child) && parent.child.length > 0) ||
          essentialMenu.includes(parent.code)
        )
      })
      // Change default url of a parent link
      // in case of the existing default url is disabled by user's configuration.
      this.setDefaultParentUrl(activeMenu)
      this.setDisabledLinksParentUrl(activeMenu, disabeldLinks)
      return activeMenu
    }
  },
  methods: {
    onToggleCollapse (collapsed) {
      this.collapsed = collapsed
    },
    onItemClick (event, item, node) {},
    onResize () {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true
        this.collapsed = true
      } else {
        this.isOnMobile = false
        this.collapsed = false
      }
    },
    includesAny (arr, arrToFind) {
      if (!commons.isValidStr(arr)) return false
      for (const el of arr) {
        for (const elToFind of arrToFind) {
          if (el === elToFind) return true
        }
      }
      return false
    },
    setDisabled (disabeldLinks, accessMenu) {
      for (const link of disabeldLinks) {
        link.disabled = this.user[link.key] === 'false'
      }
      return disabeldLinks
    },
    pushDisabledLinks (noAccMenu, disabeldLinks) {
      for (const link of disabeldLinks) {
        if (link.disabled === true) {
          noAccMenu.push(...link.codeList)
        }
      }
      return noAccMenu
    },
    setDefaultParentUrl (activeMenu) {
      for (const item of activeMenu) {
        if (commons.isNull(item)) continue
        // for Metro
        if (item.code && item.code.length > 4) {
          if (Number(item.code.substring(2, 5)) === 0) {
            // 대분류인 경우. url을 설정해줘야한다.
            if (commons.isNull(item.child) || !commons.notEmpty(item.child)) {
              continue
            }
            item.href = commons.peekFirst(item.child).href
          }
        // end
        } else {
          if (Number(item.code.substring(1, 4)) === 0) {
            // 대분류인 경우. url을 설정해줘야한다.
            if (commons.isNull(item.child) || !commons.notEmpty(item.child)) {
              continue
            }
            item.href = commons.peekFirst(item.child).href
          }
        }
      }
    },
    setDisabledLinksParentUrl (ActiveMenu, disabeldLinks) {
      for (const link of disabeldLinks) {
        if (link.disabled) {
          const parent = ActiveMenu.find(am => am.code === link.parentCode)
          if (commons.isNull(parent)) continue
          const firstChild = commons.peekFirst(parent.child)
          if (commons.isNull(firstChild)) continue
          parent.href = firstChild.href
        }
      }
    }
  }
}
</script>

<style>
.v-sidebar-menu {
  position: relative;
  background: transparent;
}
.v-sidebar-menu .vsm--toggle-btn {
  display: none;
}
.v-sidebar-menu .vsm--link {
  padding: 0 30px;
  font-size: 15px;
  color: #adb7bf;
  line-height: 65px;
  outline: none;
}
.v-sidebar-menu .vsm--link_exact-active,
.v-sidebar-menu .vsm--link_active {
  color: #fff;
  font-weight: 400;
}
.v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu .vsm--link_level-1.vsm--link_active {
  box-shadow: 7px 0px 0px 0px #fff inset;
  background: #697a8a;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 {
  background: transparent;
}
.v-sidebar-menu.vsm_expanded
  .vsm--item_open
  .vsm--link_level-1.vsm--link_active {
  background: #697a8a;
}
.v-sidebar-menu .vsm--dropdown .vsm--list {
  padding: 0;
  background: transparent;
}
.v-sidebar-menu .vsm--dropdown .vsm--list .vsm--link_level-2 {
  font-weight: 200;
  padding-left: 62px;
  line-height: 55px;
}
.v-sidebar-menu .vsm--dropdown .vsm--list .vsm--link_level-2.vsm--link_active {
  font-weight: 400;
}

/* 아이콘 : 공통 */
.v-sidebar-menu .vsm--link_level-1 .vsm--icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;
  background-color: transparent;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.menu-overview {
  background-image: url(../assets/img/nav-ico01.png);
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.menu-label {
  background-image: url(../assets/img/nav-ico02.png);
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.menu-product {
  background-image: url(../assets/img/nav-ico03.png);
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.menu-store {
  background-image: url(../assets/img/nav-ico04.png);
}
/* //Report Menu */
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.menu-report {
  background-image: url(../assets/img/rpt.png);
}
/* end */
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.menu-template {
  background-image: url(../assets/img/nav-ico05.png);
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.menu-multifunction {
  background-image: url(../assets/img/nav-ico06.png);
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.menu-user {
  background-image: url(../assets/img/nav-ico07.png);
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.menu-system {
  background-image: url(../assets/img/nav-ico08.png);
}
/* 09.27 추가 */
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.menu-gateway {
  background-image: url(../assets/img/nav-ico09.png);
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.menu-power-rail {
  background-image: url(../assets/img/nav-ico_ral.png);
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.menu-firmware {
  background-image: url(../assets/img/nav-ico09.png);
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.menu-sensor {
  background-image: url(../assets/img/access-point-custom.png);
}
/* //09.27 수정 */

.v-sidebar-menu .vsm--link_level-1.vsm--link_active .vsm--icon {
  background-position: bottom left;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
  background-color: transparent;
  background-position: bottom left;
}

.v-sidebar-menu .vsm--arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-sidebar-menu .vsm--arrow:after {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border: solid #adb7bf;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
.v-sidebar-menu .vsm--arrow_open {
  transform: rotate(180deg);
}
.v-sidebar-menu .vsm--arrow_open:after {
  border-color: #fff;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.menu-lcd {
  background-image: url(../assets/img/nav-ico10.png);
  width: 28px;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.menu-contents {
  background-image: url(../assets/img/nav-ico11.png);
  width: 28px;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.menu-playlist-group {
  background-image: url(../assets/img/nav-ico12.png);
}
.v-sidebar-menu .vsm--link_level-1.vsm--link_active .vsm--icon {
  background-position: bottom left;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
  background-color: transparent;
  background-position: bottom left;
}

.v-sidebar-menu .vsm--arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-sidebar-menu .vsm--arrow:after {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border: solid #adb7bf;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
.v-sidebar-menu .vsm--arrow_open {
  transform: rotate(180deg);
}
.v-sidebar-menu .vsm--arrow_open:after {
  border-color: #fff;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon.solum-admin {
  background-image: url(../assets/img/ico_admin.png);
}
.menuList {
  min-height: 980px;
}
.expand-links {
  color: #001E38;
  align-items: center;
  padding: 25px 0 25px 30px;
  cursor: pointer;
  background-color: #E7E7E7;
  position: fixed;
  bottom: 65px;
  width: 274px;
  z-index: 999;
  font-weight: 520;
  font-size: 15px;
}
.solum-family-link-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  cursor: pointer;
}
.expand-title {
  background-color: #001E38;
  color: white;
  /* position: absolute; */
  position: fixed;
  /* margin-top: 0px; */
  bottom: 0 !important;
  width: 274px;
  cursor: pointer;
  z-index: 999;
}
.external-link-icon {
  display: flex;
  align-items: center;
}
.external-link-icon > a {
  color: #fff;
}
.external-link-icon img {
  margin-left: 10px;
  height: 15px;
  width: 15px;
}
.Expandlinkopened{
  pointer-events: none !important;
  opacity: 0.8 !important;
}
</style>
<style scoped>
::v-deep .v-window__container {
  height: 100%;
}
::v-deep .v-window-item {
  height: 100%;
}
::v-deep .v-card--flat {
  height: 100%;
}
.v-sidebar-menu
  .vsm--scroll-wrapper
  > .vsm--list
  .vsm--item:last-child
  .vsm--dropdown
  > .vsm--list
  .vsm--item:last-child {
  margin-bottom: 50px;
}
.v-card > *:first-child:not(.v-btn):not(.v-chip),
.v-card > .v-card__progress + *:not(.v-btn):not(.v-chip) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background-color: #001E38;
}
