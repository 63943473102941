var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-multi" }),
        _vm._v(_vm._s(_vm.$t("Button Configuration")) + " ")
      ]),
      _c(
        "div",
        {
          staticClass: "col-12 pl-0 pb-0 pt-0 pr-0 d-flex",
          staticStyle: { "margin-top": "10px" }
        },
        [
          _c(
            "div",
            { staticClass: "col-3 pr-0 pt-0" },
            [
              _c(
                "v-data-table",
                {
                  staticClass: "tbl-type01 mt-10 products",
                  attrs: {
                    "server-items-length": _vm.totalProducts,
                    headers: _vm.tableHeadersFreeze,
                    "hide-default-footer": true,
                    "item-class": _vm.getRowClass,
                    items: _vm.ButtonConfigTableList,
                    "single-select": true,
                    options: _vm.options,
                    "show-select": "",
                    "item-key": "configName"
                  },
                  on: {
                    "update:options": function($event) {
                      _vm.options = $event
                    },
                    "click:row": _vm.openButtonConfigEditPopup
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.sno",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.ButtonConfigTableList.indexOf(item) + 1
                                ) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "item.data-table-select",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("v-checkbox", {
                              staticClass: "custom-checkbox ",
                              staticStyle: { height: "40px" },
                              attrs: {
                                value: _vm.selected.includes(item),
                                disabled: item.id === 1
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.toggleSelect(item)
                                }
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                },
                [
                  _c("template", { slot: "no-data" }, [
                    _c("p", [
                      _vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")
                    ])
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-9 pl-0 pt-0 pr-1" },
            [
              _c(
                "v-data-table",
                {
                  staticClass: "tbl-type01 mt-10 tblBorder",
                  attrs: {
                    "server-items-length": _vm.totalProducts,
                    headers: _vm.tableHeaders,
                    "hide-default-footer": true,
                    "item-class": _vm.getRowClass,
                    items: _vm.ButtonConfigTableList,
                    "single-select": true,
                    options: _vm.options,
                    "item-key": "configName"
                  },
                  on: {
                    "update:options": function($event) {
                      _vm.options = $event
                    },
                    "click:row": _vm.openButtonConfigEditPopup
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item.realtimeConfigHibernate",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.realtimeConfigHibernate === true
                                    ? "ON"
                                    : "OFF"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.selected,
                    callback: function($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected"
                  }
                },
                [
                  _c("template", { slot: "no-data" }, [
                    _c("p", [
                      _vm._v(" " + _vm._s(_vm.$t("No data available")) + " ")
                    ])
                  ])
                ],
                2
              )
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "table-options" }, [
        _c(
          "div",
          [
            _c(
              "v-btn",
              {
                staticClass: "btn ml-2",
                attrs: { text: "", disabled: _vm.btnDisabledNewDelete },
                on: { click: _vm.openNewButtonConfigPopup }
              },
              [_vm._v(_vm._s(_vm.$t("New")) + " ")]
            ),
            _c(
              "v-btn",
              {
                staticClass: "btn ml-2",
                attrs: {
                  disabled: _vm.btnDisabledNewDelete || _vm.checkSelected,
                  text: ""
                },
                on: { click: _vm.clickDelete }
              },
              [_vm._v(_vm._s(_vm.$t("Delete")) + " ")]
            )
          ],
          1
        )
      ]),
      _c("EditButtonConfig", {
        attrs: {
          selectedItem: _vm.selectedItem,
          dialogPopup: _vm.editDialogPopup
        },
        on: { reload: _vm.reload }
      }),
      _c("AddButtonConfig", {
        attrs: { dialogPopup: _vm.addDialogPopup },
        on: { reload: _vm.reload }
      }),
      _c("a", { ref: "link", style: { display: "none" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }