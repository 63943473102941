var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "1300", persistent: "" },
      model: {
        value: _vm.addDialogPopup,
        callback: function($$v) {
          _vm.addDialogPopup = $$v
        },
        expression: "addDialogPopup"
      }
    },
    [
      _c("v-card", { staticClass: "popup add_store_popup" }, [
        _c("div", [
          _c(
            "div",
            {
              staticClass:
                "popup-titbox d-flex align-center justify-space-between w-100 mb-5"
            },
            [
              _c(
                "h3",
                { staticClass: "page-title-bar align-start d-grid w-100 " },
                [
                  _c("div", [
                    _c("img", {
                      staticStyle: { "margin-right": "15px", height: "31px" },
                      attrs: { src: require("@/assets/img/access-point.svg") }
                    }),
                    _vm._v(_vm._s(_vm.$t(_vm.sensorDetails.name)) + " ")
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "w-100 d-flex",
                      staticStyle: { "margin-left": "48px" }
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "info-text d-flex",
                          staticStyle: { color: "#666666" }
                        },
                        [
                          _c("span", { staticClass: "mr-1" }, [
                            _vm._v(_vm._s(_vm.$t("Sensor ID")) + " : ")
                          ]),
                          _c("span", [
                            _c("b", [
                              _vm._v(
                                " " + _vm._s(_vm.sensorDetails.sensorCode) + " "
                              )
                            ])
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex justify-space-around",
                          staticStyle: { width: "calc(100% - 180px)" }
                        },
                        [
                          _c("p", { staticClass: "info-text d-flex" }, [
                            _c(
                              "span",
                              { staticClass: "mr-1 d-flex align-end" },
                              [
                                _vm._v(" " + _vm._s(_vm.$t("Temp")) + " : "),
                                _c("img", {
                                  staticClass: "ml-1",
                                  attrs: {
                                    src: require("@/assets/img/thermometer-lines.png"),
                                    alt: "temp",
                                    height: "16"
                                  }
                                })
                              ]
                            ),
                            _vm.headersStatistics.temp
                              ? _c("span", [
                                  _vm.toggleState
                                    ? _c(
                                        "span",
                                        { staticStyle: { color: "#43425D" } },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.headersStatistics.temp)
                                          ),
                                          _c("span", [_vm._v("°")]),
                                          _vm._v("C")
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticStyle: { color: "#43425D" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.headersStatistics.temp.toFixed(
                                                2
                                              )
                                            )
                                          ),
                                          _c("span", [_vm._v("°")]),
                                          _vm._v("F")
                                        ]
                                      )
                                ])
                              : _c(
                                  "span",
                                  { staticStyle: { color: "#43425D" } },
                                  [_vm._v("-")]
                                )
                          ]),
                          _c("p", { staticClass: "info-text d-flex" }, [
                            _c(
                              "span",
                              { staticClass: "mr-1 d-flex align-end" },
                              [
                                _vm._v(" " + _vm._s(_vm.$t("Hum")) + " : "),
                                _c("img", {
                                  staticClass: "ml-1",
                                  attrs: {
                                    src: require("@/assets/img/water-percent.png"),
                                    alt: "temp",
                                    height: "16"
                                  }
                                })
                              ]
                            ),
                            _vm.headersStatistics.hum
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#43425D" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.headersStatistics.hum) + "%"
                                    )
                                  ]
                                )
                              : _c(
                                  "span",
                                  { staticStyle: { color: "#43425D" } },
                                  [_vm._v("-")]
                                )
                          ]),
                          _c("p", { staticClass: "info-text d-flex" }, [
                            _c(
                              "span",
                              { staticClass: "mr-1 d-flex align-end" },
                              [
                                _vm._v(" " + _vm._s(_vm.$t("Battery")) + " : "),
                                _vm.headersStatistics.battery === "GOOD"
                                  ? _c("img", {
                                      staticClass: "ml-1",
                                      attrs: {
                                        src: require("@/assets/img/battery-good.png"),
                                        alt: "temp",
                                        height: "16"
                                      }
                                    })
                                  : _c("img", {
                                      staticClass: "ml-1",
                                      attrs: {
                                        src: require("@/assets/img/ico-state-battery.png"),
                                        alt: "temp",
                                        height: "16"
                                      }
                                    })
                              ]
                            ),
                            _vm.headersStatistics.battery
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#43425D" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.headersStatistics.battery)
                                    )
                                  ]
                                )
                              : _c(
                                  "span",
                                  { staticStyle: { color: "#43425D" } },
                                  [_vm._v("-")]
                                )
                          ]),
                          _c("p", { staticClass: "info-text d-flex" }, [
                            _c(
                              "span",
                              { staticClass: "mr-1 d-flex align-end" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Sensor Status")) + " : "
                                )
                              ]
                            ),
                            _vm.sensorDetails.status !== "-"
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "d-flex justify-center align-center border-status",
                                    style:
                                      _vm.sensorDetails.status === "OFFLINE"
                                        ? "border-color: #FF8097"
                                        : "border-color: #6DA3F8"
                                  },
                                  [
                                    _c("p", {
                                      staticClass: "circle mr-1",
                                      style:
                                        _vm.sensorDetails.status === "OFFLINE"
                                          ? "background-color: #FF8097"
                                          : "background-color: #6DA3F8"
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "font-size": "12px" },
                                        style:
                                          _vm.sensorDetails.status === "OFFLINE"
                                            ? "color: #FF8097"
                                            : "color: #6DA3F8"
                                      },
                                      [_vm._v(_vm._s(_vm.sensorDetails.status))]
                                    )
                                  ]
                                )
                              : _c("span", [_vm._v("-")])
                          ]),
                          _c("p", { staticClass: "info-text d-flex" }, [
                            _c(
                              "span",
                              {
                                staticClass: "mr-1 d-flex align-end",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function($event) {
                                    return _vm.openEditDialog(_vm.sensorDetails)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: " darken-2",
                                    staticStyle: { color: "#A2ACBD" },
                                    attrs: { size: "24" }
                                  },
                                  [_vm._v("mdi-square-edit-outline")]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ]),
        _c(
          "div",
          { staticClass: "w-100 d-flex justify-end mb-3" },
          [
            _c("v-select", {
              staticClass: "form-select col-3 pt-0",
              attrs: {
                label: "",
                placeholder: _vm.$t("Select Duration"),
                outlined: "",
                dense: "",
                "hide-details": "",
                items: _vm.durationList,
                "item-text": "text",
                "item-value": "val"
              },
              on: { change: _vm.updateDuration },
              model: {
                value: _vm.selectedDuration,
                callback: function($$v) {
                  _vm.selectedDuration = $$v
                },
                expression: "selectedDuration"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "fixedHeader",
            staticStyle: {
              "max-height": "calc(100vh - 390px) !important",
              overflow: "auto"
            }
          },
          [
            _c("div", { staticStyle: { width: "100%" } }, [
              _c(
                "div",
                {
                  staticClass: "row mb-6 mx-0 justify-space-between col-12 px-0"
                },
                [
                  _c("div", { staticClass: "px-0 py-0 col-6" }, [
                    _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        {
                          staticClass: "card-header mb-3",
                          staticStyle: { "font-weight": "600" }
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("Temperature")) + " "),
                          _vm.toggleState
                            ? _c("span", [_vm._v("(°C)")])
                            : _c("span", [_vm._v("(°F)")])
                        ]
                      ),
                      _vm.tempLineChartData.series[0].data.length !== 0 &&
                      _vm.tempLineChartData.xAxis.categories.length !== 0
                        ? _c(
                            "div",
                            { staticClass: "card-body" },
                            [
                              _c("LineChart", {
                                attrs: {
                                  chartData: _vm.tempLineChartData,
                                  legend: _vm.legend,
                                  height: 250,
                                  colors: ["#6DA3F8", "#9AC7DB"]
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-end",
                                  staticStyle: { "font-size": "12px" }
                                },
                                [
                                  _c("div", { staticClass: "mr-3" }, [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.$t("Min")) + " :")
                                    ]),
                                    _vm.toggleState
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              this.temperatureStatistics
                                                .minTemperature
                                            ) + " °C"
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              this.temperatureStatistics.minTemperature.toFixed(
                                                2
                                              )
                                            ) + "°F"
                                          )
                                        ])
                                  ]),
                                  _c("div", { staticClass: "mr-3" }, [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.$t("Max")) + " :")
                                    ]),
                                    _vm.toggleState
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              this.temperatureStatistics
                                                .maxTemperature
                                            ) + "°C"
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              this.temperatureStatistics.maxTemperature.toFixed(
                                                2
                                              )
                                            ) + "°F"
                                          )
                                        ])
                                  ]),
                                  _c("div", { staticClass: "mr-3" }, [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.$t("Avg")) + " :")
                                    ]),
                                    _vm.toggleState
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              this.temperatureStatistics.averageTemperature.toFixed(
                                                1
                                              )
                                            ) + "°C"
                                          )
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              this.temperatureStatistics
                                                .averageTemperature
                                            ) + " °F"
                                          )
                                        ])
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm.tempLineChartData.series[0].data.length === 0 &&
                          _vm.chartLoader &&
                          _vm.tempLineChartData.xAxis.categories.length === 0
                        ? _c("div", { staticClass: "wrapper-loading w-100" }, [
                            _c("div", { staticClass: "loader" })
                          ])
                        : _c(
                            "div",
                            {
                              staticClass: "w-100 px-5 mt-5",
                              staticStyle: { height: "100%" }
                            },
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "ma-0 d-flex justify-center",
                                  staticStyle: {
                                    "line-height": "19px",
                                    color: "#fff"
                                  },
                                  attrs: { dense: "", color: "#697A8A" }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { color: "#fff" }
                                    },
                                    [_vm._v("mdi-alert-outline")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("No data available")) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ])
                  ]),
                  _c("div", { staticClass: "px-0 py-0 col-6" }, [
                    _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        {
                          staticClass: "card-header mb-3",
                          staticStyle: { "font-weight": "600" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Humidity")) + " (%) ")]
                      ),
                      _vm.lineChartData.series[0].data.length !== 0 &&
                      _vm.lineChartData.xAxis.categories.length !== 0
                        ? _c(
                            "div",
                            { staticClass: "card-body" },
                            [
                              _c("LineChart", {
                                attrs: {
                                  chartData: _vm.lineChartData,
                                  legend: _vm.legend,
                                  height: 250,
                                  colors: ["#6DA3F8", "#9AC7DB"]
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-end",
                                  staticStyle: { "font-size": "12px" }
                                },
                                [
                                  _c("div", { staticClass: "mr-3" }, [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.$t("Min")) + " :")
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.humidityStatistics.minHumidity
                                        ) + "%"
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "mr-3" }, [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.$t("Max")) + " :")
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.humidityStatistics.maxHumidity
                                        ) + "%"
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "mr-3" }, [
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.$t("Avg")) + " :")
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.humidityStatistics.avergaeHumidity
                                        ) + "%"
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm.lineChartData.series[0].data.length === 0 &&
                          _vm.chartLoader &&
                          _vm.lineChartData.xAxis.categories.length === 0
                        ? _c("div", { staticClass: "wrapper-loading w-100" }, [
                            _c("div", { staticClass: "loader" })
                          ])
                        : _c(
                            "div",
                            {
                              staticClass: "w-100 px-5 mt-5",
                              staticStyle: { height: "100%" }
                            },
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "ma-0 d-flex justify-center",
                                  staticStyle: {
                                    "line-height": "19px",
                                    color: "#fff"
                                  },
                                  attrs: { dense: "", color: "#697A8A" }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { color: "#fff" }
                                    },
                                    [_vm._v("mdi-alert-outline")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("No data available")) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ])
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: " mt-5" }, [
              _c("h4", { staticClass: "d-flex" }, [
                _vm._v(" " + _vm._s(_vm.$t("Alert History")) + " ")
              ])
            ]),
            _c(
              "div",
              { staticStyle: { width: "100%" } },
              [
                _c(
                  "v-data-table",
                  {
                    staticClass: "tbl-type01 mt-5",
                    attrs: {
                      headers: _vm.tableHeaders,
                      items: _vm.tableItems,
                      "item-key": "serialNo",
                      "hide-default-footer": true,
                      options: _vm.options,
                      "items-per-page": _vm.rowPerPage,
                      "server-items-length": _vm.tableItems.length,
                      "show-select": false,
                      "fixed-header": "",
                      height:
                        _vm.tableItems && _vm.tableItems.length > 8
                          ? "550px"
                          : "auto",
                      loading: _vm.tableLoading
                    },
                    on: {
                      "update:options": function($event) {
                        _vm.options = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.value",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "div",
                                {
                                  style:
                                    item.type.toUpperCase() ===
                                      "High Temperature".toUpperCase() ||
                                    item.type.toUpperCase() ===
                                      "High Humidity".toUpperCase()
                                      ? "color:#FF8097"
                                      : "color: #6DA3F8"
                                },
                                [
                                  item.type.toUpperCase() ===
                                    "High Temperature".toUpperCase() ||
                                  item.type.toUpperCase() ===
                                    "Low Temperature".toUpperCase()
                                    ? _c("div", [
                                        _vm.toggleState
                                          ? _c("p", [
                                              _vm._v(_vm._s(item.value) + " "),
                                              _c("span", [_vm._v("°")]),
                                              _vm._v("C")
                                            ])
                                          : _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    (
                                                      (item.value * 9) / 5 +
                                                      32
                                                    ).toFixed(2)
                                                  ) +
                                                  " "
                                              ),
                                              _c("span", [_vm._v("°")]),
                                              _vm._v("F ")
                                            ])
                                      ])
                                    : _c("div", [
                                        _c("p", [
                                          _vm._v(_vm._s(item.value) + "%")
                                        ])
                                      ])
                                ]
                              )
                            ]
                          }
                        },
                        {
                          key: "item.type",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    style:
                                      item.type.toUpperCase() ===
                                        "High Temperature".toUpperCase() ||
                                      item.type.toUpperCase() ===
                                        "High Humidity".toUpperCase()
                                        ? "color:#FF8097"
                                        : "color: #6DA3F8"
                                  },
                                  [_c("p", [_vm._v(_vm._s(item.type))])]
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "item.battery",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "p",
                                {
                                  style:
                                    item.battery.toUpperCase() ===
                                    "Good".toUpperCase()
                                      ? "color:#51b0ff"
                                      : "color: #ffa99e"
                                },
                                [_vm._v(" " + _vm._s(item.battery) + " ")]
                              )
                            ]
                          }
                        },
                        {
                          key: "item.status",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-flex justify-center align-center border-status",
                                  style:
                                    item.status === "Low"
                                      ? "border-color: orangered"
                                      : "border-color: aquamarine"
                                },
                                [
                                  _c("p", {
                                    staticClass: "circle mr-1",
                                    style:
                                      item.status === "Low"
                                        ? "background-color: orangered"
                                        : "background-color: aquamarine"
                                  }),
                                  _c("span", [_vm._v(_vm._s(item.status))])
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("template", { slot: "loading" }, [
                      _c("div", { staticClass: "wrapper-loading" }, [
                        _c("div", { staticClass: "loader" })
                      ])
                    ]),
                    !_vm.tableLoading
                      ? _c(
                          "template",
                          { slot: "no-data" },
                          [
                            _c(
                              "v-alert",
                              {
                                staticClass: "ma-0",
                                staticStyle: { "line-height": "19px" },
                                attrs: { dense: "", color: "#697A8A" }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { color: "#fff" }
                                  },
                                  [_vm._v("mdi-alert-outline")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("No data available")) +
                                    " "
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            )
          ]
        ),
        _c("div", { staticClass: "table-options" }, [
          _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn mr-3",
                  attrs: {
                    text: "",
                    icon: "",
                    disabled: _vm.tableItems.length === 0
                  },
                  on: {
                    click: function($event) {
                      return _vm.exportHistory()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Export")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeModal()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Close")))]
              )
            ],
            1
          ),
          _vm.tableItems.length > 0
            ? _c("div", [
                _c("div", { staticClass: "pageInfo" }, [
                  _vm._v(_vm._s(_vm.pageInfoText))
                ]),
                _c(
                  "div",
                  { staticClass: "pagination2" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "pageInfo mr-1",
                        staticStyle: { position: "relative !important" }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Show")) + " ")]
                    ),
                    _c("v-select", {
                      staticClass: "form-input pt-0 col rowPerPage",
                      attrs: {
                        items: _vm.rowPerPageList,
                        placeholder: _vm.$t("Limit"),
                        dense: "",
                        "persistent-hint": "",
                        outlined: "",
                        "hide-details": ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.paging(1)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s("" + item) +
                                    " " +
                                    _vm._s(_vm.$t("Rows")) +
                                    " "
                                )
                              ]
                            }
                          },
                          {
                            key: "selection",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "span",
                                  { staticClass: "selection-v-select" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s("" + item) +
                                        _vm._s(_vm.$t("Rows"))
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        278647455
                      ),
                      model: {
                        value: _vm.rowPerPage,
                        callback: function($$v) {
                          _vm.rowPerPage = $$v
                        },
                        expression: "rowPerPage"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "mr-2" },
                      [
                        _c("v-text-field", {
                          staticClass: "form-input pt-0 col goToPage ml-1",
                          attrs: {
                            placeholder: "Page",
                            outlined: "",
                            dense: "",
                            "hide-details": "",
                            solo: "",
                            "append-icon": "mdi-chevron-right"
                          },
                          on: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.goToPage(
                                _vm.goToPageInput,
                                _vm.paging,
                                _vm.totalPages
                              )
                            },
                            keypress: function($event) {
                              return _vm.checkIsNumber($event)
                            },
                            "click:append": function($event) {
                              return _vm.goToPage(
                                _vm.goToPageInput,
                                _vm.paging,
                                _vm.totalPages
                              )
                            }
                          },
                          model: {
                            value: _vm.goToPageInput,
                            callback: function($$v) {
                              _vm.goToPageInput = $$v
                            },
                            expression: "goToPageInput"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "pagination-nav pagination-nav-prev",
                        class: _vm.page < 10 ? "pagination-nav-disabled" : null,
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.goTenPageBackwards(_vm.page, _vm.paging)
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets//img/ico-paging-prev.png"),
                            alt: "이전 10페이지 이동"
                          }
                        })
                      ]
                    ),
                    _c("v-pagination", {
                      staticStyle: { display: "block" },
                      attrs: {
                        length: _vm.totalPages * 1 || 1,
                        "total-visible": 8,
                        color: "#2f3b4c"
                      },
                      on: { input: _vm.paging },
                      model: {
                        value: _vm.pageIndex,
                        callback: function($$v) {
                          _vm.pageIndex = $$v
                        },
                        expression: "pageIndex"
                      }
                    }),
                    _c(
                      "button",
                      {
                        staticClass: "pagination-nav pagination-nav-next",
                        class:
                          _vm.pageIndex + 10 > _vm.totalPages
                            ? "pagination-nav-disabled"
                            : null,
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.goTenPageForward(
                              _vm.pageIndex,
                              _vm.paging,
                              _vm.totalPages
                            )
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets//img/ico-paging-next.png"),
                            alt: "다음 10페이지 이동"
                          }
                        })
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c("a", { ref: "link", style: { display: "none" } })
        ])
      ]),
      _c(
        "v-dialog",
        {
          attrs: { width: "450", persistent: "" },
          model: {
            value: _vm.confirmDownload,
            callback: function($$v) {
              _vm.confirmDownload = $$v
            },
            expression: "confirmDownload"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "popup popup-success add_store_popup",
              attrs: { align: "center", justify: "center" }
            },
            [
              _c("i", { staticClass: "ico-success" }, [
                _c("img", {
                  attrs: { src: require("@/assets/img/ico-info.png"), alt: "" }
                })
              ]),
              _c("div", { staticClass: "msg" }, [
                _c(
                  "h4",
                  { staticClass: "mb-0", staticStyle: { "font-size": "22px" } },
                  [_vm._v(_vm._s(_vm.$t("Export Labels")))]
                )
              ]),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("span", {
                      staticStyle: { "font-size": "15px" },
                      domProps: {
                        innerHTML: _vm._s(
                          "Label list will be delivered in email.<br/>Report can take upto 30 minutes.<br/>Please enter email address."
                        )
                      }
                    })
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        staticClass: "form-input ml-3 col mandatory",
                        attrs: {
                          label: _vm.$t("Email Id") + " *",
                          placeholder: _vm.$t("Enter your email id"),
                          outlined: "",
                          dense: "",
                          "hide-details": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.emailId,
                          callback: function($$v) {
                            _vm.emailId = $$v
                          },
                          expression: "emailId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: { click: _vm.downloadAlertHistory }
                    },
                    [_vm._v(_vm._s(_vm.$t("Send")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn",
                      attrs: { text: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmDownload = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }