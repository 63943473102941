<template>
  <v-dialog v-model="notiDialogPopup" width="1000" persistent>
    <v-card class="popup add_store_popup">
      <div>
        <v-btn icon dark x-small class="cornerClose" @click="closeModal">
          <v-icon dark>mdi-close</v-icon>
        </v-btn>
        <div class="popup-titbox d-flex align-center justify-space-between">
          <h3 class="page-title-bar align-start">
            <div>
              <img
                src="@/assets/img/bell-ring.png"
                style="margin-right: 15px; height: 31px;"
              />{{ $t("Notification Received By") }}
            </div>
          </h3>
          <div class="d-grid mt-2">
            <p class="info-text">
              <b>{{ $t("Sensor ID") }} :</b> {{ alertIdData.sensorCode ? alertIdData.sensorCode : '-' }}
            </p>
            <p class="info-text">
              <b>{{ $t("Sensor Name") }} :</b> {{ alertIdData.sensorName ? alertIdData.sensorName : '-' }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="fixedHeader"
        style="max-height: calc(100vh - 330px) !important;"
      >
        <div style="width: 100%;">
          <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
            item-key="serialNo"
            :hide-default-footer="true"
            :options.sync="options"
            :items-per-page="rowPerPage"
            :server-items-length="tableItems.length"
            :show-select="false"
            must-sort
            :height="tableItems && tableItems.length > 8 ? '370px' : 'auto'"
            class="tbl-type01 mt-5 row-class"
            fixed-header
          >
            <template slot="no-data">
              <v-alert
                dense
                color="#697A8A"
                class="ma-0"
                style="line-height: 19px"
              >
                <v-icon class="mr-1" color="#fff">mdi-alert-outline</v-icon>
                {{ $t("No data available") }}
              </v-alert>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div>
                <p
                  :style="
                    item.status === true
                      ? 'color:#21D600'
                      : 'color:#EF726B'
                  "
                >
                  {{ item.status === true ? 'Success' : 'Failed' }}
                </p>
              </div>
            </template>
            <template v-slot:[`item.email`]="{ item }">
              {{ item.email ? item.email : '-' }}
            </template>
            <template v-slot:[`item.isClear`]="{ item }">
  <span :class="item.isClear ? 'green-circle' : 'red-circle'"></span>
</template>
            <template v-slot:[`item.insertTime`]="{ item }">
              {{ item.insertTime ? item.insertTime : '-' }}
            </template>
          </v-data-table>
        </div>
      </div>
      <div class="table-options">
        <div>
          <v-btn text icon @click="closeModal()" class="btn">{{
            $t("Close")
          }}</v-btn>
        </div>
        <div>
          <div class="pageInfo">{{ pageInfoText }}</div>
          <div class="pagination2">
            <label class="pageInfo mr-1" style="position:relative !important;">
              {{ $t("Show") }}
            </label>
            <v-select
              v-model="rowPerPage"
              :items="rowPerPageList"
              :placeholder="$t('Limit')"
              class="form-input pt-0 col rowPerPage"
              dense
              persistent-hint
              outlined
              hide-details
              @change="paging(1)"
            >
              <template v-slot:item="{ item }">
                {{ `${item}` }}{{$t('Rows')}}
              </template>
              <template v-slot:selection="{ item }">
                <span class="selection-v-select"> {{ `${item}` }}{{$t('Rows')}}</span>
              </template>
            </v-select>

            <div class="mr-2">
              <v-text-field
                v-model="goToPageInput"
                class="form-input pt-0 col goToPage ml-1"
                placeholder="Page"
                outlined
                dense
                hide-details
                solo
                @keyup.enter="goToPage(goToPageInput, paging, totalPages)"
                append-icon="mdi-chevron-right"
                @keypress="checkIsNumber($event)"
                @click:append="goToPage(goToPageInput, paging, totalPages)"
              ></v-text-field>
            </div>
            <button
              @click="goTenPageBackwards(page, paging)"
              :class="page < 10 ? 'pagination-nav-disabled' : null"
              class="pagination-nav pagination-nav-prev"
              type="button"
            >
              <img
                src="@/assets//img/ico-paging-prev.png"
                alt="이전 10페이지 이동"
              />
            </button>
            <v-pagination
              v-model="page"
              @input="paging"
              :length="totalPages * 1 || 1"
              :total-visible="8"
              color="#2f3b4c"
              style="display: block"
            ></v-pagination>
            <button
              @click="goTenPageForward(page, paging, totalPages)"
              :class="
                page + 10 > totalPages ? 'pagination-nav-disabled' : null
              "
              class="pagination-nav pagination-nav-next"
              type="button"
            >
              <img
                src="@/assets//img/ico-paging-next.png"
                alt="다음 10페이지 이동"
              />
            </button>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import EventBus from '@/plugins/eventBus'
import commons from '@/plugins/commons'
import codes from '@/plugins/codes'
import { mapGetters } from 'vuex'
import Pagenation from '@/mixins/Pagenation'
import moment from 'moment'

export default {
  name: 'AlarmNotification',
  mixins: [Pagenation],
  props: {
    notiDialogPopup: {
      type: Boolean,
      default: false
    },
    alertIdData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      rowPerPage: 10,
      page: 1,
      totalPages: 0,
      // pageIndex: 1,
      pageInfoText: '1 to 1, 1 in total',
      goToPageInput: 1,
      options: {
        sortBy: ['insertTime'],
        sortDesc: [true],
        itemsPerPage: 10
      },
      tableItems: [],
      isMounted: false
    }
  },
  computed: {
    rowPerPageList () {
      return [5, 10, 20, 50, 100]
    },
    ...mapGetters({
      store: 'dataStore/GET_SELECTED_STORE'
    }),
    tableHeaders () {
      return [
        {
          text: this.$t('#'),
          sortable: false,
          value: 'serialNo'
        },
        {
          text: this.$t('SENT TO'),
          sortable: false,
          value: 'email'
        },
        {
          text: this.$t('EMAIL_TYPE'),
          sortable: false,
          value: 'isClear'
        },
        {
          text: this.$t('STATUS'),
          sortable: false,
          value: 'status'
        },

        {
          text: this.$t('DELIVERY TIME'),
          sortable: true,
          value: 'insertTime'
        }
      ]
    }
  },
  watch: {
    options: {
      handler () {
        if (!this.isMounted) return
        this.getAlertHistoryList(this.page)
      },
      deep: true
    },
    page: {
      handler (page) {
        this.goToPageInput = page
      }
    }
  },
  mounted () {
    console.log(this.alertIdData)
    this.getAlertHistoryList(1)
  },
  methods: {
    goToPage (goToPageInput, paging, totalPages) {
      if (totalPages < goToPageInput) {
      } else {
        paging(Number(goToPageInput))
      }
    },
    closeModal () {
      this.$emit('closeNotiModal')
    },
    checkIsNumber: commons.isNumber,
    paging (page) {
      this.page = Number(page)
      this.goToPageInput = page
      this.isMounted = false
      this.getAlertHistoryList(page)
    },
    getPageInfoText (headers, tabelItems) {
      return (
        headers['x-number'] * 1 * headers['x-size'] +
        1 +
        ' to ' +
        (headers['x-number'] * 1 * headers['x-size'] + tabelItems.length) +
        ', ' +
        headers['x-totalelements'] * 1 +
        ' in total'
      )
    },
    handleGetPrResponse (res) {
      const headers = res.headers
      this.tableItems = res.data.notificationHistory.map((obj, idx) => {
        return {
          serialNo: headers['x-number'] * 1 * headers['x-size'] + idx + 1,
          ...obj,
          insertTime: moment(obj.insertTime).format('YYYY-MM-DD HH:mm:ss')
        }
      })
      this.pageInfoText = commons.getPageInfoText(res.headers)
      if (commons.isValidStr(res.headers['x-totalpages'])) {
        this.totalPages = res.headers['x-totalpages'] * 1
      }
      this.selected = []
    },
    handleGetPrError (error) {
      this.tableItems = []
      const headers = {}
      headers['x-totalelements'] = 0
      this.pageInfoText = commons.getPageInfoText(headers)
      this.totalPages = 0
      this.selected = []
      EventBus.$emit(
        'messageAlert',
        this.$t(error.response.data.responseMessage),
        'error'
      )
      console.debug(`Could not find any power rail. ${error}`)
    },
    getSortKey (sortKey) {
      switch (sortKey) {
        case 'insertTime':
          sortKey = 'insertTime'
          break
        default:
          break
      }
      return sortKey
    },
    buildParams () {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      this.options.itemsPerPage = Number(this.rowPerPage)
      const params = { store: this.store.code }
      let sort = null
      if (commons.isSorting(sortBy, sortDesc)) {
        let sortKey = this.getSortKey(sortBy[0])
        const sortOrder = sortDesc[0]
        if (sortKey === 'insertTime') sortKey = 'insertTime'
        sort = `${sortKey},${sortOrder ? 'desc' : 'asc'}`
      }
      if (sort !== null) params.sort = sort
      if (itemsPerPage > 0) params.size = this.rowPerPage
      params.company = this.$store.state.auth.user.company
      params.store = this.store.code
      params.alertId = this.alertIdData.id
      return params
    },
    getAlertHistoryList (page) {
      const params = this.buildParams()
      if (page !== null) params.page = page - 1
      const config = { params }
      this.$utils
        .callAxios(
          codes.requests.getAlertNotiHistorySummary.method,
          codes.requests.getAlertNotiHistorySummary.url,
          config
        )
        .then(res => {
          this.isMounted = true
          if (res.data && res.status === 200) {
            this.handleGetPrResponse(res)
          } else {
            this.tableItems = []
            this.totalPages = 0
            this.selected = []
            this.pageInfoText = ''
          }
        })
        .catch(error => {
          this.isMounted = true
          this.handleGetPrError(error)
          console.log(error)
        })
    }
  }
}
</script>
<style scoped>
.green-circle {
  color: green;
    font-size: 12px;
    border: 1px solid;
    border-radius: 10px;
    padding: 4px;
    padding-right: 16px;
    padding-left: 16px;
    background: azure;
}

.red-circle {
  color: red;
    font-size: 12px;
    border: 1px solid;
    border-radius: 10px;
    padding: 4px;
    padding-right: 16px;
    padding-left: 16px;
    background: lavenderblush;
}

/* Alternatively, you could use these classes with background color */
.green-circle::before, .red-circle::before {
  content: 'Clear Mail';
}
.red-circle::before{
  content: 'Alert Mail';
}
</style>
